.glyphs.character-mapping li {
  margin: 0 30px 20px 0;
  display: inline-block;
  width: 90px;
  text-align: center;
  font-size: 24px;
  color: #2b2b2b;
}
.linea-icon {
  position: relative;
}
.linea-icon svg {
  fill: #000;
}
.glyphs.character-mapping input {
  margin: 0;
  padding: 5px 0;
  line-height: 12px;
  font-size: 12px;
  display: block;
  width: 100%;
  border: 1px solid #eee;
  text-align: center;
  outline: 0;
}
.glyphs.character-mapping input:focus,
.glyphs.character-mapping input:hover {
  border: 1px solid #2f2c2c;
}

@font-face {
  font-family: 'linea-arrows-10';
  src: url('../../fonts/linea-icons/linea-arrows-10.eot');
  src: url('../../fonts/linea-icons/linea-arrows-10d41d.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/linea-icons/linea-arrows-10.woff') format('woff'),
    url('../../fonts/linea-icons/linea-arrows-10.ttf') format('truetype'),
    url('../../fonts/linea-icons/linea-arrows-10.svg#linea-arrows-10') format('svg');
  font-weight: normal;
  font-style: normal;
}
.linea-aerrow[data-icon]:before {
  font-family: 'linea-arrows-10' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^='linea-icon-']:before,
[class*='linea- icon-']:before {
  font-family: 'linea-arrows-10' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrows-anticlockwise:before {
  content: '\e000';
}
.icon-arrows-anticlockwise-dashed:before {
  content: '\e001';
}
.icon-arrows-button-down:before {
  content: '\e002';
}
.icon-arrows-button-off:before {
  content: '\e003';
}
.icon-arrows-button-on:before {
  content: '\e004';
}
.icon-arrows-button-up:before {
  content: '\e005';
}
.icon-arrows-check:before {
  content: '\e006';
}
.icon-arrows-circle-check:before {
  content: '\e007';
}
.icon-arrows-circle-down:before {
  content: '\e008';
}
.icon-arrows-circle-downleft:before {
  content: '\e009';
}
.icon-arrows-circle-downright:before {
  content: '\e00a';
}
.icon-arrows-circle-left:before {
  content: '\e00b';
}
.icon-arrows-circle-minus:before {
  content: '\e00c';
}
.icon-arrows-circle-plus:before {
  content: '\e00d';
}
.icon-arrows-circle-remove:before {
  content: '\e00e';
}
.icon-arrows-circle-right:before {
  content: '\e00f';
}
.icon-arrows-circle-up:before {
  content: '\e010';
}
.icon-arrows-circle-upleft:before {
  content: '\e011';
}
.icon-arrows-circle-upright:before {
  content: '\e012';
}
.icon-arrows-clockwise:before {
  content: '\e013';
}
.icon-arrows-clockwise-dashed:before {
  content: '\e014';
}
.icon-arrows-compress:before {
  content: '\e015';
}
.icon-arrows-deny:before {
  content: '\e016';
}
.icon-arrows-diagonal:before {
  content: '\e017';
}
.icon-arrows-diagonal2:before {
  content: '\e018';
}
.icon-arrows-down:before {
  content: '\e019';
}
.icon-arrows-down-double:before {
  content: '\e01a';
}
.icon-arrows-downleft:before {
  content: '\e01b';
}
.icon-arrows-downright:before {
  content: '\e01c';
}
.icon-arrows-drag-down:before {
  content: '\e01d';
}
.icon-arrows-drag-down-dashed:before {
  content: '\e01e';
}
.icon-arrows-drag-horiz:before {
  content: '\e01f';
}
.icon-arrows-drag-left:before {
  content: '\e020';
}
.icon-arrows-drag-left-dashed:before {
  content: '\e021';
}
.icon-arrows-drag-right:before {
  content: '\e022';
}
.icon-arrows-drag-right-dashed:before {
  content: '\e023';
}
.icon-arrows-drag-up:before {
  content: '\e024';
}
.icon-arrows-drag-up-dashed:before {
  content: '\e025';
}
.icon-arrows-drag-vert:before {
  content: '\e026';
}
.icon-arrows-exclamation:before {
  content: '\e027';
}
.icon-arrows-expand:before {
  content: '\e028';
}
.icon-arrows-expand-diagonal1:before {
  content: '\e029';
}
.icon-arrows-expand-horizontal1:before {
  content: '\e02a';
}
.icon-arrows-expand-vertical1:before {
  content: '\e02b';
}
.icon-arrows-fit-horizontal:before {
  content: '\e02c';
}
.icon-arrows-fit-vertical:before {
  content: '\e02d';
}
.icon-arrows-glide:before {
  content: '\e02e';
}
.icon-arrows-glide-horizontal:before {
  content: '\e02f';
}
.icon-arrows-glide-vertical:before {
  content: '\e030';
}
.icon-arrows-hamburger1:before {
  content: '\e031';
}
.icon-arrows-hamburger-2:before {
  content: '\e032';
}
.icon-arrows-horizontal:before {
  content: '\e033';
}
.icon-arrows-info:before {
  content: '\e034';
}
.icon-arrows-keyboard-alt:before {
  content: '\e035';
}
.icon-arrows-keyboard-cmd:before {
  content: '\e036';
}
.icon-arrows-keyboard-delete:before {
  content: '\e037';
}
.icon-arrows-keyboard-down:before {
  content: '\e038';
}
.icon-arrows-keyboard-left:before {
  content: '\e039';
}
.icon-arrows-keyboard-return:before {
  content: '\e03a';
}
.icon-arrows-keyboard-right:before {
  content: '\e03b';
}
.icon-arrows-keyboard-shift:before {
  content: '\e03c';
}
.icon-arrows-keyboard-tab:before {
  content: '\e03d';
}
.icon-arrows-keyboard-up:before {
  content: '\e03e';
}
.icon-arrows-left:before {
  content: '\e03f';
}
.icon-arrows-left-double-32:before {
  content: '\e040';
}
.icon-arrows-minus:before {
  content: '\e041';
}
.icon-arrows-move:before {
  content: '\e042';
}
.icon-arrows-move2:before {
  content: '\e043';
}
.icon-arrows-move-bottom:before {
  content: '\e044';
}
.icon-arrows-move-left:before {
  content: '\e045';
}
.icon-arrows-move-right:before {
  content: '\e046';
}
.icon-arrows-move-top:before {
  content: '\e047';
}
.icon-arrows-plus:before {
  content: '\e048';
}
.icon-arrows-question:before {
  content: '\e049';
}
.icon-arrows-remove:before {
  content: '\e04a';
}
.icon-arrows-right:before {
  content: '\e04b';
}
.icon-arrows-right-double:before {
  content: '\e04c';
}
.icon-arrows-rotate:before {
  content: '\e04d';
}
.icon-arrows-rotate-anti:before {
  content: '\e04e';
}
.icon-arrows-rotate-anti-dashed:before {
  content: '\e04f';
}
.icon-arrows-rotate-dashed:before {
  content: '\e050';
}
.icon-arrows-shrink:before {
  content: '\e051';
}
.icon-arrows-shrink-diagonal1:before {
  content: '\e052';
}
.icon-arrows-shrink-diagonal2:before {
  content: '\e053';
}
.icon-arrows-shrink-horizonal2:before {
  content: '\e054';
}
.icon-arrows-shrink-horizontal1:before {
  content: '\e055';
}
.icon-arrows-shrink-vertical1:before {
  content: '\e056';
}
.icon-arrows-shrink-vertical2:before {
  content: '\e057';
}
.icon-arrows-sign-down:before {
  content: '\e058';
}
.icon-arrows-sign-left:before {
  content: '\e059';
}
.icon-arrows-sign-right:before {
  content: '\e05a';
}
.icon-arrows-sign-up:before {
  content: '\e05b';
}
.icon-arrows-slide-down1:before {
  content: '\e05c';
}
.icon-arrows-slide-down2:before {
  content: '\e05d';
}
.icon-arrows-slide-left1:before {
  content: '\e05e';
}
.icon-arrows-slide-left2:before {
  content: '\e05f';
}
.icon-arrows-slide-right1:before {
  content: '\e060';
}
.icon-arrows-slide-right2:before {
  content: '\e061';
}
.icon-arrows-slide-up1:before {
  content: '\e062';
}
.icon-arrows-slide-up2:before {
  content: '\e063';
}
.icon-arrows-slim-down:before {
  content: '\e064';
}
.icon-arrows-slim-down-dashed:before {
  content: '\e065';
}
.icon-arrows-slim-left:before {
  content: '\e066';
}
.icon-arrows-slim-left-dashed:before {
  content: '\e067';
}
.icon-arrows-slim-right:before {
  content: '\e068';
}
.icon-arrows-slim-right-dashed:before {
  content: '\e069';
}
.icon-arrows-slim-up:before {
  content: '\e06a';
}
.icon-arrows-slim-up-dashed:before {
  content: '\e06b';
}
.icon-arrows-square-check:before {
  content: '\e06c';
}
.icon-arrows-square-down:before {
  content: '\e06d';
}
.icon-arrows-square-downleft:before {
  content: '\e06e';
}
.icon-arrows-square-downright:before {
  content: '\e06f';
}
.icon-arrows-square-left:before {
  content: '\e070';
}
.icon-arrows-square-minus:before {
  content: '\e071';
}
.icon-arrows-square-plus:before {
  content: '\e072';
}
.icon-arrows-square-remove:before {
  content: '\e073';
}
.icon-arrows-square-right:before {
  content: '\e074';
}
.icon-arrows-square-up:before {
  content: '\e075';
}
.icon-arrows-square-upleft:before {
  content: '\e076';
}
.icon-arrows-square-upright:before {
  content: '\e077';
}
.icon-arrows-squares:before {
  content: '\e078';
}
.icon-arrows-stretch-diagonal1:before {
  content: '\e079';
}
.icon-arrows-stretch-diagonal2:before {
  content: '\e07a';
}
.icon-arrows-stretch-diagonal3:before {
  content: '\e07b';
}
.icon-arrows-stretch-diagonal4:before {
  content: '\e07c';
}
.icon-arrows-stretch-horizontal1:before {
  content: '\e07d';
}
.icon-arrows-stretch-horizontal2:before {
  content: '\e07e';
}
.icon-arrows-stretch-vertical1:before {
  content: '\e07f';
}
.icon-arrows-stretch-vertical2:before {
  content: '\e080';
}
.icon-arrows-switch-horizontal:before {
  content: '\e081';
}
.icon-arrows-switch-vertical:before {
  content: '\e082';
}
.icon-arrows-up:before {
  content: '\e083';
}
.icon-arrows-up-double-33:before {
  content: '\e084';
}
.icon-arrows-upleft:before {
  content: '\e085';
}
.icon-arrows-upright:before {
  content: '\e086';
}
.icon-arrows-vertical:before {
  content: '\e087';
}
/*Basic icon*/
@font-face {
  font-family: 'linea-basic-10';
  src: url('../../fonts/linea-icons/linea-basic-10.eot');
  src: url('../../fonts/linea-icons/linea-basic-10d41d.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/linea-icons/linea-basic-10.woff') format('woff'),
    url('../../fonts/linea-icons/linea-basic-10.ttf') format('truetype'),
    url('../../fonts/linea-icons/linea-basic-10.svg#linea-basic-10') format('svg');
  font-weight: normal;
  font-style: normal;
}
.linea-basic[data-icon]:before {
  font-family: 'linea-basic-10' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^='linea-icon-']:before,
[class*='linea- icon-']:before {
  font-family: 'linea-basic-10' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-basic-accelerator:before {
  content: 'a';
}
.icon-basic-alarm:before {
  content: 'b';
}
.icon-basic-anchor:before {
  content: 'c';
}
.icon-basic-anticlockwise:before {
  content: 'd';
}
.icon-basic-archive:before {
  content: 'e';
}
.icon-basic-archive-full:before {
  content: 'f';
}
.icon-basic-ban:before {
  content: 'g';
}
.icon-basic-battery-charge:before {
  content: 'h';
}
.icon-basic-battery-empty:before {
  content: 'i';
}
.icon-basic-battery-full:before {
  content: 'j';
}
.icon-basic-battery-half:before {
  content: 'k';
}
.icon-basic-bolt:before {
  content: 'l';
}
.icon-basic-book:before {
  content: 'm';
}
.icon-basic-book-pen:before {
  content: 'n';
}
.icon-basic-book-pencil:before {
  content: 'o';
}
.icon-basic-bookmark:before {
  content: 'p';
}
.icon-basic-calculator:before {
  content: 'q';
}
.icon-basic-calendar:before {
  content: 'r';
}
.icon-basic-cards-diamonds:before {
  content: 's';
}
.icon-basic-cards-hearts:before {
  content: 't';
}
.icon-basic-case:before {
  content: 'u';
}
.icon-basic-chronometer:before {
  content: 'v';
}
.icon-basic-clessidre:before {
  content: 'w';
}
.icon-basic-clock:before {
  content: 'x';
}
.icon-basic-clockwise:before {
  content: 'y';
}
.icon-basic-cloud:before {
  content: 'z';
}
.icon-basic-clubs:before {
  content: 'A';
}
.icon-basic-compass:before {
  content: 'B';
}
.icon-basic-cup:before {
  content: 'C';
}
.icon-basic-diamonds:before {
  content: 'D';
}
.icon-basic-display:before {
  content: 'E';
}
.icon-basic-download:before {
  content: 'F';
}
.icon-basic-exclamation:before {
  content: 'G';
}
.icon-basic-eye:before {
  content: 'H';
}
.icon-basic-eye-closed:before {
  content: 'I';
}
.icon-basic-female:before {
  content: 'J';
}
.icon-basic-flag1:before {
  content: 'K';
}
.icon-basic-flag2:before {
  content: 'L';
}
.icon-basic-floppydisk:before {
  content: 'M';
}
.icon-basic-folder:before {
  content: 'N';
}
.icon-basic-folder-multiple:before {
  content: 'O';
}
.icon-basic-gear:before {
  content: 'P';
}
.icon-basic-geolocalize-01:before {
  content: 'Q';
}
.icon-basic-geolocalize-05:before {
  content: 'R';
}
.icon-basic-globe:before {
  content: 'S';
}
.icon-basic-gunsight:before {
  content: 'T';
}
.icon-basic-hammer:before {
  content: 'U';
}
.icon-basic-headset:before {
  content: 'V';
}
.icon-basic-heart:before {
  content: 'W';
}
.icon-basic-heart-broken:before {
  content: 'X';
}
.icon-basic-helm:before {
  content: 'Y';
}
.icon-basic-home:before {
  content: 'Z';
}
.icon-basic-info:before {
  content: '0';
}
.icon-basic-ipod:before {
  content: '1';
}
.icon-basic-joypad:before {
  content: '2';
}
.icon-basic-key:before {
  content: '3';
}
.icon-basic-keyboard:before {
  content: '4';
}
.icon-basic-laptop:before {
  content: '5';
}
.icon-basic-life-buoy:before {
  content: '6';
}
.icon-basic-lightbulb:before {
  content: '7';
}
.icon-basic-link:before {
  content: '8';
}
.icon-basic-lock:before {
  content: '9';
}
.icon-basic-lock-open:before {
  content: '!';
}
.icon-basic-magic-mouse:before {
  content: '"';
}
.icon-basic-magnifier:before {
  content: '#';
}
.icon-basic-magnifier-minus:before {
  content: '$';
}
.icon-basic-magnifier-plus:before {
  content: '%';
}
.icon-basic-mail:before {
  content: '&';
}
.icon-basic-mail-multiple:before {
  content: "'";
}
.icon-basic-mail-open:before {
  content: '(';
}
.icon-basic-mail-open-text:before {
  content: ')';
}
.icon-basic-male:before {
  content: '*';
}
.icon-basic-map:before {
  content: '+';
}
.icon-basic-message:before {
  content: ',';
}
.icon-basic-message-multiple:before {
  content: '-';
}
.icon-basic-message-txt:before {
  content: '.';
}
.icon-basic-mixer2:before {
  content: '/';
}
.icon-basic-mouse:before {
  content: ':';
}
.icon-basic-notebook:before {
  content: ';';
}
.icon-basic-notebook-pen:before {
  content: '<';
}
.icon-basic-notebook-pencil:before {
  content: '=';
}
.icon-basic-paperplane:before {
  content: '>';
}
.icon-basic-pencil-ruler:before {
  content: '?';
}
.icon-basic-pencil-ruler-pen:before {
  content: '@';
}
.icon-basic-photo:before {
  content: '[';
}
.icon-basic-picture:before {
  content: ']';
}
.icon-basic-picture-multiple:before {
  content: '^';
}
.icon-basic-pin1:before {
  content: '_';
}
.icon-basic-pin2:before {
  content: '`';
}
.icon-basic-postcard:before {
  content: '{';
}
.icon-basic-postcard-multiple:before {
  content: '|';
}
.icon-basic-printer:before {
  content: '}';
}
.icon-basic-question:before {
  content: '~';
}
.icon-basic-rss:before {
  content: '\\';
}
.icon-basic-server:before {
  content: '\e000';
}
.icon-basic-server2:before {
  content: '\e001';
}
.icon-basic-server-cloud:before {
  content: '\e002';
}
.icon-basic-server-download:before {
  content: '\e003';
}
.icon-basic-server-upload:before {
  content: '\e004';
}
.icon-basic-settings:before {
  content: '\e005';
}
.icon-basic-share:before {
  content: '\e006';
}
.icon-basic-sheet:before {
  content: '\e007';
}
.icon-basic-sheet-multiple:before {
  content: '\e008';
}
.icon-basic-sheet-pen:before {
  content: '\e009';
}
.icon-basic-sheet-pencil:before {
  content: '\e00a';
}
.icon-basic-sheet-txt:before {
  content: '\e00b';
}
.icon-basic-signs:before {
  content: '\e00c';
}
.icon-basic-smartphone:before {
  content: '\e00d';
}
.icon-basic-spades:before {
  content: '\e00e';
}
.icon-basic-spread:before {
  content: '\e00f';
}
.icon-basic-spread-bookmark:before {
  content: '\e010';
}
.icon-basic-spread-text:before {
  content: '\e011';
}
.icon-basic-spread-text-bookmark:before {
  content: '\e012';
}
.icon-basic-star:before {
  content: '\e013';
}
.icon-basic-tablet:before {
  content: '\e014';
}
.icon-basic-target:before {
  content: '\e015';
}
.icon-basic-todo:before {
  content: '\e016';
}
.icon-basic-todo-pen:before {
  content: '\e017';
}
.icon-basic-todo-pencil:before {
  content: '\e018';
}
.icon-basic-todo-txt:before {
  content: '\e019';
}
.icon-basic-todolist-pen:before {
  content: '\e01a';
}
.icon-basic-todolist-pencil:before {
  content: '\e01b';
}
.icon-basic-trashcan:before {
  content: '\e01c';
}
.icon-basic-trashcan-full:before {
  content: '\e01d';
}
.icon-basic-trashcan-refresh:before {
  content: '\e01e';
}
.icon-basic-trashcan-remove:before {
  content: '\e01f';
}
.icon-basic-upload:before {
  content: '\e020';
}
.icon-basic-usb:before {
  content: '\e021';
}
.icon-basic-video:before {
  content: '\e022';
}
.icon-basic-watch:before {
  content: '\e023';
}
.icon-basic-webpage:before {
  content: '\e024';
}
.icon-basic-webpage-img-txt:before {
  content: '\e025';
}
.icon-basic-webpage-multiple:before {
  content: '\e026';
}
.icon-basic-webpage-txt:before {
  content: '\e027';
}
.icon-basic-world:before {
  content: '\e028';
}
/*Basic elaboration*/
@font-face {
  font-family: 'linea-basic-elaboration-10';
  src: url('../../fonts/linea-icons/linea-basic-elaboration-10.eot');
  src: url('../../fonts/linea-icons/linea-basic-elaboration-10d41d.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/linea-icons/linea-basic-elaboration-10.woff') format('woff'),
    url('../../fonts/linea-icons/linea-basic-elaboration-10.ttf') format('truetype'),
    url('../../fonts/linea-icons/linea-basic-elaboration-10.svg#linea-basic-elaboration-10') format('svg');
  font-weight: normal;
  font-style: normal;
}
.linea-elaborate[data-icon]:before {
  font-family: 'linea-basic-elaboration-10' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^='linea-icon-']:before,
[class*='linea- icon-']:before {
  font-family: 'linea-basic-elaboration-10' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-basic-elaboration-bookmark-checck:before {
  content: 'a';
}
.icon-basic-elaboration-bookmark-minus:before {
  content: 'b';
}
.icon-basic-elaboration-bookmark-plus:before {
  content: 'c';
}
.icon-basic-elaboration-bookmark-remove:before {
  content: 'd';
}
.icon-basic-elaboration-briefcase-check:before {
  content: 'e';
}
.icon-basic-elaboration-briefcase-download:before {
  content: 'f';
}
.icon-basic-elaboration-briefcase-flagged:before {
  content: 'g';
}
.icon-basic-elaboration-briefcase-minus:before {
  content: 'h';
}
.icon-basic-elaboration-briefcase-plus:before {
  content: 'i';
}
.icon-basic-elaboration-briefcase-refresh:before {
  content: 'j';
}
.icon-basic-elaboration-briefcase-remove:before {
  content: 'k';
}
.icon-basic-elaboration-briefcase-search:before {
  content: 'l';
}
.icon-basic-elaboration-briefcase-star:before {
  content: 'm';
}
.icon-basic-elaboration-briefcase-upload:before {
  content: 'n';
}
.icon-basic-elaboration-browser-check:before {
  content: 'o';
}
.icon-basic-elaboration-browser-download:before {
  content: 'p';
}
.icon-basic-elaboration-browser-minus:before {
  content: 'q';
}
.icon-basic-elaboration-browser-plus:before {
  content: 'r';
}
.icon-basic-elaboration-browser-refresh:before {
  content: 's';
}
.icon-basic-elaboration-browser-remove:before {
  content: 't';
}
.icon-basic-elaboration-browser-search:before {
  content: 'u';
}
.icon-basic-elaboration-browser-star:before {
  content: 'v';
}
.icon-basic-elaboration-browser-upload:before {
  content: 'w';
}
.icon-basic-elaboration-calendar-check:before {
  content: 'x';
}
.icon-basic-elaboration-calendar-cloud:before {
  content: 'y';
}
.icon-basic-elaboration-calendar-download:before {
  content: 'z';
}
.icon-basic-elaboration-calendar-empty:before {
  content: 'A';
}
.icon-basic-elaboration-calendar-flagged:before {
  content: 'B';
}
.icon-basic-elaboration-calendar-heart:before {
  content: 'C';
}
.icon-basic-elaboration-calendar-minus:before {
  content: 'D';
}
.icon-basic-elaboration-calendar-next:before {
  content: 'E';
}
.icon-basic-elaboration-calendar-noaccess:before {
  content: 'F';
}
.icon-basic-elaboration-calendar-pencil:before {
  content: 'G';
}
.icon-basic-elaboration-calendar-plus:before {
  content: 'H';
}
.icon-basic-elaboration-calendar-previous:before {
  content: 'I';
}
.icon-basic-elaboration-calendar-refresh:before {
  content: 'J';
}
.icon-basic-elaboration-calendar-remove:before {
  content: 'K';
}
.icon-basic-elaboration-calendar-search:before {
  content: 'L';
}
.icon-basic-elaboration-calendar-star:before {
  content: 'M';
}
.icon-basic-elaboration-calendar-upload:before {
  content: 'N';
}
.icon-basic-elaboration-cloud-check:before {
  content: 'O';
}
.icon-basic-elaboration-cloud-download:before {
  content: 'P';
}
.icon-basic-elaboration-cloud-minus:before {
  content: 'Q';
}
.icon-basic-elaboration-cloud-noaccess:before {
  content: 'R';
}
.icon-basic-elaboration-cloud-plus:before {
  content: 'S';
}
.icon-basic-elaboration-cloud-refresh:before {
  content: 'T';
}
.icon-basic-elaboration-cloud-remove:before {
  content: 'U';
}
.icon-basic-elaboration-cloud-search:before {
  content: 'V';
}
.icon-basic-elaboration-cloud-upload:before {
  content: 'W';
}
.icon-basic-elaboration-document-check:before {
  content: 'X';
}
.icon-basic-elaboration-document-cloud:before {
  content: 'Y';
}
.icon-basic-elaboration-document-download:before {
  content: 'Z';
}
.icon-basic-elaboration-document-flagged:before {
  content: '0';
}
.icon-basic-elaboration-document-graph:before {
  content: '1';
}
.icon-basic-elaboration-document-heart:before {
  content: '2';
}
.icon-basic-elaboration-document-minus:before {
  content: '3';
}
.icon-basic-elaboration-document-next:before {
  content: '4';
}
.icon-basic-elaboration-document-noaccess:before {
  content: '5';
}
.icon-basic-elaboration-document-note:before {
  content: '6';
}
.icon-basic-elaboration-document-pencil:before {
  content: '7';
}
.icon-basic-elaboration-document-picture:before {
  content: '8';
}
.icon-basic-elaboration-document-plus:before {
  content: '9';
}
.icon-basic-elaboration-document-previous:before {
  content: '!';
}
.icon-basic-elaboration-document-refresh:before {
  content: '"';
}
.icon-basic-elaboration-document-remove:before {
  content: '#';
}
.icon-basic-elaboration-document-search:before {
  content: '$';
}
.icon-basic-elaboration-document-star:before {
  content: '%';
}
.icon-basic-elaboration-document-upload:before {
  content: '&';
}
.icon-basic-elaboration-folder-check:before {
  content: "'";
}
.icon-basic-elaboration-folder-cloud:before {
  content: '(';
}
.icon-basic-elaboration-folder-document:before {
  content: ')';
}
.icon-basic-elaboration-folder-download:before {
  content: '*';
}
.icon-basic-elaboration-folder-flagged:before {
  content: '+';
}
.icon-basic-elaboration-folder-graph:before {
  content: ',';
}
.icon-basic-elaboration-folder-heart:before {
  content: '-';
}
.icon-basic-elaboration-folder-minus:before {
  content: '.';
}
.icon-basic-elaboration-folder-next:before {
  content: '/';
}
.icon-basic-elaboration-folder-noaccess:before {
  content: ':';
}
.icon-basic-elaboration-folder-note:before {
  content: ';';
}
.icon-basic-elaboration-folder-pencil:before {
  content: '<';
}
.icon-basic-elaboration-folder-picture:before {
  content: '=';
}
.icon-basic-elaboration-folder-plus:before {
  content: '>';
}
.icon-basic-elaboration-folder-previous:before {
  content: '?';
}
.icon-basic-elaboration-folder-refresh:before {
  content: '@';
}
.icon-basic-elaboration-folder-remove:before {
  content: '[';
}
.icon-basic-elaboration-folder-search:before {
  content: ']';
}
.icon-basic-elaboration-folder-star:before {
  content: '^';
}
.icon-basic-elaboration-folder-upload:before {
  content: '_';
}
.icon-basic-elaboration-mail-check:before {
  content: '`';
}
.icon-basic-elaboration-mail-cloud:before {
  content: '{';
}
.icon-basic-elaboration-mail-document:before {
  content: '|';
}
.icon-basic-elaboration-mail-download:before {
  content: '}';
}
.icon-basic-elaboration-mail-flagged:before {
  content: '~';
}
.icon-basic-elaboration-mail-heart:before {
  content: '\\';
}
.icon-basic-elaboration-mail-next:before {
  content: '\e000';
}
.icon-basic-elaboration-mail-noaccess:before {
  content: '\e001';
}
.icon-basic-elaboration-mail-note:before {
  content: '\e002';
}
.icon-basic-elaboration-mail-pencil:before {
  content: '\e003';
}
.icon-basic-elaboration-mail-picture:before {
  content: '\e004';
}
.icon-basic-elaboration-mail-previous:before {
  content: '\e005';
}
.icon-basic-elaboration-mail-refresh:before {
  content: '\e006';
}
.icon-basic-elaboration-mail-remove:before {
  content: '\e007';
}
.icon-basic-elaboration-mail-search:before {
  content: '\e008';
}
.icon-basic-elaboration-mail-star:before {
  content: '\e009';
}
.icon-basic-elaboration-mail-upload:before {
  content: '\e00a';
}
.icon-basic-elaboration-message-check:before {
  content: '\e00b';
}
.icon-basic-elaboration-message-dots:before {
  content: '\e00c';
}
.icon-basic-elaboration-message-happy:before {
  content: '\e00d';
}
.icon-basic-elaboration-message-heart:before {
  content: '\e00e';
}
.icon-basic-elaboration-message-minus:before {
  content: '\e00f';
}
.icon-basic-elaboration-message-note:before {
  content: '\e010';
}
.icon-basic-elaboration-message-plus:before {
  content: '\e011';
}
.icon-basic-elaboration-message-refresh:before {
  content: '\e012';
}
.icon-basic-elaboration-message-remove:before {
  content: '\e013';
}
.icon-basic-elaboration-message-sad:before {
  content: '\e014';
}
.icon-basic-elaboration-smartphone-cloud:before {
  content: '\e015';
}
.icon-basic-elaboration-smartphone-heart:before {
  content: '\e016';
}
.icon-basic-elaboration-smartphone-noaccess:before {
  content: '\e017';
}
.icon-basic-elaboration-smartphone-note:before {
  content: '\e018';
}
.icon-basic-elaboration-smartphone-pencil:before {
  content: '\e019';
}
.icon-basic-elaboration-smartphone-picture:before {
  content: '\e01a';
}
.icon-basic-elaboration-smartphone-refresh:before {
  content: '\e01b';
}
.icon-basic-elaboration-smartphone-search:before {
  content: '\e01c';
}
.icon-basic-elaboration-tablet-cloud:before {
  content: '\e01d';
}
.icon-basic-elaboration-tablet-heart:before {
  content: '\e01e';
}
.icon-basic-elaboration-tablet-noaccess:before {
  content: '\e01f';
}
.icon-basic-elaboration-tablet-note:before {
  content: '\e020';
}
.icon-basic-elaboration-tablet-pencil:before {
  content: '\e021';
}
.icon-basic-elaboration-tablet-picture:before {
  content: '\e022';
}
.icon-basic-elaboration-tablet-refresh:before {
  content: '\e023';
}
.icon-basic-elaboration-tablet-search:before {
  content: '\e024';
}
.icon-basic-elaboration-todolist-2:before {
  content: '\e025';
}
.icon-basic-elaboration-todolist-check:before {
  content: '\e026';
}
.icon-basic-elaboration-todolist-cloud:before {
  content: '\e027';
}
.icon-basic-elaboration-todolist-download:before {
  content: '\e028';
}
.icon-basic-elaboration-todolist-flagged:before {
  content: '\e029';
}
.icon-basic-elaboration-todolist-minus:before {
  content: '\e02a';
}
.icon-basic-elaboration-todolist-noaccess:before {
  content: '\e02b';
}
.icon-basic-elaboration-todolist-pencil:before {
  content: '\e02c';
}
.icon-basic-elaboration-todolist-plus:before {
  content: '\e02d';
}
.icon-basic-elaboration-todolist-refresh:before {
  content: '\e02e';
}
.icon-basic-elaboration-todolist-remove:before {
  content: '\e02f';
}
.icon-basic-elaboration-todolist-search:before {
  content: '\e030';
}
.icon-basic-elaboration-todolist-star:before {
  content: '\e031';
}
.icon-basic-elaboration-todolist-upload:before {
  content: '\e032';
}
/*Ecommerce*/
@font-face {
  font-family: 'linea-ecommerce-10';
  src: url('../../fonts/linea-icons/linea-ecommerce-10.eot');
  src: url('../../fonts/linea-icons/linea-ecommerce-10d41d.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/linea-icons/linea-ecommerce-10.woff') format('woff'),
    url('../../fonts/linea-icons/linea-ecommerce-10.ttf') format('truetype'),
    url('../../fonts/linea-icons/linea-ecommerce-10.svg#linea-ecommerce-10') format('svg');
  font-weight: normal;
  font-style: normal;
}
.linea-ecommerce[data-icon]:before {
  font-family: 'linea-ecommerce-10' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^='linea-icon-']:before,
[class*='linea- icon-']:before {
  font-family: 'linea-ecommerce-10' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-ecommerce-bag:before {
  content: 'a';
}
.icon-ecommerce-bag-check:before {
  content: 'b';
}
.icon-ecommerce-bag-cloud:before {
  content: 'c';
}
.icon-ecommerce-bag-download:before {
  content: 'd';
}
.icon-ecommerce-bag-minus:before {
  content: 'e';
}
.icon-ecommerce-bag-plus:before {
  content: 'f';
}
.icon-ecommerce-bag-refresh:before {
  content: 'g';
}
.icon-ecommerce-bag-remove:before {
  content: 'h';
}
.icon-ecommerce-bag-search:before {
  content: 'i';
}
.icon-ecommerce-bag-upload:before {
  content: 'j';
}
.icon-ecommerce-banknote:before {
  content: 'k';
}
.icon-ecommerce-banknotes:before {
  content: 'l';
}
.icon-ecommerce-basket:before {
  content: 'm';
}
.icon-ecommerce-basket-check:before {
  content: 'n';
}
.icon-ecommerce-basket-cloud:before {
  content: 'o';
}
.icon-ecommerce-basket-download:before {
  content: 'p';
}
.icon-ecommerce-basket-minus:before {
  content: 'q';
}
.icon-ecommerce-basket-plus:before {
  content: 'r';
}
.icon-ecommerce-basket-refresh:before {
  content: 's';
}
.icon-ecommerce-basket-remove:before {
  content: 't';
}
.icon-ecommerce-basket-search:before {
  content: 'u';
}
.icon-ecommerce-basket-upload:before {
  content: 'v';
}
.icon-ecommerce-bath:before {
  content: 'w';
}
.icon-ecommerce-cart:before {
  content: 'x';
}
.icon-ecommerce-cart-check:before {
  content: 'y';
}
.icon-ecommerce-cart-cloud:before {
  content: 'z';
}
.icon-ecommerce-cart-content:before {
  content: 'A';
}
.icon-ecommerce-cart-download:before {
  content: 'B';
}
.icon-ecommerce-cart-minus:before {
  content: 'C';
}
.icon-ecommerce-cart-plus:before {
  content: 'D';
}
.icon-ecommerce-cart-refresh:before {
  content: 'E';
}
.icon-ecommerce-cart-remove:before {
  content: 'F';
}
.icon-ecommerce-cart-search:before {
  content: 'G';
}
.icon-ecommerce-cart-upload:before {
  content: 'H';
}
.icon-ecommerce-cent:before {
  content: 'I';
}
.icon-ecommerce-colon:before {
  content: 'J';
}
.icon-ecommerce-creditcard:before {
  content: 'K';
}
.icon-ecommerce-diamond:before {
  content: 'L';
}
.icon-ecommerce-dollar:before {
  content: 'M';
}
.icon-ecommerce-euro:before {
  content: 'N';
}
.icon-ecommerce-franc:before {
  content: 'O';
}
.icon-ecommerce-gift:before {
  content: 'P';
}
.icon-ecommerce-graph1:before {
  content: 'Q';
}
.icon-ecommerce-graph2:before {
  content: 'R';
}
.icon-ecommerce-graph3:before {
  content: 'S';
}
.icon-ecommerce-graph-decrease:before {
  content: 'T';
}
.icon-ecommerce-graph-increase:before {
  content: 'U';
}
.icon-ecommerce-guarani:before {
  content: 'V';
}
.icon-ecommerce-kips:before {
  content: 'W';
}
.icon-ecommerce-lira:before {
  content: 'X';
}
.icon-ecommerce-megaphone:before {
  content: 'Y';
}
.icon-ecommerce-money:before {
  content: 'Z';
}
.icon-ecommerce-naira:before {
  content: '0';
}
.icon-ecommerce-pesos:before {
  content: '1';
}
.icon-ecommerce-pound:before {
  content: '2';
}
.icon-ecommerce-receipt:before {
  content: '3';
}
.icon-ecommerce-receipt-bath:before {
  content: '4';
}
.icon-ecommerce-receipt-cent:before {
  content: '5';
}
.icon-ecommerce-receipt-dollar:before {
  content: '6';
}
.icon-ecommerce-receipt-euro:before {
  content: '7';
}
.icon-ecommerce-receipt-franc:before {
  content: '8';
}
.icon-ecommerce-receipt-guarani:before {
  content: '9';
}
.icon-ecommerce-receipt-kips:before {
  content: '!';
}
.icon-ecommerce-receipt-lira:before {
  content: '"';
}
.icon-ecommerce-receipt-naira:before {
  content: '#';
}
.icon-ecommerce-receipt-pesos:before {
  content: '$';
}
.icon-ecommerce-receipt-pound:before {
  content: '%';
}
.icon-ecommerce-receipt-rublo:before {
  content: '&';
}
.icon-ecommerce-receipt-rupee:before {
  content: "'";
}
.icon-ecommerce-receipt-tugrik:before {
  content: '(';
}
.icon-ecommerce-receipt-won:before {
  content: ')';
}
.icon-ecommerce-receipt-yen:before {
  content: '*';
}
.icon-ecommerce-receipt-yen2:before {
  content: '+';
}
.icon-ecommerce-recept-colon:before {
  content: ',';
}
.icon-ecommerce-rublo:before {
  content: '-';
}
.icon-ecommerce-rupee:before {
  content: '.';
}
.icon-ecommerce-safe:before {
  content: '/';
}
.icon-ecommerce-sale:before {
  content: ':';
}
.icon-ecommerce-sales:before {
  content: ';';
}
.icon-ecommerce-ticket:before {
  content: '<';
}
.icon-ecommerce-tugriks:before {
  content: '=';
}
.icon-ecommerce-wallet:before {
  content: '>';
}
.icon-ecommerce-won:before {
  content: '?';
}
.icon-ecommerce-yen:before {
  content: '@';
}
.icon-ecommerce-yen2:before {
  content: '[';
}
/*Music */
@font-face {
  font-family: 'linea-music-10';
  src: url('../../fonts/linea-icons/linea-music-10.eot');
  src: url('../../fonts/linea-icons/linea-music-10d41d.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/linea-icons/linea-music-10.woff') format('woff'),
    url('../../fonts/linea-icons/linea-music-10.ttf') format('truetype'),
    url('../../fonts/linea-icons/linea-music-10.svg#linea-music-10') format('svg');
  font-weight: normal;
  font-style: normal;
}
.linea-music[data-icon]:before {
  font-family: 'linea-music-10' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^='linea-icon-']:before,
[class*='linea- icon-']:before {
  font-family: 'linea-music-10' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-music-beginning-button:before {
  content: 'a';
}
.icon-music-bell:before {
  content: 'b';
}
.icon-music-cd:before {
  content: 'c';
}
.icon-music-diapason:before {
  content: 'd';
}
.icon-music-eject-button:before {
  content: 'e';
}
.icon-music-end-button:before {
  content: 'f';
}
.icon-music-fastforward-button:before {
  content: 'g';
}
.icon-music-headphones:before {
  content: 'h';
}
.icon-music-ipod:before {
  content: 'i';
}
.icon-music-loudspeaker:before {
  content: 'j';
}
.icon-music-microphone:before {
  content: 'k';
}
.icon-music-microphone-old:before {
  content: 'l';
}
.icon-music-mixer:before {
  content: 'm';
}
.icon-music-mute:before {
  content: 'n';
}
.icon-music-note-multiple:before {
  content: 'o';
}
.icon-music-note-single:before {
  content: 'p';
}
.icon-music-pause-button:before {
  content: 'q';
}
.icon-music-play-button:before {
  content: 'r';
}
.icon-music-playlist:before {
  content: 's';
}
.icon-music-radio-ghettoblaster:before {
  content: 't';
}
.icon-music-radio-portable:before {
  content: 'u';
}
.icon-music-record:before {
  content: 'v';
}
.icon-music-recordplayer:before {
  content: 'w';
}
.icon-music-repeat-button:before {
  content: 'x';
}
.icon-music-rewind-button:before {
  content: 'y';
}
.icon-music-shuffle-button:before {
  content: 'z';
}
.icon-music-stop-button:before {
  content: 'A';
}
.icon-music-tape:before {
  content: 'B';
}
.icon-music-volume-down:before {
  content: 'C';
}
.icon-music-volume-up:before {
  content: 'D';
}
/*Software*/
@font-face {
  font-family: 'linea-software-10';
  src: url('../../fonts/linea-icons/linea-software-10.eot');
  src: url('../../fonts/linea-icons/linea-software-10d41d.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/linea-icons/linea-software-10.woff') format('woff'),
    url('../../fonts/linea-icons/linea-software-10.ttf') format('truetype'),
    url('../../fonts/linea-icons/linea-software-10.svg#linea-software-10') format('svg');
  font-weight: normal;
  font-style: normal;
}
.linea-software[data-icon]:before {
  font-family: 'linea-software-10' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^='linea-icon-']:before,
[class*='linea- icon-']:before {
  font-family: 'linea-software-10' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-software-add-vectorpoint:before {
  content: 'a';
}
.icon-software-box-oval:before {
  content: 'b';
}
.icon-software-box-polygon:before {
  content: 'c';
}
.icon-software-box-rectangle:before {
  content: 'd';
}
.icon-software-box-roundedrectangle:before {
  content: 'e';
}
.icon-software-character:before {
  content: 'f';
}
.icon-software-crop:before {
  content: 'g';
}
.icon-software-eyedropper:before {
  content: 'h';
}
.icon-software-font-allcaps:before {
  content: 'i';
}
.icon-software-font-baseline-shift:before {
  content: 'j';
}
.icon-software-font-horizontal-scale:before {
  content: 'k';
}
.icon-software-font-kerning:before {
  content: 'l';
}
.icon-software-font-leading:before {
  content: 'm';
}
.icon-software-font-size:before {
  content: 'n';
}
.icon-software-font-smallcapital:before {
  content: 'o';
}
.icon-software-font-smallcaps:before {
  content: 'p';
}
.icon-software-font-strikethrough:before {
  content: 'q';
}
.icon-software-font-tracking:before {
  content: 'r';
}
.icon-software-font-underline:before {
  content: 's';
}
.icon-software-font-vertical-scale:before {
  content: 't';
}
.icon-software-horizontal-align-center:before {
  content: 'u';
}
.icon-software-horizontal-align-left:before {
  content: 'v';
}
.icon-software-horizontal-align-right:before {
  content: 'w';
}
.icon-software-horizontal-distribute-center:before {
  content: 'x';
}
.icon-software-horizontal-distribute-left:before {
  content: 'y';
}
.icon-software-horizontal-distribute-right:before {
  content: 'z';
}
.icon-software-indent-firstline:before {
  content: 'A';
}
.icon-software-indent-left:before {
  content: 'B';
}
.icon-software-indent-right:before {
  content: 'C';
}
.icon-software-lasso:before {
  content: 'D';
}
.icon-software-layers1:before {
  content: 'E';
}
.icon-software-layers2:before {
  content: 'F';
}
.icon-software-layout:before {
  content: 'G';
}
.icon-software-layout-2columns:before {
  content: 'H';
}
.icon-software-layout-3columns:before {
  content: 'I';
}
.icon-software-layout-4boxes:before {
  content: 'J';
}
.icon-software-layout-4columns:before {
  content: 'K';
}
.icon-software-layout-4lines:before {
  content: 'L';
}
.icon-software-layout-8boxes:before {
  content: 'M';
}
.icon-software-layout-header:before {
  content: 'N';
}
.icon-software-layout-header-2columns:before {
  content: 'O';
}
.icon-software-layout-header-3columns:before {
  content: 'P';
}
.icon-software-layout-header-4boxes:before {
  content: 'Q';
}
.icon-software-layout-header-4columns:before {
  content: 'R';
}
.icon-software-layout-header-complex:before {
  content: 'S';
}
.icon-software-layout-header-complex2:before {
  content: 'T';
}
.icon-software-layout-header-complex3:before {
  content: 'U';
}
.icon-software-layout-header-complex4:before {
  content: 'V';
}
.icon-software-layout-header-sideleft:before {
  content: 'W';
}
.icon-software-layout-header-sideright:before {
  content: 'X';
}
.icon-software-layout-sidebar-left:before {
  content: 'Y';
}
.icon-software-layout-sidebar-right:before {
  content: 'Z';
}
.icon-software-magnete:before {
  content: '0';
}
.icon-software-pages:before {
  content: '1';
}
.icon-software-paintbrush:before {
  content: '2';
}
.icon-software-paintbucket:before {
  content: '3';
}
.icon-software-paintroller:before {
  content: '4';
}
.icon-software-paragraph:before {
  content: '5';
}
.icon-software-paragraph-align-left:before {
  content: '6';
}
.icon-software-paragraph-align-right:before {
  content: '7';
}
.icon-software-paragraph-center:before {
  content: '8';
}
.icon-software-paragraph-justify-all:before {
  content: '9';
}
.icon-software-paragraph-justify-center:before {
  content: '!';
}
.icon-software-paragraph-justify-left:before {
  content: '"';
}
.icon-software-paragraph-justify-right:before {
  content: '#';
}
.icon-software-paragraph-space-after:before {
  content: '$';
}
.icon-software-paragraph-space-before:before {
  content: '%';
}
.icon-software-pathfinder-exclude:before {
  content: '&';
}
.icon-software-pathfinder-intersect:before {
  content: "'";
}
.icon-software-pathfinder-subtract:before {
  content: '(';
}
.icon-software-pathfinder-unite:before {
  content: ')';
}
.icon-software-pen:before {
  content: '*';
}
.icon-software-pen-add:before {
  content: '+';
}
.icon-software-pen-remove:before {
  content: ',';
}
.icon-software-pencil:before {
  content: '-';
}
.icon-software-polygonallasso:before {
  content: '.';
}
.icon-software-reflect-horizontal:before {
  content: '/';
}
.icon-software-reflect-vertical:before {
  content: ':';
}
.icon-software-remove-vectorpoint:before {
  content: ';';
}
.icon-software-scale-expand:before {
  content: '<';
}
.icon-software-scale-reduce:before {
  content: '=';
}
.icon-software-selection-oval:before {
  content: '>';
}
.icon-software-selection-polygon:before {
  content: '?';
}
.icon-software-selection-rectangle:before {
  content: '@';
}
.icon-software-selection-roundedrectangle:before {
  content: '[';
}
.icon-software-shape-oval:before {
  content: ']';
}
.icon-software-shape-polygon:before {
  content: '^';
}
.icon-software-shape-rectangle:before {
  content: '_';
}
.icon-software-shape-roundedrectangle:before {
  content: '`';
}
.icon-software-slice:before {
  content: '{';
}
.icon-software-transform-bezier:before {
  content: '|';
}
.icon-software-vector-box:before {
  content: '}';
}
.icon-software-vector-composite:before {
  content: '~';
}
.icon-software-vector-line:before {
  content: '\\';
}
.icon-software-vertical-align-bottom:before {
  content: '\e000';
}
.icon-software-vertical-align-center:before {
  content: '\e001';
}
.icon-software-vertical-align-top:before {
  content: '\e002';
}
.icon-software-vertical-distribute-bottom:before {
  content: '\e003';
}
.icon-software-vertical-distribute-center:before {
  content: '\e004';
}
.icon-software-vertical-distribute-top:before {
  content: '\e005';
}
/*Weather*/
@font-face {
  font-family: 'linea-weather-10';
  src: url('../../fonts/linea-icons/linea-weather-10.eot');
  src: url('../../fonts/linea-icons/linea-weather-10d41d.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/linea-icons/linea-weather-10.woff') format('woff'),
    url('../../fonts/linea-icons/linea-weather-10.ttf') format('truetype'),
    url('../../fonts/linea-icons/linea-weather-10.svg#linea-weather-10') format('svg');
  font-weight: normal;
  font-style: normal;
}
.linea-weather[data-icon]:before {
  font-family: 'linea-weather-10' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^='linea-icon-']:before,
[class*='linea- icon-']:before {
  font-family: 'linea-weather-10' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-weather-aquarius:before {
  content: '\e000';
}
.icon-weather-aries:before {
  content: '\e001';
}
.icon-weather-cancer:before {
  content: '\e002';
}
.icon-weather-capricorn:before {
  content: '\e003';
}
.icon-weather-cloud:before {
  content: '\e004';
}
.icon-weather-cloud-drop:before {
  content: '\e005';
}
.icon-weather-cloud-lightning:before {
  content: '\e006';
}
.icon-weather-cloud-snowflake:before {
  content: '\e007';
}
.icon-weather-downpour-fullmoon:before {
  content: '\e008';
}
.icon-weather-downpour-halfmoon:before {
  content: '\e009';
}
.icon-weather-downpour-sun:before {
  content: '\e00a';
}
.icon-weather-drop:before {
  content: '\e00b';
}
.icon-weather-first-quarter:before {
  content: '\e00c';
}
.icon-weather-fog:before {
  content: '\e00d';
}
.icon-weather-fog-fullmoon:before {
  content: '\e00e';
}
.icon-weather-fog-halfmoon:before {
  content: '\e00f';
}
.icon-weather-fog-sun:before {
  content: '\e010';
}
.icon-weather-fullmoon:before {
  content: '\e011';
}
.icon-weather-gemini:before {
  content: '\e012';
}
.icon-weather-hail:before {
  content: '\e013';
}
.icon-weather-hail-fullmoon:before {
  content: '\e014';
}
.icon-weather-hail-halfmoon:before {
  content: '\e015';
}
.icon-weather-hail-sun:before {
  content: '\e016';
}
.icon-weather-last-quarter:before {
  content: '\e017';
}
.icon-weather-leo:before {
  content: '\e018';
}
.icon-weather-libra:before {
  content: '\e019';
}
.icon-weather-lightning:before {
  content: '\e01a';
}
.icon-weather-mistyrain:before {
  content: '\e01b';
}
.icon-weather-mistyrain-fullmoon:before {
  content: '\e01c';
}
.icon-weather-mistyrain-halfmoon:before {
  content: '\e01d';
}
.icon-weather-mistyrain-sun:before {
  content: '\e01e';
}
.icon-weather-moon:before {
  content: '\e01f';
}
.icon-weather-moondown-full:before {
  content: '\e020';
}
.icon-weather-moondown-half:before {
  content: '\e021';
}
.icon-weather-moonset-full:before {
  content: '\e022';
}
.icon-weather-moonset-half:before {
  content: '\e023';
}
.icon-weather-move2:before {
  content: '\e024';
}
.icon-weather-newmoon:before {
  content: '\e025';
}
.icon-weather-pisces:before {
  content: '\e026';
}
.icon-weather-rain:before {
  content: '\e027';
}
.icon-weather-rain-fullmoon:before {
  content: '\e028';
}
.icon-weather-rain-halfmoon:before {
  content: '\e029';
}
.icon-weather-rain-sun:before {
  content: '\e02a';
}
.icon-weather-sagittarius:before {
  content: '\e02b';
}
.icon-weather-scorpio:before {
  content: '\e02c';
}
.icon-weather-snow:before {
  content: '\e02d';
}
.icon-weather-snow-fullmoon:before {
  content: '\e02e';
}
.icon-weather-snow-halfmoon:before {
  content: '\e02f';
}
.icon-weather-snow-sun:before {
  content: '\e030';
}
.icon-weather-snowflake:before {
  content: '\e031';
}
.icon-weather-star:before {
  content: '\e032';
}
.icon-weather-storm-11:before {
  content: '\e033';
}
.icon-weather-storm-32:before {
  content: '\e034';
}
.icon-weather-storm-fullmoon:before {
  content: '\e035';
}
.icon-weather-storm-halfmoon:before {
  content: '\e036';
}
.icon-weather-storm-sun:before {
  content: '\e037';
}
.icon-weather-sun:before {
  content: '\e038';
}
.icon-weather-sundown:before {
  content: '\e039';
}
.icon-weather-sunset:before {
  content: '\e03a';
}
.icon-weather-taurus:before {
  content: '\e03b';
}
.icon-weather-tempest:before {
  content: '\e03c';
}
.icon-weather-tempest-fullmoon:before {
  content: '\e03d';
}
.icon-weather-tempest-halfmoon:before {
  content: '\e03e';
}
.icon-weather-tempest-sun:before {
  content: '\e03f';
}
.icon-weather-variable-fullmoon:before {
  content: '\e040';
}
.icon-weather-variable-halfmoon:before {
  content: '\e041';
}
.icon-weather-variable-sun:before {
  content: '\e042';
}
.icon-weather-virgo:before {
  content: '\e043';
}
.icon-weather-waning-cresent:before {
  content: '\e044';
}
.icon-weather-waning-gibbous:before {
  content: '\e045';
}
.icon-weather-waxing-cresent:before {
  content: '\e046';
}
.icon-weather-waxing-gibbous:before {
  content: '\e047';
}
.icon-weather-wind:before {
  content: '\e048';
}
.icon-weather-wind-e:before {
  content: '\e049';
}
.icon-weather-wind-fullmoon:before {
  content: '\e04a';
}
.icon-weather-wind-halfmoon:before {
  content: '\e04b';
}
.icon-weather-wind-n:before {
  content: '\e04c';
}
.icon-weather-wind-ne:before {
  content: '\e04d';
}
.icon-weather-wind-nw:before {
  content: '\e04e';
}
.icon-weather-wind-s:before {
  content: '\e04f';
}
.icon-weather-wind-se:before {
  content: '\e050';
}
.icon-weather-wind-sun:before {
  content: '\e051';
}
.icon-weather-wind-sw:before {
  content: '\e052';
}
.icon-weather-wind-w:before {
  content: '\e053';
}
.icon-weather-windgust:before {
  content: '\e054';
}
