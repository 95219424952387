@charset "UTF-8";
/*------------------------------------------------------------------
[Master Stylesheet]
Project:	Droopy
++++ Imports ++++
++++ Reset ++++
++++ Basic ++++

	++Effect 1: YouTube loading++
	++typography++
	++Buttons++
	++Admin Basic++
	++Form++
	++Form-Wizard++
	++Product Deatails*++
	++Product Cart++
	++Popover++
	++Typerhead++
	++Editors++
	++Accordion++
	++Tabs++
	++Table++
	++Morris Chart++
	++Flot Chart++
	++Chartist++
	++Easy pie chart++
	++Skills Counter++
	++Nestable++
	++Treeview++
	++Modals++
	++sweet-alert++
	++Notification++
	++Tooltop++
	++Icons++
	++File Upload++
	++File Manager++
	++Carousel++
	++Testimonial slider++
	++Twitter slider++
	++Range Slider++
	++Gallery++
	++Typography++
	++Panel++
	++Pricing Table++
	++Dropdown++
	++Weather++
	++Sparkline++
	++Pagination++
	++Pager++
	++Labels++
	++Badge++
	++Task Panel++
	++Vecotor Map++
	++Progressbar++
	++Search Bar++
	++Chat UI++
	++Widgets++
	++Profile++
	++Followers++
	++Small Graph++
	++Timeline++
	++Caledar++
	++Todo List++
	++Activity++
	++Message Box++
	++Inbox++
	++Contact Cards++
	++Contact List++
	++Login Form++
	++Error Page++
	++Products++
	++Cropperjs++

++++ Mediaqueries ++++
-------------------------------------------------------------------*/
/****************************/
/** Imports **/
/****************************/
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700|Poppins:400|500);
@import url(./vendor/bootstrap.min.css);
@import url(./vendor/font-awesome.min.css);
@import url(./vendor/themify-icons.css);
@import url(./vendor/animate.css);
@import url(./vendor/simple-line-icons.css);
@import url(./vendor/linea-icon.css);
@import url(./vendor/pe-icon-7-stroke.css);
@import url(./vendor/pe-icon-7-styles.css);
@import url(./vendor/material-design-iconic-font.min.css);
@import url(./vendor/filter.css);
@import url(./vendor/switchery.min.css);
@import url(./vendor/owl.carousel.min.css);
@import url(./vendor/owl.theme.default.min.css);
@import url(./vendor/lightgallery.css);
@import url(./vendor/awesome-bootstrap-checkbox.css);
@import '~antd/dist/antd.css';

/****************************/
/** Reset **/
/****************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
.error-comment,
h4,
h5,
h6,
.panel-title,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

strong {
  font-weight: bold;
}

body {
  color: #878787;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 1.5;
  background: #acb0b1;
  overflow-x: hidden;
}

a:hover,
a:active {
  outline: 0;
}

button:focus {
  border: none;
  outline: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #212121;
}

a:hover {
  text-decoration: none;
  color: #212121;
}

a:focus {
  text-decoration: none;
  outline: none;
  color: #212121;
}

h1,
h2,
h3,
.error-comment,
h4,
h5,
h6,
.panel-title {
  color: #212121;
}

h1 {
  font-size: 48px;
  line-height: 60px;
}

h2 {
  font-size: 36px;
  line-height: 44px;
}

h3,
.error-comment {
  font-size: 30px;
  line-height: 40px;
}

h4 {
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
}

h5 {
  font-size: 18px;
  line-height: 26px;
  text-transform: capitalize;
}

h6,
.panel-title {
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
}

p {
  font-size: 14px;
  line-height: 25px;
}
p.lead {
  font-size: 18px;
  line-height: 29px;
  margin-bottom: 0;
  font-weight: 400;
}
p.text-muted {
  font-size: 14px;
}

.force-UTF-8 {
  content: '¡';
}

dt {
  font-weight: 600;
}

code {
  background: transparent;
}

::-webkit-file-upload-button {
  cursor: pointer;
}

.slimScrollDiv {
  height: 100%;
}

.header-admin_items {
  display: flex;
  align-items: center;
}
.header-sey-hello {
  position: absolute;
  right: 0;
}
.header-select {
  margin: 0 2%;
}

.notifyMessage {
  font-size: 14px;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(240, 240, 240, 0.8);
  z-index: 10000;
}

.loader i {
  color: #5cb85c;
  position: static;
  font-size: 50px;
}

.brand-img {
  vertical-align: inherit;
  width: 80px;
}

.addClient_form-page {
  width: 600px;
  border: 0.5px solid rgb(175, 174, 174);
  border-radius: 3px;
  margin: 0 auto;
  padding-bottom: 2%;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.35);
}
.addClient_form-page h4 {
  color: white !important;
}
.addClient_form {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.addClient_form-item {
  width: 45%;
  background: #e4e8ec;
  padding: 1% !important;
}
.addClient_headline {
  width: 100%;
  background: #1890ff;
  padding: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addClient_headline a {
  color: white !important;
}
.site-list-page {
  border: 1px solid #ece2e2;
  border-radius: 5px 5px 0 0;
  min-height: 85vh;
}
.site-list_header {
  display: flex;
  justify-content: space-between;
  background: #19ace0;
  padding: 1%;
  border-radius: 5px 5px 0 0;
  align-items: center;
}
.site-list_header h4 div {
  color: white;
}
.users-list-action_item {
  cursor: pointer;
  margin: 0 5px;
  color: #159ad8;
}

.add-new-site_button {
  background: rgb(43, 134, 43) !important;
  border: none !important;
  margin: 0 10px 10px 0;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}
.add-new-site_button span {
  font-weight: bold;
}

/* customers */
.customer-list_select-item {
  margin: 15px 0;
  display: flex;
  justify-content: start;
}
.customer_modal {
  border: 0.5px solid rgb(175, 174, 174);
  border-radius: 3px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.35);
  font-size: 13px;
  padding: 0 !important;
}
.customer_modal .ant-modal-body {
  padding: 0;
}
.customer_modal .ant-modal-header {
  background: #44a2e0;
}
.customer_modal .ant-modal-header .ant-modal-title {
  color: white;
}
.customer_modal .ant-modal-close {
  color: white;
}
.customer_main-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2%;
}
.customer_main-info_section {
  width: 31%;
  margin: 0 2%;
}
.customer_main-info__title {
  width: 100%;
  text-align: left;
  font-size: 17px;
  font-weight: 600;
  margin: 3% 0 3% 2%;
}
.customer_type__uppercase {
  text-transform: uppercase;
}
.customer_main-info__item {
  display: flex;
  justify-content: space-between;
  margin: 0 2%;
}
.customer_main-info__item input {
  cursor: pointer;
}
.customer_main-info__item i {
  cursor: pointer;
  margin-right: 2%;
  color: rgb(73, 73, 252);
}
.customer_main-info__item-question {
  margin: 3% 2%;
}
.customer_type__bold {
  font-weight: bold;
  font-size: 13px;
  text-align: right;
  width: 100%;
  margin: 0 2% 0 2%;
}
.customer_details {
  background: #44a2e0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 4% 2% 2% 2%;
}
.customer_details_item {
  width: 30%;
  background: rgb(255, 253, 253);
  border-radius: 5px;
  margin: 0 0 2% 0;
}
.customer_details_item__one {
  width: 49%;
}
.customer_details_item__two {
  width: 38%;
}
.back-to-customers-page {
  color: white;
}
.customer_page h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* processors */
.add-processor_form-page {
  padding-top: 2%;
  padding-bottom: 2%;
  overflow: scroll;
  max-height: 80vh;
}
.button_position_fixed {
  position: absolute;
  bottom: 30px;
  right: 25px;
}
.processor_list_page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-new-processor_buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* tasks */
.task_form {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 0 2% !important;
  width: 100%;
}
.task_filter_item {
  width: 350px;
  margin-left: 10px;
}
.task_modal__select {
  width: 35%;
}
.task_product__select {
  width: 60%;
  overflow: auto;
}
.task_form-item {
  width: 100%;
  height: 35px;
}
.select_task_form_item {
  width: 300px;
  right: 0px;
  top: 0px;
  position: absolute;
}
.customers_in_task {
  height: 210px;
  overflow: scroll;
  margin-bottom: 4%;
}
.task_cycle-form {
  width: 100%;
  padding: 1%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ask_modal-item__small input {
  width: 100px;
}
.ask_modal-item__middle {
  width: 180px;
}
.task_wrong-max-delay {
  color: red;
  margin-right: 3%;
}
.row_task_items {
  display: flex;
  justify-content: space-around;
}
/* status task buttons */
.status_button {
  border: 1px solid grey;
  border-radius: 10px;
  text-align: center;
  color: white;
  box-shadow: -1px 0.5px 2px 1px rgba(0, 0, 0, 0.7);
  padding: 2px;
  font-weight: 500;
}
.runTask_status-button {
  background: green;
}
.pauseTask_status-button {
  background: rgb(192, 170, 43);
}
.stopTask_status-button {
  background: rgb(179, 60, 13);
}
.finishTask_status-button {
  background: rgb(3, 89, 202);
}

/* statistics task page */
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
  margin: 0 !important;
}

/* group list page */
.add-new-goup_modal {
  cursor: pointer;
}
.addRule_form {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.rule-form-items {
  width: 49%;
}
.form-item {
  width: 100%;
}
.select-form_item {
  width: 90%;
  min-width: 40%;
}
.rule-fomr_error-message {
  position: absolute;
  bottom: 50px;
  color: red;
}

/* rules */
.update_rule_modal {
  min-height: 500px;
}
/****************************/
/** Basic **/
/****************************/
.pa-0 {
  padding: 0px !important;
}

.pa-5 {
  padding: 5px !important;
}

.pa-10 {
  padding: 10px !important;
}

.pa-15 {
  padding: 15px !important;
}

.pa-20 {
  padding: 20px !important;
}

.pa-25 {
  padding: 25px !important;
}

.pa-30 {
  padding: 30px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.ma-0 {
  margin: 0px !important;
}

.ma-5 {
  margin: 5px !important;
}

.ma-10 {
  margin: 10px !important;
}

.ma-15 {
  margin: 15px !important;
}

.ma-20 {
  margin: 20px !important;
}

.ma-25 {
  margin: 25px !important;
}

.ma-30 {
  margin: 30px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.no-float {
  float: none !important;
}

.overflow-hide {
  overflow: hidden !important;
}

.clearfix {
  overflow: hidden;
  clear: both;
  float: none;
}

.preloader-it {
  background: #fff;
  position: fixed;
  z-index: 10001;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.bg-green,
.bg-success {
  background: #8bc34a !important;
}

.bg-light-green {
  background: #aed581 !important;
}

.bg-dark-green {
  background: #558b2f !important;
}

.bg-xtra-light-green {
  background: #dcedc8 !important;
}

.bg-seagreen {
  background: #007153 !important;
}

.bg-grey {
  background: #878787 !important;
}

.bg-yellow,
.bg-warning {
  background: #f8b32d !important;
}

.bg-red,
.bg-danger {
  background: #f33923 !important;
}

.bg-blue,
.bg-primary {
  background: #2196f3 !important;
}

.bg-pink,
.bg-info {
  background: #e7368d !important;
}

.bg-purple {
  background: #ac235c !important;
}

.bg-orange {
  background: #ff6300 !important;
}

.bg-gold {
  background: #e58b25 !important;
}

.bg-brown {
  background: #6d300e !important;
}

.bg-beige {
  background: #f7ce99 !important;
}

.bg-light {
  background: #fff !important;
}

.bg-dark {
  background: #212121 !important;
}

.bg-twitter {
  background: #00aced !important;
}

.txt-dark {
  color: #212121 !important;
}

.txt-light {
  color: #fff !important;
}

.txt-grey {
  color: #878787 !important;
}

.txt-light-grey {
  color: #dedede !important;
}

.txt-info,
.text-info {
  color: #e7368d !important;
}

.txt-warning,
.text-warning {
  color: #f8b32d !important;
}

.txt-primary,
.text-primary {
  color: #2196f3 !important;
}

.txt-success,
.text-success {
  color: #8bc34a !important;
}

.txt-light-green {
  color: #aed581 !important;
}

.txt-danger,
.text-danger {
  color: #f33923 !important;
}

.txt-purple {
  color: #ac235c !important;
}

.txt-beige {
  color: #f7ce99 !important;
}

.txt-gold {
  color: #e58b25 !important;
}

.txt-orange {
  color: #ff6300 !important;
}

.txt-brown {
  color: #6d300e !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-21 {
  font-size: 21px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.weight-500 {
  font-weight: 500 !important;
}

.weight-600 {
  font-weight: 600 !important;
}

.head-font {
  font-family: 'Roboto', sans-serif !important;
}

.main-font {
  font-family: 'Roboto', sans-serif !important;
}

.capitalize-font {
  text-transform: capitalize !important;
}

.uppercase-font {
  text-transform: uppercase !important;
}

.nonecase-font {
  text-transform: none !important;
}

.inline-block {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.table-struct {
  display: table !important;
  min-height: 100vh;
}

.table-cell {
  display: table-cell !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.full-width {
  width: 100% !important;
}

.half-width {
  width: 50% !important;
}

.auto-width {
  width: auto !important;
}

.relative {
  position: relative !important;
}

.border-none {
  border: none !important;
}

small {
  color: #878787;
  padding-left: 5px;
}

.text-muted {
  color: #878787;
}

.truncate {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile-only-view {
  display: none !important;
}

hr {
  margin-top: 10px;
  margin-bottom: 35px;
  border-top: 1px solid #8bc34a;
}
hr.light-grey-hr {
  border-top: 1px solid #dedede;
}

.seprator-block {
  margin-bottom: 80px;
}

/* Effect 1: YouTube loading */
.la-anim-1 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 3px;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  pointer-events: none;
}

.la-anim-1::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  content: '';
  opacity: 0;
  background: #212121;
  -webkit-transition: opacity 0.2s, box-shadow 2s 4s;
  -moz-transition: opacity 0.2s, box-shadow 2s 4s;
  transition: opacity 0.2s, box-shadow 2s 4s;
  -webkit-transform: rotate(2deg) translateY(-2px);
  -moz-transform: rotate(2deg) translateY(-2px);
  -ms-transform: rotate(2deg) translateY(-2px);
  -o-transform: rotate(2deg) translateY(-2px);
  transform: rotate(2deg) translateY(-2px);
}

.la-anim-1.la-animate {
  z-index: 10001;
  background: #212121;
  opacity: 1;
  -webkit-transition: -webkit-transform 5s ease-in, opacity 1s 5s;
  -moz-transition: -moz-transform 5s ease-in, opacity 1s 5s;
  transition: transform 5s ease-in, opacity 1s 5s;
  -webkit-transform: translate3d(0%, 0, 0);
  -moz-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.la-anim-1.la-animate::after {
  opacity: 1;
}

.nicescroll-rails.nicescroll-rails-vr {
  z-index: 999 !important;
}

/*typography*/
address .address-head {
  font-size: 15px;
  color: #212121;
  display: block;
}

.mark,
mark {
  padding: 3px;
  background-color: #f33923;
  color: #fff;
}

blockquote {
  padding: 15px 20px;
  margin: 0 0 20px;
  font-size: 16px;
  border-left: 4px solid #2196f3;
  background: #f7f7f9;
  color: #212121;
}
blockquote small {
  font-size: 70%;
  text-transform: uppercase;
  padding-top: 15px;
}

ul.uo-list {
  list-style: disc;
}

/*Buttons*/
.btn,
.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active,
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active,
.dt-buttons .dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button,
.sweet-alert button,
.owl-theme .owl-nav [class*='owl-'],
button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.fc-month-button.fc-state-default.fc-corner-left,
button.fc-agendaWeek-button,
.fc-prev-button,
.fc-next-button,
.fc-today-button {
  color: #fff;
  padding: 10px 25px;
  text-transform: capitalize;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
  border: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn i,
.wizard > .actions .disabled a i,
.wizard > .actions .disabled a:hover i,
.wizard > .actions .disabled a:active i,
.wizard > .actions a i,
.wizard > .actions a:hover i,
.wizard > .actions a:active i,
.dt-buttons .dt-button i,
.tablesaw-sortable th.tablesaw-sortable-head button i,
.sweet-alert button i,
.owl-theme .owl-nav [class*='owl-'] i,
button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
button.fc-month-button.fc-state-default.fc-corner-left i,
button.fc-agendaWeek-button i,
.fc-prev-button i,
.fc-next-button i,
.fc-today-button i {
  font-size: 14px;
}
.btn:focus,
.wizard > .actions a:focus,
.dt-buttons .dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button:focus,
.sweet-alert button:focus,
.owl-theme .owl-nav [class*='owl-']:focus,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.fc-agendaWeek-button:focus,
.fc-prev-button:focus,
.fc-next-button:focus,
.fc-today-button:focus,
.btn:active,
.wizard > .actions a:active,
.dt-buttons .dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button:active,
.sweet-alert button:active,
.owl-theme .owl-nav [class*='owl-']:active,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.fc-month-button.fc-state-default.fc-corner-left:active,
button.fc-agendaWeek-button:active,
.fc-prev-button:active,
.fc-next-button:active,
.fc-today-button:active {
  outline: none;
}
.btn:focus:focus,
.wizard > .actions a:focus:focus,
.dt-buttons .dt-button:focus:focus,
.tablesaw-sortable th.tablesaw-sortable-head button:focus:focus,
.sweet-alert button:focus:focus,
.owl-theme .owl-nav [class*='owl-']:focus:focus,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus:focus,
button.fc-month-button.fc-state-default.fc-corner-left:focus:focus,
button.fc-agendaWeek-button:focus:focus,
.fc-prev-button:focus:focus,
.fc-next-button:focus:focus,
.fc-today-button:focus:focus,
.btn:focus:active,
.wizard > .actions a:focus:active,
.dt-buttons .dt-button:focus:active,
.tablesaw-sortable th.tablesaw-sortable-head button:focus:active,
.sweet-alert button:focus:active,
.owl-theme .owl-nav [class*='owl-']:focus:active,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus:active,
button.fc-month-button.fc-state-default.fc-corner-left:focus:active,
button.fc-agendaWeek-button:focus:active,
.fc-prev-button:focus:active,
.fc-next-button:focus:active,
.fc-today-button:focus:active,
.btn:active:focus,
.wizard > .actions a:active:focus,
.dt-buttons .dt-button:active:focus,
.tablesaw-sortable th.tablesaw-sortable-head button:active:focus,
.sweet-alert button:active:focus,
.owl-theme .owl-nav [class*='owl-']:active:focus,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:active:focus,
button.fc-month-button.fc-state-default.fc-corner-left:active:focus,
button.fc-agendaWeek-button:active:focus,
.fc-prev-button:active:focus,
.fc-next-button:active:focus,
.fc-today-button:active:focus,
.btn:active:active,
.wizard > .actions a:active:active,
.dt-buttons .dt-button:active:active,
.tablesaw-sortable th.tablesaw-sortable-head button:active:active,
.sweet-alert button:active:active,
.owl-theme .owl-nav [class*='owl-']:active:active,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:active:active,
button.fc-month-button.fc-state-default.fc-corner-left:active:active,
button.fc-agendaWeek-button:active:active,
.fc-prev-button:active:active,
.fc-next-button:active:active,
.fc-today-button:active:active {
  outline: none;
}
.btn.btn-default,
.wizard > .actions a.btn-default,
.wizard > .actions .owl-theme .owl-nav a[class*='owl-']:hover,
.owl-theme .owl-nav .wizard > .actions a[class*='owl-']:hover,
.wizard > .actions .owl-theme .owl-nav a[class*='owl-']:active,
.owl-theme .owl-nav .wizard > .actions a[class*='owl-']:active,
.dt-buttons .btn-default.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button,
.sweet-alert button.btn-default,
.owl-theme .owl-nav [class*='owl-'],
button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme .owl-nav button.fc-agendaDay-button.fc-state-default.fc-corner-right[class*='owl-'],
button.btn-default.fc-month-button.fc-state-default.fc-corner-left,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-month-button.fc-state-default.fc-corner-left,
.owl-theme .owl-nav button.fc-month-button.fc-state-default.fc-corner-left[class*='owl-'],
button.btn-default.fc-agendaWeek-button,
.btn-default.fc-prev-button,
.btn-default.fc-next-button,
.btn-default.fc-today-button {
  background: gainsboro;
  border: solid 1px gainsboro;
  color: #212121 !important;
}
.btn.btn-default:hover,
.wizard > .actions a.btn-default:hover,
.dt-buttons .btn-default.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button:hover,
.sweet-alert button.btn-default:hover,
.owl-theme .owl-nav [class*='owl-']:hover,
button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-default.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-default.fc-agendaWeek-button:hover,
.btn-default.fc-prev-button:hover,
.btn-default.fc-next-button:hover,
.btn-default.fc-today-button:hover,
.btn.btn-default:active,
.wizard > .actions a.btn-default:active,
.dt-buttons .btn-default.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button:active,
.sweet-alert button.btn-default:active,
.owl-theme .owl-nav [class*='owl-']:active,
button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-default.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-default.fc-agendaWeek-button:active,
.btn-default.fc-prev-button:active,
.btn-default.fc-next-button:active,
.btn-default.fc-today-button:active,
.btn.btn-default:focus,
.wizard > .actions a.btn-default:focus,
.dt-buttons .btn-default.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button:focus,
.sweet-alert button.btn-default:focus,
.owl-theme .owl-nav [class*='owl-']:focus,
button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-default.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-default.fc-agendaWeek-button:focus,
.btn-default.fc-prev-button:focus,
.btn-default.fc-next-button:focus,
.btn-default.fc-today-button:focus {
  background: rgba(220, 220, 220, 0.85);
  border: solid 1px rgba(220, 220, 220, 0.1);
}
.btn.btn-info,
.wizard > .actions a.btn-info,
.dt-buttons .btn-info.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-info,
.sweet-alert button.btn-info,
.owl-theme .owl-nav .btn-info[class*='owl-'],
button.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-info.fc-month-button.fc-state-default.fc-corner-left,
button.btn-info.fc-agendaWeek-button,
.btn-info.fc-prev-button,
.btn-info.fc-next-button,
.btn-info.fc-today-button {
  background: #e7368d;
  border: solid 1px #e7368d;
}
.btn.btn-info:hover,
.wizard > .actions a.btn-info:hover,
.dt-buttons .btn-info.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-info:hover,
.sweet-alert button.btn-info:hover,
.owl-theme .owl-nav .btn-info[class*='owl-']:hover,
button.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-info.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-info.fc-agendaWeek-button:hover,
.btn-info.fc-prev-button:hover,
.btn-info.fc-next-button:hover,
.btn-info.fc-today-button:hover,
.btn.btn-info:active,
.wizard > .actions a.btn-info:active,
.dt-buttons .btn-info.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-info:active,
.sweet-alert button.btn-info:active,
.owl-theme .owl-nav .btn-info[class*='owl-']:active,
button.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-info.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-info.fc-agendaWeek-button:active,
.btn-info.fc-prev-button:active,
.btn-info.fc-next-button:active,
.btn-info.fc-today-button:active,
.btn.btn-info:focus,
.wizard > .actions a.btn-info:focus,
.dt-buttons .btn-info.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-info:focus,
.sweet-alert button.btn-info:focus,
.owl-theme .owl-nav .btn-info[class*='owl-']:focus,
button.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-info.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-info.fc-agendaWeek-button:focus,
.btn-info.fc-prev-button:focus,
.btn-info.fc-next-button:focus,
.btn-info.fc-today-button:focus {
  background: rgba(231, 54, 141, 0.85);
  border: solid 1px rgba(231, 54, 141, 0.1);
}
.btn.btn-primary,
.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active,
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active,
.dt-buttons .dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button,
.sweet-alert button.btn-primary,
.owl-theme .owl-nav .btn-primary[class*='owl-'],
.owl-theme .owl-nav .wizard > .actions a[class*='owl-'],
.wizard > .actions .owl-theme .owl-nav a[class*='owl-'],
.owl-theme .owl-nav .dt-buttons [class*='owl-'].dt-button,
.dt-buttons .owl-theme .owl-nav [class*='owl-'].dt-button,
button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons button.fc-agendaDay-button.fc-state-default.fc-corner-right.dt-button,
button.btn-primary.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons button.fc-month-button.fc-state-default.fc-corner-left.dt-button,
button.btn-primary.fc-agendaWeek-button,
.btn-primary.fc-prev-button,
.btn-primary.fc-next-button,
.btn-primary.fc-today-button {
  background: #2196f3;
  border: solid 1px #2196f3;
}
.btn.btn-primary:hover,
.wizard > .actions a:hover,
.dt-buttons .dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary:hover,
.sweet-alert button.btn-primary:hover,
.owl-theme .owl-nav .btn-primary[class*='owl-']:hover,
button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.dt-buttons button.fc-agendaDay-button.fc-state-default.fc-corner-right.dt-button:hover,
button.btn-primary.fc-month-button.fc-state-default.fc-corner-left:hover,
.dt-buttons button.fc-month-button.fc-state-default.fc-corner-left.dt-button:hover,
button.btn-primary.fc-agendaWeek-button:hover,
.btn-primary.fc-prev-button:hover,
.btn-primary.fc-next-button:hover,
.btn-primary.fc-today-button:hover,
.btn.btn-primary:active,
.wizard > .actions a:active,
.dt-buttons .dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary:active,
.sweet-alert button.btn-primary:active,
.owl-theme .owl-nav .btn-primary[class*='owl-']:active,
button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.dt-buttons button.fc-agendaDay-button.fc-state-default.fc-corner-right.dt-button:active,
button.btn-primary.fc-month-button.fc-state-default.fc-corner-left:active,
.dt-buttons button.fc-month-button.fc-state-default.fc-corner-left.dt-button:active,
button.btn-primary.fc-agendaWeek-button:active,
.btn-primary.fc-prev-button:active,
.btn-primary.fc-next-button:active,
.btn-primary.fc-today-button:active,
.btn.btn-primary:focus,
.wizard > .actions a:focus,
.dt-buttons .dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary:focus,
.sweet-alert button.btn-primary:focus,
.owl-theme .owl-nav .btn-primary[class*='owl-']:focus,
button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.dt-buttons button.fc-agendaDay-button.fc-state-default.fc-corner-right.dt-button:focus,
button.btn-primary.fc-month-button.fc-state-default.fc-corner-left:focus,
.dt-buttons button.fc-month-button.fc-state-default.fc-corner-left.dt-button:focus,
button.btn-primary.fc-agendaWeek-button:focus,
.btn-primary.fc-prev-button:focus,
.btn-primary.fc-next-button:focus,
.btn-primary.fc-today-button:focus {
  background: rgba(33, 150, 243, 0.85);
  border: solid 1px rgba(33, 150, 243, 0.1);
}
.btn.btn-success,
.wizard > .actions a.btn-success,
.wizard > .actions a.fc-prev-button,
.wizard > .actions a.fc-next-button,
.wizard > .actions a.fc-today-button,
.dt-buttons .btn-success.dt-button,
.dt-buttons button.dt-button.fc-agendaWeek-button,
.dt-buttons .dt-button.fc-prev-button,
.dt-buttons .dt-button.fc-next-button,
.dt-buttons .dt-button.fc-today-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-success,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button,
.sweet-alert button.btn-success,
.sweet-alert button.fc-prev-button,
.sweet-alert button.fc-next-button,
.sweet-alert button.fc-today-button,
.owl-theme .owl-nav .btn-success[class*='owl-'],
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button,
.owl-theme .owl-nav [class*='owl-'].fc-prev-button,
.owl-theme .owl-nav [class*='owl-'].fc-next-button,
.owl-theme .owl-nav [class*='owl-'].fc-today-button,
button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.fc-month-button.fc-state-default.fc-corner-left,
button.fc-agendaWeek-button,
.fc-prev-button,
.fc-next-button,
.fc-today-button {
  background: #8bc34a;
  border: solid 1px #8bc34a;
}
.btn.btn-success:hover,
.wizard > .actions a.btn-success:hover,
.wizard > .actions a.fc-prev-button:hover,
.wizard > .actions a.fc-next-button:hover,
.wizard > .actions a.fc-today-button:hover,
.dt-buttons .btn-success.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-success:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:hover,
.sweet-alert button.btn-success:hover,
.owl-theme .owl-nav .btn-success[class*='owl-']:hover,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:hover,
.owl-theme .owl-nav [class*='owl-'].fc-prev-button:hover,
.owl-theme .owl-nav [class*='owl-'].fc-next-button:hover,
.owl-theme .owl-nav [class*='owl-'].fc-today-button:hover,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fc-agendaWeek-button:hover,
.fc-prev-button:hover,
.fc-next-button:hover,
.fc-today-button:hover,
.btn.btn-success:active,
.wizard > .actions a.btn-success:active,
.wizard > .actions a.fc-prev-button:active,
.wizard > .actions a.fc-next-button:active,
.wizard > .actions a.fc-today-button:active,
.dt-buttons .btn-success.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-success:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:active,
.sweet-alert button.btn-success:active,
.owl-theme .owl-nav .btn-success[class*='owl-']:active,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:active,
.owl-theme .owl-nav [class*='owl-'].fc-prev-button:active,
.owl-theme .owl-nav [class*='owl-'].fc-next-button:active,
.owl-theme .owl-nav [class*='owl-'].fc-today-button:active,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.fc-month-button.fc-state-default.fc-corner-left:active,
button.fc-agendaWeek-button:active,
.fc-prev-button:active,
.fc-next-button:active,
.fc-today-button:active,
.btn.btn-success:focus,
.wizard > .actions a.btn-success:focus,
.wizard > .actions a.fc-prev-button:focus,
.wizard > .actions a.fc-next-button:focus,
.wizard > .actions a.fc-today-button:focus,
.dt-buttons .btn-success.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-success:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:focus,
.sweet-alert button.btn-success:focus,
.owl-theme .owl-nav .btn-success[class*='owl-']:focus,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:focus,
.owl-theme .owl-nav [class*='owl-'].fc-prev-button:focus,
.owl-theme .owl-nav [class*='owl-'].fc-next-button:focus,
.owl-theme .owl-nav [class*='owl-'].fc-today-button:focus,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.fc-agendaWeek-button:focus,
.fc-prev-button:focus,
.fc-next-button:focus,
.fc-today-button:focus {
  background: rgba(139, 195, 74, 0.85);
  border: solid 1px rgba(139, 195, 74, 0.1);
}
.btn.btn-danger,
.wizard > .actions a.btn-danger,
.dt-buttons .btn-danger.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-danger,
.sweet-alert button.btn-danger,
.owl-theme .owl-nav .btn-danger[class*='owl-'],
button.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-danger.fc-month-button.fc-state-default.fc-corner-left,
button.btn-danger.fc-agendaWeek-button,
.btn-danger.fc-prev-button,
.btn-danger.fc-next-button,
.btn-danger.fc-today-button {
  background: #f33923;
  border: solid 1px #f33923;
}
.btn.btn-danger:hover,
.wizard > .actions a.btn-danger:hover,
.dt-buttons .btn-danger.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-danger:hover,
.sweet-alert button.btn-danger:hover,
.owl-theme .owl-nav .btn-danger[class*='owl-']:hover,
button.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-danger.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-danger.fc-agendaWeek-button:hover,
.btn-danger.fc-prev-button:hover,
.btn-danger.fc-next-button:hover,
.btn-danger.fc-today-button:hover,
.btn.btn-danger:active,
.wizard > .actions a.btn-danger:active,
.dt-buttons .btn-danger.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-danger:active,
.sweet-alert button.btn-danger:active,
.owl-theme .owl-nav .btn-danger[class*='owl-']:active,
button.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-danger.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-danger.fc-agendaWeek-button:active,
.btn-danger.fc-prev-button:active,
.btn-danger.fc-next-button:active,
.btn-danger.fc-today-button:active,
.btn.btn-danger:focus,
.wizard > .actions a.btn-danger:focus,
.dt-buttons .btn-danger.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-danger:focus,
.sweet-alert button.btn-danger:focus,
.owl-theme .owl-nav .btn-danger[class*='owl-']:focus,
button.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-danger.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-danger.fc-agendaWeek-button:focus,
.btn-danger.fc-prev-button:focus,
.btn-danger.fc-next-button:focus,
.btn-danger.fc-today-button:focus {
  background: rgba(243, 57, 35, 0.85);
  border: solid 1px rgba(243, 57, 35, 0.1);
}
.btn.btn-warning,
.wizard > .actions a.btn-warning,
.dt-buttons .btn-warning.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-warning,
.sweet-alert button.btn-warning,
.owl-theme .owl-nav .btn-warning[class*='owl-'],
button.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-warning.fc-month-button.fc-state-default.fc-corner-left,
button.btn-warning.fc-agendaWeek-button,
.btn-warning.fc-prev-button,
.btn-warning.fc-next-button,
.btn-warning.fc-today-button {
  background: #f8b32d;
  border: solid 1px #f8b32d;
}
.btn.btn-warning:hover,
.wizard > .actions a.btn-warning:hover,
.dt-buttons .btn-warning.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-warning:hover,
.sweet-alert button.btn-warning:hover,
.owl-theme .owl-nav .btn-warning[class*='owl-']:hover,
button.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-warning.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-warning.fc-agendaWeek-button:hover,
.btn-warning.fc-prev-button:hover,
.btn-warning.fc-next-button:hover,
.btn-warning.fc-today-button:hover,
.btn.btn-warning:active,
.wizard > .actions a.btn-warning:active,
.dt-buttons .btn-warning.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-warning:active,
.sweet-alert button.btn-warning:active,
.owl-theme .owl-nav .btn-warning[class*='owl-']:active,
button.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-warning.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-warning.fc-agendaWeek-button:active,
.btn-warning.fc-prev-button:active,
.btn-warning.fc-next-button:active,
.btn-warning.fc-today-button:active,
.btn.btn-warning:focus,
.wizard > .actions a.btn-warning:focus,
.dt-buttons .btn-warning.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-warning:focus,
.sweet-alert button.btn-warning:focus,
.owl-theme .owl-nav .btn-warning[class*='owl-']:focus,
button.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-warning.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-warning.fc-agendaWeek-button:focus,
.btn-warning.fc-prev-button:focus,
.btn-warning.fc-next-button:focus,
.btn-warning.fc-today-button:focus {
  background: rgba(248, 179, 45, 0.85);
  border: solid 1px rgba(248, 179, 45, 0.1);
}
.btn.btn-white,
.wizard > .actions a.btn-white,
.dt-buttons .btn-white.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-white,
.sweet-alert button.btn-white,
.owl-theme .owl-nav .btn-white[class*='owl-'],
button.btn-white.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-white.fc-month-button.fc-state-default.fc-corner-left,
button.btn-white.fc-agendaWeek-button,
.btn-white.fc-prev-button,
.btn-white.fc-next-button,
.btn-white.fc-today-button {
  background: white;
  color: #56d365 !important;
}
.btn.btn-white:hover,
.wizard > .actions a.btn-white:hover,
.dt-buttons .btn-white.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-white:hover,
.sweet-alert button.btn-white:hover,
.owl-theme .owl-nav .btn-white[class*='owl-']:hover,
button.btn-white.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-white.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-white.fc-agendaWeek-button:hover,
.btn-white.fc-prev-button:hover,
.btn-white.fc-next-button:hover,
.btn-white.fc-today-button:hover {
  background: rgba(255, 255, 255, 0.85);
  border: solid 1px rgba(255, 255, 255, 0.1);
}
.btn.btn-outline,
.wizard > .actions a.btn-outline,
.wizard > .actions .note-editor a.note-btn.btn-sm,
.note-editor .wizard > .actions a.note-btn.btn-sm,
ul.wysihtml5-toolbar a.btn,
ul.wysihtml5-toolbar .wizard > .actions a,
.wizard > .actions ul.wysihtml5-toolbar a,
ul.wysihtml5-toolbar .dt-buttons a.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.dt-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'],
ul.wysihtml5-toolbar a.fc-prev-button,
ul.wysihtml5-toolbar a.fc-next-button,
ul.wysihtml5-toolbar a.fc-today-button,
.note-editor .btn.note-btn.btn-sm,
.note-editor .dt-buttons .note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .note-btn.btn-sm.dt-button,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.note-btn.btn-sm,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.note-btn.btn-sm,
.note-editor .sweet-alert button.note-btn.btn-sm,
.sweet-alert .note-editor button.note-btn.btn-sm,
.note-editor .owl-theme .owl-nav .note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .note-btn.btn-sm[class*='owl-'],
.note-editor button.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .note-btn.btn-sm.fc-prev-button,
.note-editor .note-btn.btn-sm.fc-next-button,
.note-editor .note-btn.btn-sm.fc-today-button,
.dt-buttons .btn-outline.dt-button,
.dt-buttons button.dt-button.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head button,
.sweet-alert button.btn-outline,
.owl-theme .owl-nav .btn-outline[class*='owl-'],
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'],
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'],
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button,
button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.fc-month-button.fc-state-default.fc-corner-left,
button.fc-agendaWeek-button,
.btn-outline.fc-prev-button,
.btn-outline.fc-next-button,
.btn-outline.fc-today-button {
  background: transparent !important;
}
.btn.btn-outline:hover,
.wizard > .actions a.btn-outline:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm:hover,
ul.wysihtml5-toolbar a.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions a:hover,
.wizard > .actions ul.wysihtml5-toolbar a:hover,
ul.wysihtml5-toolbar .dt-buttons a.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.dt-button:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:hover,
ul.wysihtml5-toolbar a.fc-prev-button:hover,
ul.wysihtml5-toolbar a.fc-next-button:hover,
ul.wysihtml5-toolbar a.fc-today-button:hover,
.note-editor .btn.note-btn.btn-sm:hover,
.note-editor .dt-buttons .note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .note-btn.btn-sm.dt-button:hover,
.note-editor .sweet-alert button.note-btn.btn-sm:hover,
.sweet-alert .note-editor button.note-btn.btn-sm:hover,
.note-editor .owl-theme .owl-nav .note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .note-btn.btn-sm[class*='owl-']:hover,
.note-editor button.note-btn.btn-sm.fc-agendaWeek-button:hover,
.note-editor .note-btn.btn-sm.fc-prev-button:hover,
.note-editor .note-btn.btn-sm.fc-next-button:hover,
.note-editor .note-btn.btn-sm.fc-today-button:hover,
.dt-buttons .btn-outline.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button:hover,
.sweet-alert button.btn-outline:hover,
.owl-theme .owl-nav .btn-outline[class*='owl-']:hover,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:hover,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fc-agendaWeek-button:hover,
.btn-outline.fc-prev-button:hover,
.btn-outline.fc-next-button:hover,
.btn-outline.fc-today-button:hover,
.btn.btn-outline:focus,
.wizard > .actions a.btn-outline:focus,
.wizard > .actions .note-editor a.note-btn.btn-sm:focus,
.note-editor .wizard > .actions a.note-btn.btn-sm:focus,
ul.wysihtml5-toolbar a.btn:focus,
ul.wysihtml5-toolbar .wizard > .actions a:focus,
.wizard > .actions ul.wysihtml5-toolbar a:focus,
ul.wysihtml5-toolbar .dt-buttons a.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.dt-button:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:focus,
ul.wysihtml5-toolbar a.fc-prev-button:focus,
ul.wysihtml5-toolbar a.fc-next-button:focus,
ul.wysihtml5-toolbar a.fc-today-button:focus,
.note-editor .btn.note-btn.btn-sm:focus,
.note-editor .dt-buttons .note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .note-btn.btn-sm.dt-button:focus,
.note-editor .sweet-alert button.note-btn.btn-sm:focus,
.sweet-alert .note-editor button.note-btn.btn-sm:focus,
.note-editor .owl-theme .owl-nav .note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .note-btn.btn-sm[class*='owl-']:focus,
.note-editor button.note-btn.btn-sm.fc-agendaWeek-button:focus,
.note-editor .note-btn.btn-sm.fc-prev-button:focus,
.note-editor .note-btn.btn-sm.fc-next-button:focus,
.note-editor .note-btn.btn-sm.fc-today-button:focus,
.dt-buttons .btn-outline.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button:focus,
.sweet-alert button.btn-outline:focus,
.owl-theme .owl-nav .btn-outline[class*='owl-']:focus,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:focus,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.fc-agendaWeek-button:focus,
.btn-outline.fc-prev-button:focus,
.btn-outline.fc-next-button:focus,
.btn-outline.fc-today-button:focus,
.btn.btn-outline:active,
.wizard > .actions a.btn-outline:active,
.wizard > .actions .note-editor a.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.note-btn.btn-sm:active,
ul.wysihtml5-toolbar a.btn:active,
ul.wysihtml5-toolbar .wizard > .actions a:active,
.wizard > .actions ul.wysihtml5-toolbar a:active,
ul.wysihtml5-toolbar .dt-buttons a.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.dt-button:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:active,
ul.wysihtml5-toolbar a.fc-prev-button:active,
ul.wysihtml5-toolbar a.fc-next-button:active,
ul.wysihtml5-toolbar a.fc-today-button:active,
.note-editor .btn.note-btn.btn-sm:active,
.note-editor .dt-buttons .note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .note-btn.btn-sm.dt-button:active,
.note-editor .sweet-alert button.note-btn.btn-sm:active,
.sweet-alert .note-editor button.note-btn.btn-sm:active,
.note-editor .owl-theme .owl-nav .note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .note-btn.btn-sm[class*='owl-']:active,
.note-editor button.note-btn.btn-sm.fc-agendaWeek-button:active,
.note-editor .note-btn.btn-sm.fc-prev-button:active,
.note-editor .note-btn.btn-sm.fc-next-button:active,
.note-editor .note-btn.btn-sm.fc-today-button:active,
.dt-buttons .btn-outline.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button:active,
.sweet-alert button.btn-outline:active,
.owl-theme .owl-nav .btn-outline[class*='owl-']:active,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:active,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.fc-month-button.fc-state-default.fc-corner-left:active,
button.fc-agendaWeek-button:active,
.btn-outline.fc-prev-button:active,
.btn-outline.fc-next-button:active,
.btn-outline.fc-today-button:active {
  border: solid 1px white;
}
.btn.btn-outline.btn-default,
.wizard > .actions .disabled a.btn-outline.btn-default,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-default.btn,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-default.btn,
.wizard > .actions .disabled ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'],
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions .disabled a[class*='owl-'],
.wizard > .actions .disabled .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions .disabled a[class*='owl-'],
.wizard > .actions .disabled .note-editor a.btn-default.note-btn.btn-sm,
.note-editor .wizard > .actions .disabled a.btn-default.note-btn.btn-sm,
.wizard > .actions .disabled .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-'],
.note-editor .owl-theme .owl-nav .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-'],
.wizard > .actions .disabled .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-'],
.wizard > .actions .disabled .owl-theme .owl-nav a.btn-outline[class*='owl-'],
.owl-theme .owl-nav .wizard > .actions .disabled a.btn-outline[class*='owl-'],
.wizard > .actions .disabled a.btn-outline.btn-default:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-default.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-default.btn:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions .disabled a[class*='owl-']:hover,
.wizard > .actions .disabled .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions .disabled a[class*='owl-']:hover,
.wizard > .actions .disabled .note-editor a.btn-default.note-btn.btn-sm:hover,
.note-editor .wizard > .actions .disabled a.btn-default.note-btn.btn-sm:hover,
.wizard > .actions .disabled .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:hover,
.note-editor .owl-theme .owl-nav .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:hover,
.wizard > .actions .disabled .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:hover,
.wizard > .actions .disabled .owl-theme .owl-nav a.btn-outline[class*='owl-']:hover,
.owl-theme .owl-nav .wizard > .actions .disabled a.btn-outline[class*='owl-']:hover,
.wizard > .actions .disabled a.btn-outline.btn-default:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-default.btn:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-default.btn:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions .disabled a[class*='owl-']:active,
.wizard > .actions .disabled .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions .disabled a[class*='owl-']:active,
.wizard > .actions .disabled .note-editor a.btn-default.note-btn.btn-sm:active,
.note-editor .wizard > .actions .disabled a.btn-default.note-btn.btn-sm:active,
.wizard > .actions .disabled .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:active,
.note-editor .owl-theme .owl-nav .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:active,
.wizard > .actions .disabled .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:active,
.wizard > .actions .disabled .owl-theme .owl-nav a.btn-outline[class*='owl-']:active,
.owl-theme .owl-nav .wizard > .actions .disabled a.btn-outline[class*='owl-']:active,
.wizard > .actions a.btn-outline.btn-default,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default.btn,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default.btn,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'],
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions a[class*='owl-'],
.wizard > .actions .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-'],
.wizard > .actions .note-editor a.btn-default.note-btn.btn-sm,
.note-editor .wizard > .actions a.btn-default.note-btn.btn-sm,
.wizard > .actions .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-'],
.note-editor .owl-theme .owl-nav .wizard > .actions a.note-btn.btn-sm[class*='owl-'],
.wizard > .actions .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .wizard > .actions a.note-btn.btn-sm[class*='owl-'],
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-'],
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-'],
.wizard > .actions a.btn-outline.btn-default:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default.btn:hover,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions a[class*='owl-']:hover,
.wizard > .actions .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:hover,
.wizard > .actions .note-editor a.btn-default.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.btn-default.note-btn.btn-sm:hover,
.wizard > .actions .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:hover,
.note-editor .owl-theme .owl-nav .wizard > .actions a.note-btn.btn-sm[class*='owl-']:hover,
.wizard > .actions .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .wizard > .actions a.note-btn.btn-sm[class*='owl-']:hover,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:hover,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:hover,
.wizard > .actions a.btn-outline.btn-default:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default.btn:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default.btn:active,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions a[class*='owl-']:active,
.wizard > .actions .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:active,
.wizard > .actions .note-editor a.btn-default.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.btn-default.note-btn.btn-sm:active,
.wizard > .actions .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:active,
.note-editor .owl-theme .owl-nav .wizard > .actions a.note-btn.btn-sm[class*='owl-']:active,
.wizard > .actions .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .wizard > .actions a.note-btn.btn-sm[class*='owl-']:active,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:active,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:active,
ul.wysihtml5-toolbar a.btn.btn-default,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default,
ul.wysihtml5-toolbar .dt-buttons a.btn-default.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.btn-default.dt-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'],
ul.wysihtml5-toolbar a.btn-default.fc-prev-button,
ul.wysihtml5-toolbar a.btn-default.fc-next-button,
ul.wysihtml5-toolbar a.btn-default.fc-today-button,
.note-editor .btn.btn-default.note-btn.btn-sm,
.note-editor .dt-buttons .btn-default.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .btn-default.note-btn.btn-sm.dt-button,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.note-btn.btn-sm,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.note-btn.btn-sm,
.note-editor .sweet-alert button.btn-default.note-btn.btn-sm,
.sweet-alert .note-editor button.btn-default.note-btn.btn-sm,
.note-editor .owl-theme .owl-nav .note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .note-btn.btn-sm[class*='owl-'],
.note-editor button.btn-default.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,
.note-editor button.btn-default.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left,
.note-editor button.btn-default.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .btn-default.note-btn.btn-sm.fc-prev-button,
.note-editor .btn-default.note-btn.btn-sm.fc-next-button,
.note-editor .btn-default.note-btn.btn-sm.fc-today-button,
.dt-buttons .btn-outline.btn-default.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.btn-default.dt-button.btn,
ul.wysihtml5-toolbar .dt-buttons a.btn-default.dt-button.btn,
.dt-buttons ul.wysihtml5-toolbar .owl-theme .owl-nav a.dt-button[class*='owl-'],
ul.wysihtml5-toolbar .owl-theme .owl-nav .dt-buttons a.dt-button[class*='owl-'],
.dt-buttons .owl-theme .owl-nav ul.wysihtml5-toolbar a.dt-button[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar .dt-buttons a.dt-button[class*='owl-'],
.dt-buttons .note-editor .owl-theme .owl-nav .dt-button.note-btn.btn-sm[class*='owl-'],
.note-editor .owl-theme .owl-nav .dt-buttons .dt-button.note-btn.btn-sm[class*='owl-'],
.dt-buttons .owl-theme .owl-nav .note-editor .dt-button.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .dt-buttons .dt-button.note-btn.btn-sm[class*='owl-'],
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button,
.dt-buttons .owl-theme .owl-nav .btn-outline.dt-button[class*='owl-'],
.owl-theme .owl-nav .dt-buttons .btn-outline.dt-button[class*='owl-'],
.dt-buttons .owl-theme .owl-nav button.dt-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme .owl-nav .dt-buttons button.dt-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons .owl-theme .owl-nav button.dt-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.owl-theme .owl-nav .dt-buttons button.dt-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons .owl-theme .owl-nav button.dt-button[class*='owl-'].fc-agendaWeek-button,
.owl-theme .owl-nav .dt-buttons button.dt-button[class*='owl-'].fc-agendaWeek-button,
.dt-buttons button.btn-default.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons button.btn-default.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons button.btn-default.dt-button.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head button,
.sweet-alert button.btn-outline.btn-default,
.sweet-alert .note-editor .owl-theme .owl-nav button.note-btn.btn-sm[class*='owl-'],
.note-editor .owl-theme .owl-nav .sweet-alert button.note-btn.btn-sm[class*='owl-'],
.sweet-alert .owl-theme .owl-nav .note-editor button.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .sweet-alert button.note-btn.btn-sm[class*='owl-'],
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button,
.sweet-alert .owl-theme .owl-nav button.btn-outline[class*='owl-'],
.owl-theme .owl-nav .sweet-alert button.btn-outline[class*='owl-'],
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-agendaWeek-button,
.sweet-alert button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right,
.sweet-alert button.btn-default.fc-month-button.fc-state-default.fc-corner-left,
.sweet-alert button.btn-default.fc-agendaWeek-button,
.owl-theme .owl-nav .btn-outline[class*='owl-'],
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'],
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'],
.owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button,
button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-default.fc-month-button.fc-state-default.fc-corner-left,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-month-button.fc-state-default.fc-corner-left,
button.btn-default.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button,
.btn-outline.btn-default.fc-prev-button,
ul.wysihtml5-toolbar a.btn-default.fc-prev-button.btn,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-prev-button[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-prev-button[class*='owl-'],
.note-editor .owl-theme .owl-nav .fc-prev-button.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .fc-prev-button.note-btn.btn-sm[class*='owl-'],
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button,
.owl-theme .owl-nav .btn-outline.fc-prev-button[class*='owl-'],
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-agendaWeek-button,
button.btn-default.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-default.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left,
button.btn-default.fc-prev-button.fc-agendaWeek-button,
.btn-outline.btn-default.fc-next-button,
ul.wysihtml5-toolbar a.btn-default.fc-next-button.btn,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-next-button[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-next-button[class*='owl-'],
.note-editor .owl-theme .owl-nav .fc-next-button.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .fc-next-button.note-btn.btn-sm[class*='owl-'],
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button,
.owl-theme .owl-nav .btn-outline.fc-next-button[class*='owl-'],
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-agendaWeek-button,
button.btn-default.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-default.fc-next-button.fc-month-button.fc-state-default.fc-corner-left,
button.btn-default.fc-next-button.fc-agendaWeek-button,
.btn-outline.btn-default.fc-today-button,
ul.wysihtml5-toolbar a.btn-default.fc-today-button.btn,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-today-button[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-today-button[class*='owl-'],
.note-editor .owl-theme .owl-nav .fc-today-button.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .fc-today-button.note-btn.btn-sm[class*='owl-'],
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button,
.owl-theme .owl-nav .btn-outline.fc-today-button[class*='owl-'],
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-agendaWeek-button,
button.btn-default.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-default.fc-today-button.fc-month-button.fc-state-default.fc-corner-left,
button.btn-default.fc-today-button.fc-agendaWeek-button {
  border: solid 1px rgba(220, 220, 220, 0.5);
  color: #212121;
}
.btn.btn-outline.btn-default:hover,
.wizard > .actions .disabled a.btn-outline.btn-default:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-default.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-default.btn:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions .disabled a[class*='owl-']:hover,
.wizard > .actions .disabled .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions .disabled a[class*='owl-']:hover,
.wizard > .actions .disabled .note-editor a.btn-default.note-btn.btn-sm:hover,
.note-editor .wizard > .actions .disabled a.btn-default.note-btn.btn-sm:hover,
.wizard > .actions .disabled .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:hover,
.note-editor .owl-theme .owl-nav .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:hover,
.wizard > .actions .disabled .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:hover,
.wizard > .actions .disabled .owl-theme .owl-nav a.btn-outline[class*='owl-']:hover,
.owl-theme .owl-nav .wizard > .actions .disabled a.btn-outline[class*='owl-']:hover,
.wizard > .actions .disabled a.btn-outline.btn-default:hover:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-default.btn:hover:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-default.btn:hover:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:hover:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions .disabled a[class*='owl-']:hover:active,
.wizard > .actions .disabled .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:hover:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions .disabled a[class*='owl-']:hover:active,
.wizard > .actions .disabled .note-editor a.btn-default.note-btn.btn-sm:hover:active,
.note-editor .wizard > .actions .disabled a.btn-default.note-btn.btn-sm:hover:active,
.wizard > .actions .disabled .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:hover:active,
.note-editor .owl-theme .owl-nav .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:hover:active,
.wizard > .actions .disabled .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:hover:active,
.owl-theme .owl-nav .note-editor .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:hover:active,
.wizard > .actions .disabled .owl-theme .owl-nav a.btn-outline[class*='owl-']:hover:active,
.owl-theme .owl-nav .wizard > .actions .disabled a.btn-outline[class*='owl-']:hover:active,
.wizard > .actions a.btn-outline.btn-default:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default.btn:hover,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions a[class*='owl-']:hover,
.wizard > .actions .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:hover,
.wizard > .actions .note-editor a.btn-default.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.btn-default.note-btn.btn-sm:hover,
.wizard > .actions .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:hover,
.note-editor .owl-theme .owl-nav .wizard > .actions a.note-btn.btn-sm[class*='owl-']:hover,
.wizard > .actions .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .wizard > .actions a.note-btn.btn-sm[class*='owl-']:hover,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:hover,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:hover,
.wizard > .actions a.btn-outline.btn-default:hover:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default.btn:hover:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default.btn:hover:active,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:hover:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions a[class*='owl-']:hover:active,
.wizard > .actions .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:hover:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:hover:active,
.wizard > .actions .note-editor a.btn-default.note-btn.btn-sm:hover:active,
.note-editor .wizard > .actions a.btn-default.note-btn.btn-sm:hover:active,
.wizard > .actions .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:hover:active,
.note-editor .owl-theme .owl-nav .wizard > .actions a.note-btn.btn-sm[class*='owl-']:hover:active,
.wizard > .actions .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:hover:active,
.owl-theme .owl-nav .note-editor .wizard > .actions a.note-btn.btn-sm[class*='owl-']:hover:active,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:hover:active,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:hover:active,
ul.wysihtml5-toolbar a.btn.btn-default:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default:hover,
ul.wysihtml5-toolbar .dt-buttons a.btn-default.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.btn-default.dt-button:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:hover,
ul.wysihtml5-toolbar a.btn-default.fc-prev-button:hover,
ul.wysihtml5-toolbar a.btn-default.fc-next-button:hover,
ul.wysihtml5-toolbar a.btn-default.fc-today-button:hover,
.note-editor .btn.btn-default.note-btn.btn-sm:hover,
.note-editor .dt-buttons .btn-default.note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .btn-default.note-btn.btn-sm.dt-button:hover,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.note-btn.btn-sm:hover,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.note-btn.btn-sm:hover,
.note-editor .sweet-alert button.btn-default.note-btn.btn-sm:hover,
.sweet-alert .note-editor button.btn-default.note-btn.btn-sm:hover,
.note-editor .owl-theme .owl-nav .note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .note-btn.btn-sm[class*='owl-']:hover,
.note-editor button.btn-default.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.note-editor button.btn-default.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left:hover,
.note-editor button.btn-default.note-btn.btn-sm.fc-agendaWeek-button:hover,
.note-editor .btn-default.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .btn-default.note-btn.btn-sm.fc-next-button:hover,
.note-editor .btn-default.note-btn.btn-sm.fc-today-button:hover,
.dt-buttons .btn-outline.btn-default.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.btn-default.dt-button.btn:hover,
ul.wysihtml5-toolbar .dt-buttons a.btn-default.dt-button.btn:hover,
.dt-buttons ul.wysihtml5-toolbar .owl-theme .owl-nav a.dt-button[class*='owl-']:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav .dt-buttons a.dt-button[class*='owl-']:hover,
.dt-buttons .owl-theme .owl-nav ul.wysihtml5-toolbar a.dt-button[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .dt-buttons a.dt-button[class*='owl-']:hover,
.dt-buttons .note-editor .owl-theme .owl-nav .dt-button.note-btn.btn-sm[class*='owl-']:hover,
.note-editor .owl-theme .owl-nav .dt-buttons .dt-button.note-btn.btn-sm[class*='owl-']:hover,
.dt-buttons .owl-theme .owl-nav .note-editor .dt-button.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .dt-buttons .dt-button.note-btn.btn-sm[class*='owl-']:hover,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button:hover,
.dt-buttons .owl-theme .owl-nav .btn-outline.dt-button[class*='owl-']:hover,
.owl-theme .owl-nav .dt-buttons .btn-outline.dt-button[class*='owl-']:hover,
.dt-buttons
  .owl-theme
  .owl-nav
  button.dt-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.owl-theme
  .owl-nav
  .dt-buttons
  button.dt-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.dt-buttons .owl-theme .owl-nav button.dt-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:hover,
.owl-theme .owl-nav .dt-buttons button.dt-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:hover,
.dt-buttons .owl-theme .owl-nav button.dt-button[class*='owl-'].fc-agendaWeek-button:hover,
.owl-theme .owl-nav .dt-buttons button.dt-button[class*='owl-'].fc-agendaWeek-button:hover,
.dt-buttons button.btn-default.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.dt-buttons button.btn-default.dt-button.fc-month-button.fc-state-default.fc-corner-left:hover,
.dt-buttons button.btn-default.dt-button.fc-agendaWeek-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button:hover,
.sweet-alert button.btn-outline.btn-default:hover,
.sweet-alert .note-editor .owl-theme .owl-nav button.note-btn.btn-sm[class*='owl-']:hover,
.note-editor .owl-theme .owl-nav .sweet-alert button.note-btn.btn-sm[class*='owl-']:hover,
.sweet-alert .owl-theme .owl-nav .note-editor button.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .sweet-alert button.note-btn.btn-sm[class*='owl-']:hover,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button:hover,
.sweet-alert .owl-theme .owl-nav button.btn-outline[class*='owl-']:hover,
.owl-theme .owl-nav .sweet-alert button.btn-outline[class*='owl-']:hover,
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:hover,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:hover,
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:hover,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-agendaWeek-button:hover,
.sweet-alert button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.sweet-alert button.btn-default.fc-month-button.fc-state-default.fc-corner-left:hover,
.sweet-alert button.btn-default.fc-agendaWeek-button:hover,
.owl-theme .owl-nav .btn-outline[class*='owl-']:hover,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-']:hover,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-']:hover,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:hover,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:hover,
button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-default.fc-month-button.fc-state-default.fc-corner-left:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-default.fc-agendaWeek-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button:hover,
.btn-outline.btn-default.fc-prev-button:hover,
ul.wysihtml5-toolbar a.btn-default.fc-prev-button.btn:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-prev-button[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-prev-button[class*='owl-']:hover,
.note-editor .owl-theme .owl-nav .fc-prev-button.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .fc-prev-button.note-btn.btn-sm[class*='owl-']:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:hover,
.owl-theme .owl-nav .btn-outline.fc-prev-button[class*='owl-']:hover,
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:hover,
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-agendaWeek-button:hover,
button.btn-default.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-default.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-default.fc-prev-button.fc-agendaWeek-button:hover,
.btn-outline.btn-default.fc-next-button:hover,
ul.wysihtml5-toolbar a.btn-default.fc-next-button.btn:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-next-button[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-next-button[class*='owl-']:hover,
.note-editor .owl-theme .owl-nav .fc-next-button.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .fc-next-button.note-btn.btn-sm[class*='owl-']:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:hover,
.owl-theme .owl-nav .btn-outline.fc-next-button[class*='owl-']:hover,
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:hover,
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-agendaWeek-button:hover,
button.btn-default.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-default.fc-next-button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-default.fc-next-button.fc-agendaWeek-button:hover,
.btn-outline.btn-default.fc-today-button:hover,
ul.wysihtml5-toolbar a.btn-default.fc-today-button.btn:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-today-button[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-today-button[class*='owl-']:hover,
.note-editor .owl-theme .owl-nav .fc-today-button.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .fc-today-button.note-btn.btn-sm[class*='owl-']:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:hover,
.owl-theme .owl-nav .btn-outline.fc-today-button[class*='owl-']:hover,
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:hover,
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-agendaWeek-button:hover,
button.btn-default.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-default.fc-today-button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-default.fc-today-button.fc-agendaWeek-button:hover,
.btn.btn-outline.btn-default:focus,
.wizard > .actions .disabled a.btn-outline.btn-default:focus,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-default.btn:focus,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-default.btn:focus,
.wizard > .actions .disabled ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions .disabled a[class*='owl-']:focus,
.wizard > .actions .disabled .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions .disabled a[class*='owl-']:focus,
.wizard > .actions .disabled .note-editor a.btn-default.note-btn.btn-sm:focus,
.note-editor .wizard > .actions .disabled a.btn-default.note-btn.btn-sm:focus,
.wizard > .actions .disabled .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:focus,
.note-editor .owl-theme .owl-nav .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:focus,
.wizard > .actions .disabled .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:focus,
.wizard > .actions .disabled .owl-theme .owl-nav a.btn-outline[class*='owl-']:focus,
.owl-theme .owl-nav .wizard > .actions .disabled a.btn-outline[class*='owl-']:focus,
.wizard > .actions .disabled a.btn-outline.btn-default:focus:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-default.btn:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-default.btn:focus:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:focus:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions .disabled a[class*='owl-']:focus:hover,
.wizard > .actions .disabled .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:focus:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions .disabled a[class*='owl-']:focus:hover,
.wizard > .actions .disabled .note-editor a.btn-default.note-btn.btn-sm:focus:hover,
.note-editor .wizard > .actions .disabled a.btn-default.note-btn.btn-sm:focus:hover,
.wizard > .actions .disabled .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:focus:hover,
.note-editor .owl-theme .owl-nav .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:focus:hover,
.wizard > .actions .disabled .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:focus:hover,
.owl-theme .owl-nav .note-editor .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:focus:hover,
.wizard > .actions .disabled .owl-theme .owl-nav a.btn-outline[class*='owl-']:focus:hover,
.owl-theme .owl-nav .wizard > .actions .disabled a.btn-outline[class*='owl-']:focus:hover,
.wizard > .actions .disabled a.btn-outline.btn-default:focus:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-default.btn:focus:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-default.btn:focus:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:focus:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions .disabled a[class*='owl-']:focus:active,
.wizard > .actions .disabled .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:focus:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions .disabled a[class*='owl-']:focus:active,
.wizard > .actions .disabled .note-editor a.btn-default.note-btn.btn-sm:focus:active,
.note-editor .wizard > .actions .disabled a.btn-default.note-btn.btn-sm:focus:active,
.wizard > .actions .disabled .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:focus:active,
.note-editor .owl-theme .owl-nav .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:focus:active,
.wizard > .actions .disabled .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:focus:active,
.owl-theme .owl-nav .note-editor .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:focus:active,
.wizard > .actions .disabled .owl-theme .owl-nav a.btn-outline[class*='owl-']:focus:active,
.owl-theme .owl-nav .wizard > .actions .disabled a.btn-outline[class*='owl-']:focus:active,
.wizard > .actions a.btn-outline.btn-default:focus,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default.btn:focus,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default.btn:focus,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions a[class*='owl-']:focus,
.wizard > .actions .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:focus,
.wizard > .actions .note-editor a.btn-default.note-btn.btn-sm:focus,
.note-editor .wizard > .actions a.btn-default.note-btn.btn-sm:focus,
.wizard > .actions .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:focus,
.note-editor .owl-theme .owl-nav .wizard > .actions a.note-btn.btn-sm[class*='owl-']:focus,
.wizard > .actions .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .wizard > .actions a.note-btn.btn-sm[class*='owl-']:focus,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:focus,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:focus,
.wizard > .actions a.btn-outline.btn-default:focus:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default.btn:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default.btn:focus:hover,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:focus:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions a[class*='owl-']:focus:hover,
.wizard > .actions .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:focus:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:focus:hover,
.wizard > .actions .note-editor a.btn-default.note-btn.btn-sm:focus:hover,
.note-editor .wizard > .actions a.btn-default.note-btn.btn-sm:focus:hover,
.wizard > .actions .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:focus:hover,
.note-editor .owl-theme .owl-nav .wizard > .actions a.note-btn.btn-sm[class*='owl-']:focus:hover,
.wizard > .actions .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:focus:hover,
.owl-theme .owl-nav .note-editor .wizard > .actions a.note-btn.btn-sm[class*='owl-']:focus:hover,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:focus:hover,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:focus:hover,
.wizard > .actions a.btn-outline.btn-default:focus:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default.btn:focus:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default.btn:focus:active,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:focus:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions a[class*='owl-']:focus:active,
.wizard > .actions .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:focus:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:focus:active,
.wizard > .actions .note-editor a.btn-default.note-btn.btn-sm:focus:active,
.note-editor .wizard > .actions a.btn-default.note-btn.btn-sm:focus:active,
.wizard > .actions .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:focus:active,
.note-editor .owl-theme .owl-nav .wizard > .actions a.note-btn.btn-sm[class*='owl-']:focus:active,
.wizard > .actions .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:focus:active,
.owl-theme .owl-nav .note-editor .wizard > .actions a.note-btn.btn-sm[class*='owl-']:focus:active,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:focus:active,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:focus:active,
ul.wysihtml5-toolbar a.btn.btn-default:focus,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default:focus,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default:focus,
ul.wysihtml5-toolbar .dt-buttons a.btn-default.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.btn-default.dt-button:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:focus,
ul.wysihtml5-toolbar a.btn-default.fc-prev-button:focus,
ul.wysihtml5-toolbar a.btn-default.fc-next-button:focus,
ul.wysihtml5-toolbar a.btn-default.fc-today-button:focus,
.note-editor .btn.btn-default.note-btn.btn-sm:focus,
.note-editor .dt-buttons .btn-default.note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .btn-default.note-btn.btn-sm.dt-button:focus,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.note-btn.btn-sm:focus,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.note-btn.btn-sm:focus,
.note-editor .sweet-alert button.btn-default.note-btn.btn-sm:focus,
.sweet-alert .note-editor button.btn-default.note-btn.btn-sm:focus,
.note-editor .owl-theme .owl-nav .note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .note-btn.btn-sm[class*='owl-']:focus,
.note-editor button.btn-default.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.note-editor button.btn-default.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left:focus,
.note-editor button.btn-default.note-btn.btn-sm.fc-agendaWeek-button:focus,
.note-editor .btn-default.note-btn.btn-sm.fc-prev-button:focus,
.note-editor .btn-default.note-btn.btn-sm.fc-next-button:focus,
.note-editor .btn-default.note-btn.btn-sm.fc-today-button:focus,
.dt-buttons .btn-outline.btn-default.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.btn-default.dt-button.btn:focus,
ul.wysihtml5-toolbar .dt-buttons a.btn-default.dt-button.btn:focus,
.dt-buttons ul.wysihtml5-toolbar .owl-theme .owl-nav a.dt-button[class*='owl-']:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav .dt-buttons a.dt-button[class*='owl-']:focus,
.dt-buttons .owl-theme .owl-nav ul.wysihtml5-toolbar a.dt-button[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar .dt-buttons a.dt-button[class*='owl-']:focus,
.dt-buttons .note-editor .owl-theme .owl-nav .dt-button.note-btn.btn-sm[class*='owl-']:focus,
.note-editor .owl-theme .owl-nav .dt-buttons .dt-button.note-btn.btn-sm[class*='owl-']:focus,
.dt-buttons .owl-theme .owl-nav .note-editor .dt-button.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .dt-buttons .dt-button.note-btn.btn-sm[class*='owl-']:focus,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button:focus,
.dt-buttons .owl-theme .owl-nav .btn-outline.dt-button[class*='owl-']:focus,
.owl-theme .owl-nav .dt-buttons .btn-outline.dt-button[class*='owl-']:focus,
.dt-buttons
  .owl-theme
  .owl-nav
  button.dt-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.owl-theme
  .owl-nav
  .dt-buttons
  button.dt-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.dt-buttons .owl-theme .owl-nav button.dt-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:focus,
.owl-theme .owl-nav .dt-buttons button.dt-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:focus,
.dt-buttons .owl-theme .owl-nav button.dt-button[class*='owl-'].fc-agendaWeek-button:focus,
.owl-theme .owl-nav .dt-buttons button.dt-button[class*='owl-'].fc-agendaWeek-button:focus,
.dt-buttons button.btn-default.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.dt-buttons button.btn-default.dt-button.fc-month-button.fc-state-default.fc-corner-left:focus,
.dt-buttons button.btn-default.dt-button.fc-agendaWeek-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button:focus,
.sweet-alert button.btn-outline.btn-default:focus,
.sweet-alert .note-editor .owl-theme .owl-nav button.note-btn.btn-sm[class*='owl-']:focus,
.note-editor .owl-theme .owl-nav .sweet-alert button.note-btn.btn-sm[class*='owl-']:focus,
.sweet-alert .owl-theme .owl-nav .note-editor button.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .sweet-alert button.note-btn.btn-sm[class*='owl-']:focus,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button:focus,
.sweet-alert .owl-theme .owl-nav button.btn-outline[class*='owl-']:focus,
.owl-theme .owl-nav .sweet-alert button.btn-outline[class*='owl-']:focus,
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:focus,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:focus,
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:focus,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-agendaWeek-button:focus,
.sweet-alert button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.sweet-alert button.btn-default.fc-month-button.fc-state-default.fc-corner-left:focus,
.sweet-alert button.btn-default.fc-agendaWeek-button:focus,
.owl-theme .owl-nav .btn-outline[class*='owl-']:focus,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-']:focus,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-']:focus,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:focus,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:focus,
button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-default.fc-month-button.fc-state-default.fc-corner-left:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-default.fc-agendaWeek-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button:focus,
.btn-outline.btn-default.fc-prev-button:focus,
ul.wysihtml5-toolbar a.btn-default.fc-prev-button.btn:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-prev-button[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-prev-button[class*='owl-']:focus,
.note-editor .owl-theme .owl-nav .fc-prev-button.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .fc-prev-button.note-btn.btn-sm[class*='owl-']:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:focus,
.owl-theme .owl-nav .btn-outline.fc-prev-button[class*='owl-']:focus,
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:focus,
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-agendaWeek-button:focus,
button.btn-default.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-default.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-default.fc-prev-button.fc-agendaWeek-button:focus,
.btn-outline.btn-default.fc-next-button:focus,
ul.wysihtml5-toolbar a.btn-default.fc-next-button.btn:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-next-button[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-next-button[class*='owl-']:focus,
.note-editor .owl-theme .owl-nav .fc-next-button.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .fc-next-button.note-btn.btn-sm[class*='owl-']:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:focus,
.owl-theme .owl-nav .btn-outline.fc-next-button[class*='owl-']:focus,
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:focus,
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-agendaWeek-button:focus,
button.btn-default.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-default.fc-next-button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-default.fc-next-button.fc-agendaWeek-button:focus,
.btn-outline.btn-default.fc-today-button:focus,
ul.wysihtml5-toolbar a.btn-default.fc-today-button.btn:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-today-button[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-today-button[class*='owl-']:focus,
.note-editor .owl-theme .owl-nav .fc-today-button.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .fc-today-button.note-btn.btn-sm[class*='owl-']:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:focus,
.owl-theme .owl-nav .btn-outline.fc-today-button[class*='owl-']:focus,
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:focus,
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-agendaWeek-button:focus,
button.btn-default.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-default.fc-today-button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-default.fc-today-button.fc-agendaWeek-button:focus,
.btn.btn-outline.btn-default:active,
.wizard > .actions .disabled a.btn-outline.btn-default:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-default.btn:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-default.btn:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions .disabled a[class*='owl-']:active,
.wizard > .actions .disabled .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions .disabled a[class*='owl-']:active,
.wizard > .actions .disabled .note-editor a.btn-default.note-btn.btn-sm:active,
.note-editor .wizard > .actions .disabled a.btn-default.note-btn.btn-sm:active,
.wizard > .actions .disabled .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:active,
.note-editor .owl-theme .owl-nav .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:active,
.wizard > .actions .disabled .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:active,
.wizard > .actions .disabled .owl-theme .owl-nav a.btn-outline[class*='owl-']:active,
.owl-theme .owl-nav .wizard > .actions .disabled a.btn-outline[class*='owl-']:active,
.wizard > .actions .disabled a.btn-outline.btn-default:active:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-default.btn:active:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-default.btn:active:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:active:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions .disabled a[class*='owl-']:active:hover,
.wizard > .actions .disabled .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:active:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions .disabled a[class*='owl-']:active:hover,
.wizard > .actions .disabled .note-editor a.btn-default.note-btn.btn-sm:active:hover,
.note-editor .wizard > .actions .disabled a.btn-default.note-btn.btn-sm:active:hover,
.wizard > .actions .disabled .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:active:hover,
.note-editor .owl-theme .owl-nav .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:active:hover,
.wizard > .actions .disabled .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:active:hover,
.owl-theme .owl-nav .note-editor .wizard > .actions .disabled a.note-btn.btn-sm[class*='owl-']:active:hover,
.wizard > .actions .disabled .owl-theme .owl-nav a.btn-outline[class*='owl-']:active:hover,
.owl-theme .owl-nav .wizard > .actions .disabled a.btn-outline[class*='owl-']:active:hover,
.wizard > .actions a.btn-outline.btn-default:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default.btn:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default.btn:active,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions a[class*='owl-']:active,
.wizard > .actions .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:active,
.wizard > .actions .note-editor a.btn-default.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.btn-default.note-btn.btn-sm:active,
.wizard > .actions .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:active,
.note-editor .owl-theme .owl-nav .wizard > .actions a.note-btn.btn-sm[class*='owl-']:active,
.wizard > .actions .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .wizard > .actions a.note-btn.btn-sm[class*='owl-']:active,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:active,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:active,
.wizard > .actions a.btn-outline.btn-default:active:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default.btn:active:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default.btn:active:hover,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:active:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav .wizard > .actions a[class*='owl-']:active:hover,
.wizard > .actions .owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:active:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:active:hover,
.wizard > .actions .note-editor a.btn-default.note-btn.btn-sm:active:hover,
.note-editor .wizard > .actions a.btn-default.note-btn.btn-sm:active:hover,
.wizard > .actions .note-editor .owl-theme .owl-nav a.note-btn.btn-sm[class*='owl-']:active:hover,
.note-editor .owl-theme .owl-nav .wizard > .actions a.note-btn.btn-sm[class*='owl-']:active:hover,
.wizard > .actions .owl-theme .owl-nav .note-editor a.note-btn.btn-sm[class*='owl-']:active:hover,
.owl-theme .owl-nav .note-editor .wizard > .actions a.note-btn.btn-sm[class*='owl-']:active:hover,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:active:hover,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:active:hover,
ul.wysihtml5-toolbar a.btn.btn-default:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-default:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-default:active,
ul.wysihtml5-toolbar .dt-buttons a.btn-default.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.btn-default.dt-button:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-']:active,
ul.wysihtml5-toolbar a.btn-default.fc-prev-button:active,
ul.wysihtml5-toolbar a.btn-default.fc-next-button:active,
ul.wysihtml5-toolbar a.btn-default.fc-today-button:active,
.note-editor .btn.btn-default.note-btn.btn-sm:active,
.note-editor .dt-buttons .btn-default.note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .btn-default.note-btn.btn-sm.dt-button:active,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.note-btn.btn-sm:active,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.note-btn.btn-sm:active,
.note-editor .sweet-alert button.btn-default.note-btn.btn-sm:active,
.sweet-alert .note-editor button.btn-default.note-btn.btn-sm:active,
.note-editor .owl-theme .owl-nav .note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .note-btn.btn-sm[class*='owl-']:active,
.note-editor button.btn-default.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.note-editor button.btn-default.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left:active,
.note-editor button.btn-default.note-btn.btn-sm.fc-agendaWeek-button:active,
.note-editor .btn-default.note-btn.btn-sm.fc-prev-button:active,
.note-editor .btn-default.note-btn.btn-sm.fc-next-button:active,
.note-editor .btn-default.note-btn.btn-sm.fc-today-button:active,
.dt-buttons .btn-outline.btn-default.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.btn-default.dt-button.btn:active,
ul.wysihtml5-toolbar .dt-buttons a.btn-default.dt-button.btn:active,
.dt-buttons ul.wysihtml5-toolbar .owl-theme .owl-nav a.dt-button[class*='owl-']:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav .dt-buttons a.dt-button[class*='owl-']:active,
.dt-buttons .owl-theme .owl-nav ul.wysihtml5-toolbar a.dt-button[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .dt-buttons a.dt-button[class*='owl-']:active,
.dt-buttons .note-editor .owl-theme .owl-nav .dt-button.note-btn.btn-sm[class*='owl-']:active,
.note-editor .owl-theme .owl-nav .dt-buttons .dt-button.note-btn.btn-sm[class*='owl-']:active,
.dt-buttons .owl-theme .owl-nav .note-editor .dt-button.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .dt-buttons .dt-button.note-btn.btn-sm[class*='owl-']:active,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button:active,
.dt-buttons .owl-theme .owl-nav .btn-outline.dt-button[class*='owl-']:active,
.owl-theme .owl-nav .dt-buttons .btn-outline.dt-button[class*='owl-']:active,
.dt-buttons
  .owl-theme
  .owl-nav
  button.dt-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.owl-theme
  .owl-nav
  .dt-buttons
  button.dt-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.dt-buttons .owl-theme .owl-nav button.dt-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:active,
.owl-theme .owl-nav .dt-buttons button.dt-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:active,
.dt-buttons .owl-theme .owl-nav button.dt-button[class*='owl-'].fc-agendaWeek-button:active,
.owl-theme .owl-nav .dt-buttons button.dt-button[class*='owl-'].fc-agendaWeek-button:active,
.dt-buttons button.btn-default.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.dt-buttons button.btn-default.dt-button.fc-month-button.fc-state-default.fc-corner-left:active,
.dt-buttons button.btn-default.dt-button.fc-agendaWeek-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button:active,
.sweet-alert button.btn-outline.btn-default:active,
.sweet-alert .note-editor .owl-theme .owl-nav button.note-btn.btn-sm[class*='owl-']:active,
.note-editor .owl-theme .owl-nav .sweet-alert button.note-btn.btn-sm[class*='owl-']:active,
.sweet-alert .owl-theme .owl-nav .note-editor button.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .sweet-alert button.note-btn.btn-sm[class*='owl-']:active,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button:active,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button:active,
.sweet-alert .owl-theme .owl-nav button.btn-outline[class*='owl-']:active,
.owl-theme .owl-nav .sweet-alert button.btn-outline[class*='owl-']:active,
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:active,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:active,
.sweet-alert .owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:active,
.owl-theme .owl-nav .sweet-alert button[class*='owl-'].fc-agendaWeek-button:active,
.sweet-alert button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.sweet-alert button.btn-default.fc-month-button.fc-state-default.fc-corner-left:active,
.sweet-alert button.btn-default.fc-agendaWeek-button:active,
.owl-theme .owl-nav .btn-outline[class*='owl-']:active,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-']:active,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-']:active,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:active,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:active,
button.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-default.fc-month-button.fc-state-default.fc-corner-left:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-default.fc-agendaWeek-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button:active,
.btn-outline.btn-default.fc-prev-button:active,
ul.wysihtml5-toolbar a.btn-default.fc-prev-button.btn:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-prev-button[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-prev-button[class*='owl-']:active,
.note-editor .owl-theme .owl-nav .fc-prev-button.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .fc-prev-button.note-btn.btn-sm[class*='owl-']:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:active,
.owl-theme .owl-nav .btn-outline.fc-prev-button[class*='owl-']:active,
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:active,
.owl-theme .owl-nav button.fc-prev-button[class*='owl-'].fc-agendaWeek-button:active,
button.btn-default.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-default.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-default.fc-prev-button.fc-agendaWeek-button:active,
.btn-outline.btn-default.fc-next-button:active,
ul.wysihtml5-toolbar a.btn-default.fc-next-button.btn:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-next-button[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-next-button[class*='owl-']:active,
.note-editor .owl-theme .owl-nav .fc-next-button.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .fc-next-button.note-btn.btn-sm[class*='owl-']:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:active,
.owl-theme .owl-nav .btn-outline.fc-next-button[class*='owl-']:active,
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:active,
.owl-theme .owl-nav button.fc-next-button[class*='owl-'].fc-agendaWeek-button:active,
button.btn-default.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-default.fc-next-button.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-default.fc-next-button.fc-agendaWeek-button:active,
.btn-outline.btn-default.fc-today-button:active,
ul.wysihtml5-toolbar a.btn-default.fc-today-button.btn:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.fc-today-button[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.fc-today-button[class*='owl-']:active,
.note-editor .owl-theme .owl-nav .fc-today-button.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .fc-today-button.note-btn.btn-sm[class*='owl-']:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:active,
.owl-theme .owl-nav .btn-outline.fc-today-button[class*='owl-']:active,
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:active,
.owl-theme .owl-nav button.fc-today-button[class*='owl-'].fc-agendaWeek-button:active,
button.btn-default.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-default.fc-today-button.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-default.fc-today-button.fc-agendaWeek-button:active {
  border: solid 1px gainsboro;
}
.btn.btn-outline.btn-info,
.wizard > .actions a.btn-outline.btn-info,
.wizard > .actions .note-editor a.btn-info.note-btn.btn-sm,
.note-editor .wizard > .actions a.btn-info.note-btn.btn-sm,
ul.wysihtml5-toolbar a.btn.btn-info,
ul.wysihtml5-toolbar .wizard > .actions a.btn-info,
.wizard > .actions ul.wysihtml5-toolbar a.btn-info,
ul.wysihtml5-toolbar .dt-buttons a.btn-info.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.btn-info.dt-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-info[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-info[class*='owl-'],
ul.wysihtml5-toolbar a.btn-info.fc-prev-button,
ul.wysihtml5-toolbar a.btn-info.fc-next-button,
ul.wysihtml5-toolbar a.btn-info.fc-today-button,
.note-editor .btn.btn-info.note-btn.btn-sm,
.note-editor .dt-buttons .btn-info.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .btn-info.note-btn.btn-sm.dt-button,
.note-editor .sweet-alert button.btn-info.note-btn.btn-sm,
.sweet-alert .note-editor button.btn-info.note-btn.btn-sm,
.note-editor .owl-theme .owl-nav .btn-info.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .btn-info.note-btn.btn-sm[class*='owl-'],
.note-editor button.btn-info.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .btn-info.note-btn.btn-sm.fc-prev-button,
.note-editor .btn-info.note-btn.btn-sm.fc-next-button,
.note-editor .btn-info.note-btn.btn-sm.fc-today-button,
.dt-buttons .btn-outline.btn-info.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-info,
.sweet-alert button.btn-outline.btn-info,
.owl-theme .owl-nav .btn-outline.btn-info[class*='owl-'],
.owl-theme .owl-nav button.btn-info[class*='owl-'].fc-agendaWeek-button,
button.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-info.fc-month-button.fc-state-default.fc-corner-left,
button.btn-info.fc-agendaWeek-button,
.btn-outline.btn-info.fc-prev-button,
.btn-outline.btn-info.fc-next-button,
.btn-outline.btn-info.fc-today-button {
  border: solid 1px rgba(231, 54, 141, 0.5);
  color: #e7368d;
}
.btn.btn-outline.btn-info:hover,
.wizard > .actions a.btn-outline.btn-info:hover,
.wizard > .actions .note-editor a.btn-info.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.btn-info.note-btn.btn-sm:hover,
ul.wysihtml5-toolbar a.btn.btn-info:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-info:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-info:hover,
ul.wysihtml5-toolbar .dt-buttons a.btn-info.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.btn-info.dt-button:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-info[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-info[class*='owl-']:hover,
ul.wysihtml5-toolbar a.btn-info.fc-prev-button:hover,
ul.wysihtml5-toolbar a.btn-info.fc-next-button:hover,
ul.wysihtml5-toolbar a.btn-info.fc-today-button:hover,
.note-editor .btn.btn-info.note-btn.btn-sm:hover,
.note-editor .dt-buttons .btn-info.note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .btn-info.note-btn.btn-sm.dt-button:hover,
.note-editor .sweet-alert button.btn-info.note-btn.btn-sm:hover,
.sweet-alert .note-editor button.btn-info.note-btn.btn-sm:hover,
.note-editor .owl-theme .owl-nav .btn-info.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .btn-info.note-btn.btn-sm[class*='owl-']:hover,
.note-editor .btn-info.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .btn-info.note-btn.btn-sm.fc-next-button:hover,
.note-editor .btn-info.note-btn.btn-sm.fc-today-button:hover,
.dt-buttons .btn-outline.btn-info.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-info:hover,
.sweet-alert button.btn-outline.btn-info:hover,
.owl-theme .owl-nav .btn-outline.btn-info[class*='owl-']:hover,
button.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-info.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-info.fc-agendaWeek-button:hover,
.btn-outline.btn-info.fc-prev-button:hover,
.btn-outline.btn-info.fc-next-button:hover,
.btn-outline.btn-info.fc-today-button:hover,
.btn.btn-outline.btn-info:focus,
.wizard > .actions a.btn-outline.btn-info:focus,
.wizard > .actions .note-editor a.btn-info.note-btn.btn-sm:focus,
.note-editor .wizard > .actions a.btn-info.note-btn.btn-sm:focus,
ul.wysihtml5-toolbar a.btn.btn-info:focus,
ul.wysihtml5-toolbar .wizard > .actions a.btn-info:focus,
.wizard > .actions ul.wysihtml5-toolbar a.btn-info:focus,
ul.wysihtml5-toolbar .dt-buttons a.btn-info.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.btn-info.dt-button:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-info[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-info[class*='owl-']:focus,
ul.wysihtml5-toolbar a.btn-info.fc-prev-button:focus,
ul.wysihtml5-toolbar a.btn-info.fc-next-button:focus,
ul.wysihtml5-toolbar a.btn-info.fc-today-button:focus,
.note-editor .btn.btn-info.note-btn.btn-sm:focus,
.note-editor .dt-buttons .btn-info.note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .btn-info.note-btn.btn-sm.dt-button:focus,
.note-editor .sweet-alert button.btn-info.note-btn.btn-sm:focus,
.sweet-alert .note-editor button.btn-info.note-btn.btn-sm:focus,
.note-editor .owl-theme .owl-nav .btn-info.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .btn-info.note-btn.btn-sm[class*='owl-']:focus,
.note-editor .btn-info.note-btn.btn-sm.fc-prev-button:focus,
.note-editor .btn-info.note-btn.btn-sm.fc-next-button:focus,
.note-editor .btn-info.note-btn.btn-sm.fc-today-button:focus,
.dt-buttons .btn-outline.btn-info.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-info:focus,
.sweet-alert button.btn-outline.btn-info:focus,
.owl-theme .owl-nav .btn-outline.btn-info[class*='owl-']:focus,
button.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-info.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-info.fc-agendaWeek-button:focus,
.btn-outline.btn-info.fc-prev-button:focus,
.btn-outline.btn-info.fc-next-button:focus,
.btn-outline.btn-info.fc-today-button:focus,
.btn.btn-outline.btn-info:active,
.wizard > .actions a.btn-outline.btn-info:active,
.wizard > .actions .note-editor a.btn-info.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.btn-info.note-btn.btn-sm:active,
ul.wysihtml5-toolbar a.btn.btn-info:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-info:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-info:active,
ul.wysihtml5-toolbar .dt-buttons a.btn-info.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.btn-info.dt-button:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-info[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-info[class*='owl-']:active,
ul.wysihtml5-toolbar a.btn-info.fc-prev-button:active,
ul.wysihtml5-toolbar a.btn-info.fc-next-button:active,
ul.wysihtml5-toolbar a.btn-info.fc-today-button:active,
.note-editor .btn.btn-info.note-btn.btn-sm:active,
.note-editor .dt-buttons .btn-info.note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .btn-info.note-btn.btn-sm.dt-button:active,
.note-editor .sweet-alert button.btn-info.note-btn.btn-sm:active,
.sweet-alert .note-editor button.btn-info.note-btn.btn-sm:active,
.note-editor .owl-theme .owl-nav .btn-info.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .btn-info.note-btn.btn-sm[class*='owl-']:active,
.note-editor .btn-info.note-btn.btn-sm.fc-prev-button:active,
.note-editor .btn-info.note-btn.btn-sm.fc-next-button:active,
.note-editor .btn-info.note-btn.btn-sm.fc-today-button:active,
.dt-buttons .btn-outline.btn-info.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-info:active,
.sweet-alert button.btn-outline.btn-info:active,
.owl-theme .owl-nav .btn-outline.btn-info[class*='owl-']:active,
button.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-info.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-info.fc-agendaWeek-button:active,
.btn-outline.btn-info.fc-prev-button:active,
.btn-outline.btn-info.fc-next-button:active,
.btn-outline.btn-info.fc-today-button:active {
  border: solid 1px #e7368d;
}
.btn.btn-outline.btn-primary,
.wizard > .actions .disabled a.btn-outline,
ul.wysihtml5-toolbar .wizard > .actions .disabled a,
.wizard > .actions .disabled ul.wysihtml5-toolbar a,
.wizard > .actions ul.wysihtml5-toolbar .disabled a,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm,
.wizard > .actions .disabled a.btn-outline:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a:hover,
.wizard > .actions ul.wysihtml5-toolbar .disabled a:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm:hover,
.wizard > .actions .disabled a.btn-outline:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a:active,
.wizard > .actions ul.wysihtml5-toolbar .disabled a:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm:active,
.wizard > .actions a.btn-outline,
ul.wysihtml5-toolbar .wizard > .actions a,
.wizard > .actions ul.wysihtml5-toolbar a,
.wizard > .actions .note-editor a.note-btn.btn-sm,
.note-editor .wizard > .actions a.note-btn.btn-sm,
.wizard > .actions a.btn-outline:hover,
ul.wysihtml5-toolbar .wizard > .actions a:hover,
.wizard > .actions ul.wysihtml5-toolbar a:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm:hover,
.wizard > .actions a.btn-outline:active,
ul.wysihtml5-toolbar .wizard > .actions a:active,
.wizard > .actions ul.wysihtml5-toolbar a:active,
.wizard > .actions .note-editor a.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.note-btn.btn-sm:active,
ul.wysihtml5-toolbar a.btn.btn-primary,
ul.wysihtml5-toolbar .dt-buttons a.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.dt-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-primary[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-primary[class*='owl-'],
ul.wysihtml5-toolbar a.btn-primary.fc-prev-button,
ul.wysihtml5-toolbar a.btn-primary.fc-next-button,
ul.wysihtml5-toolbar a.btn-primary.fc-today-button,
.note-editor .btn.btn-primary.note-btn.btn-sm,
.note-editor .dt-buttons .note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .note-btn.btn-sm.dt-button,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.note-btn.btn-sm,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.btn-primary.note-btn.btn-sm,
.note-editor .sweet-alert button.btn-primary.note-btn.btn-sm,
.sweet-alert .note-editor button.btn-primary.note-btn.btn-sm,
.note-editor .owl-theme .owl-nav .btn-primary.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .btn-primary.note-btn.btn-sm[class*='owl-'],
.note-editor button.btn-primary.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,
.note-editor button.btn-primary.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left,
.note-editor button.btn-primary.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .btn-primary.note-btn.btn-sm.fc-prev-button,
.note-editor .btn-primary.note-btn.btn-sm.fc-next-button,
.note-editor .btn-primary.note-btn.btn-sm.fc-today-button,
.dt-buttons .btn-outline.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button,
.dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons button.dt-button.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary,
.sweet-alert button.btn-outline.btn-primary,
.sweet-alert .note-editor .dt-buttons button.note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .sweet-alert button.note-btn.btn-sm.dt-button,
.sweet-alert .dt-buttons .note-editor button.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .sweet-alert button.note-btn.btn-sm.dt-button,
.sweet-alert .dt-buttons button.btn-outline.dt-button,
.dt-buttons .sweet-alert button.btn-outline.dt-button,
.sweet-alert .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.dt-button,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .sweet-alert button.dt-button,
.sweet-alert .dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons .sweet-alert button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.sweet-alert .dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons .sweet-alert button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.sweet-alert .dt-buttons button.dt-button.fc-agendaWeek-button,
.dt-buttons .sweet-alert button.dt-button.fc-agendaWeek-button,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.btn-primary,
.sweet-alert button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right,
.sweet-alert button.btn-primary.fc-month-button.fc-state-default.fc-corner-left,
.sweet-alert button.btn-primary.fc-agendaWeek-button,
.owl-theme .owl-nav .btn-outline.btn-primary[class*='owl-'],
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-'],
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-'],
.owl-theme .owl-nav .wizard > .actions .note-editor a[class*='owl-'].note-btn.btn-sm,
.wizard > .actions .note-editor .owl-theme .owl-nav a[class*='owl-'].note-btn.btn-sm,
.owl-theme .owl-nav .note-editor .wizard > .actions a[class*='owl-'].note-btn.btn-sm,
.note-editor .wizard > .actions .owl-theme .owl-nav a[class*='owl-'].note-btn.btn-sm,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-primary[class*='owl-'].btn,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-primary[class*='owl-'].btn,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-'],
ul.wysihtml5-toolbar .wizard > .actions .owl-theme .owl-nav a[class*='owl-'],
.owl-theme .owl-nav .wizard > .actions ul.wysihtml5-toolbar a[class*='owl-'],
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar .dt-buttons a[class*='owl-'].dt-button,
ul.wysihtml5-toolbar .dt-buttons .owl-theme .owl-nav a[class*='owl-'].dt-button,
.owl-theme .owl-nav .dt-buttons ul.wysihtml5-toolbar a[class*='owl-'].dt-button,
.dt-buttons ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].dt-button,
.owl-theme .owl-nav .note-editor .dt-buttons [class*='owl-'].note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.dt-button,
.owl-theme .owl-nav .dt-buttons .note-editor [class*='owl-'].note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.dt-button,
.owl-theme .owl-nav .dt-buttons .btn-outline[class*='owl-'].dt-button,
.dt-buttons .owl-theme .owl-nav .btn-outline[class*='owl-'].dt-button,
.owl-theme .owl-nav .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].dt-button,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button[class*='owl-'].dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button,
.owl-theme .owl-nav .dt-buttons button[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme .owl-nav .dt-buttons button[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left,
.owl-theme .owl-nav .dt-buttons button[class*='owl-'].dt-button.fc-agendaWeek-button,
.dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button.fc-agendaWeek-button,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary[class*='owl-'],
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button.btn-primary[class*='owl-'],
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-agendaWeek-button,
button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-primary.fc-month-button.fc-state-default.fc-corner-left,
button.btn-primary.fc-agendaWeek-button,
.btn-outline.btn-primary.fc-prev-button,
.wizard > .actions a.btn-outline.fc-prev-button,
.wizard > .actions .note-editor a.fc-prev-button.note-btn.btn-sm,
.note-editor .wizard > .actions a.fc-prev-button.note-btn.btn-sm,
ul.wysihtml5-toolbar a.btn-primary.fc-prev-button.btn,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button,
ul.wysihtml5-toolbar .dt-buttons a.fc-prev-button.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.fc-prev-button.dt-button,
.note-editor .dt-buttons .fc-prev-button.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .fc-prev-button.note-btn.btn-sm.dt-button,
.dt-buttons .btn-outline.fc-prev-button.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-prev-button.dt-button,
.dt-buttons button.fc-prev-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons button.fc-prev-button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons button.fc-prev-button.dt-button.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-prev-button,
button.btn-primary.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-primary.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left,
button.btn-primary.fc-prev-button.fc-agendaWeek-button,
.btn-outline.btn-primary.fc-next-button,
.wizard > .actions a.btn-outline.fc-next-button,
.wizard > .actions .note-editor a.fc-next-button.note-btn.btn-sm,
.note-editor .wizard > .actions a.fc-next-button.note-btn.btn-sm,
ul.wysihtml5-toolbar a.btn-primary.fc-next-button.btn,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button,
ul.wysihtml5-toolbar .dt-buttons a.fc-next-button.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.fc-next-button.dt-button,
.note-editor .dt-buttons .fc-next-button.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .fc-next-button.note-btn.btn-sm.dt-button,
.dt-buttons .btn-outline.fc-next-button.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-next-button.dt-button,
.dt-buttons button.fc-next-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons button.fc-next-button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons button.fc-next-button.dt-button.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-next-button,
button.btn-primary.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-primary.fc-next-button.fc-month-button.fc-state-default.fc-corner-left,
button.btn-primary.fc-next-button.fc-agendaWeek-button,
.btn-outline.btn-primary.fc-today-button,
.wizard > .actions a.btn-outline.fc-today-button,
.wizard > .actions .note-editor a.fc-today-button.note-btn.btn-sm,
.note-editor .wizard > .actions a.fc-today-button.note-btn.btn-sm,
ul.wysihtml5-toolbar a.btn-primary.fc-today-button.btn,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button,
ul.wysihtml5-toolbar .dt-buttons a.fc-today-button.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.fc-today-button.dt-button,
.note-editor .dt-buttons .fc-today-button.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .fc-today-button.note-btn.btn-sm.dt-button,
.dt-buttons .btn-outline.fc-today-button.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-today-button.dt-button,
.dt-buttons button.fc-today-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons button.fc-today-button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons button.fc-today-button.dt-button.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-today-button,
button.btn-primary.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-primary.fc-today-button.fc-month-button.fc-state-default.fc-corner-left,
button.btn-primary.fc-today-button.fc-agendaWeek-button {
  border: solid 1px rgba(33, 150, 243, 0.5);
  color: #2196f3;
}
.btn.btn-outline.btn-primary:hover,
.wizard > .actions .disabled a.btn-outline:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a:hover,
.wizard > .actions ul.wysihtml5-toolbar .disabled a:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm:hover,
.wizard > .actions .disabled a.btn-outline:hover:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a:hover:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a:hover:active,
.wizard > .actions ul.wysihtml5-toolbar .disabled a:hover:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm:hover:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm:hover:active,
.wizard > .actions a.btn-outline:hover,
ul.wysihtml5-toolbar .wizard > .actions a:hover,
.wizard > .actions ul.wysihtml5-toolbar a:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm:hover,
.wizard > .actions a.btn-outline:hover:active,
ul.wysihtml5-toolbar .wizard > .actions a:hover:active,
.wizard > .actions ul.wysihtml5-toolbar a:hover:active,
.wizard > .actions .note-editor a.note-btn.btn-sm:hover:active,
.note-editor .wizard > .actions a.note-btn.btn-sm:hover:active,
ul.wysihtml5-toolbar a.btn.btn-primary:hover,
ul.wysihtml5-toolbar .dt-buttons a.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.dt-button:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-primary[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-primary[class*='owl-']:hover,
ul.wysihtml5-toolbar a.btn-primary.fc-prev-button:hover,
ul.wysihtml5-toolbar a.btn-primary.fc-next-button:hover,
ul.wysihtml5-toolbar a.btn-primary.fc-today-button:hover,
.note-editor .btn.btn-primary.note-btn.btn-sm:hover,
.note-editor .dt-buttons .note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .note-btn.btn-sm.dt-button:hover,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.note-btn.btn-sm:hover,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.btn-primary.note-btn.btn-sm:hover,
.note-editor .sweet-alert button.btn-primary.note-btn.btn-sm:hover,
.sweet-alert .note-editor button.btn-primary.note-btn.btn-sm:hover,
.note-editor .owl-theme .owl-nav .btn-primary.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .btn-primary.note-btn.btn-sm[class*='owl-']:hover,
.note-editor button.btn-primary.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.note-editor button.btn-primary.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left:hover,
.note-editor button.btn-primary.note-btn.btn-sm.fc-agendaWeek-button:hover,
.note-editor .btn-primary.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .btn-primary.note-btn.btn-sm.fc-next-button:hover,
.note-editor .btn-primary.note-btn.btn-sm.fc-today-button:hover,
.dt-buttons .btn-outline.dt-button:hover,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button:hover,
.dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left:hover,
.dt-buttons button.dt-button.fc-agendaWeek-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary:hover,
.sweet-alert button.btn-outline.btn-primary:hover,
.sweet-alert .note-editor .dt-buttons button.note-btn.btn-sm.dt-button:hover,
.note-editor .dt-buttons .sweet-alert button.note-btn.btn-sm.dt-button:hover,
.sweet-alert .dt-buttons .note-editor button.note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .sweet-alert button.note-btn.btn-sm.dt-button:hover,
.sweet-alert .dt-buttons button.btn-outline.dt-button:hover,
.dt-buttons .sweet-alert button.btn-outline.dt-button:hover,
.sweet-alert .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button:hover,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.dt-button:hover,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .sweet-alert button.dt-button:hover,
.sweet-alert .dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.dt-buttons .sweet-alert button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.sweet-alert .dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left:hover,
.dt-buttons .sweet-alert button.dt-button.fc-month-button.fc-state-default.fc-corner-left:hover,
.sweet-alert .dt-buttons button.dt-button.fc-agendaWeek-button:hover,
.dt-buttons .sweet-alert button.dt-button.fc-agendaWeek-button:hover,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary:hover,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.btn-primary:hover,
.sweet-alert button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.sweet-alert button.btn-primary.fc-month-button.fc-state-default.fc-corner-left:hover,
.sweet-alert button.btn-primary.fc-agendaWeek-button:hover,
.owl-theme .owl-nav .btn-outline.btn-primary[class*='owl-']:hover,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:hover,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:hover,
.owl-theme .owl-nav .wizard > .actions .note-editor a[class*='owl-'].note-btn.btn-sm:hover,
.wizard > .actions .note-editor .owl-theme .owl-nav a[class*='owl-'].note-btn.btn-sm:hover,
.owl-theme .owl-nav .note-editor .wizard > .actions a[class*='owl-'].note-btn.btn-sm:hover,
.note-editor .wizard > .actions .owl-theme .owl-nav a[class*='owl-'].note-btn.btn-sm:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-primary[class*='owl-'].btn:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-primary[class*='owl-'].btn:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:hover,
ul.wysihtml5-toolbar .wizard > .actions .owl-theme .owl-nav a[class*='owl-']:hover,
.owl-theme .owl-nav .wizard > .actions ul.wysihtml5-toolbar a[class*='owl-']:hover,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .dt-buttons a[class*='owl-'].dt-button:hover,
ul.wysihtml5-toolbar .dt-buttons .owl-theme .owl-nav a[class*='owl-'].dt-button:hover,
.owl-theme .owl-nav .dt-buttons ul.wysihtml5-toolbar a[class*='owl-'].dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].dt-button:hover,
.owl-theme .owl-nav .note-editor .dt-buttons [class*='owl-'].note-btn.btn-sm.dt-button:hover,
.note-editor .dt-buttons .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.dt-button:hover,
.owl-theme .owl-nav .dt-buttons .note-editor [class*='owl-'].note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.dt-button:hover,
.owl-theme .owl-nav .dt-buttons .btn-outline[class*='owl-'].dt-button:hover,
.dt-buttons .owl-theme .owl-nav .btn-outline[class*='owl-'].dt-button:hover,
.owl-theme .owl-nav .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].dt-button:hover,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].dt-button:hover,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button[class*='owl-'].dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button:hover,
.owl-theme
  .owl-nav
  .dt-buttons
  button[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.dt-buttons
  .owl-theme
  .owl-nav
  button[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.owl-theme .owl-nav .dt-buttons button[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left:hover,
.dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left:hover,
.owl-theme .owl-nav .dt-buttons button[class*='owl-'].dt-button.fc-agendaWeek-button:hover,
.dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button.fc-agendaWeek-button:hover,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary[class*='owl-']:hover,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button.btn-primary[class*='owl-']:hover,
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:hover,
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-agendaWeek-button:hover,
button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-primary.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-primary.fc-agendaWeek-button:hover,
.btn-outline.btn-primary.fc-prev-button:hover,
.wizard > .actions a.btn-outline.fc-prev-button:hover,
.wizard > .actions .note-editor a.fc-prev-button.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.fc-prev-button.note-btn.btn-sm:hover,
ul.wysihtml5-toolbar a.btn-primary.fc-prev-button.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:hover,
ul.wysihtml5-toolbar .dt-buttons a.fc-prev-button.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.fc-prev-button.dt-button:hover,
.note-editor .dt-buttons .fc-prev-button.note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .fc-prev-button.note-btn.btn-sm.dt-button:hover,
.dt-buttons .btn-outline.fc-prev-button.dt-button:hover,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-prev-button.dt-button:hover,
.dt-buttons button.fc-prev-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.dt-buttons button.fc-prev-button.dt-button.fc-month-button.fc-state-default.fc-corner-left:hover,
.dt-buttons button.fc-prev-button.dt-button.fc-agendaWeek-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-prev-button:hover,
button.btn-primary.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-primary.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-primary.fc-prev-button.fc-agendaWeek-button:hover,
.btn-outline.btn-primary.fc-next-button:hover,
.wizard > .actions a.btn-outline.fc-next-button:hover,
.wizard > .actions .note-editor a.fc-next-button.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.fc-next-button.note-btn.btn-sm:hover,
ul.wysihtml5-toolbar a.btn-primary.fc-next-button.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:hover,
ul.wysihtml5-toolbar .dt-buttons a.fc-next-button.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.fc-next-button.dt-button:hover,
.note-editor .dt-buttons .fc-next-button.note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .fc-next-button.note-btn.btn-sm.dt-button:hover,
.dt-buttons .btn-outline.fc-next-button.dt-button:hover,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-next-button.dt-button:hover,
.dt-buttons button.fc-next-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.dt-buttons button.fc-next-button.dt-button.fc-month-button.fc-state-default.fc-corner-left:hover,
.dt-buttons button.fc-next-button.dt-button.fc-agendaWeek-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-next-button:hover,
button.btn-primary.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-primary.fc-next-button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-primary.fc-next-button.fc-agendaWeek-button:hover,
.btn-outline.btn-primary.fc-today-button:hover,
.wizard > .actions a.btn-outline.fc-today-button:hover,
.wizard > .actions .note-editor a.fc-today-button.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.fc-today-button.note-btn.btn-sm:hover,
ul.wysihtml5-toolbar a.btn-primary.fc-today-button.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:hover,
ul.wysihtml5-toolbar .dt-buttons a.fc-today-button.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.fc-today-button.dt-button:hover,
.note-editor .dt-buttons .fc-today-button.note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .fc-today-button.note-btn.btn-sm.dt-button:hover,
.dt-buttons .btn-outline.fc-today-button.dt-button:hover,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-today-button.dt-button:hover,
.dt-buttons button.fc-today-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.dt-buttons button.fc-today-button.dt-button.fc-month-button.fc-state-default.fc-corner-left:hover,
.dt-buttons button.fc-today-button.dt-button.fc-agendaWeek-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-today-button:hover,
button.btn-primary.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-primary.fc-today-button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-primary.fc-today-button.fc-agendaWeek-button:hover,
.btn.btn-outline.btn-primary:focus,
.wizard > .actions .disabled a.btn-outline:focus,
ul.wysihtml5-toolbar .wizard > .actions .disabled a:focus,
.wizard > .actions .disabled ul.wysihtml5-toolbar a:focus,
.wizard > .actions ul.wysihtml5-toolbar .disabled a:focus,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm:focus,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm:focus,
.wizard > .actions .disabled a.btn-outline:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a:focus:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a:focus:hover,
.wizard > .actions ul.wysihtml5-toolbar .disabled a:focus:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm:focus:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm:focus:hover,
.wizard > .actions .disabled a.btn-outline:focus:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a:focus:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a:focus:active,
.wizard > .actions ul.wysihtml5-toolbar .disabled a:focus:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm:focus:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm:focus:active,
.wizard > .actions a.btn-outline:focus,
ul.wysihtml5-toolbar .wizard > .actions a:focus,
.wizard > .actions ul.wysihtml5-toolbar a:focus,
.wizard > .actions .note-editor a.note-btn.btn-sm:focus,
.note-editor .wizard > .actions a.note-btn.btn-sm:focus,
.wizard > .actions a.btn-outline:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions a:focus:hover,
.wizard > .actions ul.wysihtml5-toolbar a:focus:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm:focus:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm:focus:hover,
.wizard > .actions a.btn-outline:focus:active,
ul.wysihtml5-toolbar .wizard > .actions a:focus:active,
.wizard > .actions ul.wysihtml5-toolbar a:focus:active,
.wizard > .actions .note-editor a.note-btn.btn-sm:focus:active,
.note-editor .wizard > .actions a.note-btn.btn-sm:focus:active,
ul.wysihtml5-toolbar a.btn.btn-primary:focus,
ul.wysihtml5-toolbar .dt-buttons a.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.dt-button:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-primary[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-primary[class*='owl-']:focus,
ul.wysihtml5-toolbar a.btn-primary.fc-prev-button:focus,
ul.wysihtml5-toolbar a.btn-primary.fc-next-button:focus,
ul.wysihtml5-toolbar a.btn-primary.fc-today-button:focus,
.note-editor .btn.btn-primary.note-btn.btn-sm:focus,
.note-editor .dt-buttons .note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .note-btn.btn-sm.dt-button:focus,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.note-btn.btn-sm:focus,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.btn-primary.note-btn.btn-sm:focus,
.note-editor .sweet-alert button.btn-primary.note-btn.btn-sm:focus,
.sweet-alert .note-editor button.btn-primary.note-btn.btn-sm:focus,
.note-editor .owl-theme .owl-nav .btn-primary.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .btn-primary.note-btn.btn-sm[class*='owl-']:focus,
.note-editor button.btn-primary.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.note-editor button.btn-primary.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left:focus,
.note-editor button.btn-primary.note-btn.btn-sm.fc-agendaWeek-button:focus,
.note-editor .btn-primary.note-btn.btn-sm.fc-prev-button:focus,
.note-editor .btn-primary.note-btn.btn-sm.fc-next-button:focus,
.note-editor .btn-primary.note-btn.btn-sm.fc-today-button:focus,
.dt-buttons .btn-outline.dt-button:focus,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button:focus,
.dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left:focus,
.dt-buttons button.dt-button.fc-agendaWeek-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary:focus,
.sweet-alert button.btn-outline.btn-primary:focus,
.sweet-alert .note-editor .dt-buttons button.note-btn.btn-sm.dt-button:focus,
.note-editor .dt-buttons .sweet-alert button.note-btn.btn-sm.dt-button:focus,
.sweet-alert .dt-buttons .note-editor button.note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .sweet-alert button.note-btn.btn-sm.dt-button:focus,
.sweet-alert .dt-buttons button.btn-outline.dt-button:focus,
.dt-buttons .sweet-alert button.btn-outline.dt-button:focus,
.sweet-alert .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button:focus,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.dt-button:focus,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .sweet-alert button.dt-button:focus,
.sweet-alert .dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.dt-buttons .sweet-alert button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.sweet-alert .dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left:focus,
.dt-buttons .sweet-alert button.dt-button.fc-month-button.fc-state-default.fc-corner-left:focus,
.sweet-alert .dt-buttons button.dt-button.fc-agendaWeek-button:focus,
.dt-buttons .sweet-alert button.dt-button.fc-agendaWeek-button:focus,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary:focus,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.btn-primary:focus,
.sweet-alert button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.sweet-alert button.btn-primary.fc-month-button.fc-state-default.fc-corner-left:focus,
.sweet-alert button.btn-primary.fc-agendaWeek-button:focus,
.owl-theme .owl-nav .btn-outline.btn-primary[class*='owl-']:focus,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:focus,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:focus,
.owl-theme .owl-nav .wizard > .actions .note-editor a[class*='owl-'].note-btn.btn-sm:focus,
.wizard > .actions .note-editor .owl-theme .owl-nav a[class*='owl-'].note-btn.btn-sm:focus,
.owl-theme .owl-nav .note-editor .wizard > .actions a[class*='owl-'].note-btn.btn-sm:focus,
.note-editor .wizard > .actions .owl-theme .owl-nav a[class*='owl-'].note-btn.btn-sm:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-primary[class*='owl-'].btn:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-primary[class*='owl-'].btn:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:focus,
ul.wysihtml5-toolbar .wizard > .actions .owl-theme .owl-nav a[class*='owl-']:focus,
.owl-theme .owl-nav .wizard > .actions ul.wysihtml5-toolbar a[class*='owl-']:focus,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar .dt-buttons a[class*='owl-'].dt-button:focus,
ul.wysihtml5-toolbar .dt-buttons .owl-theme .owl-nav a[class*='owl-'].dt-button:focus,
.owl-theme .owl-nav .dt-buttons ul.wysihtml5-toolbar a[class*='owl-'].dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].dt-button:focus,
.owl-theme .owl-nav .note-editor .dt-buttons [class*='owl-'].note-btn.btn-sm.dt-button:focus,
.note-editor .dt-buttons .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.dt-button:focus,
.owl-theme .owl-nav .dt-buttons .note-editor [class*='owl-'].note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.dt-button:focus,
.owl-theme .owl-nav .dt-buttons .btn-outline[class*='owl-'].dt-button:focus,
.dt-buttons .owl-theme .owl-nav .btn-outline[class*='owl-'].dt-button:focus,
.owl-theme .owl-nav .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].dt-button:focus,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].dt-button:focus,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button[class*='owl-'].dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button:focus,
.owl-theme
  .owl-nav
  .dt-buttons
  button[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.dt-buttons
  .owl-theme
  .owl-nav
  button[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.owl-theme .owl-nav .dt-buttons button[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left:focus,
.dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left:focus,
.owl-theme .owl-nav .dt-buttons button[class*='owl-'].dt-button.fc-agendaWeek-button:focus,
.dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button.fc-agendaWeek-button:focus,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary[class*='owl-']:focus,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button.btn-primary[class*='owl-']:focus,
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:focus,
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-agendaWeek-button:focus,
button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-primary.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-primary.fc-agendaWeek-button:focus,
.btn-outline.btn-primary.fc-prev-button:focus,
.wizard > .actions a.btn-outline.fc-prev-button:focus,
.wizard > .actions .note-editor a.fc-prev-button.note-btn.btn-sm:focus,
.note-editor .wizard > .actions a.fc-prev-button.note-btn.btn-sm:focus,
ul.wysihtml5-toolbar a.btn-primary.fc-prev-button.btn:focus,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:focus,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:focus,
ul.wysihtml5-toolbar .dt-buttons a.fc-prev-button.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.fc-prev-button.dt-button:focus,
.note-editor .dt-buttons .fc-prev-button.note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .fc-prev-button.note-btn.btn-sm.dt-button:focus,
.dt-buttons .btn-outline.fc-prev-button.dt-button:focus,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-prev-button.dt-button:focus,
.dt-buttons button.fc-prev-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.dt-buttons button.fc-prev-button.dt-button.fc-month-button.fc-state-default.fc-corner-left:focus,
.dt-buttons button.fc-prev-button.dt-button.fc-agendaWeek-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-prev-button:focus,
button.btn-primary.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-primary.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-primary.fc-prev-button.fc-agendaWeek-button:focus,
.btn-outline.btn-primary.fc-next-button:focus,
.wizard > .actions a.btn-outline.fc-next-button:focus,
.wizard > .actions .note-editor a.fc-next-button.note-btn.btn-sm:focus,
.note-editor .wizard > .actions a.fc-next-button.note-btn.btn-sm:focus,
ul.wysihtml5-toolbar a.btn-primary.fc-next-button.btn:focus,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:focus,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:focus,
ul.wysihtml5-toolbar .dt-buttons a.fc-next-button.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.fc-next-button.dt-button:focus,
.note-editor .dt-buttons .fc-next-button.note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .fc-next-button.note-btn.btn-sm.dt-button:focus,
.dt-buttons .btn-outline.fc-next-button.dt-button:focus,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-next-button.dt-button:focus,
.dt-buttons button.fc-next-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.dt-buttons button.fc-next-button.dt-button.fc-month-button.fc-state-default.fc-corner-left:focus,
.dt-buttons button.fc-next-button.dt-button.fc-agendaWeek-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-next-button:focus,
button.btn-primary.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-primary.fc-next-button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-primary.fc-next-button.fc-agendaWeek-button:focus,
.btn-outline.btn-primary.fc-today-button:focus,
.wizard > .actions a.btn-outline.fc-today-button:focus,
.wizard > .actions .note-editor a.fc-today-button.note-btn.btn-sm:focus,
.note-editor .wizard > .actions a.fc-today-button.note-btn.btn-sm:focus,
ul.wysihtml5-toolbar a.btn-primary.fc-today-button.btn:focus,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:focus,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:focus,
ul.wysihtml5-toolbar .dt-buttons a.fc-today-button.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.fc-today-button.dt-button:focus,
.note-editor .dt-buttons .fc-today-button.note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .fc-today-button.note-btn.btn-sm.dt-button:focus,
.dt-buttons .btn-outline.fc-today-button.dt-button:focus,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-today-button.dt-button:focus,
.dt-buttons button.fc-today-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.dt-buttons button.fc-today-button.dt-button.fc-month-button.fc-state-default.fc-corner-left:focus,
.dt-buttons button.fc-today-button.dt-button.fc-agendaWeek-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-today-button:focus,
button.btn-primary.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-primary.fc-today-button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-primary.fc-today-button.fc-agendaWeek-button:focus,
.btn.btn-outline.btn-primary:active,
.wizard > .actions .disabled a.btn-outline:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a:active,
.wizard > .actions ul.wysihtml5-toolbar .disabled a:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm:active,
.wizard > .actions .disabled a.btn-outline:active:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a:active:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a:active:hover,
.wizard > .actions ul.wysihtml5-toolbar .disabled a:active:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm:active:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm:active:hover,
.wizard > .actions a.btn-outline:active,
ul.wysihtml5-toolbar .wizard > .actions a:active,
.wizard > .actions ul.wysihtml5-toolbar a:active,
.wizard > .actions .note-editor a.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.note-btn.btn-sm:active,
.wizard > .actions a.btn-outline:active:hover,
ul.wysihtml5-toolbar .wizard > .actions a:active:hover,
.wizard > .actions ul.wysihtml5-toolbar a:active:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm:active:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm:active:hover,
ul.wysihtml5-toolbar a.btn.btn-primary:active,
ul.wysihtml5-toolbar .dt-buttons a.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.dt-button:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-primary[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-primary[class*='owl-']:active,
ul.wysihtml5-toolbar a.btn-primary.fc-prev-button:active,
ul.wysihtml5-toolbar a.btn-primary.fc-next-button:active,
ul.wysihtml5-toolbar a.btn-primary.fc-today-button:active,
.note-editor .btn.btn-primary.note-btn.btn-sm:active,
.note-editor .dt-buttons .note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .note-btn.btn-sm.dt-button:active,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.note-btn.btn-sm:active,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.btn-primary.note-btn.btn-sm:active,
.note-editor .sweet-alert button.btn-primary.note-btn.btn-sm:active,
.sweet-alert .note-editor button.btn-primary.note-btn.btn-sm:active,
.note-editor .owl-theme .owl-nav .btn-primary.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .btn-primary.note-btn.btn-sm[class*='owl-']:active,
.note-editor button.btn-primary.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.note-editor button.btn-primary.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left:active,
.note-editor button.btn-primary.note-btn.btn-sm.fc-agendaWeek-button:active,
.note-editor .btn-primary.note-btn.btn-sm.fc-prev-button:active,
.note-editor .btn-primary.note-btn.btn-sm.fc-next-button:active,
.note-editor .btn-primary.note-btn.btn-sm.fc-today-button:active,
.dt-buttons .btn-outline.dt-button:active,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button:active,
.dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left:active,
.dt-buttons button.dt-button.fc-agendaWeek-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary:active,
.sweet-alert button.btn-outline.btn-primary:active,
.sweet-alert .note-editor .dt-buttons button.note-btn.btn-sm.dt-button:active,
.note-editor .dt-buttons .sweet-alert button.note-btn.btn-sm.dt-button:active,
.sweet-alert .dt-buttons .note-editor button.note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .sweet-alert button.note-btn.btn-sm.dt-button:active,
.sweet-alert .dt-buttons button.btn-outline.dt-button:active,
.dt-buttons .sweet-alert button.btn-outline.dt-button:active,
.sweet-alert .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button:active,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.dt-button:active,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .sweet-alert button.dt-button:active,
.sweet-alert .dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.dt-buttons .sweet-alert button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.sweet-alert .dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left:active,
.dt-buttons .sweet-alert button.dt-button.fc-month-button.fc-state-default.fc-corner-left:active,
.sweet-alert .dt-buttons button.dt-button.fc-agendaWeek-button:active,
.dt-buttons .sweet-alert button.dt-button.fc-agendaWeek-button:active,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary:active,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.btn-primary:active,
.sweet-alert button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.sweet-alert button.btn-primary.fc-month-button.fc-state-default.fc-corner-left:active,
.sweet-alert button.btn-primary.fc-agendaWeek-button:active,
.owl-theme .owl-nav .btn-outline.btn-primary[class*='owl-']:active,
.owl-theme .owl-nav .wizard > .actions a.btn-outline[class*='owl-']:active,
.wizard > .actions .owl-theme .owl-nav a.btn-outline[class*='owl-']:active,
.owl-theme .owl-nav .wizard > .actions .note-editor a[class*='owl-'].note-btn.btn-sm:active,
.wizard > .actions .note-editor .owl-theme .owl-nav a[class*='owl-'].note-btn.btn-sm:active,
.owl-theme .owl-nav .note-editor .wizard > .actions a[class*='owl-'].note-btn.btn-sm:active,
.note-editor .wizard > .actions .owl-theme .owl-nav a[class*='owl-'].note-btn.btn-sm:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-primary[class*='owl-'].btn:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-primary[class*='owl-'].btn:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a[class*='owl-']:active,
ul.wysihtml5-toolbar .wizard > .actions .owl-theme .owl-nav a[class*='owl-']:active,
.owl-theme .owl-nav .wizard > .actions ul.wysihtml5-toolbar a[class*='owl-']:active,
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .dt-buttons a[class*='owl-'].dt-button:active,
ul.wysihtml5-toolbar .dt-buttons .owl-theme .owl-nav a[class*='owl-'].dt-button:active,
.owl-theme .owl-nav .dt-buttons ul.wysihtml5-toolbar a[class*='owl-'].dt-button:active,
.dt-buttons ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].dt-button:active,
.owl-theme .owl-nav .note-editor .dt-buttons [class*='owl-'].note-btn.btn-sm.dt-button:active,
.note-editor .dt-buttons .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.dt-button:active,
.owl-theme .owl-nav .dt-buttons .note-editor [class*='owl-'].note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.dt-button:active,
.owl-theme .owl-nav .dt-buttons .btn-outline[class*='owl-'].dt-button:active,
.dt-buttons .owl-theme .owl-nav .btn-outline[class*='owl-'].dt-button:active,
.owl-theme .owl-nav .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].dt-button:active,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].dt-button:active,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button[class*='owl-'].dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button:active,
.owl-theme
  .owl-nav
  .dt-buttons
  button[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.dt-buttons
  .owl-theme
  .owl-nav
  button[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.owl-theme .owl-nav .dt-buttons button[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left:active,
.dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left:active,
.owl-theme .owl-nav .dt-buttons button[class*='owl-'].dt-button.fc-agendaWeek-button:active,
.dt-buttons .owl-theme .owl-nav button[class*='owl-'].dt-button.fc-agendaWeek-button:active,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button.btn-primary[class*='owl-']:active,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button.btn-primary[class*='owl-']:active,
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:active,
.owl-theme .owl-nav button.btn-primary[class*='owl-'].fc-agendaWeek-button:active,
button.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-primary.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-primary.fc-agendaWeek-button:active,
.btn-outline.btn-primary.fc-prev-button:active,
.wizard > .actions a.btn-outline.fc-prev-button:active,
.wizard > .actions .note-editor a.fc-prev-button.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.fc-prev-button.note-btn.btn-sm:active,
ul.wysihtml5-toolbar a.btn-primary.fc-prev-button.btn:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:active,
ul.wysihtml5-toolbar .dt-buttons a.fc-prev-button.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.fc-prev-button.dt-button:active,
.note-editor .dt-buttons .fc-prev-button.note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .fc-prev-button.note-btn.btn-sm.dt-button:active,
.dt-buttons .btn-outline.fc-prev-button.dt-button:active,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-prev-button.dt-button:active,
.dt-buttons button.fc-prev-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.dt-buttons button.fc-prev-button.dt-button.fc-month-button.fc-state-default.fc-corner-left:active,
.dt-buttons button.fc-prev-button.dt-button.fc-agendaWeek-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-prev-button:active,
button.btn-primary.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-primary.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-primary.fc-prev-button.fc-agendaWeek-button:active,
.btn-outline.btn-primary.fc-next-button:active,
.wizard > .actions a.btn-outline.fc-next-button:active,
.wizard > .actions .note-editor a.fc-next-button.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.fc-next-button.note-btn.btn-sm:active,
ul.wysihtml5-toolbar a.btn-primary.fc-next-button.btn:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:active,
ul.wysihtml5-toolbar .dt-buttons a.fc-next-button.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.fc-next-button.dt-button:active,
.note-editor .dt-buttons .fc-next-button.note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .fc-next-button.note-btn.btn-sm.dt-button:active,
.dt-buttons .btn-outline.fc-next-button.dt-button:active,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-next-button.dt-button:active,
.dt-buttons button.fc-next-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.dt-buttons button.fc-next-button.dt-button.fc-month-button.fc-state-default.fc-corner-left:active,
.dt-buttons button.fc-next-button.dt-button.fc-agendaWeek-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-next-button:active,
button.btn-primary.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-primary.fc-next-button.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-primary.fc-next-button.fc-agendaWeek-button:active,
.btn-outline.btn-primary.fc-today-button:active,
.wizard > .actions a.btn-outline.fc-today-button:active,
.wizard > .actions .note-editor a.fc-today-button.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.fc-today-button.note-btn.btn-sm:active,
ul.wysihtml5-toolbar a.btn-primary.fc-today-button.btn:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:active,
ul.wysihtml5-toolbar .dt-buttons a.fc-today-button.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.fc-today-button.dt-button:active,
.note-editor .dt-buttons .fc-today-button.note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .fc-today-button.note-btn.btn-sm.dt-button:active,
.dt-buttons .btn-outline.fc-today-button.dt-button:active,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.fc-today-button.dt-button:active,
.dt-buttons button.fc-today-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.dt-buttons button.fc-today-button.dt-button.fc-month-button.fc-state-default.fc-corner-left:active,
.dt-buttons button.fc-today-button.dt-button.fc-agendaWeek-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-primary.fc-today-button:active,
button.btn-primary.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-primary.fc-today-button.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-primary.fc-today-button.fc-agendaWeek-button:active {
  border: solid 1px #2196f3;
}
.btn.btn-outline.btn-success,
.wizard > .actions .disabled a.btn-outline.btn-success,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-success.btn,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-success.btn,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-prev-button,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-prev-button,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-next-button,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-next-button,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-today-button,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-today-button,
.wizard > .actions .disabled .note-editor a.btn-success.note-btn.btn-sm,
.note-editor .wizard > .actions .disabled a.btn-success.note-btn.btn-sm,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-prev-button,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-prev-button,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-next-button,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-next-button,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-today-button,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-today-button,
.wizard > .actions .disabled a.btn-outline.fc-prev-button,
.wizard > .actions .disabled a.btn-outline.fc-next-button,
.wizard > .actions .disabled a.btn-outline.fc-today-button,
.wizard > .actions .disabled a.btn-outline.btn-success:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-success.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-success.btn:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-prev-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-prev-button:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-next-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-next-button:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-today-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-today-button:hover,
.wizard > .actions .disabled .note-editor a.btn-success.note-btn.btn-sm:hover,
.note-editor .wizard > .actions .disabled a.btn-success.note-btn.btn-sm:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-prev-button:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-next-button:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-next-button:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-today-button:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-today-button:hover,
.wizard > .actions .disabled a.btn-outline.fc-prev-button:hover,
.wizard > .actions .disabled a.btn-outline.fc-next-button:hover,
.wizard > .actions .disabled a.btn-outline.fc-today-button:hover,
.wizard > .actions .disabled a.btn-outline.btn-success:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-success.btn:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-success.btn:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-prev-button:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-prev-button:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-next-button:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-next-button:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-today-button:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-today-button:active,
.wizard > .actions .disabled .note-editor a.btn-success.note-btn.btn-sm:active,
.note-editor .wizard > .actions .disabled a.btn-success.note-btn.btn-sm:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-prev-button:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-prev-button:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-next-button:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-next-button:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-today-button:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-today-button:active,
.wizard > .actions .disabled a.btn-outline.fc-prev-button:active,
.wizard > .actions .disabled a.btn-outline.fc-next-button:active,
.wizard > .actions .disabled a.btn-outline.fc-today-button:active,
.wizard > .actions a.btn-outline.btn-success,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success.btn,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success.btn,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button,
.wizard > .actions .note-editor a.btn-success.note-btn.btn-sm,
.note-editor .wizard > .actions a.btn-success.note-btn.btn-sm,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-prev-button,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-prev-button,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-next-button,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-next-button,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-today-button,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-today-button,
.wizard > .actions a.btn-outline.fc-prev-button,
.wizard > .actions a.btn-outline.fc-next-button,
.wizard > .actions a.btn-outline.fc-today-button,
.wizard > .actions a.btn-outline.btn-success:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success.btn:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:hover,
.wizard > .actions .note-editor a.btn-success.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.btn-success.note-btn.btn-sm:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-prev-button:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-next-button:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-next-button:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-today-button:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-today-button:hover,
.wizard > .actions a.btn-outline.fc-prev-button:hover,
.wizard > .actions a.btn-outline.fc-next-button:hover,
.wizard > .actions a.btn-outline.fc-today-button:hover,
.wizard > .actions a.btn-outline.btn-success:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success.btn:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success.btn:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:active,
.wizard > .actions .note-editor a.btn-success.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.btn-success.note-btn.btn-sm:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-prev-button:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-prev-button:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-next-button:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-next-button:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-today-button:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-today-button:active,
.wizard > .actions a.btn-outline.fc-prev-button:active,
.wizard > .actions a.btn-outline.fc-next-button:active,
.wizard > .actions a.btn-outline.fc-today-button:active,
ul.wysihtml5-toolbar a.btn.btn-success,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success,
ul.wysihtml5-toolbar .dt-buttons a.btn-success.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.btn-success.dt-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-success[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-success[class*='owl-'],
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-prev-button,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-prev-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-next-button,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-next-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-today-button,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-today-button,
ul.wysihtml5-toolbar a.fc-prev-button,
ul.wysihtml5-toolbar a.fc-next-button,
ul.wysihtml5-toolbar a.fc-today-button,
.note-editor .btn.btn-success.note-btn.btn-sm,
.note-editor .dt-buttons .btn-success.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .btn-success.note-btn.btn-sm.dt-button,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.btn-success.note-btn.btn-sm,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.btn-success.note-btn.btn-sm,
.note-editor .sweet-alert button.btn-success.note-btn.btn-sm,
.sweet-alert .note-editor button.btn-success.note-btn.btn-sm,
.note-editor .owl-theme .owl-nav .btn-success.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .btn-success.note-btn.btn-sm[class*='owl-'],
.note-editor button.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,
.note-editor button.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left,
.note-editor button.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .note-btn.btn-sm.fc-prev-button,
.note-editor .note-btn.btn-sm.fc-next-button,
.note-editor .note-btn.btn-sm.fc-today-button,
.dt-buttons .btn-outline.btn-success.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.btn-success.dt-button.btn,
ul.wysihtml5-toolbar .dt-buttons a.btn-success.dt-button.btn,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-prev-button,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-prev-button,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-next-button,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-next-button,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-today-button,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-today-button,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-prev-button,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-prev-button,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-next-button,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-next-button,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-today-button,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-today-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.btn-success.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.btn-success.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-prev-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-prev-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-next-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-next-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-today-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-today-button,
.dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons button.dt-button.fc-agendaWeek-button,
.dt-buttons .btn-outline.dt-button.fc-prev-button,
.dt-buttons .btn-outline.dt-button.fc-next-button,
.dt-buttons .btn-outline.dt-button.fc-today-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-success,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-month-button.fc-state-default.fc-corner-left,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button,
.sweet-alert button.btn-outline.btn-success,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-agendaWeek-button,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-prev-button,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-prev-button,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-next-button,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-next-button,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-today-button,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-today-button,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.btn-success,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.btn-success,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-agendaWeek-button,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-prev-button,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-next-button,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-today-button,
.sweet-alert button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.sweet-alert button.fc-month-button.fc-state-default.fc-corner-left,
.sweet-alert button.fc-agendaWeek-button,
.sweet-alert button.btn-outline.fc-prev-button,
.sweet-alert button.btn-outline.fc-next-button,
.sweet-alert button.btn-outline.fc-today-button,
.owl-theme .owl-nav .btn-outline.btn-success[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-success[class*='owl-'].btn,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-success[class*='owl-'].btn,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-prev-button,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-prev-button,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-next-button,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-next-button,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-today-button,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-today-button,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button.btn-success[class*='owl-'],
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button.btn-success[class*='owl-'],
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-prev-button,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-prev-button,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-next-button,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-next-button,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-today-button,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-today-button,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-prev-button,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-next-button,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-today-button,
button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.fc-month-button.fc-state-default.fc-corner-left,
button.fc-agendaWeek-button,
.btn-outline.fc-prev-button,
button.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left,
button.fc-prev-button.fc-agendaWeek-button,
.btn-outline.fc-next-button,
button.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.fc-next-button.fc-month-button.fc-state-default.fc-corner-left,
button.fc-next-button.fc-agendaWeek-button,
.btn-outline.fc-today-button,
button.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.fc-today-button.fc-month-button.fc-state-default.fc-corner-left,
button.fc-today-button.fc-agendaWeek-button {
  border: solid 1px rgba(139, 195, 74, 0.5);
  color: #8bc34a;
}
.btn.btn-outline.btn-success:hover,
.wizard > .actions .disabled a.btn-outline.btn-success:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-success.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-success.btn:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-prev-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-prev-button:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-next-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-next-button:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-today-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-today-button:hover,
.wizard > .actions .disabled .note-editor a.btn-success.note-btn.btn-sm:hover,
.note-editor .wizard > .actions .disabled a.btn-success.note-btn.btn-sm:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-prev-button:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-next-button:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-next-button:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-today-button:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-today-button:hover,
.wizard > .actions .disabled a.btn-outline.fc-prev-button:hover,
.wizard > .actions .disabled a.btn-outline.fc-next-button:hover,
.wizard > .actions .disabled a.btn-outline.fc-today-button:hover,
.wizard > .actions .disabled a.btn-outline.btn-success:hover:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-success.btn:hover:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-success.btn:hover:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-prev-button:hover:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-prev-button:hover:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-next-button:hover:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-next-button:hover:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-today-button:hover:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-today-button:hover:active,
.wizard > .actions .disabled .note-editor a.btn-success.note-btn.btn-sm:hover:active,
.note-editor .wizard > .actions .disabled a.btn-success.note-btn.btn-sm:hover:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-prev-button:hover:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-prev-button:hover:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-next-button:hover:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-next-button:hover:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-today-button:hover:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-today-button:hover:active,
.wizard > .actions .disabled a.btn-outline.fc-prev-button:hover:active,
.wizard > .actions .disabled a.btn-outline.fc-next-button:hover:active,
.wizard > .actions .disabled a.btn-outline.fc-today-button:hover:active,
.wizard > .actions a.btn-outline.btn-success:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success.btn:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:hover,
.wizard > .actions .note-editor a.btn-success.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.btn-success.note-btn.btn-sm:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-prev-button:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-next-button:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-next-button:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-today-button:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-today-button:hover,
.wizard > .actions a.btn-outline.fc-prev-button:hover,
.wizard > .actions a.btn-outline.fc-next-button:hover,
.wizard > .actions a.btn-outline.fc-today-button:hover,
.wizard > .actions a.btn-outline.btn-success:hover:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success.btn:hover:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success.btn:hover:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:hover:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:hover:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:hover:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:hover:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:hover:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:hover:active,
.wizard > .actions .note-editor a.btn-success.note-btn.btn-sm:hover:active,
.note-editor .wizard > .actions a.btn-success.note-btn.btn-sm:hover:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-prev-button:hover:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-prev-button:hover:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-next-button:hover:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-next-button:hover:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-today-button:hover:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-today-button:hover:active,
.wizard > .actions a.btn-outline.fc-prev-button:hover:active,
.wizard > .actions a.btn-outline.fc-next-button:hover:active,
.wizard > .actions a.btn-outline.fc-today-button:hover:active,
ul.wysihtml5-toolbar a.btn.btn-success:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success:hover,
ul.wysihtml5-toolbar .dt-buttons a.btn-success.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.btn-success.dt-button:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-success[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-success[class*='owl-']:hover,
ul.wysihtml5-toolbar a.fc-prev-button:hover,
ul.wysihtml5-toolbar a.fc-next-button:hover,
ul.wysihtml5-toolbar a.fc-today-button:hover,
.note-editor .btn.btn-success.note-btn.btn-sm:hover,
.note-editor .dt-buttons .btn-success.note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .btn-success.note-btn.btn-sm.dt-button:hover,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.btn-success.note-btn.btn-sm:hover,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.btn-success.note-btn.btn-sm:hover,
.note-editor .sweet-alert button.btn-success.note-btn.btn-sm:hover,
.sweet-alert .note-editor button.btn-success.note-btn.btn-sm:hover,
.note-editor .owl-theme .owl-nav .btn-success.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .btn-success.note-btn.btn-sm[class*='owl-']:hover,
.note-editor button.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.note-editor button.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left:hover,
.note-editor button.note-btn.btn-sm.fc-agendaWeek-button:hover,
.note-editor .note-btn.btn-sm.fc-prev-button:hover,
.note-editor .note-btn.btn-sm.fc-next-button:hover,
.note-editor .note-btn.btn-sm.fc-today-button:hover,
.dt-buttons .btn-outline.btn-success.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.btn-success.dt-button.btn:hover,
ul.wysihtml5-toolbar .dt-buttons a.btn-success.dt-button.btn:hover,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-prev-button:hover,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-prev-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-next-button:hover,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-next-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-today-button:hover,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-today-button:hover,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-prev-button:hover,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-next-button:hover,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-next-button:hover,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-today-button:hover,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-today-button:hover,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.btn-success.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.btn-success.dt-button:hover,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-prev-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-prev-button:hover,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-next-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-next-button:hover,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-today-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-today-button:hover,
.dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left:hover,
.dt-buttons button.dt-button.fc-agendaWeek-button:hover,
.dt-buttons .btn-outline.dt-button.fc-prev-button:hover,
.dt-buttons .btn-outline.dt-button.fc-next-button:hover,
.dt-buttons .btn-outline.dt-button.fc-today-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-success:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-month-button.fc-state-default.fc-corner-left:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:hover,
.sweet-alert button.btn-outline.btn-success:hover,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-prev-button:hover,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-next-button:hover,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-next-button:hover,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-today-button:hover,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-today-button:hover,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.btn-success:hover,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.btn-success:hover,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-prev-button:hover,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-next-button:hover,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-today-button:hover,
.sweet-alert button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.sweet-alert button.fc-month-button.fc-state-default.fc-corner-left:hover,
.sweet-alert button.fc-agendaWeek-button:hover,
.sweet-alert button.btn-outline.fc-prev-button:hover,
.sweet-alert button.btn-outline.fc-next-button:hover,
.sweet-alert button.btn-outline.fc-today-button:hover,
.owl-theme .owl-nav .btn-outline.btn-success[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-success[class*='owl-'].btn:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-success[class*='owl-'].btn:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-prev-button:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-prev-button:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-next-button:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-next-button:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-today-button:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-today-button:hover,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-prev-button:hover,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-prev-button:hover,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-next-button:hover,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-next-button:hover,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-today-button:hover,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-today-button:hover,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button.btn-success[class*='owl-']:hover,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button.btn-success[class*='owl-']:hover,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-prev-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-prev-button:hover,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-next-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-next-button:hover,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-today-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-today-button:hover,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:hover,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:hover,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-prev-button:hover,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-next-button:hover,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-today-button:hover,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fc-agendaWeek-button:hover,
.btn-outline.fc-prev-button:hover,
button.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fc-prev-button.fc-agendaWeek-button:hover,
.btn-outline.fc-next-button:hover,
button.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fc-next-button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fc-next-button.fc-agendaWeek-button:hover,
.btn-outline.fc-today-button:hover,
button.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fc-today-button.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fc-today-button.fc-agendaWeek-button:hover,
.btn.btn-outline.btn-success:focus,
.wizard > .actions .disabled a.btn-outline.btn-success:focus,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-success.btn:focus,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-success.btn:focus,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-prev-button:focus,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-prev-button:focus,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-next-button:focus,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-next-button:focus,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-today-button:focus,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-today-button:focus,
.wizard > .actions .disabled .note-editor a.btn-success.note-btn.btn-sm:focus,
.note-editor .wizard > .actions .disabled a.btn-success.note-btn.btn-sm:focus,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-prev-button:focus,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-prev-button:focus,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-next-button:focus,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-next-button:focus,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-today-button:focus,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-today-button:focus,
.wizard > .actions .disabled a.btn-outline.fc-prev-button:focus,
.wizard > .actions .disabled a.btn-outline.fc-next-button:focus,
.wizard > .actions .disabled a.btn-outline.fc-today-button:focus,
.wizard > .actions .disabled a.btn-outline.btn-success:focus:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-success.btn:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-success.btn:focus:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-prev-button:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-prev-button:focus:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-next-button:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-next-button:focus:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-today-button:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-today-button:focus:hover,
.wizard > .actions .disabled .note-editor a.btn-success.note-btn.btn-sm:focus:hover,
.note-editor .wizard > .actions .disabled a.btn-success.note-btn.btn-sm:focus:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-prev-button:focus:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-prev-button:focus:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-next-button:focus:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-next-button:focus:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-today-button:focus:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-today-button:focus:hover,
.wizard > .actions .disabled a.btn-outline.fc-prev-button:focus:hover,
.wizard > .actions .disabled a.btn-outline.fc-next-button:focus:hover,
.wizard > .actions .disabled a.btn-outline.fc-today-button:focus:hover,
.wizard > .actions .disabled a.btn-outline.btn-success:focus:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-success.btn:focus:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-success.btn:focus:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-prev-button:focus:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-prev-button:focus:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-next-button:focus:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-next-button:focus:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-today-button:focus:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-today-button:focus:active,
.wizard > .actions .disabled .note-editor a.btn-success.note-btn.btn-sm:focus:active,
.note-editor .wizard > .actions .disabled a.btn-success.note-btn.btn-sm:focus:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-prev-button:focus:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-prev-button:focus:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-next-button:focus:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-next-button:focus:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-today-button:focus:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-today-button:focus:active,
.wizard > .actions .disabled a.btn-outline.fc-prev-button:focus:active,
.wizard > .actions .disabled a.btn-outline.fc-next-button:focus:active,
.wizard > .actions .disabled a.btn-outline.fc-today-button:focus:active,
.wizard > .actions a.btn-outline.btn-success:focus,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success.btn:focus,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success.btn:focus,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:focus,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:focus,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:focus,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:focus,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:focus,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:focus,
.wizard > .actions .note-editor a.btn-success.note-btn.btn-sm:focus,
.note-editor .wizard > .actions a.btn-success.note-btn.btn-sm:focus,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-prev-button:focus,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-prev-button:focus,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-next-button:focus,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-next-button:focus,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-today-button:focus,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-today-button:focus,
.wizard > .actions a.btn-outline.fc-prev-button:focus,
.wizard > .actions a.btn-outline.fc-next-button:focus,
.wizard > .actions a.btn-outline.fc-today-button:focus,
.wizard > .actions a.btn-outline.btn-success:focus:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success.btn:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success.btn:focus:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:focus:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:focus:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:focus:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:focus:hover,
.wizard > .actions .note-editor a.btn-success.note-btn.btn-sm:focus:hover,
.note-editor .wizard > .actions a.btn-success.note-btn.btn-sm:focus:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-prev-button:focus:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-prev-button:focus:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-next-button:focus:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-next-button:focus:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-today-button:focus:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-today-button:focus:hover,
.wizard > .actions a.btn-outline.fc-prev-button:focus:hover,
.wizard > .actions a.btn-outline.fc-next-button:focus:hover,
.wizard > .actions a.btn-outline.fc-today-button:focus:hover,
.wizard > .actions a.btn-outline.btn-success:focus:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success.btn:focus:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success.btn:focus:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:focus:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:focus:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:focus:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:focus:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:focus:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:focus:active,
.wizard > .actions .note-editor a.btn-success.note-btn.btn-sm:focus:active,
.note-editor .wizard > .actions a.btn-success.note-btn.btn-sm:focus:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-prev-button:focus:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-prev-button:focus:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-next-button:focus:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-next-button:focus:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-today-button:focus:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-today-button:focus:active,
.wizard > .actions a.btn-outline.fc-prev-button:focus:active,
.wizard > .actions a.btn-outline.fc-next-button:focus:active,
.wizard > .actions a.btn-outline.fc-today-button:focus:active,
ul.wysihtml5-toolbar a.btn.btn-success:focus,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success:focus,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success:focus,
ul.wysihtml5-toolbar .dt-buttons a.btn-success.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.btn-success.dt-button:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-success[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-success[class*='owl-']:focus,
ul.wysihtml5-toolbar a.fc-prev-button:focus,
ul.wysihtml5-toolbar a.fc-next-button:focus,
ul.wysihtml5-toolbar a.fc-today-button:focus,
.note-editor .btn.btn-success.note-btn.btn-sm:focus,
.note-editor .dt-buttons .btn-success.note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .btn-success.note-btn.btn-sm.dt-button:focus,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.btn-success.note-btn.btn-sm:focus,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.btn-success.note-btn.btn-sm:focus,
.note-editor .sweet-alert button.btn-success.note-btn.btn-sm:focus,
.sweet-alert .note-editor button.btn-success.note-btn.btn-sm:focus,
.note-editor .owl-theme .owl-nav .btn-success.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .btn-success.note-btn.btn-sm[class*='owl-']:focus,
.note-editor button.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.note-editor button.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left:focus,
.note-editor button.note-btn.btn-sm.fc-agendaWeek-button:focus,
.note-editor .note-btn.btn-sm.fc-prev-button:focus,
.note-editor .note-btn.btn-sm.fc-next-button:focus,
.note-editor .note-btn.btn-sm.fc-today-button:focus,
.dt-buttons .btn-outline.btn-success.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.btn-success.dt-button.btn:focus,
ul.wysihtml5-toolbar .dt-buttons a.btn-success.dt-button.btn:focus,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-prev-button:focus,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-prev-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-next-button:focus,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-next-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-today-button:focus,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-today-button:focus,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-prev-button:focus,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-prev-button:focus,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-next-button:focus,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-next-button:focus,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-today-button:focus,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-today-button:focus,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.btn-success.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.btn-success.dt-button:focus,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-prev-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-prev-button:focus,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-next-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-next-button:focus,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-today-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-today-button:focus,
.dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left:focus,
.dt-buttons button.dt-button.fc-agendaWeek-button:focus,
.dt-buttons .btn-outline.dt-button.fc-prev-button:focus,
.dt-buttons .btn-outline.dt-button.fc-next-button:focus,
.dt-buttons .btn-outline.dt-button.fc-today-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-success:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-month-button.fc-state-default.fc-corner-left:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:focus,
.sweet-alert button.btn-outline.btn-success:focus,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-prev-button:focus,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-prev-button:focus,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-next-button:focus,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-next-button:focus,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-today-button:focus,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-today-button:focus,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.btn-success:focus,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.btn-success:focus,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-prev-button:focus,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-next-button:focus,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-today-button:focus,
.sweet-alert button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.sweet-alert button.fc-month-button.fc-state-default.fc-corner-left:focus,
.sweet-alert button.fc-agendaWeek-button:focus,
.sweet-alert button.btn-outline.fc-prev-button:focus,
.sweet-alert button.btn-outline.fc-next-button:focus,
.sweet-alert button.btn-outline.fc-today-button:focus,
.owl-theme .owl-nav .btn-outline.btn-success[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-success[class*='owl-'].btn:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-success[class*='owl-'].btn:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-prev-button:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-prev-button:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-next-button:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-next-button:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-today-button:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-today-button:focus,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-prev-button:focus,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-prev-button:focus,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-next-button:focus,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-next-button:focus,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-today-button:focus,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-today-button:focus,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button.btn-success[class*='owl-']:focus,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button.btn-success[class*='owl-']:focus,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-prev-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-prev-button:focus,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-next-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-next-button:focus,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-today-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-today-button:focus,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:focus,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:focus,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-prev-button:focus,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-next-button:focus,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-today-button:focus,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.fc-agendaWeek-button:focus,
.btn-outline.fc-prev-button:focus,
button.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.fc-prev-button.fc-agendaWeek-button:focus,
.btn-outline.fc-next-button:focus,
button.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.fc-next-button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.fc-next-button.fc-agendaWeek-button:focus,
.btn-outline.fc-today-button:focus,
button.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.fc-today-button.fc-month-button.fc-state-default.fc-corner-left:focus,
button.fc-today-button.fc-agendaWeek-button:focus,
.btn.btn-outline.btn-success:active,
.wizard > .actions .disabled a.btn-outline.btn-success:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-success.btn:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-success.btn:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-prev-button:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-prev-button:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-next-button:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-next-button:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-today-button:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-today-button:active,
.wizard > .actions .disabled .note-editor a.btn-success.note-btn.btn-sm:active,
.note-editor .wizard > .actions .disabled a.btn-success.note-btn.btn-sm:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-prev-button:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-prev-button:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-next-button:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-next-button:active,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-today-button:active,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-today-button:active,
.wizard > .actions .disabled a.btn-outline.fc-prev-button:active,
.wizard > .actions .disabled a.btn-outline.fc-next-button:active,
.wizard > .actions .disabled a.btn-outline.fc-today-button:active,
.wizard > .actions .disabled a.btn-outline.btn-success:active:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.btn-success.btn:active:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.btn-success.btn:active:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-prev-button:active:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-prev-button:active:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-next-button:active:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-next-button:active:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar a.fc-today-button:active:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled a.fc-today-button:active:hover,
.wizard > .actions .disabled .note-editor a.btn-success.note-btn.btn-sm:active:hover,
.note-editor .wizard > .actions .disabled a.btn-success.note-btn.btn-sm:active:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-prev-button:active:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-prev-button:active:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-next-button:active:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-next-button:active:hover,
.wizard > .actions .disabled .note-editor a.note-btn.btn-sm.fc-today-button:active:hover,
.note-editor .wizard > .actions .disabled a.note-btn.btn-sm.fc-today-button:active:hover,
.wizard > .actions .disabled a.btn-outline.fc-prev-button:active:hover,
.wizard > .actions .disabled a.btn-outline.fc-next-button:active:hover,
.wizard > .actions .disabled a.btn-outline.fc-today-button:active:hover,
.wizard > .actions a.btn-outline.btn-success:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success.btn:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success.btn:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:active,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:active,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:active,
.wizard > .actions .note-editor a.btn-success.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.btn-success.note-btn.btn-sm:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-prev-button:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-prev-button:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-next-button:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-next-button:active,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-today-button:active,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-today-button:active,
.wizard > .actions a.btn-outline.fc-prev-button:active,
.wizard > .actions a.btn-outline.fc-next-button:active,
.wizard > .actions a.btn-outline.fc-today-button:active,
.wizard > .actions a.btn-outline.btn-success:active:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success.btn:active:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success.btn:active:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-prev-button:active:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-prev-button:active:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-next-button:active:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-next-button:active:hover,
.wizard > .actions ul.wysihtml5-toolbar a.fc-today-button:active:hover,
ul.wysihtml5-toolbar .wizard > .actions a.fc-today-button:active:hover,
.wizard > .actions .note-editor a.btn-success.note-btn.btn-sm:active:hover,
.note-editor .wizard > .actions a.btn-success.note-btn.btn-sm:active:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-prev-button:active:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-prev-button:active:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-next-button:active:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-next-button:active:hover,
.wizard > .actions .note-editor a.note-btn.btn-sm.fc-today-button:active:hover,
.note-editor .wizard > .actions a.note-btn.btn-sm.fc-today-button:active:hover,
.wizard > .actions a.btn-outline.fc-prev-button:active:hover,
.wizard > .actions a.btn-outline.fc-next-button:active:hover,
.wizard > .actions a.btn-outline.fc-today-button:active:hover,
ul.wysihtml5-toolbar a.btn.btn-success:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-success:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-success:active,
ul.wysihtml5-toolbar .dt-buttons a.btn-success.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.btn-success.dt-button:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-success[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-success[class*='owl-']:active,
ul.wysihtml5-toolbar a.fc-prev-button:active,
ul.wysihtml5-toolbar a.fc-next-button:active,
ul.wysihtml5-toolbar a.fc-today-button:active,
.note-editor .btn.btn-success.note-btn.btn-sm:active,
.note-editor .dt-buttons .btn-success.note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .btn-success.note-btn.btn-sm.dt-button:active,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head button.btn-success.note-btn.btn-sm:active,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor button.btn-success.note-btn.btn-sm:active,
.note-editor .sweet-alert button.btn-success.note-btn.btn-sm:active,
.sweet-alert .note-editor button.btn-success.note-btn.btn-sm:active,
.note-editor .owl-theme .owl-nav .btn-success.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .btn-success.note-btn.btn-sm[class*='owl-']:active,
.note-editor button.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.note-editor button.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left:active,
.note-editor button.note-btn.btn-sm.fc-agendaWeek-button:active,
.note-editor .note-btn.btn-sm.fc-prev-button:active,
.note-editor .note-btn.btn-sm.fc-next-button:active,
.note-editor .note-btn.btn-sm.fc-today-button:active,
.dt-buttons .btn-outline.btn-success.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.btn-success.dt-button.btn:active,
ul.wysihtml5-toolbar .dt-buttons a.btn-success.dt-button.btn:active,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-prev-button:active,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-prev-button:active,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-next-button:active,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-next-button:active,
.dt-buttons ul.wysihtml5-toolbar a.dt-button.fc-today-button:active,
ul.wysihtml5-toolbar .dt-buttons a.dt-button.fc-today-button:active,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-prev-button:active,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-prev-button:active,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-next-button:active,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-next-button:active,
.dt-buttons .note-editor .dt-button.note-btn.btn-sm.fc-today-button:active,
.note-editor .dt-buttons .dt-button.note-btn.btn-sm.fc-today-button:active,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.btn-success.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.btn-success.dt-button:active,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-prev-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-prev-button:active,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-next-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-next-button:active,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button.fc-today-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button.fc-today-button:active,
.dt-buttons button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.dt-buttons button.dt-button.fc-month-button.fc-state-default.fc-corner-left:active,
.dt-buttons button.dt-button.fc-agendaWeek-button:active,
.dt-buttons .btn-outline.dt-button.fc-prev-button:active,
.dt-buttons .btn-outline.dt-button.fc-next-button:active,
.dt-buttons .btn-outline.dt-button.fc-today-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-success:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-month-button.fc-state-default.fc-corner-left:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:active,
.sweet-alert button.btn-outline.btn-success:active,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-prev-button:active,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-prev-button:active,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-next-button:active,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-next-button:active,
.sweet-alert .note-editor button.note-btn.btn-sm.fc-today-button:active,
.note-editor .sweet-alert button.note-btn.btn-sm.fc-today-button:active,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.btn-success:active,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.btn-success:active,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-prev-button:active,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-next-button:active,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.fc-today-button:active,
.sweet-alert button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.sweet-alert button.fc-month-button.fc-state-default.fc-corner-left:active,
.sweet-alert button.fc-agendaWeek-button:active,
.sweet-alert button.btn-outline.fc-prev-button:active,
.sweet-alert button.btn-outline.fc-next-button:active,
.sweet-alert button.btn-outline.fc-today-button:active,
.owl-theme .owl-nav .btn-outline.btn-success[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-success[class*='owl-'].btn:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-success[class*='owl-'].btn:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-prev-button:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-prev-button:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-next-button:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-next-button:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a[class*='owl-'].fc-today-button:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a[class*='owl-'].fc-today-button:active,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-prev-button:active,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-prev-button:active,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-next-button:active,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-next-button:active,
.owl-theme .owl-nav .note-editor [class*='owl-'].note-btn.btn-sm.fc-today-button:active,
.note-editor .owl-theme .owl-nav [class*='owl-'].note-btn.btn-sm.fc-today-button:active,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button.btn-success[class*='owl-']:active,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button.btn-success[class*='owl-']:active,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-prev-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-prev-button:active,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-next-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-next-button:active,
.owl-theme .owl-nav .tablesaw-sortable th.tablesaw-sortable-head button[class*='owl-'].fc-today-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .owl-theme .owl-nav button[class*='owl-'].fc-today-button:active,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.owl-theme .owl-nav button[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left:active,
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button:active,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-prev-button:active,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-next-button:active,
.owl-theme .owl-nav .btn-outline[class*='owl-'].fc-today-button:active,
button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.fc-month-button.fc-state-default.fc-corner-left:active,
button.fc-agendaWeek-button:active,
.btn-outline.fc-prev-button:active,
button.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left:active,
button.fc-prev-button.fc-agendaWeek-button:active,
.btn-outline.fc-next-button:active,
button.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.fc-next-button.fc-month-button.fc-state-default.fc-corner-left:active,
button.fc-next-button.fc-agendaWeek-button:active,
.btn-outline.fc-today-button:active,
button.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.fc-today-button.fc-month-button.fc-state-default.fc-corner-left:active,
button.fc-today-button.fc-agendaWeek-button:active {
  border: solid 1px #8bc34a;
}
.btn.btn-outline.btn-danger,
.wizard > .actions a.btn-outline.btn-danger,
.wizard > .actions .note-editor a.btn-danger.note-btn.btn-sm,
.note-editor .wizard > .actions a.btn-danger.note-btn.btn-sm,
ul.wysihtml5-toolbar a.btn.btn-danger,
ul.wysihtml5-toolbar .wizard > .actions a.btn-danger,
.wizard > .actions ul.wysihtml5-toolbar a.btn-danger,
ul.wysihtml5-toolbar .dt-buttons a.btn-danger.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.btn-danger.dt-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-danger[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-danger[class*='owl-'],
ul.wysihtml5-toolbar a.btn-danger.fc-prev-button,
ul.wysihtml5-toolbar a.btn-danger.fc-next-button,
ul.wysihtml5-toolbar a.btn-danger.fc-today-button,
.note-editor .btn.btn-danger.note-btn.btn-sm,
.note-editor .dt-buttons .btn-danger.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .btn-danger.note-btn.btn-sm.dt-button,
.note-editor .sweet-alert button.btn-danger.note-btn.btn-sm,
.sweet-alert .note-editor button.btn-danger.note-btn.btn-sm,
.note-editor .owl-theme .owl-nav .btn-danger.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .btn-danger.note-btn.btn-sm[class*='owl-'],
.note-editor button.btn-danger.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .btn-danger.note-btn.btn-sm.fc-prev-button,
.note-editor .btn-danger.note-btn.btn-sm.fc-next-button,
.note-editor .btn-danger.note-btn.btn-sm.fc-today-button,
.dt-buttons .btn-outline.btn-danger.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-danger,
.sweet-alert button.btn-outline.btn-danger,
.owl-theme .owl-nav .btn-outline.btn-danger[class*='owl-'],
.owl-theme .owl-nav button.btn-danger[class*='owl-'].fc-agendaWeek-button,
button.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-danger.fc-month-button.fc-state-default.fc-corner-left,
button.btn-danger.fc-agendaWeek-button,
.btn-outline.btn-danger.fc-prev-button,
.btn-outline.btn-danger.fc-next-button,
.btn-outline.btn-danger.fc-today-button {
  border: solid 1px rgba(243, 57, 35, 0.5);
  color: #f33923;
}
.btn.btn-outline.btn-danger:hover,
.wizard > .actions a.btn-outline.btn-danger:hover,
.wizard > .actions .note-editor a.btn-danger.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.btn-danger.note-btn.btn-sm:hover,
ul.wysihtml5-toolbar a.btn.btn-danger:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-danger:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-danger:hover,
ul.wysihtml5-toolbar .dt-buttons a.btn-danger.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.btn-danger.dt-button:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-danger[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-danger[class*='owl-']:hover,
ul.wysihtml5-toolbar a.btn-danger.fc-prev-button:hover,
ul.wysihtml5-toolbar a.btn-danger.fc-next-button:hover,
ul.wysihtml5-toolbar a.btn-danger.fc-today-button:hover,
.note-editor .btn.btn-danger.note-btn.btn-sm:hover,
.note-editor .dt-buttons .btn-danger.note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .btn-danger.note-btn.btn-sm.dt-button:hover,
.note-editor .sweet-alert button.btn-danger.note-btn.btn-sm:hover,
.sweet-alert .note-editor button.btn-danger.note-btn.btn-sm:hover,
.note-editor .owl-theme .owl-nav .btn-danger.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .btn-danger.note-btn.btn-sm[class*='owl-']:hover,
.note-editor .btn-danger.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .btn-danger.note-btn.btn-sm.fc-next-button:hover,
.note-editor .btn-danger.note-btn.btn-sm.fc-today-button:hover,
.dt-buttons .btn-outline.btn-danger.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-danger:hover,
.sweet-alert button.btn-outline.btn-danger:hover,
.owl-theme .owl-nav .btn-outline.btn-danger[class*='owl-']:hover,
button.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-danger.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-danger.fc-agendaWeek-button:hover,
.btn-outline.btn-danger.fc-prev-button:hover,
.btn-outline.btn-danger.fc-next-button:hover,
.btn-outline.btn-danger.fc-today-button:hover,
.btn.btn-outline.btn-danger:focus,
.wizard > .actions a.btn-outline.btn-danger:focus,
.wizard > .actions .note-editor a.btn-danger.note-btn.btn-sm:focus,
.note-editor .wizard > .actions a.btn-danger.note-btn.btn-sm:focus,
ul.wysihtml5-toolbar a.btn.btn-danger:focus,
ul.wysihtml5-toolbar .wizard > .actions a.btn-danger:focus,
.wizard > .actions ul.wysihtml5-toolbar a.btn-danger:focus,
ul.wysihtml5-toolbar .dt-buttons a.btn-danger.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.btn-danger.dt-button:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-danger[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-danger[class*='owl-']:focus,
ul.wysihtml5-toolbar a.btn-danger.fc-prev-button:focus,
ul.wysihtml5-toolbar a.btn-danger.fc-next-button:focus,
ul.wysihtml5-toolbar a.btn-danger.fc-today-button:focus,
.note-editor .btn.btn-danger.note-btn.btn-sm:focus,
.note-editor .dt-buttons .btn-danger.note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .btn-danger.note-btn.btn-sm.dt-button:focus,
.note-editor .sweet-alert button.btn-danger.note-btn.btn-sm:focus,
.sweet-alert .note-editor button.btn-danger.note-btn.btn-sm:focus,
.note-editor .owl-theme .owl-nav .btn-danger.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .btn-danger.note-btn.btn-sm[class*='owl-']:focus,
.note-editor .btn-danger.note-btn.btn-sm.fc-prev-button:focus,
.note-editor .btn-danger.note-btn.btn-sm.fc-next-button:focus,
.note-editor .btn-danger.note-btn.btn-sm.fc-today-button:focus,
.dt-buttons .btn-outline.btn-danger.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-danger:focus,
.sweet-alert button.btn-outline.btn-danger:focus,
.owl-theme .owl-nav .btn-outline.btn-danger[class*='owl-']:focus,
button.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-danger.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-danger.fc-agendaWeek-button:focus,
.btn-outline.btn-danger.fc-prev-button:focus,
.btn-outline.btn-danger.fc-next-button:focus,
.btn-outline.btn-danger.fc-today-button:focus,
.btn.btn-outline.btn-danger:active,
.wizard > .actions a.btn-outline.btn-danger:active,
.wizard > .actions .note-editor a.btn-danger.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.btn-danger.note-btn.btn-sm:active,
ul.wysihtml5-toolbar a.btn.btn-danger:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-danger:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-danger:active,
ul.wysihtml5-toolbar .dt-buttons a.btn-danger.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.btn-danger.dt-button:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-danger[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-danger[class*='owl-']:active,
ul.wysihtml5-toolbar a.btn-danger.fc-prev-button:active,
ul.wysihtml5-toolbar a.btn-danger.fc-next-button:active,
ul.wysihtml5-toolbar a.btn-danger.fc-today-button:active,
.note-editor .btn.btn-danger.note-btn.btn-sm:active,
.note-editor .dt-buttons .btn-danger.note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .btn-danger.note-btn.btn-sm.dt-button:active,
.note-editor .sweet-alert button.btn-danger.note-btn.btn-sm:active,
.sweet-alert .note-editor button.btn-danger.note-btn.btn-sm:active,
.note-editor .owl-theme .owl-nav .btn-danger.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .btn-danger.note-btn.btn-sm[class*='owl-']:active,
.note-editor .btn-danger.note-btn.btn-sm.fc-prev-button:active,
.note-editor .btn-danger.note-btn.btn-sm.fc-next-button:active,
.note-editor .btn-danger.note-btn.btn-sm.fc-today-button:active,
.dt-buttons .btn-outline.btn-danger.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-danger:active,
.sweet-alert button.btn-outline.btn-danger:active,
.owl-theme .owl-nav .btn-outline.btn-danger[class*='owl-']:active,
button.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-danger.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-danger.fc-agendaWeek-button:active,
.btn-outline.btn-danger.fc-prev-button:active,
.btn-outline.btn-danger.fc-next-button:active,
.btn-outline.btn-danger.fc-today-button:active {
  border: solid 1px #f33923;
}
.btn.btn-outline.btn-warning,
.wizard > .actions a.btn-outline.btn-warning,
.wizard > .actions .note-editor a.btn-warning.note-btn.btn-sm,
.note-editor .wizard > .actions a.btn-warning.note-btn.btn-sm,
ul.wysihtml5-toolbar a.btn.btn-warning,
ul.wysihtml5-toolbar .wizard > .actions a.btn-warning,
.wizard > .actions ul.wysihtml5-toolbar a.btn-warning,
ul.wysihtml5-toolbar .dt-buttons a.btn-warning.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.btn-warning.dt-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-warning[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-warning[class*='owl-'],
ul.wysihtml5-toolbar a.btn-warning.fc-prev-button,
ul.wysihtml5-toolbar a.btn-warning.fc-next-button,
ul.wysihtml5-toolbar a.btn-warning.fc-today-button,
.note-editor .btn.btn-warning.note-btn.btn-sm,
.note-editor .dt-buttons .btn-warning.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .btn-warning.note-btn.btn-sm.dt-button,
.note-editor .sweet-alert button.btn-warning.note-btn.btn-sm,
.sweet-alert .note-editor button.btn-warning.note-btn.btn-sm,
.note-editor .owl-theme .owl-nav .btn-warning.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .btn-warning.note-btn.btn-sm[class*='owl-'],
.note-editor button.btn-warning.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .btn-warning.note-btn.btn-sm.fc-prev-button,
.note-editor .btn-warning.note-btn.btn-sm.fc-next-button,
.note-editor .btn-warning.note-btn.btn-sm.fc-today-button,
.dt-buttons .btn-outline.btn-warning.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-warning,
.sweet-alert button.btn-outline.btn-warning,
.owl-theme .owl-nav .btn-outline.btn-warning[class*='owl-'],
.owl-theme .owl-nav button.btn-warning[class*='owl-'].fc-agendaWeek-button,
button.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-warning.fc-month-button.fc-state-default.fc-corner-left,
button.btn-warning.fc-agendaWeek-button,
.btn-outline.btn-warning.fc-prev-button,
.btn-outline.btn-warning.fc-next-button,
.btn-outline.btn-warning.fc-today-button {
  border: solid 1px rgba(248, 179, 45, 0.5);
  color: #f8b32d;
}
.btn.btn-outline.btn-warning:hover,
.wizard > .actions a.btn-outline.btn-warning:hover,
.wizard > .actions .note-editor a.btn-warning.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.btn-warning.note-btn.btn-sm:hover,
ul.wysihtml5-toolbar a.btn.btn-warning:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-warning:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-warning:hover,
ul.wysihtml5-toolbar .dt-buttons a.btn-warning.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.btn-warning.dt-button:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-warning[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-warning[class*='owl-']:hover,
ul.wysihtml5-toolbar a.btn-warning.fc-prev-button:hover,
ul.wysihtml5-toolbar a.btn-warning.fc-next-button:hover,
ul.wysihtml5-toolbar a.btn-warning.fc-today-button:hover,
.note-editor .btn.btn-warning.note-btn.btn-sm:hover,
.note-editor .dt-buttons .btn-warning.note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .btn-warning.note-btn.btn-sm.dt-button:hover,
.note-editor .sweet-alert button.btn-warning.note-btn.btn-sm:hover,
.sweet-alert .note-editor button.btn-warning.note-btn.btn-sm:hover,
.note-editor .owl-theme .owl-nav .btn-warning.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .btn-warning.note-btn.btn-sm[class*='owl-']:hover,
.note-editor .btn-warning.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .btn-warning.note-btn.btn-sm.fc-next-button:hover,
.note-editor .btn-warning.note-btn.btn-sm.fc-today-button:hover,
.dt-buttons .btn-outline.btn-warning.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-warning:hover,
.sweet-alert button.btn-outline.btn-warning:hover,
.owl-theme .owl-nav .btn-outline.btn-warning[class*='owl-']:hover,
button.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-warning.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-warning.fc-agendaWeek-button:hover,
.btn-outline.btn-warning.fc-prev-button:hover,
.btn-outline.btn-warning.fc-next-button:hover,
.btn-outline.btn-warning.fc-today-button:hover,
.btn.btn-outline.btn-warning:focus,
.wizard > .actions a.btn-outline.btn-warning:focus,
.wizard > .actions .note-editor a.btn-warning.note-btn.btn-sm:focus,
.note-editor .wizard > .actions a.btn-warning.note-btn.btn-sm:focus,
ul.wysihtml5-toolbar a.btn.btn-warning:focus,
ul.wysihtml5-toolbar .wizard > .actions a.btn-warning:focus,
.wizard > .actions ul.wysihtml5-toolbar a.btn-warning:focus,
ul.wysihtml5-toolbar .dt-buttons a.btn-warning.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar a.btn-warning.dt-button:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-warning[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-warning[class*='owl-']:focus,
ul.wysihtml5-toolbar a.btn-warning.fc-prev-button:focus,
ul.wysihtml5-toolbar a.btn-warning.fc-next-button:focus,
ul.wysihtml5-toolbar a.btn-warning.fc-today-button:focus,
.note-editor .btn.btn-warning.note-btn.btn-sm:focus,
.note-editor .dt-buttons .btn-warning.note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .btn-warning.note-btn.btn-sm.dt-button:focus,
.note-editor .sweet-alert button.btn-warning.note-btn.btn-sm:focus,
.sweet-alert .note-editor button.btn-warning.note-btn.btn-sm:focus,
.note-editor .owl-theme .owl-nav .btn-warning.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .btn-warning.note-btn.btn-sm[class*='owl-']:focus,
.note-editor .btn-warning.note-btn.btn-sm.fc-prev-button:focus,
.note-editor .btn-warning.note-btn.btn-sm.fc-next-button:focus,
.note-editor .btn-warning.note-btn.btn-sm.fc-today-button:focus,
.dt-buttons .btn-outline.btn-warning.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-warning:focus,
.sweet-alert button.btn-outline.btn-warning:focus,
.owl-theme .owl-nav .btn-outline.btn-warning[class*='owl-']:focus,
button.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-warning.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-warning.fc-agendaWeek-button:focus,
.btn-outline.btn-warning.fc-prev-button:focus,
.btn-outline.btn-warning.fc-next-button:focus,
.btn-outline.btn-warning.fc-today-button:focus,
.btn.btn-outline.btn-warning:active,
.wizard > .actions a.btn-outline.btn-warning:active,
.wizard > .actions .note-editor a.btn-warning.note-btn.btn-sm:active,
.note-editor .wizard > .actions a.btn-warning.note-btn.btn-sm:active,
ul.wysihtml5-toolbar a.btn.btn-warning:active,
ul.wysihtml5-toolbar .wizard > .actions a.btn-warning:active,
.wizard > .actions ul.wysihtml5-toolbar a.btn-warning:active,
ul.wysihtml5-toolbar .dt-buttons a.btn-warning.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar a.btn-warning.dt-button:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-warning[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-warning[class*='owl-']:active,
ul.wysihtml5-toolbar a.btn-warning.fc-prev-button:active,
ul.wysihtml5-toolbar a.btn-warning.fc-next-button:active,
ul.wysihtml5-toolbar a.btn-warning.fc-today-button:active,
.note-editor .btn.btn-warning.note-btn.btn-sm:active,
.note-editor .dt-buttons .btn-warning.note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .btn-warning.note-btn.btn-sm.dt-button:active,
.note-editor .sweet-alert button.btn-warning.note-btn.btn-sm:active,
.sweet-alert .note-editor button.btn-warning.note-btn.btn-sm:active,
.note-editor .owl-theme .owl-nav .btn-warning.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .btn-warning.note-btn.btn-sm[class*='owl-']:active,
.note-editor .btn-warning.note-btn.btn-sm.fc-prev-button:active,
.note-editor .btn-warning.note-btn.btn-sm.fc-next-button:active,
.note-editor .btn-warning.note-btn.btn-sm.fc-today-button:active,
.dt-buttons .btn-outline.btn-warning.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-warning:active,
.sweet-alert button.btn-outline.btn-warning:active,
.owl-theme .owl-nav .btn-outline.btn-warning[class*='owl-']:active,
button.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-warning.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-warning.fc-agendaWeek-button:active,
.btn-outline.btn-warning.fc-prev-button:active,
.btn-outline.btn-warning.fc-next-button:active,
.btn-outline.btn-warning.fc-today-button:active {
  border: solid 1px #f8b32d;
}
.btn.btn-outline.btn-outline-white,
.wizard > .actions a.btn-outline.btn-outline-white,
.wizard > .actions .note-editor a.btn-outline-white.note-btn.btn-sm,
.note-editor .wizard > .actions a.btn-outline-white.note-btn.btn-sm,
ul.wysihtml5-toolbar a.btn.btn-outline-white,
ul.wysihtml5-toolbar .wizard > .actions a.btn-outline-white,
.wizard > .actions ul.wysihtml5-toolbar a.btn-outline-white,
ul.wysihtml5-toolbar .dt-buttons a.btn-outline-white.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.btn-outline-white.dt-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-outline-white[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-outline-white[class*='owl-'],
ul.wysihtml5-toolbar a.btn-outline-white.fc-prev-button,
ul.wysihtml5-toolbar a.btn-outline-white.fc-next-button,
ul.wysihtml5-toolbar a.btn-outline-white.fc-today-button,
.note-editor .btn.btn-outline-white.note-btn.btn-sm,
.note-editor .dt-buttons .btn-outline-white.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .btn-outline-white.note-btn.btn-sm.dt-button,
.note-editor .sweet-alert button.btn-outline-white.note-btn.btn-sm,
.sweet-alert .note-editor button.btn-outline-white.note-btn.btn-sm,
.note-editor .owl-theme .owl-nav .btn-outline-white.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .btn-outline-white.note-btn.btn-sm[class*='owl-'],
.note-editor button.btn-outline-white.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .btn-outline-white.note-btn.btn-sm.fc-prev-button,
.note-editor .btn-outline-white.note-btn.btn-sm.fc-next-button,
.note-editor .btn-outline-white.note-btn.btn-sm.fc-today-button,
.dt-buttons .btn-outline.btn-outline-white.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-outline-white,
.sweet-alert button.btn-outline.btn-outline-white,
.owl-theme .owl-nav .btn-outline.btn-outline-white[class*='owl-'],
.owl-theme .owl-nav button.btn-outline-white[class*='owl-'].fc-agendaWeek-button,
button.btn-outline-white.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-outline-white.fc-month-button.fc-state-default.fc-corner-left,
button.btn-outline-white.fc-agendaWeek-button,
.btn-outline.btn-outline-white.fc-prev-button,
.btn-outline.btn-outline-white.fc-next-button,
.btn-outline.btn-outline-white.fc-today-button {
  border: solid 1px rgba(255, 255, 255, 0.5);
  color: white !important;
}
.btn.btn-outline.btn-outline-white:hover,
.wizard > .actions a.btn-outline.btn-outline-white:hover,
.wizard > .actions .note-editor a.btn-outline-white.note-btn.btn-sm:hover,
.note-editor .wizard > .actions a.btn-outline-white.note-btn.btn-sm:hover,
ul.wysihtml5-toolbar a.btn.btn-outline-white:hover,
ul.wysihtml5-toolbar .wizard > .actions a.btn-outline-white:hover,
.wizard > .actions ul.wysihtml5-toolbar a.btn-outline-white:hover,
ul.wysihtml5-toolbar .dt-buttons a.btn-outline-white.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar a.btn-outline-white.dt-button:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-outline-white[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-outline-white[class*='owl-']:hover,
ul.wysihtml5-toolbar a.btn-outline-white.fc-prev-button:hover,
ul.wysihtml5-toolbar a.btn-outline-white.fc-next-button:hover,
ul.wysihtml5-toolbar a.btn-outline-white.fc-today-button:hover,
.note-editor .btn.btn-outline-white.note-btn.btn-sm:hover,
.note-editor .dt-buttons .btn-outline-white.note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .btn-outline-white.note-btn.btn-sm.dt-button:hover,
.note-editor .sweet-alert button.btn-outline-white.note-btn.btn-sm:hover,
.sweet-alert .note-editor button.btn-outline-white.note-btn.btn-sm:hover,
.note-editor .owl-theme .owl-nav .btn-outline-white.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .btn-outline-white.note-btn.btn-sm[class*='owl-']:hover,
.note-editor .btn-outline-white.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .btn-outline-white.note-btn.btn-sm.fc-next-button:hover,
.note-editor .btn-outline-white.note-btn.btn-sm.fc-today-button:hover,
.dt-buttons .btn-outline.btn-outline-white.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-outline-white:hover,
.sweet-alert button.btn-outline.btn-outline-white:hover,
.owl-theme .owl-nav .btn-outline.btn-outline-white[class*='owl-']:hover,
button.btn-outline-white.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-outline-white.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-outline-white.fc-agendaWeek-button:hover,
.btn-outline.btn-outline-white.fc-prev-button:hover,
.btn-outline.btn-outline-white.fc-next-button:hover,
.btn-outline.btn-outline-white.fc-today-button:hover {
  border: solid 1px white;
}
.btn.btn-rounded,
.wizard > .actions a.btn-rounded,
.dt-buttons .btn-rounded.dt-button,
.dt-buttons button.dt-button.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-rounded,
.tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button,
.sweet-alert button.btn-rounded,
.owl-theme .owl-nav .btn-rounded[class*='owl-'],
.owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button,
button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.fc-month-button.fc-state-default.fc-corner-left,
button.fc-agendaWeek-button,
.btn-rounded.fc-prev-button,
.btn-rounded.fc-next-button,
.btn-rounded.fc-today-button {
  border-radius: 60px;
}
.btn.btn-lg,
.wizard > .actions a.btn-lg,
.dt-buttons .btn-lg.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-lg,
.sweet-alert button.btn-lg,
.owl-theme .owl-nav .btn-lg[class*='owl-'],
button.btn-lg.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-lg.fc-month-button.fc-state-default.fc-corner-left,
button.btn-lg.fc-agendaWeek-button,
.btn-lg.fc-prev-button,
.btn-lg.fc-next-button,
.btn-lg.fc-today-button {
  padding: 12px 25px;
}
.btn.btn-sm,
.wizard > .actions a.btn-sm,
.dt-buttons .btn-sm.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-sm,
.sweet-alert button.btn-sm,
.owl-theme .owl-nav .btn-sm[class*='owl-'],
button.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-sm.fc-month-button.fc-state-default.fc-corner-left,
button.btn-sm.fc-agendaWeek-button,
.btn-sm.fc-prev-button,
.btn-sm.fc-next-button,
.btn-sm.fc-today-button {
  padding: 8px 25px;
}
.btn.btn-xs,
.wizard > .actions a.btn-xs,
.wizard > .actions .dt-buttons a.dt-button,
.dt-buttons .wizard > .actions a.dt-button,
.wizard > .actions .owl-theme .owl-nav a[class*='owl-']:hover,
.owl-theme .owl-nav .wizard > .actions a[class*='owl-']:hover,
.wizard > .actions .owl-theme .owl-nav a[class*='owl-']:active,
.owl-theme .owl-nav .wizard > .actions a[class*='owl-']:active,
.dt-buttons .dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-xs,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dt-button,
.sweet-alert button.btn-xs,
.owl-theme .owl-nav [class*='owl-'],
button.btn-xs.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons button.fc-agendaDay-button.fc-state-default.fc-corner-right.dt-button,
.owl-theme .owl-nav button.fc-agendaDay-button.fc-state-default.fc-corner-right[class*='owl-'],
button.btn-xs.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons button.fc-month-button.fc-state-default.fc-corner-left.dt-button,
.owl-theme .owl-nav button.fc-month-button.fc-state-default.fc-corner-left[class*='owl-'],
button.btn-xs.fc-agendaWeek-button,
.btn-xs.fc-prev-button,
.btn-xs.fc-next-button,
.btn-xs.fc-today-button {
  padding: 4px 25px;
}
.btn.btn-icon i,
.wizard > .actions a.btn-icon i,
.dt-buttons .btn-icon.dt-button i,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon i,
.sweet-alert button.btn-icon i,
.owl-theme .owl-nav .btn-icon[class*='owl-'] i,
button.btn-icon.fc-agendaDay-button.fc-state-default.fc-corner-right i,
button.btn-icon.fc-month-button.fc-state-default.fc-corner-left i,
button.btn-icon.fc-agendaWeek-button i,
.btn-icon.fc-prev-button i,
.btn-icon.fc-next-button i,
.btn-icon.fc-today-button i {
  padding-right: 6px;
  font-size: 14px;
}
.btn.btn-icon.right-icon i,
.wizard > .actions a.btn-icon.right-icon i,
.dt-buttons .btn-icon.right-icon.dt-button i,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon.right-icon i,
.sweet-alert button.btn-icon.right-icon i,
.owl-theme .owl-nav .btn-icon.right-icon[class*='owl-'] i,
button.btn-icon.right-icon.fc-agendaDay-button.fc-state-default.fc-corner-right i,
button.btn-icon.right-icon.fc-month-button.fc-state-default.fc-corner-left i,
button.btn-icon.right-icon.fc-agendaWeek-button i,
.btn-icon.right-icon.fc-prev-button i,
.btn-icon.right-icon.fc-next-button i,
.btn-icon.right-icon.fc-today-button i {
  padding-left: 6px;
}
.btn.btn-icon.left-icon i,
.wizard > .actions a.btn-icon.left-icon i,
.dt-buttons .btn-icon.left-icon.dt-button i,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon.left-icon i,
.sweet-alert button.btn-icon.left-icon i,
.owl-theme .owl-nav .btn-icon.left-icon[class*='owl-'] i,
button.btn-icon.left-icon.fc-agendaDay-button.fc-state-default.fc-corner-right i,
button.btn-icon.left-icon.fc-month-button.fc-state-default.fc-corner-left i,
button.btn-icon.left-icon.fc-agendaWeek-button i,
.btn-icon.left-icon.fc-prev-button i,
.btn-icon.left-icon.fc-next-button i,
.btn-icon.left-icon.fc-today-button i {
  padding-right: 6px;
}
.btn.btn-lable-wrap,
.wizard > .actions a.btn-lable-wrap,
.dt-buttons .btn-lable-wrap.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-lable-wrap,
.sweet-alert button.btn-lable-wrap,
.owl-theme .owl-nav .btn-lable-wrap[class*='owl-'],
button.btn-lable-wrap.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-lable-wrap.fc-month-button.fc-state-default.fc-corner-left,
button.btn-lable-wrap.fc-agendaWeek-button,
.btn-lable-wrap.fc-prev-button,
.btn-lable-wrap.fc-next-button,
.btn-lable-wrap.fc-today-button {
  position: relative;
}
.btn.btn-lable-wrap .btn-label,
.wizard > .actions a.btn-lable-wrap .btn-label,
.dt-buttons .btn-lable-wrap.dt-button .btn-label,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-lable-wrap .btn-label,
.sweet-alert button.btn-lable-wrap .btn-label,
.owl-theme .owl-nav .btn-lable-wrap[class*='owl-'] .btn-label,
button.btn-lable-wrap.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-label,
button.btn-lable-wrap.fc-month-button.fc-state-default.fc-corner-left .btn-label,
button.btn-lable-wrap.fc-agendaWeek-button .btn-label,
.btn-lable-wrap.fc-prev-button .btn-label,
.btn-lable-wrap.fc-next-button .btn-label,
.btn-lable-wrap.fc-today-button .btn-label {
  background: rgba(0, 0, 0, 0.05) none repeat scroll 0 0;
  padding: 11px 15px;
  position: absolute;
  top: -2px;
}
.btn.btn-lable-wrap .btn-label i,
.wizard > .actions a.btn-lable-wrap .btn-label i,
.dt-buttons .btn-lable-wrap.dt-button .btn-label i,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-lable-wrap .btn-label i,
.sweet-alert button.btn-lable-wrap .btn-label i,
.owl-theme .owl-nav .btn-lable-wrap[class*='owl-'] .btn-label i,
button.btn-lable-wrap.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-label i,
button.btn-lable-wrap.fc-month-button.fc-state-default.fc-corner-left .btn-label i,
button.btn-lable-wrap.fc-agendaWeek-button .btn-label i,
.btn-lable-wrap.fc-prev-button .btn-label i,
.btn-lable-wrap.fc-next-button .btn-label i,
.btn-lable-wrap.fc-today-button .btn-label i {
  font-size: 14px;
}
.btn.btn-lable-wrap.left-label .btn-label,
.wizard > .actions a.btn-lable-wrap.left-label .btn-label,
.dt-buttons .btn-lable-wrap.left-label.dt-button .btn-label,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-lable-wrap.left-label .btn-label,
.sweet-alert button.btn-lable-wrap.left-label .btn-label,
.owl-theme .owl-nav .btn-lable-wrap.left-label[class*='owl-'] .btn-label,
button.btn-lable-wrap.left-label.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-label,
button.btn-lable-wrap.left-label.fc-month-button.fc-state-default.fc-corner-left .btn-label,
button.btn-lable-wrap.left-label.fc-agendaWeek-button .btn-label,
.btn-lable-wrap.left-label.fc-prev-button .btn-label,
.btn-lable-wrap.left-label.fc-next-button .btn-label,
.btn-lable-wrap.left-label.fc-today-button .btn-label {
  left: -2px;
}
.btn.btn-lable-wrap.left-label .btn-text,
.wizard > .actions a.btn-lable-wrap.left-label .btn-text,
.dt-buttons .btn-lable-wrap.left-label.dt-button .btn-text,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-lable-wrap.left-label .btn-text,
.sweet-alert button.btn-lable-wrap.left-label .btn-text,
.owl-theme .owl-nav .btn-lable-wrap.left-label[class*='owl-'] .btn-text,
button.btn-lable-wrap.left-label.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-text,
button.btn-lable-wrap.left-label.fc-month-button.fc-state-default.fc-corner-left .btn-text,
button.btn-lable-wrap.left-label.fc-agendaWeek-button .btn-text,
.btn-lable-wrap.left-label.fc-prev-button .btn-text,
.btn-lable-wrap.left-label.fc-next-button .btn-text,
.btn-lable-wrap.left-label.fc-today-button .btn-text {
  margin-left: 35px;
}
.btn.btn-lable-wrap.right-label .btn-label,
.wizard > .actions a.btn-lable-wrap.right-label .btn-label,
.dt-buttons .btn-lable-wrap.right-label.dt-button .btn-label,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-lable-wrap.right-label .btn-label,
.sweet-alert button.btn-lable-wrap.right-label .btn-label,
.owl-theme .owl-nav .btn-lable-wrap.right-label[class*='owl-'] .btn-label,
button.btn-lable-wrap.right-label.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-label,
button.btn-lable-wrap.right-label.fc-month-button.fc-state-default.fc-corner-left .btn-label,
button.btn-lable-wrap.right-label.fc-agendaWeek-button .btn-label,
.btn-lable-wrap.right-label.fc-prev-button .btn-label,
.btn-lable-wrap.right-label.fc-next-button .btn-label,
.btn-lable-wrap.right-label.fc-today-button .btn-label {
  right: -2px;
}
.btn.btn-lable-wrap.right-label .btn-text,
.wizard > .actions a.btn-lable-wrap.right-label .btn-text,
.dt-buttons .btn-lable-wrap.right-label.dt-button .btn-text,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-lable-wrap.right-label .btn-text,
.sweet-alert button.btn-lable-wrap.right-label .btn-text,
.owl-theme .owl-nav .btn-lable-wrap.right-label[class*='owl-'] .btn-text,
button.btn-lable-wrap.right-label.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-text,
button.btn-lable-wrap.right-label.fc-month-button.fc-state-default.fc-corner-left .btn-text,
button.btn-lable-wrap.right-label.fc-agendaWeek-button .btn-text,
.btn-lable-wrap.right-label.fc-prev-button .btn-text,
.btn-lable-wrap.right-label.fc-next-button .btn-text,
.btn-lable-wrap.right-label.fc-today-button .btn-text {
  margin-right: 35px;
}
.btn.btn-rounded.left-label .btn-label,
.wizard > .actions a.btn-rounded.left-label .btn-label,
.dt-buttons .btn-rounded.left-label.dt-button .btn-label,
.dt-buttons button.left-label.dt-button.fc-agendaWeek-button .btn-label,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-rounded.left-label .btn-label,
.tablesaw-sortable th.tablesaw-sortable-head button.left-label.fc-agendaWeek-button .btn-label,
.sweet-alert button.btn-rounded.left-label .btn-label,
.sweet-alert button.left-label.fc-agendaWeek-button .btn-label,
.owl-theme .owl-nav .btn-rounded.left-label[class*='owl-'] .btn-label,
.owl-theme .owl-nav button.left-label[class*='owl-'].fc-agendaWeek-button .btn-label,
button.left-label.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-label,
button.left-label.fc-month-button.fc-state-default.fc-corner-left .btn-label,
button.left-label.fc-agendaWeek-button .btn-label,
.btn-rounded.left-label.fc-prev-button .btn-label,
button.left-label.fc-prev-button.fc-agendaWeek-button .btn-label,
.btn-rounded.left-label.fc-next-button .btn-label,
button.left-label.fc-next-button.fc-agendaWeek-button .btn-label,
.btn-rounded.left-label.fc-today-button .btn-label,
button.left-label.fc-today-button.fc-agendaWeek-button .btn-label {
  border-bottom-left-radius: 60px;
  border-top-left-radius: 60px;
}
.btn.btn-rounded.right-label .btn-label,
.wizard > .actions a.btn-rounded.right-label .btn-label,
.dt-buttons .btn-rounded.right-label.dt-button .btn-label,
.dt-buttons button.right-label.dt-button.fc-agendaWeek-button .btn-label,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-rounded.right-label .btn-label,
.tablesaw-sortable th.tablesaw-sortable-head button.right-label.fc-agendaWeek-button .btn-label,
.sweet-alert button.btn-rounded.right-label .btn-label,
.sweet-alert button.right-label.fc-agendaWeek-button .btn-label,
.owl-theme .owl-nav .btn-rounded.right-label[class*='owl-'] .btn-label,
.owl-theme .owl-nav button.right-label[class*='owl-'].fc-agendaWeek-button .btn-label,
button.right-label.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-label,
button.right-label.fc-month-button.fc-state-default.fc-corner-left .btn-label,
button.right-label.fc-agendaWeek-button .btn-label,
.btn-rounded.right-label.fc-prev-button .btn-label,
button.right-label.fc-prev-button.fc-agendaWeek-button .btn-label,
.btn-rounded.right-label.fc-next-button .btn-label,
button.right-label.fc-next-button.fc-agendaWeek-button .btn-label,
.btn-rounded.right-label.fc-today-button .btn-label,
button.right-label.fc-today-button.fc-agendaWeek-button .btn-label {
  border-bottom-right-radius: 60px;
  border-top-right-radius: 60px;
}
.btn.btn-outline.btn-lable-wrap .btn-label,
.wizard > .actions a.btn-outline.btn-lable-wrap .btn-label,
.wizard > .actions .note-editor a.btn-lable-wrap.note-btn.btn-sm .btn-label,
.note-editor .wizard > .actions a.btn-lable-wrap.note-btn.btn-sm .btn-label,
ul.wysihtml5-toolbar a.btn.btn-lable-wrap .btn-label,
ul.wysihtml5-toolbar .wizard > .actions a.btn-lable-wrap .btn-label,
.wizard > .actions ul.wysihtml5-toolbar a.btn-lable-wrap .btn-label,
ul.wysihtml5-toolbar .dt-buttons a.btn-lable-wrap.dt-button .btn-label,
.dt-buttons ul.wysihtml5-toolbar a.btn-lable-wrap.dt-button .btn-label,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.btn-lable-wrap[class*='owl-'] .btn-label,
.owl-theme .owl-nav ul.wysihtml5-toolbar a.btn-lable-wrap[class*='owl-'] .btn-label,
ul.wysihtml5-toolbar a.btn-lable-wrap.fc-prev-button .btn-label,
ul.wysihtml5-toolbar a.btn-lable-wrap.fc-next-button .btn-label,
ul.wysihtml5-toolbar a.btn-lable-wrap.fc-today-button .btn-label,
.note-editor .btn.btn-lable-wrap.note-btn.btn-sm .btn-label,
.note-editor .dt-buttons .btn-lable-wrap.note-btn.btn-sm.dt-button .btn-label,
.dt-buttons .note-editor .btn-lable-wrap.note-btn.btn-sm.dt-button .btn-label,
.note-editor .sweet-alert button.btn-lable-wrap.note-btn.btn-sm .btn-label,
.sweet-alert .note-editor button.btn-lable-wrap.note-btn.btn-sm .btn-label,
.note-editor .owl-theme .owl-nav .btn-lable-wrap.note-btn.btn-sm[class*='owl-'] .btn-label,
.owl-theme .owl-nav .note-editor .btn-lable-wrap.note-btn.btn-sm[class*='owl-'] .btn-label,
.note-editor button.btn-lable-wrap.note-btn.btn-sm.fc-agendaWeek-button .btn-label,
.note-editor .btn-lable-wrap.note-btn.btn-sm.fc-prev-button .btn-label,
.note-editor .btn-lable-wrap.note-btn.btn-sm.fc-next-button .btn-label,
.note-editor .btn-lable-wrap.note-btn.btn-sm.fc-today-button .btn-label,
.dt-buttons .btn-outline.btn-lable-wrap.dt-button .btn-label,
.dt-buttons button.btn-lable-wrap.dt-button.fc-agendaWeek-button .btn-label,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-lable-wrap .btn-label,
.sweet-alert button.btn-outline.btn-lable-wrap .btn-label,
.sweet-alert button.btn-lable-wrap.fc-agendaWeek-button .btn-label,
.owl-theme .owl-nav .btn-outline.btn-lable-wrap[class*='owl-'] .btn-label,
.owl-theme .owl-nav button.btn-lable-wrap[class*='owl-'].fc-agendaWeek-button .btn-label,
button.btn-lable-wrap.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-label,
button.btn-lable-wrap.fc-month-button.fc-state-default.fc-corner-left .btn-label,
button.btn-lable-wrap.fc-agendaWeek-button .btn-label,
.btn-outline.btn-lable-wrap.fc-prev-button .btn-label,
button.btn-lable-wrap.fc-prev-button.fc-agendaWeek-button .btn-label,
.btn-outline.btn-lable-wrap.fc-next-button .btn-label,
button.btn-lable-wrap.fc-next-button.fc-agendaWeek-button .btn-label,
.btn-outline.btn-lable-wrap.fc-today-button .btn-label,
button.btn-lable-wrap.fc-today-button.fc-agendaWeek-button .btn-label {
  background: transparent;
}
.btn.btn-anim,
.wizard > .actions a.btn-anim,
.dt-buttons .btn-anim.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-anim,
.sweet-alert button.btn-anim,
.owl-theme .owl-nav .btn-anim[class*='owl-'],
button.btn-anim.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-anim.fc-month-button.fc-state-default.fc-corner-left,
button.btn-anim.fc-agendaWeek-button,
.btn-anim.fc-prev-button,
.btn-anim.fc-next-button,
.btn-anim.fc-today-button {
  position: relative;
  overflow: hidden;
}
.btn.btn-anim i,
.wizard > .actions a.btn-anim i,
.dt-buttons .btn-anim.dt-button i,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-anim i,
.sweet-alert button.btn-anim i,
.owl-theme .owl-nav .btn-anim[class*='owl-'] i,
button.btn-anim.fc-agendaDay-button.fc-state-default.fc-corner-right i,
button.btn-anim.fc-month-button.fc-state-default.fc-corner-left i,
button.btn-anim.fc-agendaWeek-button i,
.btn-anim.fc-prev-button i,
.btn-anim.fc-next-button i,
.btn-anim.fc-today-button i {
  font-size: 16px;
  height: 100%;
  left: 0;
  padding-top: 10px;
  position: absolute;
  top: -100%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 100%;
}
.btn.btn-anim .btn-text,
.wizard > .actions a.btn-anim .btn-text,
.dt-buttons .btn-anim.dt-button .btn-text,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-anim .btn-text,
.sweet-alert button.btn-anim .btn-text,
.owl-theme .owl-nav .btn-anim[class*='owl-'] .btn-text,
button.btn-anim.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-text,
button.btn-anim.fc-month-button.fc-state-default.fc-corner-left .btn-text,
button.btn-anim.fc-agendaWeek-button .btn-text,
.btn-anim.fc-prev-button .btn-text,
.btn-anim.fc-next-button .btn-text,
.btn-anim.fc-today-button .btn-text {
  position: relative;
  top: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.btn.btn-anim:hover i,
.wizard > .actions a.btn-anim:hover i,
.dt-buttons .btn-anim.dt-button:hover i,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-anim:hover i,
.sweet-alert button.btn-anim:hover i,
.owl-theme .owl-nav .btn-anim[class*='owl-']:hover i,
button.btn-anim.fc-agendaDay-button.fc-state-default.fc-corner-right:hover i,
button.btn-anim.fc-month-button.fc-state-default.fc-corner-left:hover i,
button.btn-anim.fc-agendaWeek-button:hover i,
.btn-anim.fc-prev-button:hover i,
.btn-anim.fc-next-button:hover i,
.btn-anim.fc-today-button:hover i {
  top: 0;
}
.btn.btn-anim:hover .btn-text,
.wizard > .actions a.btn-anim:hover .btn-text,
.dt-buttons .btn-anim.dt-button:hover .btn-text,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-anim:hover .btn-text,
.sweet-alert button.btn-anim:hover .btn-text,
.owl-theme .owl-nav .btn-anim[class*='owl-']:hover .btn-text,
button.btn-anim.fc-agendaDay-button.fc-state-default.fc-corner-right:hover .btn-text,
button.btn-anim.fc-month-button.fc-state-default.fc-corner-left:hover .btn-text,
button.btn-anim.fc-agendaWeek-button:hover .btn-text,
.btn-anim.fc-prev-button:hover .btn-text,
.btn-anim.fc-next-button:hover .btn-text,
.btn-anim.fc-today-button:hover .btn-text {
  top: 50px;
}
.btn.btn-icon-anim,
.wizard > .actions a.btn-icon-anim,
.dt-buttons .btn-icon-anim.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim,
.sweet-alert button.btn-icon-anim,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-'],
button.btn-icon-anim.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-icon-anim.fc-month-button.fc-state-default.fc-corner-left,
button.btn-icon-anim.fc-agendaWeek-button,
.btn-icon-anim.fc-prev-button,
.btn-icon-anim.fc-next-button,
.btn-icon-anim.fc-today-button {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: none;
  padding: 11px;
  background: transparent;
}
.btn.btn-icon-anim:hover,
.wizard > .actions a.btn-icon-anim:hover,
.dt-buttons .btn-icon-anim.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim:hover,
.sweet-alert button.btn-icon-anim:hover,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-']:hover,
button.btn-icon-anim.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.fc-agendaWeek-button:hover,
.btn-icon-anim.fc-prev-button:hover,
.btn-icon-anim.fc-next-button:hover,
.btn-icon-anim.fc-today-button:hover,
.btn.btn-icon-anim:focus,
.wizard > .actions a.btn-icon-anim:focus,
.dt-buttons .btn-icon-anim.dt-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim:focus,
.sweet-alert button.btn-icon-anim:focus,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-']:focus,
button.btn-icon-anim.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
button.btn-icon-anim.fc-month-button.fc-state-default.fc-corner-left:focus,
button.btn-icon-anim.fc-agendaWeek-button:focus,
.btn-icon-anim.fc-prev-button:focus,
.btn-icon-anim.fc-next-button:focus,
.btn-icon-anim.fc-today-button:focus,
.btn.btn-icon-anim:active,
.wizard > .actions a.btn-icon-anim:active,
.dt-buttons .btn-icon-anim.dt-button:active,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim:active,
.sweet-alert button.btn-icon-anim:active,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-']:active,
button.btn-icon-anim.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
button.btn-icon-anim.fc-month-button.fc-state-default.fc-corner-left:active,
button.btn-icon-anim.fc-agendaWeek-button:active,
.btn-icon-anim.fc-prev-button:active,
.btn-icon-anim.fc-next-button:active,
.btn-icon-anim.fc-today-button:active {
  background: transparent;
  border: none;
}
.btn.btn-icon-anim i,
.wizard > .actions a.btn-icon-anim i,
.dt-buttons .btn-icon-anim.dt-button i,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim i,
.sweet-alert button.btn-icon-anim i,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-'] i,
button.btn-icon-anim.fc-agendaDay-button.fc-state-default.fc-corner-right i,
button.btn-icon-anim.fc-month-button.fc-state-default.fc-corner-left i,
button.btn-icon-anim.fc-agendaWeek-button i,
.btn-icon-anim.fc-prev-button i,
.btn-icon-anim.fc-next-button i,
.btn-icon-anim.fc-today-button i {
  font-size: 16px;
}
.btn.btn-icon-anim:before,
.wizard > .actions a.btn-icon-anim:before,
.dt-buttons .btn-icon-anim.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim:before,
.sweet-alert button.btn-icon-anim:before,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-']:before,
button.btn-icon-anim.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.fc-agendaWeek-button:before,
.btn-icon-anim.fc-prev-button:before,
.btn-icon-anim.fc-next-button:before,
.btn-icon-anim.fc-today-button:before {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: color 0.3s ease 0s, box-shadow 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  -moz-transition: color 0.3s ease 0s, box-shadow 0.3s ease 0s, -moz-transform 0.3s ease 0s;
  transition: color 0.3s ease 0s, box-shadow 0.3s ease 0s, transform 0.3s ease 0s;
  width: 100%;
  z-index: -1;
}
.btn.btn-icon-anim.btn-default:before,
.wizard > .actions a.btn-icon-anim.btn-default:before,
.dt-buttons .btn-icon-anim.btn-default.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim:before,
.sweet-alert button.btn-icon-anim.btn-default:before,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-']:before,
button.btn-icon-anim.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-default.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-default.fc-agendaWeek-button:before,
.btn-icon-anim.btn-default.fc-prev-button:before,
.btn-icon-anim.btn-default.fc-next-button:before,
.btn-icon-anim.btn-default.fc-today-button:before {
  box-shadow: 0 0 0 35px gainsboro inset;
}
.btn.btn-icon-anim.btn-default:hover,
.wizard > .actions a.btn-icon-anim.btn-default:hover,
.dt-buttons .btn-icon-anim.btn-default.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim:hover,
.sweet-alert button.btn-icon-anim.btn-default:hover,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-']:hover,
button.btn-icon-anim.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-default.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-default.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-default.fc-prev-button:hover,
.btn-icon-anim.btn-default.fc-next-button:hover,
.btn-icon-anim.btn-default.fc-today-button:hover {
  color: #212121 !important;
}
.btn.btn-icon-anim.btn-default:hover:before,
.wizard > .actions a.btn-icon-anim.btn-default:hover:before,
.dt-buttons .btn-icon-anim.btn-default.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim:hover:before,
.sweet-alert button.btn-icon-anim.btn-default:hover:before,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-']:hover:before,
button.btn-icon-anim.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-default.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-default.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-default.fc-prev-button:hover:before,
.btn-icon-anim.btn-default.fc-next-button:hover:before,
.btn-icon-anim.btn-default.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px gainsboro inset;
}
.btn.btn-icon-anim.btn-info:before,
.wizard > .actions a.btn-icon-anim.btn-info:before,
.dt-buttons .btn-icon-anim.btn-info.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-info:before,
.sweet-alert button.btn-icon-anim.btn-info:before,
.owl-theme .owl-nav .btn-icon-anim.btn-info[class*='owl-']:before,
button.btn-icon-anim.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-info.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-info.fc-agendaWeek-button:before,
.btn-icon-anim.btn-info.fc-prev-button:before,
.btn-icon-anim.btn-info.fc-next-button:before,
.btn-icon-anim.btn-info.fc-today-button:before {
  box-shadow: 0 0 0 35px #e7368d inset;
}
.btn.btn-icon-anim.btn-info:hover,
.wizard > .actions a.btn-icon-anim.btn-info:hover,
.dt-buttons .btn-icon-anim.btn-info.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-info:hover,
.sweet-alert button.btn-icon-anim.btn-info:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-info[class*='owl-']:hover,
button.btn-icon-anim.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-info.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-info.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-info.fc-prev-button:hover,
.btn-icon-anim.btn-info.fc-next-button:hover,
.btn-icon-anim.btn-info.fc-today-button:hover {
  color: #e7368d !important;
}
.btn.btn-icon-anim.btn-info:hover:before,
.wizard > .actions a.btn-icon-anim.btn-info:hover:before,
.dt-buttons .btn-icon-anim.btn-info.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-info:hover:before,
.sweet-alert button.btn-icon-anim.btn-info:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-info[class*='owl-']:hover:before,
button.btn-icon-anim.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-info.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-info.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-info.fc-prev-button:hover:before,
.btn-icon-anim.btn-info.fc-next-button:hover:before,
.btn-icon-anim.btn-info.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #e7368d inset;
}
.btn.btn-icon-anim.btn-primary:before,
.wizard > .actions a.btn-icon-anim:before,
.dt-buttons .btn-icon-anim.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-primary:before,
.sweet-alert button.btn-icon-anim.btn-primary:before,
.owl-theme .owl-nav .btn-icon-anim.btn-primary[class*='owl-']:before,
button.btn-icon-anim.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-primary.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-primary.fc-agendaWeek-button:before,
.btn-icon-anim.btn-primary.fc-prev-button:before,
.btn-icon-anim.btn-primary.fc-next-button:before,
.btn-icon-anim.btn-primary.fc-today-button:before {
  box-shadow: 0 0 0 35px #2196f3 inset;
}
.btn.btn-icon-anim.btn-primary:hover,
.wizard > .actions a.btn-icon-anim:hover,
.dt-buttons .btn-icon-anim.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-primary:hover,
.sweet-alert button.btn-icon-anim.btn-primary:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-primary[class*='owl-']:hover,
button.btn-icon-anim.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-primary.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-primary.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-primary.fc-prev-button:hover,
.btn-icon-anim.btn-primary.fc-next-button:hover,
.btn-icon-anim.btn-primary.fc-today-button:hover {
  color: #2196f3 !important;
}
.btn.btn-icon-anim.btn-primary:hover:before,
.wizard > .actions a.btn-icon-anim:hover:before,
.dt-buttons .btn-icon-anim.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-primary:hover:before,
.sweet-alert button.btn-icon-anim.btn-primary:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-primary[class*='owl-']:hover:before,
button.btn-icon-anim.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-primary.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-primary.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-primary.fc-prev-button:hover:before,
.btn-icon-anim.btn-primary.fc-next-button:hover:before,
.btn-icon-anim.btn-primary.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #2196f3 inset;
}
.btn.btn-icon-anim.btn-success:before,
.wizard > .actions a.btn-icon-anim.btn-success:before,
.wizard > .actions a.btn-icon-anim.fc-prev-button:hover:before,
.wizard > .actions a.btn-icon-anim.fc-next-button:hover:before,
.wizard > .actions a.btn-icon-anim.fc-today-button:hover:before,
.wizard > .actions a.btn-icon-anim.fc-prev-button:active:before,
.wizard > .actions a.btn-icon-anim.fc-next-button:active:before,
.wizard > .actions a.btn-icon-anim.fc-today-button:active:before,
.dt-buttons .btn-icon-anim.btn-success.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-success:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.fc-prev-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.fc-next-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.fc-today-button:before,
.sweet-alert button.btn-icon-anim.btn-success:before,
.owl-theme .owl-nav .btn-icon-anim.btn-success[class*='owl-']:before,
.owl-theme .owl-nav button.btn-icon-anim[class*='owl-'].fc-agendaWeek-button:before,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-'].fc-prev-button:before,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-'].fc-next-button:before,
.owl-theme .owl-nav .btn-icon-anim[class*='owl-'].fc-today-button:before,
button.btn-icon-anim.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.fc-agendaWeek-button:before,
.btn-icon-anim.fc-prev-button:before,
.btn-icon-anim.fc-next-button:before,
.btn-icon-anim.fc-today-button:before {
  box-shadow: 0 0 0 35px #8bc34a inset;
}
.btn.btn-icon-anim.btn-success:hover,
.wizard > .actions a.btn-icon-anim.btn-success:hover,
.wizard > .actions a.btn-icon-anim.fc-prev-button:hover:active,
.wizard > .actions a.btn-icon-anim.fc-next-button:hover:active,
.wizard > .actions a.btn-icon-anim.fc-today-button:hover:active,
.dt-buttons .btn-icon-anim.btn-success.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-success:hover,
.sweet-alert button.btn-icon-anim.btn-success:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-success[class*='owl-']:hover,
button.btn-icon-anim.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.fc-agendaWeek-button:hover,
.btn-icon-anim.fc-prev-button:hover,
.btn-icon-anim.fc-next-button:hover,
.btn-icon-anim.fc-today-button:hover {
  color: #8bc34a !important;
}
.btn.btn-icon-anim.btn-success:hover:before,
.wizard > .actions a.btn-icon-anim.btn-success:hover:before,
.dt-buttons .btn-icon-anim.btn-success.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-success:hover:before,
.sweet-alert button.btn-icon-anim.btn-success:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-success[class*='owl-']:hover:before,
button.btn-icon-anim.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.fc-agendaWeek-button:hover:before,
.btn-icon-anim.fc-prev-button:hover:before,
.btn-icon-anim.fc-next-button:hover:before,
.btn-icon-anim.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #8bc34a inset;
}
.btn.btn-icon-anim.btn-danger:before,
.wizard > .actions a.btn-icon-anim.btn-danger:before,
.dt-buttons .btn-icon-anim.btn-danger.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-danger:before,
.sweet-alert button.btn-icon-anim.btn-danger:before,
.owl-theme .owl-nav .btn-icon-anim.btn-danger[class*='owl-']:before,
button.btn-icon-anim.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-danger.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-danger.fc-agendaWeek-button:before,
.btn-icon-anim.btn-danger.fc-prev-button:before,
.btn-icon-anim.btn-danger.fc-next-button:before,
.btn-icon-anim.btn-danger.fc-today-button:before {
  box-shadow: 0 0 0 35px #f33923 inset;
}
.btn.btn-icon-anim.btn-danger:hover,
.wizard > .actions a.btn-icon-anim.btn-danger:hover,
.dt-buttons .btn-icon-anim.btn-danger.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-danger:hover,
.sweet-alert button.btn-icon-anim.btn-danger:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-danger[class*='owl-']:hover,
button.btn-icon-anim.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-danger.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-danger.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-danger.fc-prev-button:hover,
.btn-icon-anim.btn-danger.fc-next-button:hover,
.btn-icon-anim.btn-danger.fc-today-button:hover {
  color: #f33923 !important;
}
.btn.btn-icon-anim.btn-danger:hover:before,
.wizard > .actions a.btn-icon-anim.btn-danger:hover:before,
.dt-buttons .btn-icon-anim.btn-danger.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-danger:hover:before,
.sweet-alert button.btn-icon-anim.btn-danger:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-danger[class*='owl-']:hover:before,
button.btn-icon-anim.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-danger.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-danger.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-danger.fc-prev-button:hover:before,
.btn-icon-anim.btn-danger.fc-next-button:hover:before,
.btn-icon-anim.btn-danger.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #f33923 inset;
}
.btn.btn-icon-anim.btn-warning:before,
.wizard > .actions a.btn-icon-anim.btn-warning:before,
.dt-buttons .btn-icon-anim.btn-warning.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-warning:before,
.sweet-alert button.btn-icon-anim.btn-warning:before,
.owl-theme .owl-nav .btn-icon-anim.btn-warning[class*='owl-']:before,
button.btn-icon-anim.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-warning.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-warning.fc-agendaWeek-button:before,
.btn-icon-anim.btn-warning.fc-prev-button:before,
.btn-icon-anim.btn-warning.fc-next-button:before,
.btn-icon-anim.btn-warning.fc-today-button:before {
  box-shadow: 0 0 0 35px #f8b32d inset;
}
.btn.btn-icon-anim.btn-warning:hover,
.wizard > .actions a.btn-icon-anim.btn-warning:hover,
.dt-buttons .btn-icon-anim.btn-warning.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-warning:hover,
.sweet-alert button.btn-icon-anim.btn-warning:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-warning[class*='owl-']:hover,
button.btn-icon-anim.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-warning.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-warning.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-warning.fc-prev-button:hover,
.btn-icon-anim.btn-warning.fc-next-button:hover,
.btn-icon-anim.btn-warning.fc-today-button:hover {
  color: #f8b32d !important;
}
.btn.btn-icon-anim.btn-warning:hover:before,
.wizard > .actions a.btn-icon-anim.btn-warning:hover:before,
.dt-buttons .btn-icon-anim.btn-warning.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-warning:hover:before,
.sweet-alert button.btn-icon-anim.btn-warning:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-warning[class*='owl-']:hover:before,
button.btn-icon-anim.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-warning.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-warning.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-warning.fc-prev-button:hover:before,
.btn-icon-anim.btn-warning.fc-next-button:hover:before,
.btn-icon-anim.btn-warning.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #f8b32d inset;
}
.btn.btn-icon-anim.btn-facebook:before,
.wizard > .actions a.btn-icon-anim.btn-facebook:before,
.dt-buttons .btn-icon-anim.btn-facebook.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-facebook:before,
.sweet-alert button.btn-icon-anim.btn-facebook:before,
.owl-theme .owl-nav .btn-icon-anim.btn-facebook[class*='owl-']:before,
button.btn-icon-anim.btn-facebook.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-facebook.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-facebook.fc-agendaWeek-button:before,
.btn-icon-anim.btn-facebook.fc-prev-button:before,
.btn-icon-anim.btn-facebook.fc-next-button:before,
.btn-icon-anim.btn-facebook.fc-today-button:before {
  box-shadow: 0 0 0 35px #3b5998 inset;
}
.btn.btn-icon-anim.btn-facebook:hover,
.wizard > .actions a.btn-icon-anim.btn-facebook:hover,
.dt-buttons .btn-icon-anim.btn-facebook.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-facebook:hover,
.sweet-alert button.btn-icon-anim.btn-facebook:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-facebook[class*='owl-']:hover,
button.btn-icon-anim.btn-facebook.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-facebook.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-facebook.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-facebook.fc-prev-button:hover,
.btn-icon-anim.btn-facebook.fc-next-button:hover,
.btn-icon-anim.btn-facebook.fc-today-button:hover {
  color: #3b5998 !important;
}
.btn.btn-icon-anim.btn-facebook:hover:before,
.wizard > .actions a.btn-icon-anim.btn-facebook:hover:before,
.dt-buttons .btn-icon-anim.btn-facebook.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-facebook:hover:before,
.sweet-alert button.btn-icon-anim.btn-facebook:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-facebook[class*='owl-']:hover:before,
button.btn-icon-anim.btn-facebook.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-facebook.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-facebook.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-facebook.fc-prev-button:hover:before,
.btn-icon-anim.btn-facebook.fc-next-button:hover:before,
.btn-icon-anim.btn-facebook.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #3b5998 inset;
}
.btn.btn-icon-anim.btn-twitter:before,
.wizard > .actions a.btn-icon-anim.btn-twitter:before,
.dt-buttons .btn-icon-anim.btn-twitter.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-twitter:before,
.sweet-alert button.btn-icon-anim.btn-twitter:before,
.owl-theme .owl-nav .btn-icon-anim.btn-twitter[class*='owl-']:before,
button.btn-icon-anim.btn-twitter.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-twitter.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-twitter.fc-agendaWeek-button:before,
.btn-icon-anim.btn-twitter.fc-prev-button:before,
.btn-icon-anim.btn-twitter.fc-next-button:before,
.btn-icon-anim.btn-twitter.fc-today-button:before {
  box-shadow: 0 0 0 35px #55acee inset;
}
.btn.btn-icon-anim.btn-twitter:hover,
.wizard > .actions a.btn-icon-anim.btn-twitter:hover,
.dt-buttons .btn-icon-anim.btn-twitter.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-twitter:hover,
.sweet-alert button.btn-icon-anim.btn-twitter:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-twitter[class*='owl-']:hover,
button.btn-icon-anim.btn-twitter.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-twitter.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-twitter.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-twitter.fc-prev-button:hover,
.btn-icon-anim.btn-twitter.fc-next-button:hover,
.btn-icon-anim.btn-twitter.fc-today-button:hover {
  color: #55acee !important;
}
.btn.btn-icon-anim.btn-twitter:hover:before,
.wizard > .actions a.btn-icon-anim.btn-twitter:hover:before,
.dt-buttons .btn-icon-anim.btn-twitter.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-twitter:hover:before,
.sweet-alert button.btn-icon-anim.btn-twitter:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-twitter[class*='owl-']:hover:before,
button.btn-icon-anim.btn-twitter.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-twitter.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-twitter.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-twitter.fc-prev-button:hover:before,
.btn-icon-anim.btn-twitter.fc-next-button:hover:before,
.btn-icon-anim.btn-twitter.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #55acee inset;
}
.btn.btn-icon-anim.btn-linkedin:before,
.wizard > .actions a.btn-icon-anim.btn-linkedin:before,
.dt-buttons .btn-icon-anim.btn-linkedin.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-linkedin:before,
.sweet-alert button.btn-icon-anim.btn-linkedin:before,
.owl-theme .owl-nav .btn-icon-anim.btn-linkedin[class*='owl-']:before,
button.btn-icon-anim.btn-linkedin.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-linkedin.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-linkedin.fc-agendaWeek-button:before,
.btn-icon-anim.btn-linkedin.fc-prev-button:before,
.btn-icon-anim.btn-linkedin.fc-next-button:before,
.btn-icon-anim.btn-linkedin.fc-today-button:before {
  box-shadow: 0 0 0 35px #007bb6 inset;
}
.btn.btn-icon-anim.btn-linkedin:hover,
.wizard > .actions a.btn-icon-anim.btn-linkedin:hover,
.dt-buttons .btn-icon-anim.btn-linkedin.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-linkedin:hover,
.sweet-alert button.btn-icon-anim.btn-linkedin:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-linkedin[class*='owl-']:hover,
button.btn-icon-anim.btn-linkedin.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-linkedin.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-linkedin.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-linkedin.fc-prev-button:hover,
.btn-icon-anim.btn-linkedin.fc-next-button:hover,
.btn-icon-anim.btn-linkedin.fc-today-button:hover {
  color: #007bb6 !important;
}
.btn.btn-icon-anim.btn-linkedin:hover:before,
.wizard > .actions a.btn-icon-anim.btn-linkedin:hover:before,
.dt-buttons .btn-icon-anim.btn-linkedin.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-linkedin:hover:before,
.sweet-alert button.btn-icon-anim.btn-linkedin:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-linkedin[class*='owl-']:hover:before,
button.btn-icon-anim.btn-linkedin.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-linkedin.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-linkedin.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-linkedin.fc-prev-button:hover:before,
.btn-icon-anim.btn-linkedin.fc-next-button:hover:before,
.btn-icon-anim.btn-linkedin.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #007bb6 inset;
}
.btn.btn-icon-anim.btn-dribbble:before,
.wizard > .actions a.btn-icon-anim.btn-dribbble:before,
.dt-buttons .btn-icon-anim.btn-dribbble.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-dribbble:before,
.sweet-alert button.btn-icon-anim.btn-dribbble:before,
.owl-theme .owl-nav .btn-icon-anim.btn-dribbble[class*='owl-']:before,
button.btn-icon-anim.btn-dribbble.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-dribbble.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-dribbble.fc-agendaWeek-button:before,
.btn-icon-anim.btn-dribbble.fc-prev-button:before,
.btn-icon-anim.btn-dribbble.fc-next-button:before,
.btn-icon-anim.btn-dribbble.fc-today-button:before {
  box-shadow: 0 0 0 35px #ea4c89 inset;
}
.btn.btn-icon-anim.btn-dribbble:hover,
.wizard > .actions a.btn-icon-anim.btn-dribbble:hover,
.dt-buttons .btn-icon-anim.btn-dribbble.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-dribbble:hover,
.sweet-alert button.btn-icon-anim.btn-dribbble:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-dribbble[class*='owl-']:hover,
button.btn-icon-anim.btn-dribbble.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-dribbble.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-dribbble.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-dribbble.fc-prev-button:hover,
.btn-icon-anim.btn-dribbble.fc-next-button:hover,
.btn-icon-anim.btn-dribbble.fc-today-button:hover {
  color: #ea4c89 !important;
}
.btn.btn-icon-anim.btn-dribbble:hover:before,
.wizard > .actions a.btn-icon-anim.btn-dribbble:hover:before,
.dt-buttons .btn-icon-anim.btn-dribbble.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-dribbble:hover:before,
.sweet-alert button.btn-icon-anim.btn-dribbble:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-dribbble[class*='owl-']:hover:before,
button.btn-icon-anim.btn-dribbble.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-dribbble.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-dribbble.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-dribbble.fc-prev-button:hover:before,
.btn-icon-anim.btn-dribbble.fc-next-button:hover:before,
.btn-icon-anim.btn-dribbble.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #ea4c89 inset;
}
.btn.btn-icon-anim.btn-googleplus:before,
.wizard > .actions a.btn-icon-anim.btn-googleplus:before,
.dt-buttons .btn-icon-anim.btn-googleplus.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-googleplus:before,
.sweet-alert button.btn-icon-anim.btn-googleplus:before,
.owl-theme .owl-nav .btn-icon-anim.btn-googleplus[class*='owl-']:before,
button.btn-icon-anim.btn-googleplus.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-googleplus.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-googleplus.fc-agendaWeek-button:before,
.btn-icon-anim.btn-googleplus.fc-prev-button:before,
.btn-icon-anim.btn-googleplus.fc-next-button:before,
.btn-icon-anim.btn-googleplus.fc-today-button:before {
  box-shadow: 0 0 0 35px #dd4b39 inset;
}
.btn.btn-icon-anim.btn-googleplus:hover,
.wizard > .actions a.btn-icon-anim.btn-googleplus:hover,
.dt-buttons .btn-icon-anim.btn-googleplus.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-googleplus:hover,
.sweet-alert button.btn-icon-anim.btn-googleplus:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-googleplus[class*='owl-']:hover,
button.btn-icon-anim.btn-googleplus.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-googleplus.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-googleplus.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-googleplus.fc-prev-button:hover,
.btn-icon-anim.btn-googleplus.fc-next-button:hover,
.btn-icon-anim.btn-googleplus.fc-today-button:hover {
  color: #dd4b39 !important;
}
.btn.btn-icon-anim.btn-googleplus:hover:before,
.wizard > .actions a.btn-icon-anim.btn-googleplus:hover:before,
.dt-buttons .btn-icon-anim.btn-googleplus.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-googleplus:hover:before,
.sweet-alert button.btn-icon-anim.btn-googleplus:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-googleplus[class*='owl-']:hover:before,
button.btn-icon-anim.btn-googleplus.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-googleplus.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-googleplus.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-googleplus.fc-prev-button:hover:before,
.btn-icon-anim.btn-googleplus.fc-next-button:hover:before,
.btn-icon-anim.btn-googleplus.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #dd4b39 inset;
}
.btn.btn-icon-anim.btn-instagram:before,
.wizard > .actions a.btn-icon-anim.btn-instagram:before,
.dt-buttons .btn-icon-anim.btn-instagram.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-instagram:before,
.sweet-alert button.btn-icon-anim.btn-instagram:before,
.owl-theme .owl-nav .btn-icon-anim.btn-instagram[class*='owl-']:before,
button.btn-icon-anim.btn-instagram.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-instagram.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-instagram.fc-agendaWeek-button:before,
.btn-icon-anim.btn-instagram.fc-prev-button:before,
.btn-icon-anim.btn-instagram.fc-next-button:before,
.btn-icon-anim.btn-instagram.fc-today-button:before {
  box-shadow: 0 0 0 35px #3f729b inset;
}
.btn.btn-icon-anim.btn-instagram:hover,
.wizard > .actions a.btn-icon-anim.btn-instagram:hover,
.dt-buttons .btn-icon-anim.btn-instagram.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-instagram:hover,
.sweet-alert button.btn-icon-anim.btn-instagram:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-instagram[class*='owl-']:hover,
button.btn-icon-anim.btn-instagram.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-instagram.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-instagram.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-instagram.fc-prev-button:hover,
.btn-icon-anim.btn-instagram.fc-next-button:hover,
.btn-icon-anim.btn-instagram.fc-today-button:hover {
  color: #3f729b !important;
}
.btn.btn-icon-anim.btn-instagram:hover:before,
.wizard > .actions a.btn-icon-anim.btn-instagram:hover:before,
.dt-buttons .btn-icon-anim.btn-instagram.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-instagram:hover:before,
.sweet-alert button.btn-icon-anim.btn-instagram:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-instagram[class*='owl-']:hover:before,
button.btn-icon-anim.btn-instagram.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-instagram.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-instagram.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-instagram.fc-prev-button:hover:before,
.btn-icon-anim.btn-instagram.fc-next-button:hover:before,
.btn-icon-anim.btn-instagram.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #3f729b inset;
}
.btn.btn-icon-anim.btn-pinterest:before,
.wizard > .actions a.btn-icon-anim.btn-pinterest:before,
.dt-buttons .btn-icon-anim.btn-pinterest.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-pinterest:before,
.sweet-alert button.btn-icon-anim.btn-pinterest:before,
.owl-theme .owl-nav .btn-icon-anim.btn-pinterest[class*='owl-']:before,
button.btn-icon-anim.btn-pinterest.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-pinterest.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-pinterest.fc-agendaWeek-button:before,
.btn-icon-anim.btn-pinterest.fc-prev-button:before,
.btn-icon-anim.btn-pinterest.fc-next-button:before,
.btn-icon-anim.btn-pinterest.fc-today-button:before {
  box-shadow: 0 0 0 35px #cb2027 inset;
}
.btn.btn-icon-anim.btn-pinterest:hover,
.wizard > .actions a.btn-icon-anim.btn-pinterest:hover,
.dt-buttons .btn-icon-anim.btn-pinterest.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-pinterest:hover,
.sweet-alert button.btn-icon-anim.btn-pinterest:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-pinterest[class*='owl-']:hover,
button.btn-icon-anim.btn-pinterest.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-pinterest.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-pinterest.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-pinterest.fc-prev-button:hover,
.btn-icon-anim.btn-pinterest.fc-next-button:hover,
.btn-icon-anim.btn-pinterest.fc-today-button:hover {
  color: #cb2027 !important;
}
.btn.btn-icon-anim.btn-pinterest:hover:before,
.wizard > .actions a.btn-icon-anim.btn-pinterest:hover:before,
.dt-buttons .btn-icon-anim.btn-pinterest.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-pinterest:hover:before,
.sweet-alert button.btn-icon-anim.btn-pinterest:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-pinterest[class*='owl-']:hover:before,
button.btn-icon-anim.btn-pinterest.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-pinterest.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-pinterest.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-pinterest.fc-prev-button:hover:before,
.btn-icon-anim.btn-pinterest.fc-next-button:hover:before,
.btn-icon-anim.btn-pinterest.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #cb2027 inset;
}
.btn.btn-icon-anim.btn-dropbox:before,
.wizard > .actions a.btn-icon-anim.btn-dropbox:before,
.dt-buttons .btn-icon-anim.btn-dropbox.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-dropbox:before,
.sweet-alert button.btn-icon-anim.btn-dropbox:before,
.owl-theme .owl-nav .btn-icon-anim.btn-dropbox[class*='owl-']:before,
button.btn-icon-anim.btn-dropbox.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-dropbox.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-dropbox.fc-agendaWeek-button:before,
.btn-icon-anim.btn-dropbox.fc-prev-button:before,
.btn-icon-anim.btn-dropbox.fc-next-button:before,
.btn-icon-anim.btn-dropbox.fc-today-button:before {
  box-shadow: 0 0 0 35px #007ee5 inset;
}
.btn.btn-icon-anim.btn-dropbox:hover,
.wizard > .actions a.btn-icon-anim.btn-dropbox:hover,
.dt-buttons .btn-icon-anim.btn-dropbox.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-dropbox:hover,
.sweet-alert button.btn-icon-anim.btn-dropbox:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-dropbox[class*='owl-']:hover,
button.btn-icon-anim.btn-dropbox.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-dropbox.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-dropbox.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-dropbox.fc-prev-button:hover,
.btn-icon-anim.btn-dropbox.fc-next-button:hover,
.btn-icon-anim.btn-dropbox.fc-today-button:hover {
  color: #007ee5 !important;
}
.btn.btn-icon-anim.btn-dropbox:hover:before,
.wizard > .actions a.btn-icon-anim.btn-dropbox:hover:before,
.dt-buttons .btn-icon-anim.btn-dropbox.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-dropbox:hover:before,
.sweet-alert button.btn-icon-anim.btn-dropbox:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-dropbox[class*='owl-']:hover:before,
button.btn-icon-anim.btn-dropbox.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-dropbox.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-dropbox.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-dropbox.fc-prev-button:hover:before,
.btn-icon-anim.btn-dropbox.fc-next-button:hover:before,
.btn-icon-anim.btn-dropbox.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #007ee5 inset;
}
.btn.btn-icon-anim.btn-flickr:before,
.wizard > .actions a.btn-icon-anim.btn-flickr:before,
.dt-buttons .btn-icon-anim.btn-flickr.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-flickr:before,
.sweet-alert button.btn-icon-anim.btn-flickr:before,
.owl-theme .owl-nav .btn-icon-anim.btn-flickr[class*='owl-']:before,
button.btn-icon-anim.btn-flickr.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-flickr.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-flickr.fc-agendaWeek-button:before,
.btn-icon-anim.btn-flickr.fc-prev-button:before,
.btn-icon-anim.btn-flickr.fc-next-button:before,
.btn-icon-anim.btn-flickr.fc-today-button:before {
  box-shadow: 0 0 0 35px #ff0084 inset;
}
.btn.btn-icon-anim.btn-flickr:hover,
.wizard > .actions a.btn-icon-anim.btn-flickr:hover,
.dt-buttons .btn-icon-anim.btn-flickr.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-flickr:hover,
.sweet-alert button.btn-icon-anim.btn-flickr:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-flickr[class*='owl-']:hover,
button.btn-icon-anim.btn-flickr.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-flickr.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-flickr.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-flickr.fc-prev-button:hover,
.btn-icon-anim.btn-flickr.fc-next-button:hover,
.btn-icon-anim.btn-flickr.fc-today-button:hover {
  color: #ff0084 !important;
}
.btn.btn-icon-anim.btn-flickr:hover:before,
.wizard > .actions a.btn-icon-anim.btn-flickr:hover:before,
.dt-buttons .btn-icon-anim.btn-flickr.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-flickr:hover:before,
.sweet-alert button.btn-icon-anim.btn-flickr:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-flickr[class*='owl-']:hover:before,
button.btn-icon-anim.btn-flickr.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-flickr.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-flickr.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-flickr.fc-prev-button:hover:before,
.btn-icon-anim.btn-flickr.fc-next-button:hover:before,
.btn-icon-anim.btn-flickr.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #ff0084 inset;
}
.btn.btn-icon-anim.btn-tumblr:before,
.wizard > .actions a.btn-icon-anim.btn-tumblr:before,
.dt-buttons .btn-icon-anim.btn-tumblr.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-tumblr:before,
.sweet-alert button.btn-icon-anim.btn-tumblr:before,
.owl-theme .owl-nav .btn-icon-anim.btn-tumblr[class*='owl-']:before,
button.btn-icon-anim.btn-tumblr.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-tumblr.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-tumblr.fc-agendaWeek-button:before,
.btn-icon-anim.btn-tumblr.fc-prev-button:before,
.btn-icon-anim.btn-tumblr.fc-next-button:before,
.btn-icon-anim.btn-tumblr.fc-today-button:before {
  box-shadow: 0 0 0 35px #32506d inset;
}
.btn.btn-icon-anim.btn-tumblr:hover,
.wizard > .actions a.btn-icon-anim.btn-tumblr:hover,
.dt-buttons .btn-icon-anim.btn-tumblr.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-tumblr:hover,
.sweet-alert button.btn-icon-anim.btn-tumblr:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-tumblr[class*='owl-']:hover,
button.btn-icon-anim.btn-tumblr.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-tumblr.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-tumblr.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-tumblr.fc-prev-button:hover,
.btn-icon-anim.btn-tumblr.fc-next-button:hover,
.btn-icon-anim.btn-tumblr.fc-today-button:hover {
  color: #32506d !important;
}
.btn.btn-icon-anim.btn-tumblr:hover:before,
.wizard > .actions a.btn-icon-anim.btn-tumblr:hover:before,
.dt-buttons .btn-icon-anim.btn-tumblr.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-tumblr:hover:before,
.sweet-alert button.btn-icon-anim.btn-tumblr:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-tumblr[class*='owl-']:hover:before,
button.btn-icon-anim.btn-tumblr.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-tumblr.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-tumblr.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-tumblr.fc-prev-button:hover:before,
.btn-icon-anim.btn-tumblr.fc-next-button:hover:before,
.btn-icon-anim.btn-tumblr.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #32506d inset;
}
.btn.btn-icon-anim.btn-skype:before,
.wizard > .actions a.btn-icon-anim.btn-skype:before,
.dt-buttons .btn-icon-anim.btn-skype.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-skype:before,
.sweet-alert button.btn-icon-anim.btn-skype:before,
.owl-theme .owl-nav .btn-icon-anim.btn-skype[class*='owl-']:before,
button.btn-icon-anim.btn-skype.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-skype.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-skype.fc-agendaWeek-button:before,
.btn-icon-anim.btn-skype.fc-prev-button:before,
.btn-icon-anim.btn-skype.fc-next-button:before,
.btn-icon-anim.btn-skype.fc-today-button:before {
  box-shadow: 0 0 0 35px #00aff0 inset;
}
.btn.btn-icon-anim.btn-skype:hover,
.wizard > .actions a.btn-icon-anim.btn-skype:hover,
.dt-buttons .btn-icon-anim.btn-skype.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-skype:hover,
.sweet-alert button.btn-icon-anim.btn-skype:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-skype[class*='owl-']:hover,
button.btn-icon-anim.btn-skype.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-skype.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-skype.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-skype.fc-prev-button:hover,
.btn-icon-anim.btn-skype.fc-next-button:hover,
.btn-icon-anim.btn-skype.fc-today-button:hover {
  color: #00aff0 !important;
}
.btn.btn-icon-anim.btn-skype:hover:before,
.wizard > .actions a.btn-icon-anim.btn-skype:hover:before,
.dt-buttons .btn-icon-anim.btn-skype.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-skype:hover:before,
.sweet-alert button.btn-icon-anim.btn-skype:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-skype[class*='owl-']:hover:before,
button.btn-icon-anim.btn-skype.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-skype.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-skype.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-skype.fc-prev-button:hover:before,
.btn-icon-anim.btn-skype.fc-next-button:hover:before,
.btn-icon-anim.btn-skype.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #00aff0 inset;
}
.btn.btn-icon-anim.btn-youtube:before,
.wizard > .actions a.btn-icon-anim.btn-youtube:before,
.dt-buttons .btn-icon-anim.btn-youtube.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-youtube:before,
.sweet-alert button.btn-icon-anim.btn-youtube:before,
.owl-theme .owl-nav .btn-icon-anim.btn-youtube[class*='owl-']:before,
button.btn-icon-anim.btn-youtube.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-youtube.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-youtube.fc-agendaWeek-button:before,
.btn-icon-anim.btn-youtube.fc-prev-button:before,
.btn-icon-anim.btn-youtube.fc-next-button:before,
.btn-icon-anim.btn-youtube.fc-today-button:before {
  box-shadow: 0 0 0 35px #bb0000 inset;
}
.btn.btn-icon-anim.btn-youtube:hover,
.wizard > .actions a.btn-icon-anim.btn-youtube:hover,
.dt-buttons .btn-icon-anim.btn-youtube.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-youtube:hover,
.sweet-alert button.btn-icon-anim.btn-youtube:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-youtube[class*='owl-']:hover,
button.btn-icon-anim.btn-youtube.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-youtube.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-youtube.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-youtube.fc-prev-button:hover,
.btn-icon-anim.btn-youtube.fc-next-button:hover,
.btn-icon-anim.btn-youtube.fc-today-button:hover {
  color: #bb0000 !important;
}
.btn.btn-icon-anim.btn-youtube:hover:before,
.wizard > .actions a.btn-icon-anim.btn-youtube:hover:before,
.dt-buttons .btn-icon-anim.btn-youtube.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-youtube:hover:before,
.sweet-alert button.btn-icon-anim.btn-youtube:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-youtube[class*='owl-']:hover:before,
button.btn-icon-anim.btn-youtube.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-youtube.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-youtube.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-youtube.fc-prev-button:hover:before,
.btn-icon-anim.btn-youtube.fc-next-button:hover:before,
.btn-icon-anim.btn-youtube.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #bb0000 inset;
}
.btn.btn-icon-anim.btn-github:before,
.wizard > .actions a.btn-icon-anim.btn-github:before,
.dt-buttons .btn-icon-anim.btn-github.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-github:before,
.sweet-alert button.btn-icon-anim.btn-github:before,
.owl-theme .owl-nav .btn-icon-anim.btn-github[class*='owl-']:before,
button.btn-icon-anim.btn-github.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-icon-anim.btn-github.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-icon-anim.btn-github.fc-agendaWeek-button:before,
.btn-icon-anim.btn-github.fc-prev-button:before,
.btn-icon-anim.btn-github.fc-next-button:before,
.btn-icon-anim.btn-github.fc-today-button:before {
  box-shadow: 0 0 0 35px #171515 inset;
}
.btn.btn-icon-anim.btn-github:hover,
.wizard > .actions a.btn-icon-anim.btn-github:hover,
.dt-buttons .btn-icon-anim.btn-github.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-github:hover,
.sweet-alert button.btn-icon-anim.btn-github:hover,
.owl-theme .owl-nav .btn-icon-anim.btn-github[class*='owl-']:hover,
button.btn-icon-anim.btn-github.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.btn-icon-anim.btn-github.fc-month-button.fc-state-default.fc-corner-left:hover,
button.btn-icon-anim.btn-github.fc-agendaWeek-button:hover,
.btn-icon-anim.btn-github.fc-prev-button:hover,
.btn-icon-anim.btn-github.fc-next-button:hover,
.btn-icon-anim.btn-github.fc-today-button:hover {
  color: #171515 !important;
}
.btn.btn-icon-anim.btn-github:hover:before,
.wizard > .actions a.btn-icon-anim.btn-github:hover:before,
.dt-buttons .btn-icon-anim.btn-github.dt-button:hover:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-icon-anim.btn-github:hover:before,
.sweet-alert button.btn-icon-anim.btn-github:hover:before,
.owl-theme .owl-nav .btn-icon-anim.btn-github[class*='owl-']:hover:before,
button.btn-icon-anim.btn-github.fc-agendaDay-button.fc-state-default.fc-corner-right:hover:before,
button.btn-icon-anim.btn-github.fc-month-button.fc-state-default.fc-corner-left:hover:before,
button.btn-icon-anim.btn-github.fc-agendaWeek-button:hover:before,
.btn-icon-anim.btn-github.fc-prev-button:hover:before,
.btn-icon-anim.btn-github.fc-next-button:hover:before,
.btn-icon-anim.btn-github.fc-today-button:hover:before {
  box-shadow: 0 0 0 1px #171515 inset;
}
.btn.btn-square,
.wizard > .actions a.btn-square,
.dt-buttons .btn-square.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-square,
.sweet-alert button.btn-square,
.owl-theme .owl-nav .btn-square[class*='owl-'],
button.btn-square.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-square.fc-month-button.fc-state-default.fc-corner-left,
button.btn-square.fc-agendaWeek-button,
.btn-square.fc-prev-button,
.btn-square.fc-next-button,
.btn-square.fc-today-button {
  height: 42px;
  width: 42px;
  padding: 0;
}
.btn.btn-square.btn-lg,
.wizard > .actions a.btn-square.btn-lg,
.dt-buttons .btn-square.btn-lg.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-square.btn-lg,
.sweet-alert button.btn-square.btn-lg,
.owl-theme .owl-nav .btn-square.btn-lg[class*='owl-'],
button.btn-square.btn-lg.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-square.btn-lg.fc-month-button.fc-state-default.fc-corner-left,
button.btn-square.btn-lg.fc-agendaWeek-button,
.btn-square.btn-lg.fc-prev-button,
.btn-square.btn-lg.fc-next-button,
.btn-square.btn-lg.fc-today-button {
  height: 48px;
  width: 48px;
}
.btn.btn-square.btn-sm,
.wizard > .actions a.btn-square.btn-sm,
.dt-buttons .btn-square.btn-sm.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-square.btn-sm,
.sweet-alert button.btn-square.btn-sm,
.owl-theme .owl-nav .btn-square.btn-sm[class*='owl-'],
button.btn-square.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-square.btn-sm.fc-month-button.fc-state-default.fc-corner-left,
button.btn-square.btn-sm.fc-agendaWeek-button,
.btn-square.btn-sm.fc-prev-button,
.btn-square.btn-sm.fc-next-button,
.btn-square.btn-sm.fc-today-button {
  height: 35px;
  width: 35px;
  padding: 6px !important;
}
.btn.btn-circle,
.wizard > .actions a.btn-circle,
.dt-buttons .btn-circle.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-circle,
.sweet-alert button.btn-circle,
.owl-theme .owl-nav .btn-circle[class*='owl-'],
button.btn-circle.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-circle.fc-month-button.fc-state-default.fc-corner-left,
button.btn-circle.fc-agendaWeek-button,
.btn-circle.fc-prev-button,
.btn-circle.fc-next-button,
.btn-circle.fc-today-button {
  height: 40px;
  width: 40px;
  padding: 0;
  border-radius: 50%;
}
.btn.btn-circle:before,
.wizard > .actions a.btn-circle:before,
.dt-buttons .btn-circle.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-circle:before,
.sweet-alert button.btn-circle:before,
.owl-theme .owl-nav .btn-circle[class*='owl-']:before,
button.btn-circle.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.btn-circle.fc-month-button.fc-state-default.fc-corner-left:before,
button.btn-circle.fc-agendaWeek-button:before,
.btn-circle.fc-prev-button:before,
.btn-circle.fc-next-button:before,
.btn-circle.fc-today-button:before {
  border-radius: 50%;
}
.btn.btn-circle.btn-lg,
.wizard > .actions a.btn-circle.btn-lg,
.dt-buttons .btn-circle.btn-lg.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-circle.btn-lg,
.sweet-alert button.btn-circle.btn-lg,
.owl-theme .owl-nav .btn-circle.btn-lg[class*='owl-'],
button.btn-circle.btn-lg.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-circle.btn-lg.fc-month-button.fc-state-default.fc-corner-left,
button.btn-circle.btn-lg.fc-agendaWeek-button,
.btn-circle.btn-lg.fc-prev-button,
.btn-circle.btn-lg.fc-next-button,
.btn-circle.btn-lg.fc-today-button {
  height: 45px;
  width: 45px;
}
.btn.btn-circle.btn-sm,
.wizard > .actions a.btn-circle.btn-sm,
.dt-buttons .btn-circle.btn-sm.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-circle.btn-sm,
.sweet-alert button.btn-circle.btn-sm,
.owl-theme .owl-nav .btn-circle.btn-sm[class*='owl-'],
button.btn-circle.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-circle.btn-sm.fc-month-button.fc-state-default.fc-corner-left,
button.btn-circle.btn-sm.fc-agendaWeek-button,
.btn-circle.btn-sm.fc-prev-button,
.btn-circle.btn-sm.fc-next-button,
.btn-circle.btn-sm.fc-today-button {
  height: 35px;
  width: 35px;
  padding: 6px !important;
}
.btn.fancy-button.btn-default.btn-0:before,
.wizard > .actions a.fancy-button.btn-default.btn-0:before,
.dt-buttons .fancy-button.btn-default.btn-0.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-0:before,
.sweet-alert button.fancy-button.btn-default.btn-0:before,
.owl-theme .owl-nav .fancy-button.btn-0[class*='owl-']:before,
button.fancy-button.btn-default.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.fancy-button.btn-default.btn-0.fc-month-button.fc-state-default.fc-corner-left:before,
button.fancy-button.btn-default.btn-0.fc-agendaWeek-button:before,
.fancy-button.btn-default.btn-0.fc-prev-button:before,
.fancy-button.btn-default.btn-0.fc-next-button:before,
.fancy-button.btn-default.btn-0.fc-today-button:before {
  background: gainsboro;
}
.btn.fancy-button.btn-default.btn-0:hover,
.wizard > .actions a.fancy-button.btn-default.btn-0:hover,
.dt-buttons .fancy-button.btn-default.btn-0.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-0:hover,
.sweet-alert button.fancy-button.btn-default.btn-0:hover,
.owl-theme .owl-nav .fancy-button.btn-0[class*='owl-']:hover,
button.fancy-button.btn-default.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fancy-button.btn-default.btn-0.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fancy-button.btn-default.btn-0.fc-agendaWeek-button:hover,
.fancy-button.btn-default.btn-0.fc-prev-button:hover,
.fancy-button.btn-default.btn-0.fc-next-button:hover,
.fancy-button.btn-default.btn-0.fc-today-button:hover {
  color: #212121 !important;
}
.btn.fancy-button.btn-info.btn-0:before,
.wizard > .actions a.fancy-button.btn-info.btn-0:before,
.dt-buttons .fancy-button.btn-info.btn-0.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-info.btn-0:before,
.sweet-alert button.fancy-button.btn-info.btn-0:before,
.owl-theme .owl-nav .fancy-button.btn-info.btn-0[class*='owl-']:before,
button.fancy-button.btn-info.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.fancy-button.btn-info.btn-0.fc-month-button.fc-state-default.fc-corner-left:before,
button.fancy-button.btn-info.btn-0.fc-agendaWeek-button:before,
.fancy-button.btn-info.btn-0.fc-prev-button:before,
.fancy-button.btn-info.btn-0.fc-next-button:before,
.fancy-button.btn-info.btn-0.fc-today-button:before {
  background: #e7368d;
}
.btn.fancy-button.btn-info.btn-0:hover,
.wizard > .actions a.fancy-button.btn-info.btn-0:hover,
.dt-buttons .fancy-button.btn-info.btn-0.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-info.btn-0:hover,
.sweet-alert button.fancy-button.btn-info.btn-0:hover,
.owl-theme .owl-nav .fancy-button.btn-info.btn-0[class*='owl-']:hover,
button.fancy-button.btn-info.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fancy-button.btn-info.btn-0.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fancy-button.btn-info.btn-0.fc-agendaWeek-button:hover,
.fancy-button.btn-info.btn-0.fc-prev-button:hover,
.fancy-button.btn-info.btn-0.fc-next-button:hover,
.fancy-button.btn-info.btn-0.fc-today-button:hover {
  color: #fff !important;
}
.btn.fancy-button.btn-primary.btn-0:before,
.wizard > .actions a.fancy-button.btn-0:before,
.dt-buttons .fancy-button.btn-0.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-primary.btn-0:before,
.sweet-alert button.fancy-button.btn-primary.btn-0:before,
.owl-theme .owl-nav .fancy-button.btn-primary.btn-0[class*='owl-']:before,
button.fancy-button.btn-primary.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.fancy-button.btn-primary.btn-0.fc-month-button.fc-state-default.fc-corner-left:before,
button.fancy-button.btn-primary.btn-0.fc-agendaWeek-button:before,
.fancy-button.btn-primary.btn-0.fc-prev-button:before,
.fancy-button.btn-primary.btn-0.fc-next-button:before,
.fancy-button.btn-primary.btn-0.fc-today-button:before {
  background: #2196f3;
}
.btn.fancy-button.btn-primary.btn-0:hover,
.wizard > .actions a.fancy-button.btn-0:hover,
.dt-buttons .fancy-button.btn-0.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-primary.btn-0:hover,
.sweet-alert button.fancy-button.btn-primary.btn-0:hover,
.owl-theme .owl-nav .fancy-button.btn-primary.btn-0[class*='owl-']:hover,
button.fancy-button.btn-primary.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fancy-button.btn-primary.btn-0.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fancy-button.btn-primary.btn-0.fc-agendaWeek-button:hover,
.fancy-button.btn-primary.btn-0.fc-prev-button:hover,
.fancy-button.btn-primary.btn-0.fc-next-button:hover,
.fancy-button.btn-primary.btn-0.fc-today-button:hover {
  color: #fff !important;
}
.btn.fancy-button.btn-danger.btn-0:before,
.wizard > .actions a.fancy-button.btn-danger.btn-0:before,
.dt-buttons .fancy-button.btn-danger.btn-0.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-danger.btn-0:before,
.sweet-alert button.fancy-button.btn-danger.btn-0:before,
.owl-theme .owl-nav .fancy-button.btn-danger.btn-0[class*='owl-']:before,
button.fancy-button.btn-danger.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.fancy-button.btn-danger.btn-0.fc-month-button.fc-state-default.fc-corner-left:before,
button.fancy-button.btn-danger.btn-0.fc-agendaWeek-button:before,
.fancy-button.btn-danger.btn-0.fc-prev-button:before,
.fancy-button.btn-danger.btn-0.fc-next-button:before,
.fancy-button.btn-danger.btn-0.fc-today-button:before {
  background: #f33923;
}
.btn.fancy-button.btn-danger.btn-0:hover,
.wizard > .actions a.fancy-button.btn-danger.btn-0:hover,
.dt-buttons .fancy-button.btn-danger.btn-0.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-danger.btn-0:hover,
.sweet-alert button.fancy-button.btn-danger.btn-0:hover,
.owl-theme .owl-nav .fancy-button.btn-danger.btn-0[class*='owl-']:hover,
button.fancy-button.btn-danger.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fancy-button.btn-danger.btn-0.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fancy-button.btn-danger.btn-0.fc-agendaWeek-button:hover,
.fancy-button.btn-danger.btn-0.fc-prev-button:hover,
.fancy-button.btn-danger.btn-0.fc-next-button:hover,
.fancy-button.btn-danger.btn-0.fc-today-button:hover {
  color: #fff !important;
}
.btn.fancy-button.btn-success.btn-0:before,
.wizard > .actions a.fancy-button.btn-success.btn-0:before,
.dt-buttons .fancy-button.btn-success.btn-0.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-success.btn-0:before,
.sweet-alert button.fancy-button.btn-success.btn-0:before,
.owl-theme .owl-nav .fancy-button.btn-success.btn-0[class*='owl-']:before,
button.fancy-button.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.fancy-button.btn-0.fc-month-button.fc-state-default.fc-corner-left:before,
button.fancy-button.btn-0.fc-agendaWeek-button:before,
.fancy-button.btn-0.fc-prev-button:before,
.fancy-button.btn-0.fc-next-button:before,
.fancy-button.btn-0.fc-today-button:before {
  background: #8bc34a;
}
.btn.fancy-button.btn-success.btn-0:hover,
.wizard > .actions a.fancy-button.btn-success.btn-0:hover,
.dt-buttons .fancy-button.btn-success.btn-0.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-success.btn-0:hover,
.sweet-alert button.fancy-button.btn-success.btn-0:hover,
.owl-theme .owl-nav .fancy-button.btn-success.btn-0[class*='owl-']:hover,
button.fancy-button.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fancy-button.btn-0.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fancy-button.btn-0.fc-agendaWeek-button:hover,
.fancy-button.btn-0.fc-prev-button:hover,
.fancy-button.btn-0.fc-next-button:hover,
.fancy-button.btn-0.fc-today-button:hover {
  color: #fff !important;
}
.btn.fancy-button.btn-warning.btn-0:before,
.wizard > .actions a.fancy-button.btn-warning.btn-0:before,
.dt-buttons .fancy-button.btn-warning.btn-0.dt-button:before,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-warning.btn-0:before,
.sweet-alert button.fancy-button.btn-warning.btn-0:before,
.owl-theme .owl-nav .fancy-button.btn-warning.btn-0[class*='owl-']:before,
button.fancy-button.btn-warning.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:before,
button.fancy-button.btn-warning.btn-0.fc-month-button.fc-state-default.fc-corner-left:before,
button.fancy-button.btn-warning.btn-0.fc-agendaWeek-button:before,
.fancy-button.btn-warning.btn-0.fc-prev-button:before,
.fancy-button.btn-warning.btn-0.fc-next-button:before,
.fancy-button.btn-warning.btn-0.fc-today-button:before {
  background: #f8b32d;
}
.btn.fancy-button.btn-warning.btn-0:hover,
.wizard > .actions a.fancy-button.btn-warning.btn-0:hover,
.dt-buttons .fancy-button.btn-warning.btn-0.dt-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head button.fancy-button.btn-warning.btn-0:hover,
.sweet-alert button.fancy-button.btn-warning.btn-0:hover,
.owl-theme .owl-nav .fancy-button.btn-warning.btn-0[class*='owl-']:hover,
button.fancy-button.btn-warning.btn-0.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
button.fancy-button.btn-warning.btn-0.fc-month-button.fc-state-default.fc-corner-left:hover,
button.fancy-button.btn-warning.btn-0.fc-agendaWeek-button:hover,
.fancy-button.btn-warning.btn-0.fc-prev-button:hover,
.fancy-button.btn-warning.btn-0.fc-next-button:hover,
.fancy-button.btn-warning.btn-0.fc-today-button:hover {
  color: #fff !important;
}
.btn.fixed-btn,
.wizard > .actions a.fixed-btn,
.dt-buttons .fixed-btn.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.fixed-btn,
.sweet-alert button.fixed-btn,
.owl-theme .owl-nav .fixed-btn[class*='owl-'],
button.fixed-btn.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.fixed-btn.fc-month-button.fc-state-default.fc-corner-left,
button.fixed-btn.fc-agendaWeek-button,
.fixed-btn.fc-prev-button,
.fixed-btn.fc-next-button,
.fixed-btn.fc-today-button {
  width: 100px;
}

.open .dropdown-toggle.btn.btn-default,
.open .wizard > .actions a.dropdown-toggle.btn-default,
.wizard > .actions .open a.dropdown-toggle.btn-default,
.open .dt-buttons .dropdown-toggle.btn-default.dt-button,
.dt-buttons .open .dropdown-toggle.btn-default.dt-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle,
.open .sweet-alert button.dropdown-toggle.btn-default,
.sweet-alert .open button.dropdown-toggle.btn-default,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'],
.open button.dropdown-toggle.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-default.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-default.fc-agendaWeek-button,
.open .dropdown-toggle.btn-default.fc-prev-button,
.open .dropdown-toggle.btn-default.fc-next-button,
.open .dropdown-toggle.btn-default.fc-today-button {
  background: gainsboro;
  border: solid 1px gainsboro;
}
.open .dropdown-toggle.btn.btn-default:hover,
.open .wizard > .actions a.dropdown-toggle.btn-default:hover,
.wizard > .actions .open a.dropdown-toggle.btn-default:hover,
.open .dt-buttons .dropdown-toggle.btn-default.dt-button:hover,
.dt-buttons .open .dropdown-toggle.btn-default.dt-button:hover,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle:hover,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle:hover,
.open .sweet-alert button.dropdown-toggle.btn-default:hover,
.sweet-alert .open button.dropdown-toggle.btn-default:hover,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-']:hover,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-']:hover,
.open button.dropdown-toggle.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.open button.dropdown-toggle.btn-default.fc-month-button.fc-state-default.fc-corner-left:hover,
.open button.dropdown-toggle.btn-default.fc-agendaWeek-button:hover,
.open .dropdown-toggle.btn-default.fc-prev-button:hover,
.open .dropdown-toggle.btn-default.fc-next-button:hover,
.open .dropdown-toggle.btn-default.fc-today-button:hover,
.open .dropdown-toggle.btn.btn-default:active,
.open .wizard > .actions a.dropdown-toggle.btn-default:active,
.wizard > .actions .open a.dropdown-toggle.btn-default:active,
.open .dt-buttons .dropdown-toggle.btn-default.dt-button:active,
.dt-buttons .open .dropdown-toggle.btn-default.dt-button:active,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle:active,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle:active,
.open .sweet-alert button.dropdown-toggle.btn-default:active,
.sweet-alert .open button.dropdown-toggle.btn-default:active,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-']:active,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-']:active,
.open button.dropdown-toggle.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.open button.dropdown-toggle.btn-default.fc-month-button.fc-state-default.fc-corner-left:active,
.open button.dropdown-toggle.btn-default.fc-agendaWeek-button:active,
.open .dropdown-toggle.btn-default.fc-prev-button:active,
.open .dropdown-toggle.btn-default.fc-next-button:active,
.open .dropdown-toggle.btn-default.fc-today-button:active,
.open .dropdown-toggle.btn.btn-default:focus,
.open .wizard > .actions a.dropdown-toggle.btn-default:focus,
.wizard > .actions .open a.dropdown-toggle.btn-default:focus,
.open .dt-buttons .dropdown-toggle.btn-default.dt-button:focus,
.dt-buttons .open .dropdown-toggle.btn-default.dt-button:focus,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle:focus,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle:focus,
.open .sweet-alert button.dropdown-toggle.btn-default:focus,
.sweet-alert .open button.dropdown-toggle.btn-default:focus,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-']:focus,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-']:focus,
.open button.dropdown-toggle.btn-default.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.open button.dropdown-toggle.btn-default.fc-month-button.fc-state-default.fc-corner-left:focus,
.open button.dropdown-toggle.btn-default.fc-agendaWeek-button:focus,
.open .dropdown-toggle.btn-default.fc-prev-button:focus,
.open .dropdown-toggle.btn-default.fc-next-button:focus,
.open .dropdown-toggle.btn-default.fc-today-button:focus {
  background: rgba(220, 220, 220, 0.85);
  border: solid 1px rgba(220, 220, 220, 0.1);
}
.open .dropdown-toggle.btn.btn-info,
.open .wizard > .actions a.dropdown-toggle.btn-info,
.wizard > .actions .open a.dropdown-toggle.btn-info,
.open .dt-buttons .dropdown-toggle.btn-info.dt-button,
.dt-buttons .open .dropdown-toggle.btn-info.dt-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-info,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-info,
.open .sweet-alert button.dropdown-toggle.btn-info,
.sweet-alert .open button.dropdown-toggle.btn-info,
.open .owl-theme .owl-nav .dropdown-toggle.btn-info[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle.btn-info[class*='owl-'],
.open button.dropdown-toggle.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-info.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-info.fc-agendaWeek-button,
.open .dropdown-toggle.btn-info.fc-prev-button,
.open .dropdown-toggle.btn-info.fc-next-button,
.open .dropdown-toggle.btn-info.fc-today-button {
  background: #e7368d;
  border: solid 1px #e7368d;
}
.open .dropdown-toggle.btn.btn-info:hover,
.open .wizard > .actions a.dropdown-toggle.btn-info:hover,
.wizard > .actions .open a.dropdown-toggle.btn-info:hover,
.open .dt-buttons .dropdown-toggle.btn-info.dt-button:hover,
.dt-buttons .open .dropdown-toggle.btn-info.dt-button:hover,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-info:hover,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-info:hover,
.open .sweet-alert button.dropdown-toggle.btn-info:hover,
.sweet-alert .open button.dropdown-toggle.btn-info:hover,
.open .owl-theme .owl-nav .dropdown-toggle.btn-info[class*='owl-']:hover,
.owl-theme .owl-nav .open .dropdown-toggle.btn-info[class*='owl-']:hover,
.open button.dropdown-toggle.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.open button.dropdown-toggle.btn-info.fc-month-button.fc-state-default.fc-corner-left:hover,
.open button.dropdown-toggle.btn-info.fc-agendaWeek-button:hover,
.open .dropdown-toggle.btn-info.fc-prev-button:hover,
.open .dropdown-toggle.btn-info.fc-next-button:hover,
.open .dropdown-toggle.btn-info.fc-today-button:hover,
.open .dropdown-toggle.btn.btn-info:active,
.open .wizard > .actions a.dropdown-toggle.btn-info:active,
.wizard > .actions .open a.dropdown-toggle.btn-info:active,
.open .dt-buttons .dropdown-toggle.btn-info.dt-button:active,
.dt-buttons .open .dropdown-toggle.btn-info.dt-button:active,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-info:active,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-info:active,
.open .sweet-alert button.dropdown-toggle.btn-info:active,
.sweet-alert .open button.dropdown-toggle.btn-info:active,
.open .owl-theme .owl-nav .dropdown-toggle.btn-info[class*='owl-']:active,
.owl-theme .owl-nav .open .dropdown-toggle.btn-info[class*='owl-']:active,
.open button.dropdown-toggle.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.open button.dropdown-toggle.btn-info.fc-month-button.fc-state-default.fc-corner-left:active,
.open button.dropdown-toggle.btn-info.fc-agendaWeek-button:active,
.open .dropdown-toggle.btn-info.fc-prev-button:active,
.open .dropdown-toggle.btn-info.fc-next-button:active,
.open .dropdown-toggle.btn-info.fc-today-button:active,
.open .dropdown-toggle.btn.btn-info:focus,
.open .wizard > .actions a.dropdown-toggle.btn-info:focus,
.wizard > .actions .open a.dropdown-toggle.btn-info:focus,
.open .dt-buttons .dropdown-toggle.btn-info.dt-button:focus,
.dt-buttons .open .dropdown-toggle.btn-info.dt-button:focus,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-info:focus,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-info:focus,
.open .sweet-alert button.dropdown-toggle.btn-info:focus,
.sweet-alert .open button.dropdown-toggle.btn-info:focus,
.open .owl-theme .owl-nav .dropdown-toggle.btn-info[class*='owl-']:focus,
.owl-theme .owl-nav .open .dropdown-toggle.btn-info[class*='owl-']:focus,
.open button.dropdown-toggle.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.open button.dropdown-toggle.btn-info.fc-month-button.fc-state-default.fc-corner-left:focus,
.open button.dropdown-toggle.btn-info.fc-agendaWeek-button:focus,
.open .dropdown-toggle.btn-info.fc-prev-button:focus,
.open .dropdown-toggle.btn-info.fc-next-button:focus,
.open .dropdown-toggle.btn-info.fc-today-button:focus {
  background: rgba(231, 54, 141, 0.85);
  border: solid 1px rgba(231, 54, 141, 0.1);
}
.open .dropdown-toggle.btn.btn-primary,
.open .wizard > .actions a.dropdown-toggle,
.wizard > .actions .open a.dropdown-toggle,
.open .dt-buttons .dropdown-toggle.dt-button,
.dt-buttons .open .dropdown-toggle.dt-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-primary,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-primary,
.open .sweet-alert button.dropdown-toggle.btn-primary,
.sweet-alert .open button.dropdown-toggle.btn-primary,
.open .owl-theme .owl-nav .dropdown-toggle.btn-primary[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle.btn-primary[class*='owl-'],
.open button.dropdown-toggle.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-primary.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-primary.fc-agendaWeek-button,
.open .dropdown-toggle.btn-primary.fc-prev-button,
.open .dropdown-toggle.btn-primary.fc-next-button,
.open .dropdown-toggle.btn-primary.fc-today-button {
  background: #2196f3;
  border: solid 1px #2196f3;
}
.open .dropdown-toggle.btn.btn-primary:hover,
.open .wizard > .actions a.dropdown-toggle:hover,
.wizard > .actions .open a.dropdown-toggle:hover,
.open .dt-buttons .dropdown-toggle.dt-button:hover,
.dt-buttons .open .dropdown-toggle.dt-button:hover,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-primary:hover,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-primary:hover,
.open .sweet-alert button.dropdown-toggle.btn-primary:hover,
.sweet-alert .open button.dropdown-toggle.btn-primary:hover,
.open .owl-theme .owl-nav .dropdown-toggle.btn-primary[class*='owl-']:hover,
.owl-theme .owl-nav .open .dropdown-toggle.btn-primary[class*='owl-']:hover,
.open button.dropdown-toggle.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.open button.dropdown-toggle.btn-primary.fc-month-button.fc-state-default.fc-corner-left:hover,
.open button.dropdown-toggle.btn-primary.fc-agendaWeek-button:hover,
.open .dropdown-toggle.btn-primary.fc-prev-button:hover,
.open .dropdown-toggle.btn-primary.fc-next-button:hover,
.open .dropdown-toggle.btn-primary.fc-today-button:hover,
.open .dropdown-toggle.btn.btn-primary:active,
.open .wizard > .actions a.dropdown-toggle:active,
.wizard > .actions .open a.dropdown-toggle:active,
.open .dt-buttons .dropdown-toggle.dt-button:active,
.dt-buttons .open .dropdown-toggle.dt-button:active,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-primary:active,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-primary:active,
.open .sweet-alert button.dropdown-toggle.btn-primary:active,
.sweet-alert .open button.dropdown-toggle.btn-primary:active,
.open .owl-theme .owl-nav .dropdown-toggle.btn-primary[class*='owl-']:active,
.owl-theme .owl-nav .open .dropdown-toggle.btn-primary[class*='owl-']:active,
.open button.dropdown-toggle.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.open button.dropdown-toggle.btn-primary.fc-month-button.fc-state-default.fc-corner-left:active,
.open button.dropdown-toggle.btn-primary.fc-agendaWeek-button:active,
.open .dropdown-toggle.btn-primary.fc-prev-button:active,
.open .dropdown-toggle.btn-primary.fc-next-button:active,
.open .dropdown-toggle.btn-primary.fc-today-button:active,
.open .dropdown-toggle.btn.btn-primary:focus,
.open .wizard > .actions a.dropdown-toggle:focus,
.wizard > .actions .open a.dropdown-toggle:focus,
.open .dt-buttons .dropdown-toggle.dt-button:focus,
.dt-buttons .open .dropdown-toggle.dt-button:focus,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-primary:focus,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-primary:focus,
.open .sweet-alert button.dropdown-toggle.btn-primary:focus,
.sweet-alert .open button.dropdown-toggle.btn-primary:focus,
.open .owl-theme .owl-nav .dropdown-toggle.btn-primary[class*='owl-']:focus,
.owl-theme .owl-nav .open .dropdown-toggle.btn-primary[class*='owl-']:focus,
.open button.dropdown-toggle.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.open button.dropdown-toggle.btn-primary.fc-month-button.fc-state-default.fc-corner-left:focus,
.open button.dropdown-toggle.btn-primary.fc-agendaWeek-button:focus,
.open .dropdown-toggle.btn-primary.fc-prev-button:focus,
.open .dropdown-toggle.btn-primary.fc-next-button:focus,
.open .dropdown-toggle.btn-primary.fc-today-button:focus {
  background: rgba(33, 150, 243, 0.85);
  border: solid 1px rgba(33, 150, 243, 0.1);
}
.open .dropdown-toggle.btn.btn-success,
.open .wizard > .actions a.dropdown-toggle.btn-success,
.wizard > .actions .open a.dropdown-toggle.btn-success,
.open .wizard > .actions a.dropdown-toggle.fc-prev-button,
.wizard > .actions .open a.dropdown-toggle.fc-prev-button,
.open .wizard > .actions a.dropdown-toggle.fc-next-button,
.wizard > .actions .open a.dropdown-toggle.fc-next-button,
.open .wizard > .actions a.dropdown-toggle.fc-today-button,
.wizard > .actions .open a.dropdown-toggle.fc-today-button,
.open .dt-buttons .dropdown-toggle.btn-success.dt-button,
.dt-buttons .open .dropdown-toggle.btn-success.dt-button,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.open .dt-buttons .dropdown-toggle.dt-button.fc-prev-button,
.dt-buttons .open .dropdown-toggle.dt-button.fc-prev-button,
.open .dt-buttons .dropdown-toggle.dt-button.fc-next-button,
.dt-buttons .open .dropdown-toggle.dt-button.fc-next-button,
.open .dt-buttons .dropdown-toggle.dt-button.fc-today-button,
.dt-buttons .open .dropdown-toggle.dt-button.fc-today-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-success,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-success,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-agendaWeek-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-prev-button,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-prev-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-next-button,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-next-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-today-button,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-today-button,
.open .sweet-alert button.dropdown-toggle.btn-success,
.sweet-alert .open button.dropdown-toggle.btn-success,
.open .sweet-alert button.dropdown-toggle.fc-agendaWeek-button,
.sweet-alert .open button.dropdown-toggle.fc-agendaWeek-button,
.open .sweet-alert button.dropdown-toggle.fc-prev-button,
.sweet-alert .open button.dropdown-toggle.fc-prev-button,
.open .sweet-alert button.dropdown-toggle.fc-next-button,
.sweet-alert .open button.dropdown-toggle.fc-next-button,
.open .sweet-alert button.dropdown-toggle.fc-today-button,
.sweet-alert .open button.dropdown-toggle.fc-today-button,
.open .owl-theme .owl-nav .dropdown-toggle.btn-success[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle.btn-success[class*='owl-'],
.open .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button,
.owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-prev-button,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-prev-button,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-next-button,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-next-button,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-today-button,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-today-button,
.open button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.fc-agendaWeek-button,
.open .dropdown-toggle.fc-prev-button,
.open .dropdown-toggle.fc-next-button,
.open .dropdown-toggle.fc-today-button {
  background: #8bc34a;
  border: solid 1px #8bc34a;
}
.open .dropdown-toggle.btn.btn-success:hover,
.open .wizard > .actions a.dropdown-toggle.btn-success:hover,
.wizard > .actions .open a.dropdown-toggle.btn-success:hover,
.open .wizard > .actions a.dropdown-toggle.fc-prev-button:hover,
.wizard > .actions .open a.dropdown-toggle.fc-prev-button:hover,
.open .wizard > .actions a.dropdown-toggle.fc-next-button:hover,
.wizard > .actions .open a.dropdown-toggle.fc-next-button:hover,
.open .wizard > .actions a.dropdown-toggle.fc-today-button:hover,
.wizard > .actions .open a.dropdown-toggle.fc-today-button:hover,
.open .dt-buttons .dropdown-toggle.btn-success.dt-button:hover,
.dt-buttons .open .dropdown-toggle.btn-success.dt-button:hover,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaWeek-button:hover,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaWeek-button:hover,
.open .dt-buttons .dropdown-toggle.dt-button.fc-prev-button:hover,
.dt-buttons .open .dropdown-toggle.dt-button.fc-prev-button:hover,
.open .dt-buttons .dropdown-toggle.dt-button.fc-next-button:hover,
.dt-buttons .open .dropdown-toggle.dt-button.fc-next-button:hover,
.open .dt-buttons .dropdown-toggle.dt-button.fc-today-button:hover,
.dt-buttons .open .dropdown-toggle.dt-button.fc-today-button:hover,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-success:hover,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-success:hover,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-agendaWeek-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-agendaWeek-button:hover,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-prev-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-prev-button:hover,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-next-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-next-button:hover,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-today-button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-today-button:hover,
.open .sweet-alert button.dropdown-toggle.btn-success:hover,
.sweet-alert .open button.dropdown-toggle.btn-success:hover,
.open .sweet-alert button.dropdown-toggle.fc-agendaWeek-button:hover,
.sweet-alert .open button.dropdown-toggle.fc-agendaWeek-button:hover,
.open .sweet-alert button.dropdown-toggle.fc-prev-button:hover,
.sweet-alert .open button.dropdown-toggle.fc-prev-button:hover,
.open .sweet-alert button.dropdown-toggle.fc-next-button:hover,
.sweet-alert .open button.dropdown-toggle.fc-next-button:hover,
.open .sweet-alert button.dropdown-toggle.fc-today-button:hover,
.sweet-alert .open button.dropdown-toggle.fc-today-button:hover,
.open .owl-theme .owl-nav .dropdown-toggle.btn-success[class*='owl-']:hover,
.owl-theme .owl-nav .open .dropdown-toggle.btn-success[class*='owl-']:hover,
.open .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:hover,
.owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:hover,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-prev-button:hover,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-prev-button:hover,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-next-button:hover,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-next-button:hover,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-today-button:hover,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-today-button:hover,
.open button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.open button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left:hover,
.open button.dropdown-toggle.fc-agendaWeek-button:hover,
.open .dropdown-toggle.fc-prev-button:hover,
.open .dropdown-toggle.fc-next-button:hover,
.open .dropdown-toggle.fc-today-button:hover,
.open .dropdown-toggle.btn.btn-success:active,
.open .wizard > .actions a.dropdown-toggle.btn-success:active,
.wizard > .actions .open a.dropdown-toggle.btn-success:active,
.open .wizard > .actions a.dropdown-toggle.fc-prev-button:active,
.wizard > .actions .open a.dropdown-toggle.fc-prev-button:active,
.open .wizard > .actions a.dropdown-toggle.fc-next-button:active,
.wizard > .actions .open a.dropdown-toggle.fc-next-button:active,
.open .wizard > .actions a.dropdown-toggle.fc-today-button:active,
.wizard > .actions .open a.dropdown-toggle.fc-today-button:active,
.open .dt-buttons .dropdown-toggle.btn-success.dt-button:active,
.dt-buttons .open .dropdown-toggle.btn-success.dt-button:active,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaWeek-button:active,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaWeek-button:active,
.open .dt-buttons .dropdown-toggle.dt-button.fc-prev-button:active,
.dt-buttons .open .dropdown-toggle.dt-button.fc-prev-button:active,
.open .dt-buttons .dropdown-toggle.dt-button.fc-next-button:active,
.dt-buttons .open .dropdown-toggle.dt-button.fc-next-button:active,
.open .dt-buttons .dropdown-toggle.dt-button.fc-today-button:active,
.dt-buttons .open .dropdown-toggle.dt-button.fc-today-button:active,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-success:active,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-success:active,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-agendaWeek-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-agendaWeek-button:active,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-prev-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-prev-button:active,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-next-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-next-button:active,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-today-button:active,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-today-button:active,
.open .sweet-alert button.dropdown-toggle.btn-success:active,
.sweet-alert .open button.dropdown-toggle.btn-success:active,
.open .sweet-alert button.dropdown-toggle.fc-agendaWeek-button:active,
.sweet-alert .open button.dropdown-toggle.fc-agendaWeek-button:active,
.open .sweet-alert button.dropdown-toggle.fc-prev-button:active,
.sweet-alert .open button.dropdown-toggle.fc-prev-button:active,
.open .sweet-alert button.dropdown-toggle.fc-next-button:active,
.sweet-alert .open button.dropdown-toggle.fc-next-button:active,
.open .sweet-alert button.dropdown-toggle.fc-today-button:active,
.sweet-alert .open button.dropdown-toggle.fc-today-button:active,
.open .owl-theme .owl-nav .dropdown-toggle.btn-success[class*='owl-']:active,
.owl-theme .owl-nav .open .dropdown-toggle.btn-success[class*='owl-']:active,
.open .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:active,
.owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:active,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-prev-button:active,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-prev-button:active,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-next-button:active,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-next-button:active,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-today-button:active,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-today-button:active,
.open button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.open button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left:active,
.open button.dropdown-toggle.fc-agendaWeek-button:active,
.open .dropdown-toggle.fc-prev-button:active,
.open .dropdown-toggle.fc-next-button:active,
.open .dropdown-toggle.fc-today-button:active,
.open .dropdown-toggle.btn.btn-success:focus,
.open .wizard > .actions a.dropdown-toggle.btn-success:focus,
.wizard > .actions .open a.dropdown-toggle.btn-success:focus,
.open .wizard > .actions a.dropdown-toggle.fc-prev-button:focus,
.wizard > .actions .open a.dropdown-toggle.fc-prev-button:focus,
.open .wizard > .actions a.dropdown-toggle.fc-next-button:focus,
.wizard > .actions .open a.dropdown-toggle.fc-next-button:focus,
.open .wizard > .actions a.dropdown-toggle.fc-today-button:focus,
.wizard > .actions .open a.dropdown-toggle.fc-today-button:focus,
.open .dt-buttons .dropdown-toggle.btn-success.dt-button:focus,
.dt-buttons .open .dropdown-toggle.btn-success.dt-button:focus,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaWeek-button:focus,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaWeek-button:focus,
.open .dt-buttons .dropdown-toggle.dt-button.fc-prev-button:focus,
.dt-buttons .open .dropdown-toggle.dt-button.fc-prev-button:focus,
.open .dt-buttons .dropdown-toggle.dt-button.fc-next-button:focus,
.dt-buttons .open .dropdown-toggle.dt-button.fc-next-button:focus,
.open .dt-buttons .dropdown-toggle.dt-button.fc-today-button:focus,
.dt-buttons .open .dropdown-toggle.dt-button.fc-today-button:focus,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-success:focus,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-success:focus,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-agendaWeek-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-agendaWeek-button:focus,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-prev-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-prev-button:focus,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-next-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-next-button:focus,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-today-button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-today-button:focus,
.open .sweet-alert button.dropdown-toggle.btn-success:focus,
.sweet-alert .open button.dropdown-toggle.btn-success:focus,
.open .sweet-alert button.dropdown-toggle.fc-agendaWeek-button:focus,
.sweet-alert .open button.dropdown-toggle.fc-agendaWeek-button:focus,
.open .sweet-alert button.dropdown-toggle.fc-prev-button:focus,
.sweet-alert .open button.dropdown-toggle.fc-prev-button:focus,
.open .sweet-alert button.dropdown-toggle.fc-next-button:focus,
.sweet-alert .open button.dropdown-toggle.fc-next-button:focus,
.open .sweet-alert button.dropdown-toggle.fc-today-button:focus,
.sweet-alert .open button.dropdown-toggle.fc-today-button:focus,
.open .owl-theme .owl-nav .dropdown-toggle.btn-success[class*='owl-']:focus,
.owl-theme .owl-nav .open .dropdown-toggle.btn-success[class*='owl-']:focus,
.open .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:focus,
.owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:focus,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-prev-button:focus,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-prev-button:focus,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-next-button:focus,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-next-button:focus,
.open .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].fc-today-button:focus,
.owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].fc-today-button:focus,
.open button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.open button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left:focus,
.open button.dropdown-toggle.fc-agendaWeek-button:focus,
.open .dropdown-toggle.fc-prev-button:focus,
.open .dropdown-toggle.fc-next-button:focus,
.open .dropdown-toggle.fc-today-button:focus {
  background: rgba(139, 195, 74, 0.85);
  border: solid 1px rgba(139, 195, 74, 0.1);
}
.open .dropdown-toggle.btn.btn-danger,
.open .wizard > .actions a.dropdown-toggle.btn-danger,
.wizard > .actions .open a.dropdown-toggle.btn-danger,
.open .dt-buttons .dropdown-toggle.btn-danger.dt-button,
.dt-buttons .open .dropdown-toggle.btn-danger.dt-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-danger,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-danger,
.open .sweet-alert button.dropdown-toggle.btn-danger,
.sweet-alert .open button.dropdown-toggle.btn-danger,
.open .owl-theme .owl-nav .dropdown-toggle.btn-danger[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle.btn-danger[class*='owl-'],
.open button.dropdown-toggle.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-danger.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-danger.fc-agendaWeek-button,
.open .dropdown-toggle.btn-danger.fc-prev-button,
.open .dropdown-toggle.btn-danger.fc-next-button,
.open .dropdown-toggle.btn-danger.fc-today-button {
  background: #f33923;
  border: solid 1px #f33923;
}
.open .dropdown-toggle.btn.btn-danger:hover,
.open .wizard > .actions a.dropdown-toggle.btn-danger:hover,
.wizard > .actions .open a.dropdown-toggle.btn-danger:hover,
.open .dt-buttons .dropdown-toggle.btn-danger.dt-button:hover,
.dt-buttons .open .dropdown-toggle.btn-danger.dt-button:hover,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-danger:hover,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-danger:hover,
.open .sweet-alert button.dropdown-toggle.btn-danger:hover,
.sweet-alert .open button.dropdown-toggle.btn-danger:hover,
.open .owl-theme .owl-nav .dropdown-toggle.btn-danger[class*='owl-']:hover,
.owl-theme .owl-nav .open .dropdown-toggle.btn-danger[class*='owl-']:hover,
.open button.dropdown-toggle.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.open button.dropdown-toggle.btn-danger.fc-month-button.fc-state-default.fc-corner-left:hover,
.open button.dropdown-toggle.btn-danger.fc-agendaWeek-button:hover,
.open .dropdown-toggle.btn-danger.fc-prev-button:hover,
.open .dropdown-toggle.btn-danger.fc-next-button:hover,
.open .dropdown-toggle.btn-danger.fc-today-button:hover,
.open .dropdown-toggle.btn.btn-danger:active,
.open .wizard > .actions a.dropdown-toggle.btn-danger:active,
.wizard > .actions .open a.dropdown-toggle.btn-danger:active,
.open .dt-buttons .dropdown-toggle.btn-danger.dt-button:active,
.dt-buttons .open .dropdown-toggle.btn-danger.dt-button:active,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-danger:active,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-danger:active,
.open .sweet-alert button.dropdown-toggle.btn-danger:active,
.sweet-alert .open button.dropdown-toggle.btn-danger:active,
.open .owl-theme .owl-nav .dropdown-toggle.btn-danger[class*='owl-']:active,
.owl-theme .owl-nav .open .dropdown-toggle.btn-danger[class*='owl-']:active,
.open button.dropdown-toggle.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.open button.dropdown-toggle.btn-danger.fc-month-button.fc-state-default.fc-corner-left:active,
.open button.dropdown-toggle.btn-danger.fc-agendaWeek-button:active,
.open .dropdown-toggle.btn-danger.fc-prev-button:active,
.open .dropdown-toggle.btn-danger.fc-next-button:active,
.open .dropdown-toggle.btn-danger.fc-today-button:active,
.open .dropdown-toggle.btn.btn-danger:focus,
.open .wizard > .actions a.dropdown-toggle.btn-danger:focus,
.wizard > .actions .open a.dropdown-toggle.btn-danger:focus,
.open .dt-buttons .dropdown-toggle.btn-danger.dt-button:focus,
.dt-buttons .open .dropdown-toggle.btn-danger.dt-button:focus,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-danger:focus,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-danger:focus,
.open .sweet-alert button.dropdown-toggle.btn-danger:focus,
.sweet-alert .open button.dropdown-toggle.btn-danger:focus,
.open .owl-theme .owl-nav .dropdown-toggle.btn-danger[class*='owl-']:focus,
.owl-theme .owl-nav .open .dropdown-toggle.btn-danger[class*='owl-']:focus,
.open button.dropdown-toggle.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.open button.dropdown-toggle.btn-danger.fc-month-button.fc-state-default.fc-corner-left:focus,
.open button.dropdown-toggle.btn-danger.fc-agendaWeek-button:focus,
.open .dropdown-toggle.btn-danger.fc-prev-button:focus,
.open .dropdown-toggle.btn-danger.fc-next-button:focus,
.open .dropdown-toggle.btn-danger.fc-today-button:focus {
  background: rgba(243, 57, 35, 0.85);
  border: solid 1px rgba(243, 57, 35, 0.1);
}
.open .dropdown-toggle.btn.btn-warning,
.open .wizard > .actions a.dropdown-toggle.btn-warning,
.wizard > .actions .open a.dropdown-toggle.btn-warning,
.open .dt-buttons .dropdown-toggle.btn-warning.dt-button,
.dt-buttons .open .dropdown-toggle.btn-warning.dt-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-warning,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-warning,
.open .sweet-alert button.dropdown-toggle.btn-warning,
.sweet-alert .open button.dropdown-toggle.btn-warning,
.open .owl-theme .owl-nav .dropdown-toggle.btn-warning[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle.btn-warning[class*='owl-'],
.open button.dropdown-toggle.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-warning.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-warning.fc-agendaWeek-button,
.open .dropdown-toggle.btn-warning.fc-prev-button,
.open .dropdown-toggle.btn-warning.fc-next-button,
.open .dropdown-toggle.btn-warning.fc-today-button {
  background: #f8b32d;
  border: solid 1px #f8b32d;
}
.open .dropdown-toggle.btn.btn-warning:hover,
.open .wizard > .actions a.dropdown-toggle.btn-warning:hover,
.wizard > .actions .open a.dropdown-toggle.btn-warning:hover,
.open .dt-buttons .dropdown-toggle.btn-warning.dt-button:hover,
.dt-buttons .open .dropdown-toggle.btn-warning.dt-button:hover,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-warning:hover,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-warning:hover,
.open .sweet-alert button.dropdown-toggle.btn-warning:hover,
.sweet-alert .open button.dropdown-toggle.btn-warning:hover,
.open .owl-theme .owl-nav .dropdown-toggle.btn-warning[class*='owl-']:hover,
.owl-theme .owl-nav .open .dropdown-toggle.btn-warning[class*='owl-']:hover,
.open button.dropdown-toggle.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.open button.dropdown-toggle.btn-warning.fc-month-button.fc-state-default.fc-corner-left:hover,
.open button.dropdown-toggle.btn-warning.fc-agendaWeek-button:hover,
.open .dropdown-toggle.btn-warning.fc-prev-button:hover,
.open .dropdown-toggle.btn-warning.fc-next-button:hover,
.open .dropdown-toggle.btn-warning.fc-today-button:hover,
.open .dropdown-toggle.btn.btn-warning:active,
.open .wizard > .actions a.dropdown-toggle.btn-warning:active,
.wizard > .actions .open a.dropdown-toggle.btn-warning:active,
.open .dt-buttons .dropdown-toggle.btn-warning.dt-button:active,
.dt-buttons .open .dropdown-toggle.btn-warning.dt-button:active,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-warning:active,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-warning:active,
.open .sweet-alert button.dropdown-toggle.btn-warning:active,
.sweet-alert .open button.dropdown-toggle.btn-warning:active,
.open .owl-theme .owl-nav .dropdown-toggle.btn-warning[class*='owl-']:active,
.owl-theme .owl-nav .open .dropdown-toggle.btn-warning[class*='owl-']:active,
.open button.dropdown-toggle.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.open button.dropdown-toggle.btn-warning.fc-month-button.fc-state-default.fc-corner-left:active,
.open button.dropdown-toggle.btn-warning.fc-agendaWeek-button:active,
.open .dropdown-toggle.btn-warning.fc-prev-button:active,
.open .dropdown-toggle.btn-warning.fc-next-button:active,
.open .dropdown-toggle.btn-warning.fc-today-button:active,
.open .dropdown-toggle.btn.btn-warning:focus,
.open .wizard > .actions a.dropdown-toggle.btn-warning:focus,
.wizard > .actions .open a.dropdown-toggle.btn-warning:focus,
.open .dt-buttons .dropdown-toggle.btn-warning.dt-button:focus,
.dt-buttons .open .dropdown-toggle.btn-warning.dt-button:focus,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-warning:focus,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-warning:focus,
.open .sweet-alert button.dropdown-toggle.btn-warning:focus,
.sweet-alert .open button.dropdown-toggle.btn-warning:focus,
.open .owl-theme .owl-nav .dropdown-toggle.btn-warning[class*='owl-']:focus,
.owl-theme .owl-nav .open .dropdown-toggle.btn-warning[class*='owl-']:focus,
.open button.dropdown-toggle.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.open button.dropdown-toggle.btn-warning.fc-month-button.fc-state-default.fc-corner-left:focus,
.open button.dropdown-toggle.btn-warning.fc-agendaWeek-button:focus,
.open .dropdown-toggle.btn-warning.fc-prev-button:focus,
.open .dropdown-toggle.btn-warning.fc-next-button:focus,
.open .dropdown-toggle.btn-warning.fc-today-button:focus {
  background: rgba(248, 179, 45, 0.85);
  border: solid 1px rgba(248, 179, 45, 0.1);
}
.open .dropdown-toggle.btn.btn-outline,
.open .wizard > .actions a.dropdown-toggle.btn-outline,
.wizard > .actions .open a.dropdown-toggle.btn-outline,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.dt-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.dt-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.dt-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.dt-button,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle[class*='owl-'],
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-'],
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle[class*='owl-'],
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button,
.open .note-editor .dropdown-toggle.btn.note-btn.btn-sm,
.note-editor .open .dropdown-toggle.btn.note-btn.btn-sm,
.open .note-editor .dt-buttons .dropdown-toggle.note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .open .dropdown-toggle.note-btn.btn-sm.dt-button,
.open .dt-buttons .note-editor .dropdown-toggle.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .open .dropdown-toggle.note-btn.btn-sm.dt-button,
.open .note-editor .sweet-alert button.dropdown-toggle.note-btn.btn-sm,
.note-editor .sweet-alert .open button.dropdown-toggle.note-btn.btn-sm,
.open .sweet-alert .note-editor button.dropdown-toggle.note-btn.btn-sm,
.sweet-alert .note-editor .open button.dropdown-toggle.note-btn.btn-sm,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle.note-btn.btn-sm[class*='owl-'],
.note-editor .owl-theme .owl-nav .open .dropdown-toggle.note-btn.btn-sm[class*='owl-'],
.open .owl-theme .owl-nav .note-editor .dropdown-toggle.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .open .dropdown-toggle.note-btn.btn-sm[class*='owl-'],
.open .note-editor button.dropdown-toggle.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .open button.dropdown-toggle.note-btn.btn-sm.fc-agendaWeek-button,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-next-button,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-next-button,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-today-button,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-today-button,
.open .dt-buttons .dropdown-toggle.btn-outline.dt-button,
.dt-buttons .open .dropdown-toggle.btn-outline.dt-button,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle,
.open .sweet-alert button.dropdown-toggle.btn-outline,
.sweet-alert .open button.dropdown-toggle.btn-outline,
.open .sweet-alert button.dropdown-toggle.fc-agendaWeek-button,
.sweet-alert .open button.dropdown-toggle.fc-agendaWeek-button,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline[class*='owl-'],
.open .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button,
.owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button,
.open button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.fc-prev-button,
.open button.dropdown-toggle.fc-prev-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.fc-next-button,
.open button.dropdown-toggle.fc-next-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.fc-today-button,
.open button.dropdown-toggle.fc-today-button.fc-agendaWeek-button {
  background: transparent;
}
.open .dropdown-toggle.btn.btn-outline:hover,
.open .wizard > .actions a.dropdown-toggle.btn-outline:hover,
.wizard > .actions .open a.dropdown-toggle.btn-outline:hover,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm:hover,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm:hover,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm:hover,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm:hover,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn:hover,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn:hover,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle:hover,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle:hover,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle:hover,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle:hover,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.dt-button:hover,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.dt-button:hover,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.dt-button:hover,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.dt-button:hover,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle[class*='owl-']:hover,
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-']:hover,
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle[class*='owl-']:hover,
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle[class*='owl-']:hover,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button:hover,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button:hover,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button:hover,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button:hover,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button:hover,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button:hover,
.open .note-editor .dropdown-toggle.btn.note-btn.btn-sm:hover,
.note-editor .open .dropdown-toggle.btn.note-btn.btn-sm:hover,
.open .note-editor .dt-buttons .dropdown-toggle.note-btn.btn-sm.dt-button:hover,
.note-editor .dt-buttons .open .dropdown-toggle.note-btn.btn-sm.dt-button:hover,
.open .dt-buttons .note-editor .dropdown-toggle.note-btn.btn-sm.dt-button:hover,
.dt-buttons .note-editor .open .dropdown-toggle.note-btn.btn-sm.dt-button:hover,
.open .note-editor .sweet-alert button.dropdown-toggle.note-btn.btn-sm:hover,
.note-editor .sweet-alert .open button.dropdown-toggle.note-btn.btn-sm:hover,
.open .sweet-alert .note-editor button.dropdown-toggle.note-btn.btn-sm:hover,
.sweet-alert .note-editor .open button.dropdown-toggle.note-btn.btn-sm:hover,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle.note-btn.btn-sm[class*='owl-']:hover,
.note-editor .owl-theme .owl-nav .open .dropdown-toggle.note-btn.btn-sm[class*='owl-']:hover,
.open .owl-theme .owl-nav .note-editor .dropdown-toggle.note-btn.btn-sm[class*='owl-']:hover,
.owl-theme .owl-nav .note-editor .open .dropdown-toggle.note-btn.btn-sm[class*='owl-']:hover,
.open .note-editor button.dropdown-toggle.note-btn.btn-sm.fc-agendaWeek-button:hover,
.note-editor .open button.dropdown-toggle.note-btn.btn-sm.fc-agendaWeek-button:hover,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-prev-button:hover,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-next-button:hover,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-next-button:hover,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-today-button:hover,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-today-button:hover,
.open .dt-buttons .dropdown-toggle.btn-outline.dt-button:hover,
.dt-buttons .open .dropdown-toggle.btn-outline.dt-button:hover,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaWeek-button:hover,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaWeek-button:hover,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle:hover,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle:hover,
.open .sweet-alert button.dropdown-toggle.btn-outline:hover,
.sweet-alert .open button.dropdown-toggle.btn-outline:hover,
.open .sweet-alert button.dropdown-toggle.fc-agendaWeek-button:hover,
.sweet-alert .open button.dropdown-toggle.fc-agendaWeek-button:hover,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline[class*='owl-']:hover,
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline[class*='owl-']:hover,
.open .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:hover,
.owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:hover,
.open button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.open button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left:hover,
.open button.dropdown-toggle.fc-agendaWeek-button:hover,
.open .dropdown-toggle.btn-outline.fc-prev-button:hover,
.open button.dropdown-toggle.fc-prev-button.fc-agendaWeek-button:hover,
.open .dropdown-toggle.btn-outline.fc-next-button:hover,
.open button.dropdown-toggle.fc-next-button.fc-agendaWeek-button:hover,
.open .dropdown-toggle.btn-outline.fc-today-button:hover,
.open button.dropdown-toggle.fc-today-button.fc-agendaWeek-button:hover,
.open .dropdown-toggle.btn.btn-outline:active,
.open .wizard > .actions a.dropdown-toggle.btn-outline:active,
.wizard > .actions .open a.dropdown-toggle.btn-outline:active,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm:active,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm:active,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm:active,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm:active,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn:active,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn:active,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle:active,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle:active,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle:active,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle:active,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.dt-button:active,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.dt-button:active,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.dt-button:active,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.dt-button:active,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle[class*='owl-']:active,
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-']:active,
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle[class*='owl-']:active,
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle[class*='owl-']:active,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button:active,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button:active,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button:active,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button:active,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button:active,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button:active,
.open .note-editor .dropdown-toggle.btn.note-btn.btn-sm:active,
.note-editor .open .dropdown-toggle.btn.note-btn.btn-sm:active,
.open .note-editor .dt-buttons .dropdown-toggle.note-btn.btn-sm.dt-button:active,
.note-editor .dt-buttons .open .dropdown-toggle.note-btn.btn-sm.dt-button:active,
.open .dt-buttons .note-editor .dropdown-toggle.note-btn.btn-sm.dt-button:active,
.dt-buttons .note-editor .open .dropdown-toggle.note-btn.btn-sm.dt-button:active,
.open .note-editor .sweet-alert button.dropdown-toggle.note-btn.btn-sm:active,
.note-editor .sweet-alert .open button.dropdown-toggle.note-btn.btn-sm:active,
.open .sweet-alert .note-editor button.dropdown-toggle.note-btn.btn-sm:active,
.sweet-alert .note-editor .open button.dropdown-toggle.note-btn.btn-sm:active,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle.note-btn.btn-sm[class*='owl-']:active,
.note-editor .owl-theme .owl-nav .open .dropdown-toggle.note-btn.btn-sm[class*='owl-']:active,
.open .owl-theme .owl-nav .note-editor .dropdown-toggle.note-btn.btn-sm[class*='owl-']:active,
.owl-theme .owl-nav .note-editor .open .dropdown-toggle.note-btn.btn-sm[class*='owl-']:active,
.open .note-editor button.dropdown-toggle.note-btn.btn-sm.fc-agendaWeek-button:active,
.note-editor .open button.dropdown-toggle.note-btn.btn-sm.fc-agendaWeek-button:active,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-prev-button:active,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-prev-button:active,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-next-button:active,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-next-button:active,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-today-button:active,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-today-button:active,
.open .dt-buttons .dropdown-toggle.btn-outline.dt-button:active,
.dt-buttons .open .dropdown-toggle.btn-outline.dt-button:active,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaWeek-button:active,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaWeek-button:active,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle:active,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle:active,
.open .sweet-alert button.dropdown-toggle.btn-outline:active,
.sweet-alert .open button.dropdown-toggle.btn-outline:active,
.open .sweet-alert button.dropdown-toggle.fc-agendaWeek-button:active,
.sweet-alert .open button.dropdown-toggle.fc-agendaWeek-button:active,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline[class*='owl-']:active,
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline[class*='owl-']:active,
.open .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:active,
.owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:active,
.open button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.open button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left:active,
.open button.dropdown-toggle.fc-agendaWeek-button:active,
.open .dropdown-toggle.btn-outline.fc-prev-button:active,
.open button.dropdown-toggle.fc-prev-button.fc-agendaWeek-button:active,
.open .dropdown-toggle.btn-outline.fc-next-button:active,
.open button.dropdown-toggle.fc-next-button.fc-agendaWeek-button:active,
.open .dropdown-toggle.btn-outline.fc-today-button:active,
.open button.dropdown-toggle.fc-today-button.fc-agendaWeek-button:active,
.open .dropdown-toggle.btn.btn-outline:focus,
.open .wizard > .actions a.dropdown-toggle.btn-outline:focus,
.wizard > .actions .open a.dropdown-toggle.btn-outline:focus,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm:focus,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm:focus,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm:focus,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm:focus,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn:focus,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn:focus,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle:focus,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle:focus,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle:focus,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle:focus,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.dt-button:focus,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.dt-button:focus,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.dt-button:focus,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.dt-button:focus,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle[class*='owl-']:focus,
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-']:focus,
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle[class*='owl-']:focus,
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle[class*='owl-']:focus,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button:focus,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button:focus,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button:focus,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button:focus,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button:focus,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button:focus,
.open .note-editor .dropdown-toggle.btn.note-btn.btn-sm:focus,
.note-editor .open .dropdown-toggle.btn.note-btn.btn-sm:focus,
.open .note-editor .dt-buttons .dropdown-toggle.note-btn.btn-sm.dt-button:focus,
.note-editor .dt-buttons .open .dropdown-toggle.note-btn.btn-sm.dt-button:focus,
.open .dt-buttons .note-editor .dropdown-toggle.note-btn.btn-sm.dt-button:focus,
.dt-buttons .note-editor .open .dropdown-toggle.note-btn.btn-sm.dt-button:focus,
.open .note-editor .sweet-alert button.dropdown-toggle.note-btn.btn-sm:focus,
.note-editor .sweet-alert .open button.dropdown-toggle.note-btn.btn-sm:focus,
.open .sweet-alert .note-editor button.dropdown-toggle.note-btn.btn-sm:focus,
.sweet-alert .note-editor .open button.dropdown-toggle.note-btn.btn-sm:focus,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle.note-btn.btn-sm[class*='owl-']:focus,
.note-editor .owl-theme .owl-nav .open .dropdown-toggle.note-btn.btn-sm[class*='owl-']:focus,
.open .owl-theme .owl-nav .note-editor .dropdown-toggle.note-btn.btn-sm[class*='owl-']:focus,
.owl-theme .owl-nav .note-editor .open .dropdown-toggle.note-btn.btn-sm[class*='owl-']:focus,
.open .note-editor button.dropdown-toggle.note-btn.btn-sm.fc-agendaWeek-button:focus,
.note-editor .open button.dropdown-toggle.note-btn.btn-sm.fc-agendaWeek-button:focus,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-prev-button:focus,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-prev-button:focus,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-next-button:focus,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-next-button:focus,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-today-button:focus,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-today-button:focus,
.open .dt-buttons .dropdown-toggle.btn-outline.dt-button:focus,
.dt-buttons .open .dropdown-toggle.btn-outline.dt-button:focus,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaWeek-button:focus,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaWeek-button:focus,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle:focus,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle:focus,
.open .sweet-alert button.dropdown-toggle.btn-outline:focus,
.sweet-alert .open button.dropdown-toggle.btn-outline:focus,
.open .sweet-alert button.dropdown-toggle.fc-agendaWeek-button:focus,
.sweet-alert .open button.dropdown-toggle.fc-agendaWeek-button:focus,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline[class*='owl-']:focus,
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline[class*='owl-']:focus,
.open .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:focus,
.owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button:focus,
.open button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.open button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left:focus,
.open button.dropdown-toggle.fc-agendaWeek-button:focus,
.open .dropdown-toggle.btn-outline.fc-prev-button:focus,
.open button.dropdown-toggle.fc-prev-button.fc-agendaWeek-button:focus,
.open .dropdown-toggle.btn-outline.fc-next-button:focus,
.open button.dropdown-toggle.fc-next-button.fc-agendaWeek-button:focus,
.open .dropdown-toggle.btn-outline.fc-today-button:focus,
.open button.dropdown-toggle.fc-today-button.fc-agendaWeek-button:focus {
  background: transparent;
  border: solid 1px gainsboro;
}
.open .dropdown-toggle.btn.btn-outline.btn-success,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.btn-success,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.btn-success,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.btn-success.btn,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-success.btn,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.btn-success.btn,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.btn-success.btn,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.fc-prev-button,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.fc-prev-button,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.fc-next-button,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.fc-next-button,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.fc-today-button,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.fc-today-button,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.btn-success.note-btn.btn-sm,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.btn-success.note-btn.btn-sm,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.btn-success.note-btn.btn-sm,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.btn-success.note-btn.btn-sm,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.fc-prev-button,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.fc-prev-button,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.fc-next-button,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.fc-next-button,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.fc-today-button,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.fc-today-button,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.btn-success:hover,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.btn-success:hover,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.btn-success.btn:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-success.btn:hover,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.btn-success.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.btn-success.btn:hover,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button:hover,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.fc-prev-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.fc-prev-button:hover,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button:hover,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.fc-next-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.fc-next-button:hover,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button:hover,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.fc-today-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.fc-today-button:hover,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.btn-success.note-btn.btn-sm:hover,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.btn-success.note-btn.btn-sm:hover,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.btn-success.note-btn.btn-sm:hover,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.btn-success.note-btn.btn-sm:hover,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:hover,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:hover,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:hover,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-next-button:hover,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button:hover,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm.fc-next-button:hover,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button:hover,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-today-button:hover,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button:hover,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm.fc-today-button:hover,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button:hover,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.fc-prev-button:hover,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.fc-prev-button:hover,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.fc-next-button:hover,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.fc-next-button:hover,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.fc-today-button:hover,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.fc-today-button:hover,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.btn-success:active,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.btn-success:active,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.btn-success.btn:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-success.btn:active,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.btn-success.btn:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.btn-success.btn:active,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button:active,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.fc-prev-button:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.fc-prev-button:active,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button:active,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.fc-next-button:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.fc-next-button:active,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button:active,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle.fc-today-button:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle.fc-today-button:active,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.btn-success.note-btn.btn-sm:active,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.btn-success.note-btn.btn-sm:active,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.btn-success.note-btn.btn-sm:active,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.btn-success.note-btn.btn-sm:active,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:active,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:active,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:active,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:active,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-next-button:active,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button:active,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm.fc-next-button:active,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button:active,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-today-button:active,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button:active,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm.fc-today-button:active,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button:active,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.fc-prev-button:active,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.fc-prev-button:active,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.fc-next-button:active,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.fc-next-button:active,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline.fc-today-button:active,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline.fc-today-button:active,
.open .wizard > .actions a.dropdown-toggle.btn-outline.btn-success,
.wizard > .actions .open a.dropdown-toggle.btn-outline.btn-success,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.btn-success.btn,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-success.btn,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.btn-success.btn,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.btn-success.btn,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-prev-button,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-prev-button,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-next-button,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-next-button,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-today-button,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-today-button,
.open .wizard > .actions .note-editor a.dropdown-toggle.btn-success.note-btn.btn-sm,
.wizard > .actions .note-editor .open a.dropdown-toggle.btn-success.note-btn.btn-sm,
.open .note-editor .wizard > .actions a.dropdown-toggle.btn-success.note-btn.btn-sm,
.note-editor .wizard > .actions .open a.dropdown-toggle.btn-success.note-btn.btn-sm,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-prev-button,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-prev-button,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-next-button,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-next-button,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-today-button,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-today-button,
.open .wizard > .actions a.dropdown-toggle.btn-outline.btn-success:hover,
.wizard > .actions .open a.dropdown-toggle.btn-outline.btn-success:hover,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.btn-success.btn:hover,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-success.btn:hover,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.btn-success.btn:hover,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.btn-success.btn:hover,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button:hover,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button:hover,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-prev-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-prev-button:hover,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button:hover,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button:hover,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-next-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-next-button:hover,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button:hover,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button:hover,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-today-button:hover,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-today-button:hover,
.open .wizard > .actions .note-editor a.dropdown-toggle.btn-success.note-btn.btn-sm:hover,
.wizard > .actions .note-editor .open a.dropdown-toggle.btn-success.note-btn.btn-sm:hover,
.open .note-editor .wizard > .actions a.dropdown-toggle.btn-success.note-btn.btn-sm:hover,
.note-editor .wizard > .actions .open a.dropdown-toggle.btn-success.note-btn.btn-sm:hover,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:hover,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:hover,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:hover,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:hover,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-next-button:hover,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button:hover,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm.fc-next-button:hover,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button:hover,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-today-button:hover,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button:hover,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm.fc-today-button:hover,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button:hover,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-prev-button:hover,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-prev-button:hover,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-next-button:hover,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-next-button:hover,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-today-button:hover,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-today-button:hover,
.open .wizard > .actions a.dropdown-toggle.btn-outline.btn-success:active,
.wizard > .actions .open a.dropdown-toggle.btn-outline.btn-success:active,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.btn-success.btn:active,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-success.btn:active,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.btn-success.btn:active,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.btn-success.btn:active,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button:active,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button:active,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-prev-button:active,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-prev-button:active,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button:active,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button:active,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-next-button:active,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-next-button:active,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button:active,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button:active,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-today-button:active,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-today-button:active,
.open .wizard > .actions .note-editor a.dropdown-toggle.btn-success.note-btn.btn-sm:active,
.wizard > .actions .note-editor .open a.dropdown-toggle.btn-success.note-btn.btn-sm:active,
.open .note-editor .wizard > .actions a.dropdown-toggle.btn-success.note-btn.btn-sm:active,
.note-editor .wizard > .actions .open a.dropdown-toggle.btn-success.note-btn.btn-sm:active,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:active,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:active,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:active,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm.fc-prev-button:active,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-next-button:active,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button:active,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm.fc-next-button:active,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm.fc-next-button:active,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm.fc-today-button:active,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button:active,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm.fc-today-button:active,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm.fc-today-button:active,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-prev-button:active,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-prev-button:active,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-next-button:active,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-next-button:active,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-today-button:active,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-today-button:active,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn.btn-success,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn.btn-success,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.btn-success,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.btn-success,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.btn-success,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-success,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.btn-success.dt-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.btn-success.dt-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.btn-success.dt-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-success.dt-button,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle.btn-success[class*='owl-'],
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle.btn-success[class*='owl-'],
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle.btn-success[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-success[class*='owl-'],
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button,
.open .note-editor .dropdown-toggle.btn.btn-success.note-btn.btn-sm,
.note-editor .open .dropdown-toggle.btn.btn-success.note-btn.btn-sm,
.open .note-editor .dt-buttons .dropdown-toggle.btn-success.note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .open .dropdown-toggle.btn-success.note-btn.btn-sm.dt-button,
.open .dt-buttons .note-editor .dropdown-toggle.btn-success.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .open .dropdown-toggle.btn-success.note-btn.btn-sm.dt-button,
.open .note-editor .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-success.note-btn.btn-sm,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-success.note-btn.btn-sm,
.open .tablesaw-sortable th.tablesaw-sortable-head .note-editor button.dropdown-toggle.btn-success.note-btn.btn-sm,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor .open button.dropdown-toggle.btn-success.note-btn.btn-sm,
.open .note-editor .sweet-alert button.dropdown-toggle.btn-success.note-btn.btn-sm,
.note-editor .sweet-alert .open button.dropdown-toggle.btn-success.note-btn.btn-sm,
.open .sweet-alert .note-editor button.dropdown-toggle.btn-success.note-btn.btn-sm,
.sweet-alert .note-editor .open button.dropdown-toggle.btn-success.note-btn.btn-sm,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle.btn-success.note-btn.btn-sm[class*='owl-'],
.note-editor .owl-theme .owl-nav .open .dropdown-toggle.btn-success.note-btn.btn-sm[class*='owl-'],
.open .owl-theme .owl-nav .note-editor .dropdown-toggle.btn-success.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .open .dropdown-toggle.btn-success.note-btn.btn-sm[class*='owl-'],
.open .note-editor button.dropdown-toggle.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,
.note-editor .open button.dropdown-toggle.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .note-editor button.dropdown-toggle.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left,
.note-editor .open button.dropdown-toggle.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left,
.open .note-editor button.dropdown-toggle.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .open button.dropdown-toggle.note-btn.btn-sm.fc-agendaWeek-button,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-next-button,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-next-button,
.open .note-editor .dropdown-toggle.note-btn.btn-sm.fc-today-button,
.note-editor .open .dropdown-toggle.note-btn.btn-sm.fc-today-button,
.open .dt-buttons .dropdown-toggle.btn-outline.btn-success.dt-button,
.dt-buttons .open .dropdown-toggle.btn-outline.btn-success.dt-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.btn-success.dt-button.btn,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-success.dt-button.btn,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.btn-success.dt-button.btn,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.btn-success.dt-button.btn,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.dt-button.fc-prev-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.dt-button.fc-prev-button,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.dt-button.fc-prev-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.dt-button.fc-prev-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.dt-button.fc-next-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.dt-button.fc-next-button,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.dt-button.fc-next-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.dt-button.fc-next-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.dt-button.fc-today-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.dt-button.fc-today-button,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.dt-button.fc-today-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.dt-button.fc-today-button,
.open .dt-buttons .note-editor .dropdown-toggle.dt-button.note-btn.btn-sm.fc-prev-button,
.dt-buttons .note-editor .open .dropdown-toggle.dt-button.note-btn.btn-sm.fc-prev-button,
.open .note-editor .dt-buttons .dropdown-toggle.dt-button.note-btn.btn-sm.fc-prev-button,
.note-editor .dt-buttons .open .dropdown-toggle.dt-button.note-btn.btn-sm.fc-prev-button,
.open .dt-buttons .note-editor .dropdown-toggle.dt-button.note-btn.btn-sm.fc-next-button,
.dt-buttons .note-editor .open .dropdown-toggle.dt-button.note-btn.btn-sm.fc-next-button,
.open .note-editor .dt-buttons .dropdown-toggle.dt-button.note-btn.btn-sm.fc-next-button,
.note-editor .dt-buttons .open .dropdown-toggle.dt-button.note-btn.btn-sm.fc-next-button,
.open .dt-buttons .note-editor .dropdown-toggle.dt-button.note-btn.btn-sm.fc-today-button,
.dt-buttons .note-editor .open .dropdown-toggle.dt-button.note-btn.btn-sm.fc-today-button,
.open .note-editor .dt-buttons .dropdown-toggle.dt-button.note-btn.btn-sm.fc-today-button,
.note-editor .dt-buttons .open .dropdown-toggle.dt-button.note-btn.btn-sm.fc-today-button,
.open .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-success.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-success.dt-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dropdown-toggle.btn-success.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .open button.dropdown-toggle.btn-success.dt-button,
.open .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.dt-button.fc-prev-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.dt-button.fc-prev-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dropdown-toggle.dt-button.fc-prev-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .open button.dropdown-toggle.dt-button.fc-prev-button,
.open .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.dt-button.fc-next-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.dt-button.fc-next-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dropdown-toggle.dt-button.fc-next-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .open button.dropdown-toggle.dt-button.fc-next-button,
.open .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.dt-button.fc-today-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.dt-button.fc-today-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dropdown-toggle.dt-button.fc-today-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .open button.dropdown-toggle.dt-button.fc-today-button,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.open .dt-buttons .dropdown-toggle.btn-outline.dt-button.fc-prev-button,
.dt-buttons .open .dropdown-toggle.btn-outline.dt-button.fc-prev-button,
.open .dt-buttons .dropdown-toggle.btn-outline.dt-button.fc-next-button,
.dt-buttons .open .dropdown-toggle.btn-outline.dt-button.fc-next-button,
.open .dt-buttons .dropdown-toggle.btn-outline.dt-button.fc-today-button,
.dt-buttons .open .dropdown-toggle.btn-outline.dt-button.fc-today-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-success,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-success,
.open
  .tablesaw-sortable
  th.tablesaw-sortable-head
  button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,
.tablesaw-sortable
  th.tablesaw-sortable-head
  .open
  button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open
  .tablesaw-sortable
  th.tablesaw-sortable-head
  button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,
.tablesaw-sortable
  th.tablesaw-sortable-head
  .open
  button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-agendaWeek-button,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-agendaWeek-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-prev-button,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-prev-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-next-button,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-next-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-today-button,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-today-button,
.open .sweet-alert button.dropdown-toggle.btn-outline.btn-success,
.sweet-alert .open button.dropdown-toggle.btn-outline.btn-success,
.open .sweet-alert .note-editor button.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.sweet-alert .note-editor .open button.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.open .note-editor .sweet-alert button.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.note-editor .sweet-alert .open button.dropdown-toggle.note-btn.btn-sm.fc-prev-button,
.open .sweet-alert .note-editor button.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.sweet-alert .note-editor .open button.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.open .note-editor .sweet-alert button.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.note-editor .sweet-alert .open button.dropdown-toggle.note-btn.btn-sm.fc-next-button,
.open .sweet-alert .note-editor button.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.sweet-alert .note-editor .open button.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.open .note-editor .sweet-alert button.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.note-editor .sweet-alert .open button.dropdown-toggle.note-btn.btn-sm.fc-today-button,
.open .sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-success,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-success,
.open .tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.dropdown-toggle.btn-success,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert .open button.dropdown-toggle.btn-success,
.open .sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-prev-button,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-prev-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.dropdown-toggle.fc-prev-button,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert .open button.dropdown-toggle.fc-prev-button,
.open .sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-next-button,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-next-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.dropdown-toggle.fc-next-button,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert .open button.dropdown-toggle.fc-next-button,
.open .sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-today-button,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-today-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.dropdown-toggle.fc-today-button,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert .open button.dropdown-toggle.fc-today-button,
.open .sweet-alert button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,
.sweet-alert .open button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .sweet-alert button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,
.sweet-alert .open button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,
.open .sweet-alert button.dropdown-toggle.fc-agendaWeek-button,
.sweet-alert .open button.dropdown-toggle.fc-agendaWeek-button,
.open .sweet-alert button.dropdown-toggle.btn-outline.fc-prev-button,
.sweet-alert .open button.dropdown-toggle.btn-outline.fc-prev-button,
.open .sweet-alert button.dropdown-toggle.btn-outline.fc-next-button,
.sweet-alert .open button.dropdown-toggle.btn-outline.fc-next-button,
.open .sweet-alert button.dropdown-toggle.btn-outline.fc-today-button,
.sweet-alert .open button.dropdown-toggle.btn-outline.fc-today-button,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline.btn-success[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline.btn-success[class*='owl-'],
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle.btn-success[class*='owl-'].btn,
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-success[class*='owl-'].btn,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle.btn-success[class*='owl-'].btn,
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle.btn-success[class*='owl-'].btn,
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle[class*='owl-'].fc-prev-button,
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle[class*='owl-'].fc-prev-button,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle[class*='owl-'].fc-prev-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-'].fc-prev-button,
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle[class*='owl-'].fc-next-button,
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle[class*='owl-'].fc-next-button,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle[class*='owl-'].fc-next-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-'].fc-next-button,
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle[class*='owl-'].fc-today-button,
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle[class*='owl-'].fc-today-button,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle[class*='owl-'].fc-today-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-'].fc-today-button,
.open .owl-theme .owl-nav .note-editor .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-prev-button,
.owl-theme .owl-nav .note-editor .open .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-prev-button,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-prev-button,
.note-editor .owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-prev-button,
.open .owl-theme .owl-nav .note-editor .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-next-button,
.owl-theme .owl-nav .note-editor .open .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-next-button,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-next-button,
.note-editor .owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-next-button,
.open .owl-theme .owl-nav .note-editor .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-today-button,
.owl-theme .owl-nav .note-editor .open .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-today-button,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-today-button,
.note-editor .owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].note-btn.btn-sm.fc-today-button,
.open
  .owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  button.dropdown-toggle.btn-success[class*='owl-'],
.owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .open
  button.dropdown-toggle.btn-success[class*='owl-'],
.open
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  button.dropdown-toggle.btn-success[class*='owl-'],
.tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  .open
  button.dropdown-toggle.btn-success[class*='owl-'],
.open
  .owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  button.dropdown-toggle[class*='owl-'].fc-prev-button,
.owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .open
  button.dropdown-toggle[class*='owl-'].fc-prev-button,
.open
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  button.dropdown-toggle[class*='owl-'].fc-prev-button,
.tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  .open
  button.dropdown-toggle[class*='owl-'].fc-prev-button,
.open
  .owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  button.dropdown-toggle[class*='owl-'].fc-next-button,
.owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .open
  button.dropdown-toggle[class*='owl-'].fc-next-button,
.open
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  button.dropdown-toggle[class*='owl-'].fc-next-button,
.tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  .open
  button.dropdown-toggle[class*='owl-'].fc-next-button,
.open
  .owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  button.dropdown-toggle[class*='owl-'].fc-today-button,
.owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .open
  button.dropdown-toggle[class*='owl-'].fc-today-button,
.open
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  button.dropdown-toggle[class*='owl-'].fc-today-button,
.tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  .open
  button.dropdown-toggle[class*='owl-'].fc-today-button,
.open .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.open .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button,
.owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].fc-agendaWeek-button,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline[class*='owl-'].fc-prev-button,
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline[class*='owl-'].fc-prev-button,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline[class*='owl-'].fc-next-button,
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline[class*='owl-'].fc-next-button,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline[class*='owl-'].fc-today-button,
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline[class*='owl-'].fc-today-button,
.open button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.fc-prev-button,
.open button.dropdown-toggle.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.fc-prev-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.fc-next-button,
.open button.dropdown-toggle.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.fc-next-button.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.fc-next-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.fc-today-button,
.open button.dropdown-toggle.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.fc-today-button.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.fc-today-button.fc-agendaWeek-button {
  color: #8bc34a;
  border: solid 1px #8bc34a;
}
.open .dropdown-toggle.btn.btn-outline.btn-warning,
.open .wizard > .actions a.dropdown-toggle.btn-outline.btn-warning,
.wizard > .actions .open a.dropdown-toggle.btn-outline.btn-warning,
.open .wizard > .actions .note-editor a.dropdown-toggle.btn-warning.note-btn.btn-sm,
.wizard > .actions .note-editor .open a.dropdown-toggle.btn-warning.note-btn.btn-sm,
.open .note-editor .wizard > .actions a.dropdown-toggle.btn-warning.note-btn.btn-sm,
.note-editor .wizard > .actions .open a.dropdown-toggle.btn-warning.note-btn.btn-sm,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn.btn-warning,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn.btn-warning,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.btn-warning,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.btn-warning,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.btn-warning,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-warning,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.btn-warning.dt-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.btn-warning.dt-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.btn-warning.dt-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-warning.dt-button,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle.btn-warning[class*='owl-'],
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle.btn-warning[class*='owl-'],
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle.btn-warning[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-warning[class*='owl-'],
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-warning.fc-prev-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-warning.fc-prev-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-warning.fc-next-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-warning.fc-next-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-warning.fc-today-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-warning.fc-today-button,
.open .note-editor .dropdown-toggle.btn.btn-warning.note-btn.btn-sm,
.note-editor .open .dropdown-toggle.btn.btn-warning.note-btn.btn-sm,
.open .note-editor .dt-buttons .dropdown-toggle.btn-warning.note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .open .dropdown-toggle.btn-warning.note-btn.btn-sm.dt-button,
.open .dt-buttons .note-editor .dropdown-toggle.btn-warning.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .open .dropdown-toggle.btn-warning.note-btn.btn-sm.dt-button,
.open .note-editor .sweet-alert button.dropdown-toggle.btn-warning.note-btn.btn-sm,
.note-editor .sweet-alert .open button.dropdown-toggle.btn-warning.note-btn.btn-sm,
.open .sweet-alert .note-editor button.dropdown-toggle.btn-warning.note-btn.btn-sm,
.sweet-alert .note-editor .open button.dropdown-toggle.btn-warning.note-btn.btn-sm,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle.btn-warning.note-btn.btn-sm[class*='owl-'],
.note-editor .owl-theme .owl-nav .open .dropdown-toggle.btn-warning.note-btn.btn-sm[class*='owl-'],
.open .owl-theme .owl-nav .note-editor .dropdown-toggle.btn-warning.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .open .dropdown-toggle.btn-warning.note-btn.btn-sm[class*='owl-'],
.open .note-editor button.dropdown-toggle.btn-warning.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .open button.dropdown-toggle.btn-warning.note-btn.btn-sm.fc-agendaWeek-button,
.open .note-editor .dropdown-toggle.btn-warning.note-btn.btn-sm.fc-prev-button,
.note-editor .open .dropdown-toggle.btn-warning.note-btn.btn-sm.fc-prev-button,
.open .note-editor .dropdown-toggle.btn-warning.note-btn.btn-sm.fc-next-button,
.note-editor .open .dropdown-toggle.btn-warning.note-btn.btn-sm.fc-next-button,
.open .note-editor .dropdown-toggle.btn-warning.note-btn.btn-sm.fc-today-button,
.note-editor .open .dropdown-toggle.btn-warning.note-btn.btn-sm.fc-today-button,
.open .dt-buttons .dropdown-toggle.btn-outline.btn-warning.dt-button,
.dt-buttons .open .dropdown-toggle.btn-outline.btn-warning.dt-button,
.open .dt-buttons button.dropdown-toggle.btn-warning.dt-button.fc-agendaWeek-button,
.dt-buttons .open button.dropdown-toggle.btn-warning.dt-button.fc-agendaWeek-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-warning,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-warning,
.open .sweet-alert button.dropdown-toggle.btn-outline.btn-warning,
.sweet-alert .open button.dropdown-toggle.btn-outline.btn-warning,
.open .sweet-alert button.dropdown-toggle.btn-warning.fc-agendaWeek-button,
.sweet-alert .open button.dropdown-toggle.btn-warning.fc-agendaWeek-button,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline.btn-warning[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline.btn-warning[class*='owl-'],
.open .owl-theme .owl-nav button.dropdown-toggle.btn-warning[class*='owl-'].fc-agendaWeek-button,
.owl-theme .owl-nav .open button.dropdown-toggle.btn-warning[class*='owl-'].fc-agendaWeek-button,
.open button.dropdown-toggle.btn-warning.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-warning.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-warning.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-warning.fc-prev-button,
.open button.dropdown-toggle.btn-warning.fc-prev-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-warning.fc-next-button,
.open button.dropdown-toggle.btn-warning.fc-next-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-warning.fc-today-button,
.open button.dropdown-toggle.btn-warning.fc-today-button.fc-agendaWeek-button {
  color: #f8b32d;
  border: solid 1px #f8b32d;
}
.open .dropdown-toggle.btn.btn-outline.btn-info,
.open .wizard > .actions a.dropdown-toggle.btn-outline.btn-info,
.wizard > .actions .open a.dropdown-toggle.btn-outline.btn-info,
.open .wizard > .actions .note-editor a.dropdown-toggle.btn-info.note-btn.btn-sm,
.wizard > .actions .note-editor .open a.dropdown-toggle.btn-info.note-btn.btn-sm,
.open .note-editor .wizard > .actions a.dropdown-toggle.btn-info.note-btn.btn-sm,
.note-editor .wizard > .actions .open a.dropdown-toggle.btn-info.note-btn.btn-sm,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn.btn-info,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn.btn-info,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.btn-info,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.btn-info,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.btn-info,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-info,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.btn-info.dt-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.btn-info.dt-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.btn-info.dt-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-info.dt-button,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle.btn-info[class*='owl-'],
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle.btn-info[class*='owl-'],
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle.btn-info[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-info[class*='owl-'],
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-info.fc-prev-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-info.fc-prev-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-info.fc-next-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-info.fc-next-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-info.fc-today-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-info.fc-today-button,
.open .note-editor .dropdown-toggle.btn.btn-info.note-btn.btn-sm,
.note-editor .open .dropdown-toggle.btn.btn-info.note-btn.btn-sm,
.open .note-editor .dt-buttons .dropdown-toggle.btn-info.note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .open .dropdown-toggle.btn-info.note-btn.btn-sm.dt-button,
.open .dt-buttons .note-editor .dropdown-toggle.btn-info.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .open .dropdown-toggle.btn-info.note-btn.btn-sm.dt-button,
.open .note-editor .sweet-alert button.dropdown-toggle.btn-info.note-btn.btn-sm,
.note-editor .sweet-alert .open button.dropdown-toggle.btn-info.note-btn.btn-sm,
.open .sweet-alert .note-editor button.dropdown-toggle.btn-info.note-btn.btn-sm,
.sweet-alert .note-editor .open button.dropdown-toggle.btn-info.note-btn.btn-sm,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle.btn-info.note-btn.btn-sm[class*='owl-'],
.note-editor .owl-theme .owl-nav .open .dropdown-toggle.btn-info.note-btn.btn-sm[class*='owl-'],
.open .owl-theme .owl-nav .note-editor .dropdown-toggle.btn-info.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .open .dropdown-toggle.btn-info.note-btn.btn-sm[class*='owl-'],
.open .note-editor button.dropdown-toggle.btn-info.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .open button.dropdown-toggle.btn-info.note-btn.btn-sm.fc-agendaWeek-button,
.open .note-editor .dropdown-toggle.btn-info.note-btn.btn-sm.fc-prev-button,
.note-editor .open .dropdown-toggle.btn-info.note-btn.btn-sm.fc-prev-button,
.open .note-editor .dropdown-toggle.btn-info.note-btn.btn-sm.fc-next-button,
.note-editor .open .dropdown-toggle.btn-info.note-btn.btn-sm.fc-next-button,
.open .note-editor .dropdown-toggle.btn-info.note-btn.btn-sm.fc-today-button,
.note-editor .open .dropdown-toggle.btn-info.note-btn.btn-sm.fc-today-button,
.open .dt-buttons .dropdown-toggle.btn-outline.btn-info.dt-button,
.dt-buttons .open .dropdown-toggle.btn-outline.btn-info.dt-button,
.open .dt-buttons button.dropdown-toggle.btn-info.dt-button.fc-agendaWeek-button,
.dt-buttons .open button.dropdown-toggle.btn-info.dt-button.fc-agendaWeek-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-info,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-info,
.open .sweet-alert button.dropdown-toggle.btn-outline.btn-info,
.sweet-alert .open button.dropdown-toggle.btn-outline.btn-info,
.open .sweet-alert button.dropdown-toggle.btn-info.fc-agendaWeek-button,
.sweet-alert .open button.dropdown-toggle.btn-info.fc-agendaWeek-button,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline.btn-info[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline.btn-info[class*='owl-'],
.open .owl-theme .owl-nav button.dropdown-toggle.btn-info[class*='owl-'].fc-agendaWeek-button,
.owl-theme .owl-nav .open button.dropdown-toggle.btn-info[class*='owl-'].fc-agendaWeek-button,
.open button.dropdown-toggle.btn-info.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-info.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-info.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-info.fc-prev-button,
.open button.dropdown-toggle.btn-info.fc-prev-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-info.fc-next-button,
.open button.dropdown-toggle.btn-info.fc-next-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-info.fc-today-button,
.open button.dropdown-toggle.btn-info.fc-today-button.fc-agendaWeek-button {
  color: #e7368d;
  border: solid 1px #e7368d;
}
.open .dropdown-toggle.btn.btn-outline.btn-danger,
.open .wizard > .actions a.dropdown-toggle.btn-outline.btn-danger,
.wizard > .actions .open a.dropdown-toggle.btn-outline.btn-danger,
.open .wizard > .actions .note-editor a.dropdown-toggle.btn-danger.note-btn.btn-sm,
.wizard > .actions .note-editor .open a.dropdown-toggle.btn-danger.note-btn.btn-sm,
.open .note-editor .wizard > .actions a.dropdown-toggle.btn-danger.note-btn.btn-sm,
.note-editor .wizard > .actions .open a.dropdown-toggle.btn-danger.note-btn.btn-sm,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn.btn-danger,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn.btn-danger,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.btn-danger,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.btn-danger,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.btn-danger,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-danger,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.btn-danger.dt-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.btn-danger.dt-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.btn-danger.dt-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-danger.dt-button,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle.btn-danger[class*='owl-'],
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle.btn-danger[class*='owl-'],
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle.btn-danger[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-danger[class*='owl-'],
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-danger.fc-prev-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-danger.fc-prev-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-danger.fc-next-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-danger.fc-next-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-danger.fc-today-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-danger.fc-today-button,
.open .note-editor .dropdown-toggle.btn.btn-danger.note-btn.btn-sm,
.note-editor .open .dropdown-toggle.btn.btn-danger.note-btn.btn-sm,
.open .note-editor .dt-buttons .dropdown-toggle.btn-danger.note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .open .dropdown-toggle.btn-danger.note-btn.btn-sm.dt-button,
.open .dt-buttons .note-editor .dropdown-toggle.btn-danger.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .open .dropdown-toggle.btn-danger.note-btn.btn-sm.dt-button,
.open .note-editor .sweet-alert button.dropdown-toggle.btn-danger.note-btn.btn-sm,
.note-editor .sweet-alert .open button.dropdown-toggle.btn-danger.note-btn.btn-sm,
.open .sweet-alert .note-editor button.dropdown-toggle.btn-danger.note-btn.btn-sm,
.sweet-alert .note-editor .open button.dropdown-toggle.btn-danger.note-btn.btn-sm,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle.btn-danger.note-btn.btn-sm[class*='owl-'],
.note-editor .owl-theme .owl-nav .open .dropdown-toggle.btn-danger.note-btn.btn-sm[class*='owl-'],
.open .owl-theme .owl-nav .note-editor .dropdown-toggle.btn-danger.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .open .dropdown-toggle.btn-danger.note-btn.btn-sm[class*='owl-'],
.open .note-editor button.dropdown-toggle.btn-danger.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .open button.dropdown-toggle.btn-danger.note-btn.btn-sm.fc-agendaWeek-button,
.open .note-editor .dropdown-toggle.btn-danger.note-btn.btn-sm.fc-prev-button,
.note-editor .open .dropdown-toggle.btn-danger.note-btn.btn-sm.fc-prev-button,
.open .note-editor .dropdown-toggle.btn-danger.note-btn.btn-sm.fc-next-button,
.note-editor .open .dropdown-toggle.btn-danger.note-btn.btn-sm.fc-next-button,
.open .note-editor .dropdown-toggle.btn-danger.note-btn.btn-sm.fc-today-button,
.note-editor .open .dropdown-toggle.btn-danger.note-btn.btn-sm.fc-today-button,
.open .dt-buttons .dropdown-toggle.btn-outline.btn-danger.dt-button,
.dt-buttons .open .dropdown-toggle.btn-outline.btn-danger.dt-button,
.open .dt-buttons button.dropdown-toggle.btn-danger.dt-button.fc-agendaWeek-button,
.dt-buttons .open button.dropdown-toggle.btn-danger.dt-button.fc-agendaWeek-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-danger,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-danger,
.open .sweet-alert button.dropdown-toggle.btn-outline.btn-danger,
.sweet-alert .open button.dropdown-toggle.btn-outline.btn-danger,
.open .sweet-alert button.dropdown-toggle.btn-danger.fc-agendaWeek-button,
.sweet-alert .open button.dropdown-toggle.btn-danger.fc-agendaWeek-button,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline.btn-danger[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline.btn-danger[class*='owl-'],
.open .owl-theme .owl-nav button.dropdown-toggle.btn-danger[class*='owl-'].fc-agendaWeek-button,
.owl-theme .owl-nav .open button.dropdown-toggle.btn-danger[class*='owl-'].fc-agendaWeek-button,
.open button.dropdown-toggle.btn-danger.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-danger.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-danger.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-danger.fc-prev-button,
.open button.dropdown-toggle.btn-danger.fc-prev-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-danger.fc-next-button,
.open button.dropdown-toggle.btn-danger.fc-next-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-danger.fc-today-button,
.open button.dropdown-toggle.btn-danger.fc-today-button.fc-agendaWeek-button {
  color: #f33923;
  border: solid 1px #f33923;
}
.open .dropdown-toggle.btn.btn-outline.btn-primary,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle,
.open .wizard > .actions ul.wysihtml5-toolbar .disabled a.dropdown-toggle,
.wizard > .actions ul.wysihtml5-toolbar .disabled .open a.dropdown-toggle,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline:hover,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline:hover,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle:hover,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle:hover,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle:hover,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle:hover,
.open .wizard > .actions ul.wysihtml5-toolbar .disabled a.dropdown-toggle:hover,
.wizard > .actions ul.wysihtml5-toolbar .disabled .open a.dropdown-toggle:hover,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm:hover,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm:hover,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm:hover,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm:hover,
.open .wizard > .actions .disabled a.dropdown-toggle.btn-outline:active,
.wizard > .actions .disabled .open a.dropdown-toggle.btn-outline:active,
.open ul.wysihtml5-toolbar .wizard > .actions .disabled a.dropdown-toggle:active,
ul.wysihtml5-toolbar .wizard > .actions .disabled .open a.dropdown-toggle:active,
.open .wizard > .actions .disabled ul.wysihtml5-toolbar a.dropdown-toggle:active,
.wizard > .actions .disabled ul.wysihtml5-toolbar .open a.dropdown-toggle:active,
.open .wizard > .actions ul.wysihtml5-toolbar .disabled a.dropdown-toggle:active,
.wizard > .actions ul.wysihtml5-toolbar .disabled .open a.dropdown-toggle:active,
.open .wizard > .actions .disabled .note-editor a.dropdown-toggle.note-btn.btn-sm:active,
.wizard > .actions .disabled .note-editor .open a.dropdown-toggle.note-btn.btn-sm:active,
.open .note-editor .wizard > .actions .disabled a.dropdown-toggle.note-btn.btn-sm:active,
.note-editor .wizard > .actions .disabled .open a.dropdown-toggle.note-btn.btn-sm:active,
.open .wizard > .actions a.dropdown-toggle.btn-outline,
.wizard > .actions .open a.dropdown-toggle.btn-outline,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm,
.open .wizard > .actions a.dropdown-toggle.btn-outline:hover,
.wizard > .actions .open a.dropdown-toggle.btn-outline:hover,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle:hover,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle:hover,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle:hover,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle:hover,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm:hover,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm:hover,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm:hover,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm:hover,
.open .wizard > .actions a.dropdown-toggle.btn-outline:active,
.wizard > .actions .open a.dropdown-toggle.btn-outline:active,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle:active,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle:active,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle:active,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle:active,
.open .wizard > .actions .note-editor a.dropdown-toggle.note-btn.btn-sm:active,
.wizard > .actions .note-editor .open a.dropdown-toggle.note-btn.btn-sm:active,
.open .note-editor .wizard > .actions a.dropdown-toggle.note-btn.btn-sm:active,
.note-editor .wizard > .actions .open a.dropdown-toggle.note-btn.btn-sm:active,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn.btn-primary,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn.btn-primary,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.dt-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.dt-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.dt-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.dt-button,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle.btn-primary[class*='owl-'],
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle.btn-primary[class*='owl-'],
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle.btn-primary[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-primary[class*='owl-'],
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-primary.fc-prev-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-primary.fc-prev-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-primary.fc-next-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-primary.fc-next-button,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-primary.fc-today-button,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-primary.fc-today-button,
.open .note-editor .dropdown-toggle.btn.btn-primary.note-btn.btn-sm,
.note-editor .open .dropdown-toggle.btn.btn-primary.note-btn.btn-sm,
.open .note-editor .dt-buttons .dropdown-toggle.note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .open .dropdown-toggle.note-btn.btn-sm.dt-button,
.open .dt-buttons .note-editor .dropdown-toggle.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .open .dropdown-toggle.note-btn.btn-sm.dt-button,
.open .note-editor .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-primary.note-btn.btn-sm,
.note-editor .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-primary.note-btn.btn-sm,
.open .tablesaw-sortable th.tablesaw-sortable-head .note-editor button.dropdown-toggle.btn-primary.note-btn.btn-sm,
.tablesaw-sortable th.tablesaw-sortable-head .note-editor .open button.dropdown-toggle.btn-primary.note-btn.btn-sm,
.open .note-editor .sweet-alert button.dropdown-toggle.btn-primary.note-btn.btn-sm,
.note-editor .sweet-alert .open button.dropdown-toggle.btn-primary.note-btn.btn-sm,
.open .sweet-alert .note-editor button.dropdown-toggle.btn-primary.note-btn.btn-sm,
.sweet-alert .note-editor .open button.dropdown-toggle.btn-primary.note-btn.btn-sm,
.open .note-editor .owl-theme .owl-nav .dropdown-toggle.btn-primary.note-btn.btn-sm[class*='owl-'],
.note-editor .owl-theme .owl-nav .open .dropdown-toggle.btn-primary.note-btn.btn-sm[class*='owl-'],
.open .owl-theme .owl-nav .note-editor .dropdown-toggle.btn-primary.note-btn.btn-sm[class*='owl-'],
.owl-theme .owl-nav .note-editor .open .dropdown-toggle.btn-primary.note-btn.btn-sm[class*='owl-'],
.open
  .note-editor
  button.dropdown-toggle.btn-primary.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,
.note-editor
  .open
  button.dropdown-toggle.btn-primary.note-btn.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .note-editor button.dropdown-toggle.btn-primary.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left,
.note-editor .open button.dropdown-toggle.btn-primary.note-btn.btn-sm.fc-month-button.fc-state-default.fc-corner-left,
.open .note-editor button.dropdown-toggle.btn-primary.note-btn.btn-sm.fc-agendaWeek-button,
.note-editor .open button.dropdown-toggle.btn-primary.note-btn.btn-sm.fc-agendaWeek-button,
.open .note-editor .dropdown-toggle.btn-primary.note-btn.btn-sm.fc-prev-button,
.note-editor .open .dropdown-toggle.btn-primary.note-btn.btn-sm.fc-prev-button,
.open .note-editor .dropdown-toggle.btn-primary.note-btn.btn-sm.fc-next-button,
.note-editor .open .dropdown-toggle.btn-primary.note-btn.btn-sm.fc-next-button,
.open .note-editor .dropdown-toggle.btn-primary.note-btn.btn-sm.fc-today-button,
.note-editor .open .dropdown-toggle.btn-primary.note-btn.btn-sm.fc-today-button,
.open .dt-buttons .dropdown-toggle.btn-outline.dt-button,
.dt-buttons .open .dropdown-toggle.btn-outline.dt-button,
.open .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.dt-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dropdown-toggle.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .open button.dropdown-toggle.dt-button,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.open .dt-buttons button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-primary,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-primary,
.open .sweet-alert button.dropdown-toggle.btn-outline.btn-primary,
.sweet-alert .open button.dropdown-toggle.btn-outline.btn-primary,
.open .sweet-alert .note-editor .dt-buttons button.dropdown-toggle.note-btn.btn-sm.dt-button,
.sweet-alert .note-editor .dt-buttons .open button.dropdown-toggle.note-btn.btn-sm.dt-button,
.open .note-editor .dt-buttons .sweet-alert button.dropdown-toggle.note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .sweet-alert .open button.dropdown-toggle.note-btn.btn-sm.dt-button,
.open .sweet-alert .dt-buttons .note-editor button.dropdown-toggle.note-btn.btn-sm.dt-button,
.sweet-alert .dt-buttons .note-editor .open button.dropdown-toggle.note-btn.btn-sm.dt-button,
.open .dt-buttons .note-editor .sweet-alert button.dropdown-toggle.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .sweet-alert .open button.dropdown-toggle.note-btn.btn-sm.dt-button,
.open .sweet-alert .dt-buttons button.dropdown-toggle.btn-outline.dt-button,
.sweet-alert .dt-buttons .open button.dropdown-toggle.btn-outline.dt-button,
.open .dt-buttons .sweet-alert button.dropdown-toggle.btn-outline.dt-button,
.dt-buttons .sweet-alert .open button.dropdown-toggle.btn-outline.dt-button,
.open .sweet-alert .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.dt-button,
.sweet-alert .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.dt-button,
.open .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.dropdown-toggle.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .sweet-alert .open button.dropdown-toggle.dt-button,
.open .sweet-alert .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dropdown-toggle.dt-button,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .open button.dropdown-toggle.dt-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .sweet-alert button.dropdown-toggle.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .sweet-alert .open button.dropdown-toggle.dt-button,
.open .sweet-alert .dt-buttons button.dropdown-toggle.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.sweet-alert .dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .dt-buttons .sweet-alert button.dropdown-toggle.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons .sweet-alert .open button.dropdown-toggle.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .sweet-alert .dt-buttons button.dropdown-toggle.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.sweet-alert .dt-buttons .open button.dropdown-toggle.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.open .dt-buttons .sweet-alert button.dropdown-toggle.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons .sweet-alert .open button.dropdown-toggle.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.open .sweet-alert .dt-buttons button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.sweet-alert .dt-buttons .open button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.open .dt-buttons .sweet-alert button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.dt-buttons .sweet-alert .open button.dropdown-toggle.dt-button.fc-agendaWeek-button,
.open .sweet-alert .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-primary,
.sweet-alert .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-primary,
.open .tablesaw-sortable th.tablesaw-sortable-head .sweet-alert button.dropdown-toggle.btn-primary,
.tablesaw-sortable th.tablesaw-sortable-head .sweet-alert .open button.dropdown-toggle.btn-primary,
.open .sweet-alert button.dropdown-toggle.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right,
.sweet-alert .open button.dropdown-toggle.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .sweet-alert button.dropdown-toggle.btn-primary.fc-month-button.fc-state-default.fc-corner-left,
.sweet-alert .open button.dropdown-toggle.btn-primary.fc-month-button.fc-state-default.fc-corner-left,
.open .sweet-alert button.dropdown-toggle.btn-primary.fc-agendaWeek-button,
.sweet-alert .open button.dropdown-toggle.btn-primary.fc-agendaWeek-button,
.open .owl-theme .owl-nav .dropdown-toggle.btn-outline.btn-primary[class*='owl-'],
.owl-theme .owl-nav .open .dropdown-toggle.btn-outline.btn-primary[class*='owl-'],
.open .owl-theme .owl-nav .wizard > .actions a.dropdown-toggle.btn-outline[class*='owl-'],
.owl-theme .owl-nav .wizard > .actions .open a.dropdown-toggle.btn-outline[class*='owl-'],
.open .wizard > .actions .owl-theme .owl-nav a.dropdown-toggle.btn-outline[class*='owl-'],
.wizard > .actions .owl-theme .owl-nav .open a.dropdown-toggle.btn-outline[class*='owl-'],
.open .owl-theme .owl-nav .wizard > .actions .note-editor a.dropdown-toggle[class*='owl-'].note-btn.btn-sm,
.owl-theme .owl-nav .wizard > .actions .note-editor .open a.dropdown-toggle[class*='owl-'].note-btn.btn-sm,
.open .wizard > .actions .note-editor .owl-theme .owl-nav a.dropdown-toggle[class*='owl-'].note-btn.btn-sm,
.wizard > .actions .note-editor .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-'].note-btn.btn-sm,
.open .owl-theme .owl-nav .note-editor .wizard > .actions a.dropdown-toggle[class*='owl-'].note-btn.btn-sm,
.owl-theme .owl-nav .note-editor .wizard > .actions .open a.dropdown-toggle[class*='owl-'].note-btn.btn-sm,
.open .note-editor .wizard > .actions .owl-theme .owl-nav a.dropdown-toggle[class*='owl-'].note-btn.btn-sm,
.note-editor .wizard > .actions .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-'].note-btn.btn-sm,
.open .owl-theme .owl-nav ul.wysihtml5-toolbar a.dropdown-toggle.btn-primary[class*='owl-'].btn,
.owl-theme .owl-nav ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-primary[class*='owl-'].btn,
.open ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle.btn-primary[class*='owl-'].btn,
ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle.btn-primary[class*='owl-'].btn,
.open .owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle[class*='owl-'],
.open ul.wysihtml5-toolbar .wizard > .actions .owl-theme .owl-nav a.dropdown-toggle[class*='owl-'],
ul.wysihtml5-toolbar .wizard > .actions .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-'],
.open .owl-theme .owl-nav .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle[class*='owl-'],
.owl-theme .owl-nav .wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle[class*='owl-'],
.open .wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle[class*='owl-'],
.wizard > .actions ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-'],
.open .owl-theme .owl-nav ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle[class*='owl-'].dt-button,
.owl-theme .owl-nav ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle[class*='owl-'].dt-button,
.open ul.wysihtml5-toolbar .dt-buttons .owl-theme .owl-nav a.dropdown-toggle[class*='owl-'].dt-button,
ul.wysihtml5-toolbar .dt-buttons .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-'].dt-button,
.open .owl-theme .owl-nav .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle[class*='owl-'].dt-button,
.owl-theme .owl-nav .dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle[class*='owl-'].dt-button,
.open .dt-buttons ul.wysihtml5-toolbar .owl-theme .owl-nav a.dropdown-toggle[class*='owl-'].dt-button,
.dt-buttons ul.wysihtml5-toolbar .owl-theme .owl-nav .open a.dropdown-toggle[class*='owl-'].dt-button,
.open .owl-theme .owl-nav .note-editor .dt-buttons .dropdown-toggle[class*='owl-'].note-btn.btn-sm.dt-button,
.owl-theme .owl-nav .note-editor .dt-buttons .open .dropdown-toggle[class*='owl-'].note-btn.btn-sm.dt-button,
.open .note-editor .dt-buttons .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].note-btn.btn-sm.dt-button,
.open .owl-theme .owl-nav .dt-buttons .note-editor .dropdown-toggle[class*='owl-'].note-btn.btn-sm.dt-button,
.owl-theme .owl-nav .dt-buttons .note-editor .open .dropdown-toggle[class*='owl-'].note-btn.btn-sm.dt-button,
.open .dt-buttons .note-editor .owl-theme .owl-nav .dropdown-toggle[class*='owl-'].note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .owl-theme .owl-nav .open .dropdown-toggle[class*='owl-'].note-btn.btn-sm.dt-button,
.open .owl-theme .owl-nav .dt-buttons .dropdown-toggle.btn-outline[class*='owl-'].dt-button,
.owl-theme .owl-nav .dt-buttons .open .dropdown-toggle.btn-outline[class*='owl-'].dt-button,
.open .dt-buttons .owl-theme .owl-nav .dropdown-toggle.btn-outline[class*='owl-'].dt-button,
.dt-buttons .owl-theme .owl-nav .open .dropdown-toggle.btn-outline[class*='owl-'].dt-button,
.open
  .owl-theme
  .owl-nav
  .dt-buttons
  .tablesaw-sortable
  th.tablesaw-sortable-head
  button.dropdown-toggle[class*='owl-'].dt-button,
.owl-theme
  .owl-nav
  .dt-buttons
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .open
  button.dropdown-toggle[class*='owl-'].dt-button,
.open
  .dt-buttons
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  button.dropdown-toggle[class*='owl-'].dt-button,
.dt-buttons
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  .open
  button.dropdown-toggle[class*='owl-'].dt-button,
.open
  .owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .dt-buttons
  button.dropdown-toggle[class*='owl-'].dt-button,
.owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .dt-buttons
  .open
  button.dropdown-toggle[class*='owl-'].dt-button,
.open
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .dt-buttons
  .owl-theme
  .owl-nav
  button.dropdown-toggle[class*='owl-'].dt-button,
.tablesaw-sortable
  th.tablesaw-sortable-head
  .dt-buttons
  .owl-theme
  .owl-nav
  .open
  button.dropdown-toggle[class*='owl-'].dt-button,
.open
  .owl-theme
  .owl-nav
  .dt-buttons
  button.dropdown-toggle[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme
  .owl-nav
  .dt-buttons
  .open
  button.dropdown-toggle[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open
  .dt-buttons
  .owl-theme
  .owl-nav
  button.dropdown-toggle[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons
  .owl-theme
  .owl-nav
  .open
  button.dropdown-toggle[class*='owl-'].dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open
  .owl-theme
  .owl-nav
  .dt-buttons
  button.dropdown-toggle[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left,
.owl-theme
  .owl-nav
  .dt-buttons
  .open
  button.dropdown-toggle[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left,
.open
  .dt-buttons
  .owl-theme
  .owl-nav
  button.dropdown-toggle[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons
  .owl-theme
  .owl-nav
  .open
  button.dropdown-toggle[class*='owl-'].dt-button.fc-month-button.fc-state-default.fc-corner-left,
.open .owl-theme .owl-nav .dt-buttons button.dropdown-toggle[class*='owl-'].dt-button.fc-agendaWeek-button,
.owl-theme .owl-nav .dt-buttons .open button.dropdown-toggle[class*='owl-'].dt-button.fc-agendaWeek-button,
.open .dt-buttons .owl-theme .owl-nav button.dropdown-toggle[class*='owl-'].dt-button.fc-agendaWeek-button,
.dt-buttons .owl-theme .owl-nav .open button.dropdown-toggle[class*='owl-'].dt-button.fc-agendaWeek-button,
.open
  .owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  button.dropdown-toggle.btn-primary[class*='owl-'],
.owl-theme
  .owl-nav
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .open
  button.dropdown-toggle.btn-primary[class*='owl-'],
.open
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  button.dropdown-toggle.btn-primary[class*='owl-'],
.tablesaw-sortable
  th.tablesaw-sortable-head
  .owl-theme
  .owl-nav
  .open
  button.dropdown-toggle.btn-primary[class*='owl-'],
.open
  .owl-theme
  .owl-nav
  button.dropdown-toggle.btn-primary[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.owl-theme
  .owl-nav
  .open
  button.dropdown-toggle.btn-primary[class*='owl-'].fc-agendaDay-button.fc-state-default.fc-corner-right,
.open
  .owl-theme
  .owl-nav
  button.dropdown-toggle.btn-primary[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.owl-theme
  .owl-nav
  .open
  button.dropdown-toggle.btn-primary[class*='owl-'].fc-month-button.fc-state-default.fc-corner-left,
.open .owl-theme .owl-nav button.dropdown-toggle.btn-primary[class*='owl-'].fc-agendaWeek-button,
.owl-theme .owl-nav .open button.dropdown-toggle.btn-primary[class*='owl-'].fc-agendaWeek-button,
.open button.dropdown-toggle.btn-primary.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-primary.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-primary.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-primary.fc-prev-button,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-prev-button,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-prev-button,
.open .wizard > .actions .note-editor a.dropdown-toggle.fc-prev-button.note-btn.btn-sm,
.wizard > .actions .note-editor .open a.dropdown-toggle.fc-prev-button.note-btn.btn-sm,
.open .note-editor .wizard > .actions a.dropdown-toggle.fc-prev-button.note-btn.btn-sm,
.note-editor .wizard > .actions .open a.dropdown-toggle.fc-prev-button.note-btn.btn-sm,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-primary.fc-prev-button.btn,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-primary.fc-prev-button.btn,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-prev-button,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-prev-button,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.fc-prev-button.dt-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.fc-prev-button.dt-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.fc-prev-button.dt-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-prev-button.dt-button,
.open .note-editor .dt-buttons .dropdown-toggle.fc-prev-button.note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .open .dropdown-toggle.fc-prev-button.note-btn.btn-sm.dt-button,
.open .dt-buttons .note-editor .dropdown-toggle.fc-prev-button.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .open .dropdown-toggle.fc-prev-button.note-btn.btn-sm.dt-button,
.open .dt-buttons .dropdown-toggle.btn-outline.fc-prev-button.dt-button,
.dt-buttons .open .dropdown-toggle.btn-outline.fc-prev-button.dt-button,
.open .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-prev-button.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-prev-button.dt-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dropdown-toggle.fc-prev-button.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .open button.dropdown-toggle.fc-prev-button.dt-button,
.open .dt-buttons button.dropdown-toggle.fc-prev-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons .open button.dropdown-toggle.fc-prev-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .dt-buttons button.dropdown-toggle.fc-prev-button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons .open button.dropdown-toggle.fc-prev-button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.open .dt-buttons button.dropdown-toggle.fc-prev-button.dt-button.fc-agendaWeek-button,
.dt-buttons .open button.dropdown-toggle.fc-prev-button.dt-button.fc-agendaWeek-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-primary.fc-prev-button,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-primary.fc-prev-button,
.open button.dropdown-toggle.btn-primary.fc-prev-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-primary.fc-prev-button.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-primary.fc-prev-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-primary.fc-next-button,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-next-button,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-next-button,
.open .wizard > .actions .note-editor a.dropdown-toggle.fc-next-button.note-btn.btn-sm,
.wizard > .actions .note-editor .open a.dropdown-toggle.fc-next-button.note-btn.btn-sm,
.open .note-editor .wizard > .actions a.dropdown-toggle.fc-next-button.note-btn.btn-sm,
.note-editor .wizard > .actions .open a.dropdown-toggle.fc-next-button.note-btn.btn-sm,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-primary.fc-next-button.btn,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-primary.fc-next-button.btn,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-next-button,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-next-button,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.fc-next-button.dt-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.fc-next-button.dt-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.fc-next-button.dt-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-next-button.dt-button,
.open .note-editor .dt-buttons .dropdown-toggle.fc-next-button.note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .open .dropdown-toggle.fc-next-button.note-btn.btn-sm.dt-button,
.open .dt-buttons .note-editor .dropdown-toggle.fc-next-button.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .open .dropdown-toggle.fc-next-button.note-btn.btn-sm.dt-button,
.open .dt-buttons .dropdown-toggle.btn-outline.fc-next-button.dt-button,
.dt-buttons .open .dropdown-toggle.btn-outline.fc-next-button.dt-button,
.open .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-next-button.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-next-button.dt-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dropdown-toggle.fc-next-button.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .open button.dropdown-toggle.fc-next-button.dt-button,
.open .dt-buttons button.dropdown-toggle.fc-next-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons .open button.dropdown-toggle.fc-next-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .dt-buttons button.dropdown-toggle.fc-next-button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons .open button.dropdown-toggle.fc-next-button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.open .dt-buttons button.dropdown-toggle.fc-next-button.dt-button.fc-agendaWeek-button,
.dt-buttons .open button.dropdown-toggle.fc-next-button.dt-button.fc-agendaWeek-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-primary.fc-next-button,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-primary.fc-next-button,
.open button.dropdown-toggle.btn-primary.fc-next-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-primary.fc-next-button.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-primary.fc-next-button.fc-agendaWeek-button,
.open .dropdown-toggle.btn-outline.btn-primary.fc-today-button,
.open .wizard > .actions a.dropdown-toggle.btn-outline.fc-today-button,
.wizard > .actions .open a.dropdown-toggle.btn-outline.fc-today-button,
.open .wizard > .actions .note-editor a.dropdown-toggle.fc-today-button.note-btn.btn-sm,
.wizard > .actions .note-editor .open a.dropdown-toggle.fc-today-button.note-btn.btn-sm,
.open .note-editor .wizard > .actions a.dropdown-toggle.fc-today-button.note-btn.btn-sm,
.note-editor .wizard > .actions .open a.dropdown-toggle.fc-today-button.note-btn.btn-sm,
.open ul.wysihtml5-toolbar a.dropdown-toggle.btn-primary.fc-today-button.btn,
ul.wysihtml5-toolbar .open a.dropdown-toggle.btn-primary.fc-today-button.btn,
.open ul.wysihtml5-toolbar .wizard > .actions a.dropdown-toggle.fc-today-button,
ul.wysihtml5-toolbar .wizard > .actions .open a.dropdown-toggle.fc-today-button,
.open .wizard > .actions ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button,
.wizard > .actions ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button,
.open ul.wysihtml5-toolbar .dt-buttons a.dropdown-toggle.fc-today-button.dt-button,
ul.wysihtml5-toolbar .dt-buttons .open a.dropdown-toggle.fc-today-button.dt-button,
.open .dt-buttons ul.wysihtml5-toolbar a.dropdown-toggle.fc-today-button.dt-button,
.dt-buttons ul.wysihtml5-toolbar .open a.dropdown-toggle.fc-today-button.dt-button,
.open .note-editor .dt-buttons .dropdown-toggle.fc-today-button.note-btn.btn-sm.dt-button,
.note-editor .dt-buttons .open .dropdown-toggle.fc-today-button.note-btn.btn-sm.dt-button,
.open .dt-buttons .note-editor .dropdown-toggle.fc-today-button.note-btn.btn-sm.dt-button,
.dt-buttons .note-editor .open .dropdown-toggle.fc-today-button.note-btn.btn-sm.dt-button,
.open .dt-buttons .dropdown-toggle.btn-outline.fc-today-button.dt-button,
.dt-buttons .open .dropdown-toggle.btn-outline.fc-today-button.dt-button,
.open .dt-buttons .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.fc-today-button.dt-button,
.dt-buttons .tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.fc-today-button.dt-button,
.open .tablesaw-sortable th.tablesaw-sortable-head .dt-buttons button.dropdown-toggle.fc-today-button.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .dt-buttons .open button.dropdown-toggle.fc-today-button.dt-button,
.open .dt-buttons button.dropdown-toggle.fc-today-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.dt-buttons .open button.dropdown-toggle.fc-today-button.dt-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open .dt-buttons button.dropdown-toggle.fc-today-button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.dt-buttons .open button.dropdown-toggle.fc-today-button.dt-button.fc-month-button.fc-state-default.fc-corner-left,
.open .dt-buttons button.dropdown-toggle.fc-today-button.dt-button.fc-agendaWeek-button,
.dt-buttons .open button.dropdown-toggle.fc-today-button.dt-button.fc-agendaWeek-button,
.open .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle.btn-primary.fc-today-button,
.tablesaw-sortable th.tablesaw-sortable-head .open button.dropdown-toggle.btn-primary.fc-today-button,
.open button.dropdown-toggle.btn-primary.fc-today-button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.open button.dropdown-toggle.btn-primary.fc-today-button.fc-month-button.fc-state-default.fc-corner-left,
.open button.dropdown-toggle.btn-primary.fc-today-button.fc-agendaWeek-button {
  color: #2196f3;
  border: solid 1px #2196f3;
}

.button-list .btn,
.button-list .wizard > .actions a,
.wizard > .actions .button-list a,
.button-list .dt-buttons .dt-button,
.dt-buttons .button-list .dt-button,
.button-list .tablesaw-sortable th.tablesaw-sortable-head button,
.tablesaw-sortable th.tablesaw-sortable-head .button-list button,
.button-list .sweet-alert button,
.sweet-alert .button-list button,
.button-list .owl-theme .owl-nav [class*='owl-'],
.owl-theme .owl-nav .button-list [class*='owl-'],
.button-list button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.button-list button.fc-month-button.fc-state-default.fc-corner-left,
.button-list button.fc-agendaWeek-button,
.button-list .fc-prev-button,
.button-list .fc-next-button,
.button-list .fc-today-button {
  margin-top: 15px;
  margin-right: 10px;
}

/*MDL Shadow*/
.shadow-2dp,
#setting_panel_btn.shadow-2dp:focus,
#setting_panel_btn.shadow-2dp:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.shadow-3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.shadow-4dp,
#setting_panel_btn.shadow-4dp:focus,
#setting_panel_btn.shadow-4dp:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.shadow-6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.shadow-8dp,
#setting_panel_btn.shadow-8dp:focus,
#setting_panel_btn.shadow-8dp:active {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.fixed-sidebar-left .right-nav-text {
  cursor: pointer;
}

/* Admin Basic */
.fixed-sidebar-left {
  position: fixed;
  top: 66px;
  left: 0;
  width: 225px;
  margin-left: 0;
  bottom: 0;
  z-index: 100;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.35);
  border-right: 1px solid #dedede;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.fixed-sidebar-left .right-nav-text {
  opacity: 1;
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  transition: opacity 0.2s ease 0s;
}
.fixed-sidebar-left .side-nav {
  border: none;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 0;
  margin: 0;
  overflow-y: auto;
  background-color: #fff;
  backface-visibility: hidden;
}
.fixed-sidebar-left .side-nav li {
  width: 225px;
}
.fixed-sidebar-left .side-nav li.navigation-header > span,
.fixed-sidebar-left .side-nav li.navigation-header > i {
  padding: 11px 15px;
}
.fixed-sidebar-left .side-nav li.navigation-header > span {
  font-size: 11px;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  color: #878787;
}
.fixed-sidebar-left .side-nav li.navigation-header > i {
  display: none;
  font-size: 16px;
}
.fixed-sidebar-left .side-nav li a[aria-expanded='true'] {
  background: rgba(33, 33, 33, 0.05);
}
.fixed-sidebar-left .side-nav li a[aria-expanded='true'] > i {
  color: #e2724c;
}
.fixed-sidebar-left .side-nav li a[aria-expanded='true'] .pull-right i:before {
  content: '\f2f8';
}
.fixed-sidebar-left .side-nav li a {
  width: 100%;
  text-transform: capitalize;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
}
.fixed-sidebar-left .side-nav li a .pull-left > i {
  font-size: 18px;
  width: 18px;
}
.fixed-sidebar-left .side-nav li a .pull-right i {
  color: #878787;
}
.fixed-sidebar-left .side-nav > li > a {
  color: #212121;
  padding: 7px 15px;
}
.fixed-sidebar-left .side-nav > li > a:hover {
  background: rgba(33, 33, 33, 0.05);
}
.fixed-sidebar-left .side-nav > li > a:focus,
.fixed-sidebar-left .side-nav > li > a:active {
  background: rgba(33, 33, 33, 0.05);
  color: #212121;
}
.fixed-sidebar-left .side-nav > li > a:focus > i,
.fixed-sidebar-left .side-nav > li > a:active > i {
  color: #e2724c;
}
.fixed-sidebar-left .side-nav > li a.active-page {
  background: rgba(33, 33, 33, 0.05);
}
.fixed-sidebar-left .side-nav > li > ul > li a {
  padding: 7px 15px 7px 51px;
  display: block;
  color: #878787;
}
.fixed-sidebar-left .side-nav > li > ul > li a:hover,
.fixed-sidebar-left .side-nav > li > ul > li a:focus,
.fixed-sidebar-left .side-nav > li > ul > li a:active {
  background: rgba(33, 33, 33, 0.05);
  color: #878787;
}
.fixed-sidebar-left .side-nav > li > ul > li > ul > li a {
  padding-left: 62px;
  color: #878787;
}
.fixed-sidebar-left .side-nav > li > ul > li > ul > li a:hover,
.fixed-sidebar-left .side-nav > li > ul > li > ul > li a:focus,
.fixed-sidebar-left .side-nav > li > ul > li > ul > li a:active {
  color: #878787;
}
.fixed-sidebar-left .side-nav > li > a.active {
  color: #f33923;
}
.fixed-sidebar-left .side-nav > li > a.active > i {
  color: #f33923;
}
.fixed-sidebar-left .side-nav > li ul.collapse li a.active {
  color: #212121;
}

a.toggle-left-nav-btn,
a#toggle_mobile_nav,
a#toggle_mobile_search {
  color: #878787;
  position: relative;
  line-height: 66px;
}
a.toggle-left-nav-btn i,
a#toggle_mobile_nav i,
a#toggle_mobile_search i {
  font-size: 21px;
  vertical-align: middle;
}

a#toggle_mobile_nav {
  display: inline-block;
}

.top-fixed-nav .page-wrapper {
  margin-left: 0;
  padding-top: 134px;
}
.top-fixed-nav .fixed-sidebar-left {
  width: 100%;
  bottom: inherit;
}
.top-fixed-nav .fixed-sidebar-left:hover {
  width: 100%;
}
.top-fixed-nav .fixed-sidebar-left:hover .collapse.in {
  display: none;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv {
  overflow: visible !important;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv .slimScrollBar {
  display: none !important;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul {
  overflow: visible !important;
  width: 100% !important;
  height: auto !important;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul > li {
  width: auto;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul > li > a {
  text-align: center;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul > li > a > i {
  display: block;
  margin-bottom: 10px;
  margin-right: 0 !important;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul > li > a > .pull-right {
  display: none;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul li {
  position: relative;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul .collapse-level-1 {
  background: #ebebeb;
  height: auto !important;
  left: 0;
  position: absolute !important;
  z-index: 1;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul .collapse-level-1.two-col-list {
  width: 450px;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul .collapse-level-1 li {
  float: left;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul .collapse-level-1 li > a i:before {
  content: '\f105';
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul .collapse-level-1 li > ul {
  background: #ebebeb;
  left: 225px;
  position: absolute;
  top: 0;
  z-index: 1;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul > li:hover > ul {
  display: block;
}
.top-fixed-nav .fixed-sidebar-left > .slimScrollDiv > ul > li > ul > li:hover > ul {
  display: block;
}
.top-fixed-nav.slide-nav-toggle .page-wrapper {
  margin-left: 0;
  padding-top: 60px;
}
.top-fixed-nav.slide-nav-toggle .fixed-sidebar-left {
  top: -14px;
  width: 100%;
}
.top-fixed-nav.slide-nav-toggle .fixed-sidebar-left:hover {
  width: 100%;
}

.scrollable-nav {
  position: relative;
}
.scrollable-nav .fixed-sidebar-left,
.scrollable-nav .navbar-fixed-top,
.scrollable-nav .fixed-sidebar-right {
  position: absolute;
}
.scrollable-nav.box-layout .fixed-sidebar-right {
  right: 300px !important;
}

.fixed-sidebar-right,
.setting-panel {
  position: fixed;
  top: 66px;
  right: 0;
  width: 300px;
  margin-right: -300px;
  bottom: 0;
  z-index: 101;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.setting-panel {
  z-index: 1030;
  margin-right: -220px;
  width: 220px;
  top: 0;
}
.setting-panel .setting-panel-title {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 17px;
  padding-left: 20px;
  padding-top: 17px;
  color: #fff;
  background: #8bc34a;
  text-transform: capitalize;
}
.setting-panel .layout-switcher-wrap > ul {
  padding-left: 20px;
  padding-top: 35px;
}
.setting-panel .layout-switcher-wrap > ul .layout-title {
  color: #444;
  display: inline-block;
  font-size: 14px;
  margin-right: 15px;
  text-transform: capitalize;
  width: 130px;
}
.setting-panel .layout-switcher-wrap > ul .layout-switcher {
  display: inline-block;
}
.setting-panel .layout-switcher-wrap > ul .layout-switcher .switchery {
  margin: 0;
}
.setting-panel .layout-switcher-wrap > ul > li {
  margin-bottom: 30px;
}
.setting-panel .theme-option-wrap > li {
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 38px;
  margin-bottom: 15px;
  margin-right: 18px;
  width: 38px;
  position: relative;
  overflow: hidden;
  border: 1px solid #dedede;
  background: #fff;
}
.setting-panel .theme-option-wrap > li i {
  font-size: 25px;
  padding-top: 9px;
  padding-left: 15px;
  color: #8bc34a;
  opacity: 0;
}
.setting-panel .theme-option-wrap > li.active-theme i {
  opacity: 1;
}
.setting-panel .theme-option-wrap > li::after {
  content: '';
  height: 10px;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.setting-panel .theme-option-wrap > li::before {
  content: '';
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 10px;
}
.setting-panel .theme-option-wrap > li#theme-1::after {
  background: #f2f2f2;
}
.setting-panel .theme-option-wrap > li#theme-1::before {
  background: #2a3e4c;
}
.setting-panel .theme-option-wrap > li#theme-2::after {
  background: #2a3e4c;
}
.setting-panel .theme-option-wrap > li#theme-2::before {
  background: #f2f2f2;
}
.setting-panel .theme-option-wrap > li#theme-3::after {
  background: #f2f2f2;
}
.setting-panel .theme-option-wrap > li#theme-3::before {
  background: #2a3e4c;
  z-index: 2;
}
.setting-panel .theme-option-wrap > li#theme-4::after {
  background: #2a3e4c;
}
.setting-panel .theme-option-wrap > li#theme-4::before {
  background: #f2f2f2;
  z-index: 2;
}
.setting-panel .theme-option-wrap > li#theme-5::after {
  background: #f2f2f2;
}
.setting-panel .theme-option-wrap > li#theme-5::before {
  background: #f2f2f2;
}
.setting-panel .theme-option-wrap > li#theme-6::after {
  background: #2a3e4c;
}
.setting-panel .theme-option-wrap > li#theme-6::before {
  background: #2a3e4c;
}

#setting_panel_btn {
  position: fixed;
  right: 10px;
  bottom: 25px;
  z-index: 1031;
}

.right-sidebar {
  width: 100%;
  height: 100%;
  position: relative;
  border-left: 1px solid #dedede;
  border-radius: 0;
  background: #fff;
  overflow: hidden;
}
.right-sidebar .card-view:hover {
  box-shadow: none;
}
.right-sidebar .custom-tab-1 .nav-tabs {
  border-bottom: 1px solid #dedede;
}
.right-sidebar .custom-tab-1 .nav-tabs > li {
  margin-right: 0;
  text-align: center;
  width: 33.33%;
}
.right-sidebar .custom-tab-1 .nav-tabs > li > a {
  padding: 8px 20px;
  font-size: 13px;
  font-weight: 500;
  border-bottom: none;
}
.right-sidebar .custom-tab-1 .nav-tabs > li.active > a {
  border-bottom: none;
}

.right-sidebar-backdrop {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1031;
  display: none;
  pointer-events: none;
}

.page-wrapper {
  margin-left: 225px;
  background: #fafafa;
  padding: 70px 20px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  left: 0;
}

.open-right-sidebar .fixed-sidebar-right {
  margin-right: 0;
}
.open-right-sidebar .right-sidebar-backdrop {
  display: block;
}

.open-setting-panel .setting-panel {
  margin-right: 0;
}
.open-setting-panel .right-sidebar-backdrop {
  display: block;
}

.wrapper {
  background: #fff;
}
.wrapper.no-transition .fixed-sidebar-left,
.wrapper.no-transition .page-wrapper {
  /*Only for Setting Panel*/
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  transition: all 0s ease;
}
.wrapper.box-layout {
  max-width: 1400px;
  margin: 0 auto;
}
.wrapper.box-layout .navbar-fixed-top {
  margin: 0 auto;
  max-width: 1400px;
}
.wrapper.box-layout .fixed-sidebar-left {
  left: auto;
}
.wrapper.box-layout .fixed-sidebar-right {
  width: 0;
  overflow: hidden;
}
.wrapper.box-layout .fixed-sidebar-right .right-sidebar {
  width: 300px;
}
.wrapper.box-layout.open-right-sidebar .fixed-sidebar-right {
  margin-right: -300px;
  width: 300px;
}
.wrapper.box-layout.top-fixed-nav .fixed-sidebar-left {
  max-width: 1300px;
}
.wrapper.darkmode .card-view {
  background: #212121;
}
.wrapper.theme-1-active .navbar.navbar-inverse {
  background: #f2f2f2;
}
.wrapper.theme-1-active .fixed-sidebar-left {
  z-index: 1030;
}
.wrapper.theme-1-active .fixed-sidebar-left .side-nav {
  background: #2a3e4c;
}
.wrapper.theme-2-active .navbar.navbar-inverse.navbar-fixed-top {
  background: #2a3e4c;
  border-bottom: 1px solid #2a3e4c;
}
.wrapper.theme-2-active .navbar.navbar-inverse.navbar-fixed-top .mobile-only-brand {
  background: #2a3e4c;
}
.wrapper.theme-2-active .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
  color: #fff;
}
.wrapper.theme-2-active .navbar.navbar-inverse.navbar-fixed-top .nav > li > a:active,
.wrapper.theme-2-active .navbar.navbar-inverse.navbar-fixed-top .nav > li > a:hover,
.wrapper.theme-2-active .navbar.navbar-inverse.navbar-fixed-top .nav > li > a:focus {
  color: #fff;
}
.wrapper.theme-2-active .fixed-sidebar-left .side-nav {
  background: #f2f2f2;
}
.wrapper.theme-3-active .fixed-sidebar-left {
  z-index: 1030;
}
.wrapper.theme-3-active .fixed-sidebar-left .side-nav {
  background: #2a3e4c;
}
.wrapper.theme-3-active .navbar.navbar-inverse.navbar-fixed-top {
  background: #f2f2f2;
}
.wrapper.theme-3-active .navbar.navbar-inverse.navbar-fixed-top .nav-header {
  background: #2a3e4c;
}
.wrapper.theme-3-active .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
  color: #fff;
}
.wrapper.theme-4-active .navbar.navbar-inverse.navbar-fixed-top {
  background: #2a3e4c;
}
.wrapper.theme-4-active .navbar.navbar-inverse.navbar-fixed-top .mobile-only-brand {
  background: #2a3e4c;
}
.wrapper.theme-4-active .navbar.navbar-inverse.navbar-fixed-top .nav-header {
  background: #f2f2f2;
  border-right: 1px solid #2a3e4c;
}
.wrapper.theme-4-active .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
  color: #212121;
}
.wrapper.theme-4-active .navbar.navbar-inverse.navbar-fixed-top .nav > li > a:active,
.wrapper.theme-4-active .navbar.navbar-inverse.navbar-fixed-top .nav > li > a:hover,
.wrapper.theme-4-active .navbar.navbar-inverse.navbar-fixed-top .nav > li > a:focus {
  color: #fff;
}
.wrapper.theme-4-active .fixed-sidebar-left {
  z-index: 1030;
}
.wrapper.theme-4-active .fixed-sidebar-left .side-nav {
  background: #f2f2f2;
}
.wrapper.theme-5-active .navbar.navbar-inverse {
  background: #f2f2f2;
}
.wrapper.theme-5-active .fixed-sidebar-left .side-nav {
  background: #f2f2f2;
}
.wrapper.theme-6-active .navbar.navbar-inverse.navbar-fixed-top {
  background: #24333e;
  border-bottom: 1px solid #24333e;
}
.wrapper.theme-6-active .navbar.navbar-inverse.navbar-fixed-top .mobile-only-brand {
  background: #24333e;
}
.wrapper.theme-6-active .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
  color: #fff;
}
.wrapper.theme-6-active .navbar.navbar-inverse.navbar-fixed-top .nav > li > a:active,
.wrapper.theme-6-active .navbar.navbar-inverse.navbar-fixed-top .nav > li > a:hover,
.wrapper.theme-6-active .navbar.navbar-inverse.navbar-fixed-top .nav > li > a:focus {
  color: #fff;
}
.wrapper.theme-6-active .fixed-sidebar-left {
  z-index: 1030;
}
.wrapper.theme-6-active .fixed-sidebar-left .side-nav {
  background: #2a3e4c;
}
.wrapper.theme-1-active .fixed-sidebar-left,
.wrapper.theme-3-active .fixed-sidebar-left,
.wrapper.theme-6-active .fixed-sidebar-left {
  border-right: 1px solid #2a3e4c;
}
.wrapper.theme-1-active .fixed-sidebar-left .side-nav > li > a,
.wrapper.theme-3-active .fixed-sidebar-left .side-nav > li > a,
.wrapper.theme-6-active .fixed-sidebar-left .side-nav > li > a {
  color: #fff;
}
.wrapper.theme-1-active .fixed-sidebar-left .side-nav > li > a:hover,
.wrapper.theme-1-active .fixed-sidebar-left .side-nav > li > a:focus,
.wrapper.theme-1-active .fixed-sidebar-left .side-nav > li > a:active,
.wrapper.theme-3-active .fixed-sidebar-left .side-nav > li > a:hover,
.wrapper.theme-3-active .fixed-sidebar-left .side-nav > li > a:focus,
.wrapper.theme-3-active .fixed-sidebar-left .side-nav > li > a:active,
.wrapper.theme-6-active .fixed-sidebar-left .side-nav > li > a:hover,
.wrapper.theme-6-active .fixed-sidebar-left .side-nav > li > a:focus,
.wrapper.theme-6-active .fixed-sidebar-left .side-nav > li > a:active {
  color: #fff;
}
.wrapper.theme-1-active .fixed-sidebar-left .side-nav li a:hover,
.wrapper.theme-1-active .fixed-sidebar-left .side-nav li a:focus,
.wrapper.theme-1-active .fixed-sidebar-left .side-nav li a:active,
.wrapper.theme-3-active .fixed-sidebar-left .side-nav li a:hover,
.wrapper.theme-3-active .fixed-sidebar-left .side-nav li a:focus,
.wrapper.theme-3-active .fixed-sidebar-left .side-nav li a:active,
.wrapper.theme-6-active .fixed-sidebar-left .side-nav li a:hover,
.wrapper.theme-6-active .fixed-sidebar-left .side-nav li a:focus,
.wrapper.theme-6-active .fixed-sidebar-left .side-nav li a:active {
  background: rgba(255, 255, 255, 0.05);
}
.wrapper.theme-1-active .fixed-sidebar-left .side-nav li a.active-page,
.wrapper.theme-3-active .fixed-sidebar-left .side-nav li a.active-page,
.wrapper.theme-6-active .fixed-sidebar-left .side-nav li a.active-page {
  background: rgba(255, 255, 255, 0.05);
}
.wrapper.theme-1-active .fixed-sidebar-left .side-nav li a[aria-expanded='true'],
.wrapper.theme-3-active .fixed-sidebar-left .side-nav li a[aria-expanded='true'],
.wrapper.theme-6-active .fixed-sidebar-left .side-nav li a[aria-expanded='true'] {
  background: rgba(255, 255, 255, 0.05);
}
.wrapper.theme-1-active .fixed-sidebar-left hr.light-grey-hr,
.wrapper.theme-3-active .fixed-sidebar-left hr.light-grey-hr,
.wrapper.theme-6-active .fixed-sidebar-left hr.light-grey-hr {
  border-color: rgba(255, 255, 255, 0.1);
}
.wrapper.theme-1-active.pimary-color-red .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-2-active.pimary-color-red .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-3-active.pimary-color-red .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-4-active.pimary-color-red .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-5-active.pimary-color-red .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-6-active.pimary-color-red .fixed-sidebar-left .side-nav > li > a.active {
  color: #f33923;
}
.wrapper.theme-1-active.pimary-color-blue .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-2-active.pimary-color-blue .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-3-active.pimary-color-blue .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-4-active.pimary-color-blue .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-5-active.pimary-color-blue .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-6-active.pimary-color-blue .fixed-sidebar-left .side-nav > li > a.active {
  color: #2196f3;
}
.wrapper.theme-1-active.pimary-color-green .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-2-active.pimary-color-green .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-3-active.pimary-color-green .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-4-active.pimary-color-green .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-5-active.pimary-color-green .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-6-active.pimary-color-green .fixed-sidebar-left .side-nav > li > a.active {
  color: #8bc34a;
}
.wrapper.theme-1-active.pimary-color-yellow .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-2-active.pimary-color-yellow .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-3-active.pimary-color-yellow .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-4-active.pimary-color-yellow .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-5-active.pimary-color-yellow .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-6-active.pimary-color-yellow .fixed-sidebar-left .side-nav > li > a.active {
  color: #f8b32d;
}
.wrapper.theme-1-active.pimary-color-pink .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-2-active.pimary-color-pink .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-3-active.pimary-color-pink .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-4-active.pimary-color-pink .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-5-active.pimary-color-pink .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-6-active.pimary-color-pink .fixed-sidebar-left .side-nav > li > a.active {
  color: #e7368d;
}
.wrapper.theme-1-active.pimary-color-orange .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-2-active.pimary-color-orange .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-3-active.pimary-color-orange .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-4-active.pimary-color-orange .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-5-active.pimary-color-orange .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-6-active.pimary-color-orange .fixed-sidebar-left .side-nav > li > a.active {
  color: #ff6300;
}
.wrapper.theme-1-active.pimary-color-gold .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-2-active.pimary-color-gold .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-3-active.pimary-color-gold .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-4-active.pimary-color-gold .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-5-active.pimary-color-gold .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-6-active.pimary-color-gold .fixed-sidebar-left .side-nav > li > a.active {
  color: #e58b25;
}
.wrapper.theme-1-active.pimary-color-silver .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-2-active.pimary-color-silver .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-3-active.pimary-color-silver .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-4-active.pimary-color-silver .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-5-active.pimary-color-silver .fixed-sidebar-left .side-nav > li > a.active,
.wrapper.theme-6-active.pimary-color-silver .fixed-sidebar-left .side-nav > li > a.active {
  color: #83878d;
}

@media (max-width: 1024px) {
  .wrapper.theme-2-active .navbar.navbar-inverse.navbar-fixed-top .mobile-only-nav,
  .wrapper.theme-4-active .navbar.navbar-inverse.navbar-fixed-top .mobile-only-nav,
  .wrapper.theme-5-active .navbar.navbar-inverse.navbar-fixed-top .mobile-only-nav,
  .wrapper.theme-6-active .navbar.navbar-inverse.navbar-fixed-top .mobile-only-nav {
    background: #292929;
  }
  .wrapper.theme-2-active .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group input {
    background: #2a3e4c;
    color: #fff;
  }
  .wrapper.theme-3-active .navbar.navbar-inverse.navbar-fixed-top .nav-header {
    background: #fff;
  }
  .wrapper.theme-3-active .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
    color: #212121;
  }
  .wrapper.theme-4-active .navbar.navbar-inverse.navbar-fixed-top .nav-header {
    background: #2a3e4c;
    border-right: none;
  }
  .wrapper.theme-4-active .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
    color: #fff;
  }
  .wrapper.theme-4-active .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group input {
    background: #2a3e4c;
    color: #fff;
  }
  .wrapper.theme-5-active .navbar.navbar-inverse.navbar-fixed-top .mobile-only-nav {
    background: #f5f5f5;
  }
  .wrapper.theme-6-active .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group input {
    background: #2a3e4c;
    color: #fff;
  }
}
/* Top Navigation */
.navbar.navbar-inverse {
  background: #fff;
}
.navbar.navbar-inverse.navbar-fixed-top {
  border-bottom: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
  margin-bottom: 0;
  min-height: 66px;
  padding: 0 15px 0 0;
}
.navbar.navbar-inverse.navbar-fixed-top .nav-header {
  width: 225px;
  padding-left: 15px;
  height: 66px;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  transition: 0.4s ease;
  overflow: hidden;
}
.navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap {
  width: 225px;
  padding-top: 19px;
}
.goIndex {
  display: flex;
  align-items: center;
}
.goIndex:hover {
  text-decoration: none;
}
.navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-img {
  margin-right: 10px;
  position: absolute;
}
.navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  color: #212121;
  font-size: 21px;
  text-transform: capitalize;
  opacity: 1;
  visibility: visible;
}
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search {
  padding-top: 14px;
  width: 320px;
  margin-left: 25px;
}
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group {
  width: 100%;
}
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group input {
  border-radius: 50px;
  height: 36px;
  background: #f2f2f2;
  padding-right: 30px;
}
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn {
  position: absolute;
  right: 22px;
  top: 8px;
  z-index: 4;
}
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > .btn,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .wizard > .actions .disabled .input-group-btn > a,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .wizard > .actions .input-group-btn > a,
.wizard > .actions .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > a,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .dt-buttons .input-group-btn > .dt-button,
.dt-buttons .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > .dt-button,
.navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .input-group-btn
  > button,
.tablesaw-sortable
  th.tablesaw-sortable-head
  .navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .input-group-btn
  > button,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .sweet-alert .input-group-btn > button,
.sweet-alert .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > button,
.navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .owl-theme
  .owl-nav
  .input-group-btn
  > [class*='owl-'],
.owl-theme
  .owl-nav
  .navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .input-group-btn
  > [class*='owl-'],
.navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .input-group-btn
  > button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .input-group-btn
  > button.fc-month-button.fc-state-default.fc-corner-left,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > button.fc-agendaWeek-button,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > .fc-prev-button,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > .fc-next-button,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > .fc-today-button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  padding: 0;
}
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > .btn i,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .wizard > .actions .input-group-btn > a i,
.wizard > .actions .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > a i,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .dt-buttons .input-group-btn > .dt-button i,
.dt-buttons .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > .dt-button i,
.navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .tablesaw-sortable
  th.tablesaw-sortable-head
  .input-group-btn
  > button
  i,
.tablesaw-sortable
  th.tablesaw-sortable-head
  .navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .input-group-btn
  > button
  i,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .sweet-alert .input-group-btn > button i,
.sweet-alert .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > button i,
.navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .owl-theme
  .owl-nav
  .input-group-btn
  > [class*='owl-']
  i,
.owl-theme
  .owl-nav
  .navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .input-group-btn
  > [class*='owl-']
  i,
.navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .input-group-btn
  > button.fc-agendaDay-button.fc-state-default.fc-corner-right
  i,
.navbar.navbar-inverse.navbar-fixed-top
  .top-nav-search
  .input-group
  .input-group-btn
  > button.fc-month-button.fc-state-default.fc-corner-left
  i,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > button.fc-agendaWeek-button i,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > .fc-prev-button i,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > .fc-next-button i,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn > .fc-today-button i {
  color: #878787;
  font-size: 20px;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li.dropdown {
  position: relative;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li.full-width-drp {
  position: static;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li > a {
  color: #878787;
  background: transparent !important;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  padding: 0 9px;
  line-height: 66px;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li > a:hover,
.navbar.navbar-inverse.navbar-fixed-top .nav > li > a:focus,
.navbar.navbar-inverse.navbar-fixed-top .nav > li > a:active {
  color: #212121;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li > a .user-auth-img {
  height: 43px;
  width: 43px;
  vertical-align: middle;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li > a .user-online-status {
  background: #8bc34a;
  border-radius: 50%;
  bottom: 10px;
  height: 10px;
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  width: 10px;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.app-dropdown {
  min-width: 270px;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.app-dropdown .connection-item {
  display: inline-block;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  color: #878787;
  text-transform: capitalize;
  width: 80px;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.app-dropdown .connection-item i {
  font-size: 25px;
  padding-bottom: 5px;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.app-dropdown .connection-item:hover {
  color: #878787;
  background: rgba(33, 33, 33, 0.05);
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.app-dropdown .app-icon-wrap {
  text-align: center;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.app-dropdown .app-icon-wrap > li {
  display: inline-block;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.app-dropdown .app-box-bottom-wrap .read-all {
  padding: 5px 15px 0;
  font-size: 13px;
  color: #878787;
  text-transform: capitalize;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown {
  width: 400px;
}
.navbar.navbar-inverse.navbar-fixed-top
  .nav
  > li
  .dropdown-menu.alert-dropdown
  .notification-box-head-wrap
  .notification-box-head {
  font-size: 16px;
  color: #212121;
  display: block;
  text-transform: capitalize;
  padding: 7px 15px 10px;
}
.navbar.navbar-inverse.navbar-fixed-top
  .nav
  > li
  .dropdown-menu.alert-dropdown
  .notification-box-head-wrap
  .clear-notifications {
  padding: 12px 15px 10px;
  text-transform: uppercase;
  font-size: 11px;
}
.navbar.navbar-inverse.navbar-fixed-top
  .nav
  > li
  .dropdown-menu.alert-dropdown
  .notification-box-bottom-wrap
  .read-all {
  padding: 5px 15px 0;
  font-size: 13px;
  color: #878787;
  text-transform: capitalize;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown .streamline {
  height: 229px;
  position: relative;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown .streamline .sl-item {
  margin-left: 0;
  border-left: none;
  padding: 7px 15px 0;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  transition: 0.2s ease;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown .streamline .sl-item:hover {
  background: rgba(33, 33, 33, 0.05);
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown .streamline .sl-item:before {
  display: none;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown .streamline .sl-item > a {
  display: block;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown .streamline .sl-item .icon {
  height: 44px;
  position: absolute;
  text-align: center;
  width: 44px;
  display: table;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown .streamline .sl-item .icon i {
  font-size: 24px;
  vertical-align: middle;
  display: table-cell;
  color: #fff;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown .streamline .sl-item .sl-avatar {
  left: auto;
  height: 44px;
  width: 44px;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown .streamline .sl-item .sl-avatar img {
  height: 44px;
  width: 44px;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown .streamline .sl-item .sl-content {
  margin-left: 60px;
}
.navbar.navbar-inverse.navbar-fixed-top
  .nav
  > li
  .dropdown-menu.alert-dropdown
  .streamline
  .sl-item
  .sl-content
  > .head-notifications {
  width: 230px;
  font-size: 13px;
  font-weight: 500;
}
.navbar.navbar-inverse.navbar-fixed-top
  .nav
  > li
  .dropdown-menu.alert-dropdown
  .streamline
  .sl-item
  .sl-content
  .notifications-time {
  color: #878787;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown .streamline .sl-item .sl-content > p {
  font-size: 13px;
  color: #878787;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.user-auth-dropdown {
  min-width: 175px;
  text-transform: capitalize;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.mega-menu {
  right: 23px;
  width: calc(100% - 270px);
  display: block;
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.mega-menu li.col-menu-list > a {
  font-size: 16px;
  padding: 10px 0;
  display: block;
  background: transparent;
  text-transform: capitalize;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.mega-menu li.col-menu-list > a .pull-left i {
  color: #212121;
  font-size: 18px;
  margin-right: 10px;
  width: 18px;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.mega-menu li.col-menu-list > a .pull-right i {
  color: #212121;
  font-size: inherit;
  margin-right: 0;
  width: auto;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.mega-menu li.col-menu-list > ul > li > a {
  display: block;
  text-transform: capitalize;
  padding: 8px 0 8px 39px;
  color: #878787;
}
.navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.mega-menu li.preview-carousel > a {
  font-size: 16px;
  padding: 10px 0;
  background: transparent;
  display: inline-block;
  text-transform: capitalize;
}
.navbar.navbar-inverse.navbar-fixed-top
  .nav
  > li
  .dropdown-menu.mega-menu
  li.preview-carousel
  .product-carousel
  a
  > span {
  font-size: 12px;
  color: #212121;
  text-transform: uppercase;
  font-weight: 500;
}
.navbar.navbar-inverse.navbar-fixed-top
  .nav
  > li
  .dropdown-menu.mega-menu
  li.preview-carousel
  .product-carousel.owl-theme
  > .owl-nav {
  margin: 0;
  position: absolute;
  right: 0;
  top: -40px;
}
.navbar.navbar-inverse.navbar-fixed-top
  .nav
  > li
  .dropdown-menu.mega-menu
  li.preview-carousel
  .product-carousel.owl-theme
  > .owl-nav
  .owl-prev,
.navbar.navbar-inverse.navbar-fixed-top
  .nav
  > li
  .dropdown-menu.mega-menu
  li.preview-carousel
  .product-carousel.owl-theme
  > .owl-nav
  .owl-next {
  padding: 0;
  border: none;
  background: transparent;
}
.navbar.navbar-inverse.navbar-fixed-top .nav .open > a {
  background: #fff;
}
.navbar.navbar-inverse.navbar-fixed-top .nav .open > a:focus,
.navbar.navbar-inverse.navbar-fixed-top .nav .open > a:hover,
.navbar.navbar-inverse.navbar-fixed-top .nav .open > a:active {
  background: #fff;
}
.navbar.navbar-inverse.navbar-fixed-top .nav .open > .dropdown-menu.mega-menu {
  opacity: 1;
  visibility: visible;
  height: auto;
  overflow: auto;
}
.navbar.navbar-inverse.navbar-fixed-top .nav.top-nav > li {
  display: inline-block;
  float: left;
}
.navbar.navbar-inverse.navbar-fixed-top .nav.top-nav > li > a i.top-nav-icon {
  font-size: 24px;
  vertical-align: middle;
}
.navbar.navbar-inverse.navbar-fixed-top .nav.top-nav > li > a .top-nav-icon-badge {
  background: #8bc34a;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 16px;
  width: 16px;
}
.navbar.navbar-inverse.navbar-fixed-top .nav.navbar-right {
  margin-right: 0;
}
.navbar.navbar-inverse.navbar-fixed-top .navbar-search-overlap {
  background-color: #fff;
  left: 0;
  position: absolute !important;
  right: 0;
  top: 0;
}
.navbar.navbar-inverse.navbar-fixed-top .navbar-search-overlap input {
  border: medium none !important;
  min-height: 59px;
}
.navbar.navbar-inverse.navbar-fixed-top .navbar-right .dropdown-menu {
  left: auto;
  right: 0;
}

.slide-nav-toggle .navbar.navbar-inverse.navbar-fixed-top .nav-header {
  width: 44px;
}
.slide-nav-toggle .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
  opacity: 0;
  visibility: hidden;
}
.slide-nav-toggle.sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header {
  width: 225px;
}
.slide-nav-toggle.sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
  opacity: 1;
  visibility: visible;
}
.slide-nav-toggle .fixed-sidebar-left {
  width: 44px;
}
.slide-nav-toggle .fixed-sidebar-left .side-nav li.navigation-header > span {
  display: none;
}
.slide-nav-toggle .fixed-sidebar-left .side-nav li.navigation-header > i {
  display: block;
}
.slide-nav-toggle .fixed-sidebar-left .collapse.in {
  display: none;
}
.slide-nav-toggle .fixed-sidebar-left:hover {
  width: 225px;
}
.slide-nav-toggle .fixed-sidebar-left:hover .side-nav li.navigation-header > span {
  display: block;
}
.slide-nav-toggle .fixed-sidebar-left:hover .side-nav li.navigation-header > i {
  display: none;
}
.slide-nav-toggle .fixed-sidebar-left:hover .collapse.in {
  display: block;
}
.slide-nav-toggle .fixed-sidebar-left:hover .right-nav-text {
  opacity: 1;
}
.slide-nav-toggle .fixed-sidebar-left .right-nav-text {
  opacity: 0;
}
.slide-nav-toggle .page-wrapper {
  margin-left: 44px;
}

.card-view {
  background: #fff;
  margin-bottom: 20px;
  border: none;
  border-radius: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 15px 15px 0;
  margin-left: -5px;
  margin-right: -5px;
}
.card-view.panel > .panel-heading {
  border: none;
  color: inherit;
  border-radius: 0;
  margin: -15px -15px 0;
  padding: 20px 15px;
}
.card-view.panel > .panel-heading.small-panel-heading {
  padding: 11px 15px;
}
.card-view.panel > .panel-heading .head-overlay {
  background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.card-view.panel > .panel-heading .pull-right button {
  background: #f2f2f2;
}
.card-view.panel .panel-body {
  padding: 20px 0 20px;
}
.card-view.panel.panel-default > .panel-heading {
  background: #f2f2f2;
}
.card-view.panel.panel-default.border-panel > .panel-heading,
.card-view.panel.panel-default.panel-tabs > .panel-heading {
  border-bottom: 1px solid #dedede;
}
.card-view.panel.panel-default.border-panel .panel-body,
.card-view.panel.panel-default.panel-tabs .panel-body {
  padding-top: 20px;
}
.card-view.panel.panel-default.panel-tabs > .panel-heading {
  padding-bottom: 0;
}
.card-view.panel.panel-default.panel-tabs .nav-tabs {
  border-bottom: none;
}
.card-view.panel.panel-default.panel-tabs .nav-tabs > li > a {
  padding: 0 5px 12px;
  float: left;
}
.card-view.panel.panel-info .panel-body,
.card-view.panel.panel-success .panel-body,
.card-view.panel.panel-warning .panel-body,
.card-view.panel.panel-danger .panel-body,
.card-view.panel.panel-primary .panel-body,
.card-view.panel.panel-inverse .panel-body {
  padding-top: 20px;
}
.card-view.panel.fullscreen {
  height: 100%;
  left: 0;
  margin: 0;
  position: fixed;
  overflow-y: auto;
  top: 0;
  width: 100%;
  z-index: 1040;
}
.card-view.panel.fullscreen > .panel-heading .pull-right > a,
.card-view.panel.fullscreen > .panel-heading .pull-right .dropdown {
  opacity: 0;
  visibility: hidden;
}
.card-view.panel.fullscreen > .panel-heading .pull-right > a.full-screen,
.card-view.panel.fullscreen > .panel-heading .pull-right .dropdown.full-screen {
  opacity: 1;
  visibility: visible;
}
.card-view.panel.fullscreen > .panel-heading .pull-right > a.full-screen i.zmdi-fullscreen::before,
.card-view.panel.fullscreen > .panel-heading .pull-right .dropdown.full-screen i.zmdi-fullscreen::before {
  content: '\f16c';
}

.heading-bg {
  height: 67px;
  margin: 0 -20px 10px;
  padding: 13px 0;
}
.heading-bg h5 {
  padding-top: 6px;
}

.breadcrumb {
  background-color: transparent;
  float: right;
  list-style: outside none none;
  margin-bottom: 0;
  padding: 9px 0 0 0;
  font-size: 13px;
}
.breadcrumb a,
.breadcrumb span {
  text-transform: capitalize;
}
.breadcrumb a {
  color: #212121;
  opacity: 0.5;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
}
.breadcrumb a:hover {
  opacity: 1;
}
.breadcrumb span {
  color: #212121;
}

.breadcrumb > li + li::before {
  color: #212121;
  opacity: 0.5;
  font-family: FontAwesome;
  content: '\f105';
  padding: 0 9px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  left: 0;
}
footer p {
  display: block;
  font-size: 12px;
  line-height: 40px;
}
footer .brand {
  display: block;
  float: left;
  padding-top: 4px;
}
footer .navbar-nav > li > a {
  padding-bottom: 0;
  padding-top: 5px;
  padding-left: 10px;
  padding-left: 10px;
  font-size: 12px;
  text-transform: capitalize;
}
footer .nav > li > a:focus,
footer .nav > li > a:hover {
  background: transparent;
  opacity: 0.8;
}

.font-icon-detail span {
  border-radius: 0 !important;
}

/*Form*/
.form-control,
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single,
.select2-selection.select2-selection--multiple,
.wizard .content > .body input,
.mce-floatpanel .mce-textbox,
.mce-floatpanel .mce-btn,
.dataTables_wrapper .dataTables_filter input,
.dataTables_wrapper .dataTables_length select,
.jsgrid-cell input,
.jsgrid-cell select,
.tablesaw-bar .btn-select select,
.dd-handle,
.dd3-content,
.app-search .form-control:focus,
.app-search .select2-container--default .select2-selection--single:focus,
.select2-container--default .app-search .select2-selection--single:focus,
.app-search .select2-selection.select2-selection--multiple:focus,
.app-search .mce-floatpanel .mce-textbox:focus,
.mce-floatpanel .app-search .mce-textbox:focus,
.app-search .mce-floatpanel .mce-btn:focus,
.mce-floatpanel .app-search .mce-btn:focus,
.app-search .dataTables_wrapper .dataTables_filter input:focus,
.dataTables_wrapper .dataTables_filter .app-search input:focus,
.app-search .dataTables_wrapper .dataTables_length select:focus,
.dataTables_wrapper .dataTables_length .app-search select:focus,
.app-search .jsgrid-cell input:focus,
.jsgrid-cell .app-search input:focus,
.app-search .jsgrid-cell select:focus,
.jsgrid-cell .app-search select:focus,
.app-search .tablesaw-bar .btn-select select:focus,
.tablesaw-bar .btn-select .app-search select:focus,
.app-search .dd-handle:focus,
.app-search .dd3-content:focus {
  border: 1px solid rgba(33, 33, 33, 0.12);
  border-radius: 0;
  background-color: #fff;
  box-shadow: none;
  color: #212121;
  height: 42px;
}
.form-control:focus,
.select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.select2-container--default .select2-selection--single:focus,
.select2-selection.select2-selection--multiple:focus,
.wizard .content > .body input:focus,
.mce-floatpanel .mce-textbox:focus,
.mce-floatpanel .mce-btn:focus,
.dataTables_wrapper .dataTables_filter input:focus,
.dataTables_wrapper .dataTables_length select:focus,
.jsgrid-cell input:focus,
.jsgrid-cell select:focus,
.tablesaw-bar .btn-select select:focus,
.dd-handle:focus,
.dd3-content:focus,
.app-search .form-control:focus,
.app-search .jsgrid-cell input:focus,
.jsgrid-cell .app-search input:focus,
.app-search .jsgrid-cell select:focus,
.jsgrid-cell .app-search select:focus,
.app-search .dd-handle:focus,
.app-search .dd3-content:focus {
  border-color: rgba(33, 33, 33, 0.3);
  box-shadow: none;
  outline: 0 none;
}
.form-control::-webkit-input-placeholder,
.select2-container--default .select2-selection--single .select2-selection__rendered::-webkit-input-placeholder,
.select2-container--default .select2-selection--single::-webkit-input-placeholder,
.select2-selection.select2-selection--multiple::-webkit-input-placeholder,
.wizard .content > .body input::-webkit-input-placeholder,
.mce-floatpanel .mce-textbox::-webkit-input-placeholder,
.mce-floatpanel .mce-btn::-webkit-input-placeholder,
.dataTables_wrapper .dataTables_filter input::-webkit-input-placeholder,
.dataTables_wrapper .dataTables_length select::-webkit-input-placeholder,
.jsgrid-cell input::-webkit-input-placeholder,
.jsgrid-cell select::-webkit-input-placeholder,
.tablesaw-bar .btn-select select::-webkit-input-placeholder,
.dd-handle::-webkit-input-placeholder,
.dd3-content::-webkit-input-placeholder,
.app-search .form-control:focus::-webkit-input-placeholder,
.app-search .select2-container--default .select2-selection--single:focus::-webkit-input-placeholder,
.select2-container--default .app-search .select2-selection--single:focus::-webkit-input-placeholder,
.app-search .select2-selection.select2-selection--multiple:focus::-webkit-input-placeholder,
.app-search .mce-floatpanel .mce-textbox:focus::-webkit-input-placeholder,
.mce-floatpanel .app-search .mce-textbox:focus::-webkit-input-placeholder,
.app-search .mce-floatpanel .mce-btn:focus::-webkit-input-placeholder,
.mce-floatpanel .app-search .mce-btn:focus::-webkit-input-placeholder,
.app-search .dataTables_wrapper .dataTables_filter input:focus::-webkit-input-placeholder,
.dataTables_wrapper .dataTables_filter .app-search input:focus::-webkit-input-placeholder,
.app-search .dataTables_wrapper .dataTables_length select:focus::-webkit-input-placeholder,
.dataTables_wrapper .dataTables_length .app-search select:focus::-webkit-input-placeholder,
.app-search .jsgrid-cell input:focus::-webkit-input-placeholder,
.jsgrid-cell .app-search input:focus::-webkit-input-placeholder,
.app-search .jsgrid-cell select:focus::-webkit-input-placeholder,
.jsgrid-cell .app-search select:focus::-webkit-input-placeholder,
.app-search .tablesaw-bar .btn-select select:focus::-webkit-input-placeholder,
.tablesaw-bar .btn-select .app-search select:focus::-webkit-input-placeholder,
.app-search .dd-handle:focus::-webkit-input-placeholder,
.app-search .dd3-content:focus::-webkit-input-placeholder {
  text-transform: capitalize;
}
.form-control:-moz-placeholder,
.select2-container--default .select2-selection--single .select2-selection__rendered:-moz-placeholder,
.select2-container--default .select2-selection--single:-moz-placeholder,
.select2-selection.select2-selection--multiple:-moz-placeholder,
.wizard .content > .body input:-moz-placeholder,
.mce-floatpanel .mce-textbox:-moz-placeholder,
.mce-floatpanel .mce-btn:-moz-placeholder,
.dataTables_wrapper .dataTables_filter input:-moz-placeholder,
.dataTables_wrapper .dataTables_length select:-moz-placeholder,
.jsgrid-cell input:-moz-placeholder,
.jsgrid-cell select:-moz-placeholder,
.tablesaw-bar .btn-select select:-moz-placeholder,
.dd-handle:-moz-placeholder,
.dd3-content:-moz-placeholder,
.app-search .form-control:-moz-placeholder:focus,
.app-search .jsgrid-cell input:-moz-placeholder:focus,
.jsgrid-cell .app-search input:-moz-placeholder:focus,
.app-search .jsgrid-cell select:-moz-placeholder:focus,
.jsgrid-cell .app-search select:-moz-placeholder:focus,
.app-search .dd-handle:-moz-placeholder:focus,
.app-search .dd3-content:-moz-placeholder:focus {
  text-transform: capitalize;
}
.form-control::-moz-placeholder,
.select2-container--default .select2-selection--single .select2-selection__rendered::-moz-placeholder,
.select2-container--default .select2-selection--single::-moz-placeholder,
.select2-selection.select2-selection--multiple::-moz-placeholder,
.wizard .content > .body input::-moz-placeholder,
.mce-floatpanel .mce-textbox::-moz-placeholder,
.mce-floatpanel .mce-btn::-moz-placeholder,
.dataTables_wrapper .dataTables_filter input::-moz-placeholder,
.dataTables_wrapper .dataTables_length select::-moz-placeholder,
.jsgrid-cell input::-moz-placeholder,
.jsgrid-cell select::-moz-placeholder,
.tablesaw-bar .btn-select select::-moz-placeholder,
.dd-handle::-moz-placeholder,
.dd3-content::-moz-placeholder,
.app-search .form-control:focus::-moz-placeholder,
.app-search .select2-container--default .select2-selection--single:focus::-moz-placeholder,
.select2-container--default .app-search .select2-selection--single:focus::-moz-placeholder,
.app-search .select2-selection.select2-selection--multiple:focus::-moz-placeholder,
.app-search .mce-floatpanel .mce-textbox:focus::-moz-placeholder,
.mce-floatpanel .app-search .mce-textbox:focus::-moz-placeholder,
.app-search .mce-floatpanel .mce-btn:focus::-moz-placeholder,
.mce-floatpanel .app-search .mce-btn:focus::-moz-placeholder,
.app-search .dataTables_wrapper .dataTables_filter input:focus::-moz-placeholder,
.dataTables_wrapper .dataTables_filter .app-search input:focus::-moz-placeholder,
.app-search .dataTables_wrapper .dataTables_length select:focus::-moz-placeholder,
.dataTables_wrapper .dataTables_length .app-search select:focus::-moz-placeholder,
.app-search .jsgrid-cell input:focus::-moz-placeholder,
.jsgrid-cell .app-search input:focus::-moz-placeholder,
.app-search .jsgrid-cell select:focus::-moz-placeholder,
.jsgrid-cell .app-search select:focus::-moz-placeholder,
.app-search .tablesaw-bar .btn-select select:focus::-moz-placeholder,
.tablesaw-bar .btn-select .app-search select:focus::-moz-placeholder,
.app-search .dd-handle:focus::-moz-placeholder,
.app-search .dd3-content:focus::-moz-placeholder {
  text-transform: capitalize;
}
.form-control:-ms-input-placeholder,
.select2-container--default .select2-selection--single .select2-selection__rendered:-ms-input-placeholder,
.select2-container--default .select2-selection--single:-ms-input-placeholder,
.select2-selection.select2-selection--multiple:-ms-input-placeholder,
.wizard .content > .body input:-ms-input-placeholder,
.mce-floatpanel .mce-textbox:-ms-input-placeholder,
.mce-floatpanel .mce-btn:-ms-input-placeholder,
.dataTables_wrapper .dataTables_filter input:-ms-input-placeholder,
.dataTables_wrapper .dataTables_length select:-ms-input-placeholder,
.jsgrid-cell input:-ms-input-placeholder,
.jsgrid-cell select:-ms-input-placeholder,
.tablesaw-bar .btn-select select:-ms-input-placeholder,
.dd-handle:-ms-input-placeholder,
.dd3-content:-ms-input-placeholder,
.app-search .form-control:-ms-input-placeholder:focus,
.app-search .jsgrid-cell input:-ms-input-placeholder:focus,
.jsgrid-cell .app-search input:-ms-input-placeholder:focus,
.app-search .jsgrid-cell select:-ms-input-placeholder:focus,
.jsgrid-cell .app-search select:-ms-input-placeholder:focus,
.app-search .dd-handle:-ms-input-placeholder:focus,
.app-search .dd3-content:-ms-input-placeholder:focus {
  text-transform: capitalize;
}
.form-control.filled-input,
.select2-container--default .select2-selection--single .filled-input.select2-selection__rendered,
.select2-container--default .filled-input.select2-selection--single,
.filled-input.select2-selection.select2-selection--multiple,
.wizard .content > .body input.filled-input,
.mce-floatpanel .filled-input.mce-textbox,
.mce-floatpanel .filled-input.mce-btn,
.dataTables_wrapper .dataTables_filter input.filled-input,
.dataTables_wrapper .dataTables_length select.filled-input,
.jsgrid-cell input.filled-input,
.jsgrid-cell select.filled-input,
.tablesaw-bar .btn-select select.filled-input,
.filled-input.dd-handle,
.filled-input.dd3-content,
.app-search .filled-input.form-control:focus,
.app-search .jsgrid-cell input.filled-input:focus,
.jsgrid-cell .app-search input.filled-input:focus,
.app-search .jsgrid-cell select.filled-input:focus,
.jsgrid-cell .app-search select.filled-input:focus,
.app-search .filled-input.dd-handle:focus,
.app-search .filled-input.dd3-content:focus {
  background: #f7f7f9;
  border: none;
}
.form-control.filled-input:focus,
.select2-container--default .select2-selection--single .filled-input.select2-selection__rendered:focus,
.select2-container--default .filled-input.select2-selection--single:focus,
.filled-input.select2-selection.select2-selection--multiple:focus,
.wizard .content > .body input.filled-input:focus,
.mce-floatpanel .filled-input.mce-textbox:focus,
.mce-floatpanel .filled-input.mce-btn:focus,
.dataTables_wrapper .dataTables_filter input.filled-input:focus,
.dataTables_wrapper .dataTables_length select.filled-input:focus,
.jsgrid-cell input.filled-input:focus,
.jsgrid-cell select.filled-input:focus,
.tablesaw-bar .btn-select select.filled-input:focus,
.filled-input.dd-handle:focus,
.filled-input.dd3-content:focus {
  background: rgba(247, 247, 249, 0.8);
}
.form-control.rounded-input,
.select2-container--default .select2-selection--single .rounded-input.select2-selection__rendered,
.select2-container--default .rounded-input.select2-selection--single,
.rounded-input.select2-selection.select2-selection--multiple,
.wizard .content > .body input.rounded-input,
.mce-floatpanel .rounded-input.mce-textbox,
.mce-floatpanel .rounded-input.mce-btn,
.dataTables_wrapper .dataTables_filter input.rounded-input,
.dataTables_wrapper .dataTables_length select.rounded-input,
.jsgrid-cell input.rounded-input,
.jsgrid-cell select.rounded-input,
.tablesaw-bar .btn-select select.rounded-input,
.rounded-input.dd-handle,
.rounded-input.dd3-content,
.app-search .rounded-input.form-control:focus,
.app-search .jsgrid-cell input.rounded-input:focus,
.jsgrid-cell .app-search input.rounded-input:focus,
.app-search .jsgrid-cell select.rounded-input:focus,
.jsgrid-cell .app-search select.rounded-input:focus,
.app-search .rounded-input.dd-handle:focus,
.app-search .rounded-input.dd3-content:focus {
  border-radius: 60px;
}
.form-control.rounded-outline-input,
.select2-container--default .select2-selection--single .rounded-outline-input.select2-selection__rendered,
.select2-container--default .rounded-outline-input.select2-selection--single,
.rounded-outline-input.select2-selection.select2-selection--multiple,
.wizard .content > .body input.rounded-outline-input,
.mce-floatpanel .rounded-outline-input.mce-textbox,
.mce-floatpanel .rounded-outline-input.mce-btn,
.dataTables_wrapper .dataTables_filter input.rounded-outline-input,
.dataTables_wrapper .dataTables_length select.rounded-outline-input,
.jsgrid-cell input.rounded-outline-input,
.jsgrid-cell select.rounded-outline-input,
.tablesaw-bar .btn-select select.rounded-outline-input,
.rounded-outline-input.dd-handle,
.rounded-outline-input.dd3-content,
.app-search .rounded-outline-input.form-control:focus,
.app-search .jsgrid-cell input.rounded-outline-input:focus,
.jsgrid-cell .app-search input.rounded-outline-input:focus,
.app-search .jsgrid-cell select.rounded-outline-input:focus,
.jsgrid-cell .app-search select.rounded-outline-input:focus,
.app-search .rounded-outline-input.dd-handle:focus,
.app-search .rounded-outline-input.dd3-content:focus {
  background: transparent;
  border: 1px solid #dedede;
}

.control-label {
  color: #212121;
}

.input-group-addon {
  background: transparent none repeat scroll 0 0;
  border-color: #dedede;
  border-radius: 0;
  color: #212121;
  min-width: 42px;
}
.input-group-addon.btn,
.wizard > .actions a.input-group-addon,
.dt-buttons .input-group-addon.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.input-group-addon,
.sweet-alert button.input-group-addon,
.owl-theme .owl-nav .input-group-addon[class*='owl-'],
button.input-group-addon.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.input-group-addon.fc-month-button.fc-state-default.fc-corner-left,
button.input-group-addon.fc-agendaWeek-button,
.input-group-addon.fc-prev-button,
.input-group-addon.fc-next-button,
.input-group-addon.fc-today-button {
  color: #fff;
}

.form-inline .checkbox label,
.form-inline .radio label {
  padding-left: 3px;
}

.control-label {
  text-transform: capitalize;
}

.checkbox,
.radio {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 27px;
  margin-top: 0;
  margin-bottom: 0;
}

.form-inline .checkbox,
.form-inline .radio {
  padding-top: 0px;
  padding-bottom: 0px;
}

.form-control-feedback {
  line-height: 42px;
  height: 42px;
}

.help-block {
  color: #878787;
}

.has-success .form-control,
.has-success .select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .has-success .select2-selection__rendered,
.has-success .select2-container--default .select2-selection--single,
.select2-container--default .has-success .select2-selection--single,
.has-success .select2-selection.select2-selection--multiple,
.has-success .wizard .content > .body input,
.wizard .content > .body .has-success input,
.has-success .mce-floatpanel .mce-textbox,
.mce-floatpanel .has-success .mce-textbox,
.has-success .mce-floatpanel .mce-btn,
.mce-floatpanel .has-success .mce-btn,
.has-success .dataTables_wrapper .dataTables_filter input,
.dataTables_wrapper .dataTables_filter .has-success input,
.has-success .dataTables_wrapper .dataTables_length select,
.dataTables_wrapper .dataTables_length .has-success select,
.has-success .jsgrid-cell input,
.jsgrid-cell .has-success input,
.has-success .jsgrid-cell select,
.jsgrid-cell .has-success select,
.has-success .tablesaw-bar .btn-select select,
.tablesaw-bar .btn-select .has-success select,
.has-success .dd-handle,
.has-success .dd3-content,
.has-success .app-search .form-control:focus,
.app-search .has-success .form-control:focus,
.has-success .app-search .jsgrid-cell input:focus,
.app-search .jsgrid-cell .has-success input:focus,
.has-success .jsgrid-cell .app-search input:focus,
.jsgrid-cell .app-search .has-success input:focus,
.has-success .app-search .jsgrid-cell select:focus,
.app-search .jsgrid-cell .has-success select:focus,
.has-success .jsgrid-cell .app-search select:focus,
.jsgrid-cell .app-search .has-success select:focus,
.has-success .app-search .dd-handle:focus,
.app-search .has-success .dd-handle:focus,
.has-success .app-search .dd3-content:focus,
.app-search .has-success .dd3-content:focus {
  border-color: rgba(139, 195, 74, 0.5);
}
.has-success .form-control:focus,
.has-success .select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.select2-container--default .select2-selection--single .has-success .select2-selection__rendered:focus,
.has-success .select2-container--default .select2-selection--single:focus,
.select2-container--default .has-success .select2-selection--single:focus,
.has-success .select2-selection.select2-selection--multiple:focus,
.has-success .wizard .content > .body input:focus,
.wizard .content > .body .has-success input:focus,
.has-success .mce-floatpanel .mce-textbox:focus,
.mce-floatpanel .has-success .mce-textbox:focus,
.has-success .mce-floatpanel .mce-btn:focus,
.mce-floatpanel .has-success .mce-btn:focus,
.has-success .dataTables_wrapper .dataTables_filter input:focus,
.dataTables_wrapper .dataTables_filter .has-success input:focus,
.has-success .dataTables_wrapper .dataTables_length select:focus,
.dataTables_wrapper .dataTables_length .has-success select:focus,
.has-success .jsgrid-cell input:focus,
.jsgrid-cell .has-success input:focus,
.has-success .jsgrid-cell select:focus,
.jsgrid-cell .has-success select:focus,
.has-success .tablesaw-bar .btn-select select:focus,
.tablesaw-bar .btn-select .has-success select:focus,
.has-success .dd-handle:focus,
.has-success .dd3-content:focus {
  border-color: #8bc34a;
}

.has-warning .form-control,
.has-warning .select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .has-warning .select2-selection__rendered,
.has-warning .select2-container--default .select2-selection--single,
.select2-container--default .has-warning .select2-selection--single,
.has-warning .select2-selection.select2-selection--multiple,
.has-warning .wizard .content > .body input,
.wizard .content > .body .has-warning input,
.has-warning .mce-floatpanel .mce-textbox,
.mce-floatpanel .has-warning .mce-textbox,
.has-warning .mce-floatpanel .mce-btn,
.mce-floatpanel .has-warning .mce-btn,
.has-warning .dataTables_wrapper .dataTables_filter input,
.dataTables_wrapper .dataTables_filter .has-warning input,
.has-warning .dataTables_wrapper .dataTables_length select,
.dataTables_wrapper .dataTables_length .has-warning select,
.has-warning .jsgrid-cell input,
.jsgrid-cell .has-warning input,
.has-warning .jsgrid-cell select,
.jsgrid-cell .has-warning select,
.has-warning .tablesaw-bar .btn-select select,
.tablesaw-bar .btn-select .has-warning select,
.has-warning .dd-handle,
.has-warning .dd3-content,
.has-warning .app-search .form-control:focus,
.app-search .has-warning .form-control:focus,
.has-warning .app-search .jsgrid-cell input:focus,
.app-search .jsgrid-cell .has-warning input:focus,
.has-warning .jsgrid-cell .app-search input:focus,
.jsgrid-cell .app-search .has-warning input:focus,
.has-warning .app-search .jsgrid-cell select:focus,
.app-search .jsgrid-cell .has-warning select:focus,
.has-warning .jsgrid-cell .app-search select:focus,
.jsgrid-cell .app-search .has-warning select:focus,
.has-warning .app-search .dd-handle:focus,
.app-search .has-warning .dd-handle:focus,
.has-warning .app-search .dd3-content:focus,
.app-search .has-warning .dd3-content:focus {
  border-color: rgba(248, 179, 45, 0.5);
}
.has-warning .form-control:focus,
.has-warning .select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.select2-container--default .select2-selection--single .has-warning .select2-selection__rendered:focus,
.has-warning .select2-container--default .select2-selection--single:focus,
.select2-container--default .has-warning .select2-selection--single:focus,
.has-warning .select2-selection.select2-selection--multiple:focus,
.has-warning .wizard .content > .body input:focus,
.wizard .content > .body .has-warning input:focus,
.has-warning .mce-floatpanel .mce-textbox:focus,
.mce-floatpanel .has-warning .mce-textbox:focus,
.has-warning .mce-floatpanel .mce-btn:focus,
.mce-floatpanel .has-warning .mce-btn:focus,
.has-warning .dataTables_wrapper .dataTables_filter input:focus,
.dataTables_wrapper .dataTables_filter .has-warning input:focus,
.has-warning .dataTables_wrapper .dataTables_length select:focus,
.dataTables_wrapper .dataTables_length .has-warning select:focus,
.has-warning .jsgrid-cell input:focus,
.jsgrid-cell .has-warning input:focus,
.has-warning .jsgrid-cell select:focus,
.jsgrid-cell .has-warning select:focus,
.has-warning .tablesaw-bar .btn-select select:focus,
.tablesaw-bar .btn-select .has-warning select:focus,
.has-warning .dd-handle:focus,
.has-warning .dd3-content:focus {
  border-color: #f8b32d;
}

.has-error .form-control,
.has-error .select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .has-error .select2-selection__rendered,
.has-error .select2-container--default .select2-selection--single,
.select2-container--default .has-error .select2-selection--single,
.has-error .select2-selection.select2-selection--multiple,
.has-error .wizard .content > .body input,
.wizard .content > .body .has-error input,
.has-error .mce-floatpanel .mce-textbox,
.mce-floatpanel .has-error .mce-textbox,
.has-error .mce-floatpanel .mce-btn,
.mce-floatpanel .has-error .mce-btn,
.has-error .dataTables_wrapper .dataTables_filter input,
.dataTables_wrapper .dataTables_filter .has-error input,
.has-error .dataTables_wrapper .dataTables_length select,
.dataTables_wrapper .dataTables_length .has-error select,
.has-error .jsgrid-cell input,
.jsgrid-cell .has-error input,
.has-error .jsgrid-cell select,
.jsgrid-cell .has-error select,
.has-error .tablesaw-bar .btn-select select,
.tablesaw-bar .btn-select .has-error select,
.has-error .dd-handle,
.has-error .dd3-content,
.has-error .app-search .form-control:focus,
.app-search .has-error .form-control:focus,
.has-error .app-search .jsgrid-cell input:focus,
.app-search .jsgrid-cell .has-error input:focus,
.has-error .jsgrid-cell .app-search input:focus,
.jsgrid-cell .app-search .has-error input:focus,
.has-error .app-search .jsgrid-cell select:focus,
.app-search .jsgrid-cell .has-error select:focus,
.has-error .jsgrid-cell .app-search select:focus,
.jsgrid-cell .app-search .has-error select:focus,
.has-error .app-search .dd-handle:focus,
.app-search .has-error .dd-handle:focus,
.has-error .app-search .dd3-content:focus,
.app-search .has-error .dd3-content:focus {
  border-color: rgba(243, 57, 35, 0.5);
}
.has-error .form-control:focus,
.has-error .select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.select2-container--default .select2-selection--single .has-error .select2-selection__rendered:focus,
.has-error .select2-container--default .select2-selection--single:focus,
.select2-container--default .has-error .select2-selection--single:focus,
.has-error .select2-selection.select2-selection--multiple:focus,
.has-error .wizard .content > .body input:focus,
.wizard .content > .body .has-error input:focus,
.has-error .mce-floatpanel .mce-textbox:focus,
.mce-floatpanel .has-error .mce-textbox:focus,
.has-error .mce-floatpanel .mce-btn:focus,
.mce-floatpanel .has-error .mce-btn:focus,
.has-error .dataTables_wrapper .dataTables_filter input:focus,
.dataTables_wrapper .dataTables_filter .has-error input:focus,
.has-error .dataTables_wrapper .dataTables_length select:focus,
.dataTables_wrapper .dataTables_length .has-error select:focus,
.has-error .jsgrid-cell input:focus,
.jsgrid-cell .has-error input:focus,
.has-error .jsgrid-cell select:focus,
.jsgrid-cell .has-error select:focus,
.has-error .tablesaw-bar .btn-select select:focus,
.tablesaw-bar .btn-select .has-error select:focus,
.has-error .dd-handle:focus,
.has-error .dd3-content:focus {
  border-color: #f33923;
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
  color: #8bc34a;
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
  color: #f8b32d;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: #f33923;
}

.has-error .input-group-addon {
  background-color: transparent;
  color: #f33923;
  border-color: rgba(243, 57, 35, 0.5);
}

.has-success .input-group-addon {
  background-color: transparent;
  color: #8bc34a;
  border-color: rgba(139, 195, 74, 0.5);
}

.has-success .form-control-feedback {
  color: #8bc34a;
}

.has-warning .form-control-feedback {
  color: #f8b32d;
}

.has-error .form-control-feedback {
  color: #f33923;
}

div.colorpicker {
  padding: 0;
}

.colorpicker {
  z-index: 10;
  margin: 0;
}
.colorpicker::before,
.colorpicker::after {
  display: none;
}

.daterangepicker .input-mini {
  border-color: #dedede;
  border-radius: 0;
  color: #212121;
}
.daterangepicker .input-mini.active {
  border-color: #dedede;
  border-radius: 0;
  color: #212121;
}
.daterangepicker .input-mini:focus {
  border-color: #878787;
  box-shadow: none !important;
  outline: 0 none;
}
.daterangepicker td {
  border-radius: 0 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover,
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background: #8bc34a;
}

.bootstrap-datetimepicker-widget table td.day {
  border-radius: 0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-results__option[aria-selected='true'] {
  padding-left: 12px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single {
  line-height: 40px;
}

.select2-container--default .select2-selection--single {
  border: none;
}

.select2-container--default {
  width: 100% !important;
}
.select2-container--default .select2-results__group,
.select2-container--default .select2-results__options--nested li {
  padding-left: 12px;
}
.select2-container--default.select2-container--open .select2-selection__rendered {
  border-color: #878787;
}
.select2-container--default .select2-selection__arrow {
  height: 40px !important;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding-left: 12px;
  line-height: 40px;
}
.select2-container .select2-selection--multiple .select2-search__field {
  margin-top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 0;
  color: #fff;
  padding: 8px 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  background: #8bc34a;
  border: none;
  line-height: 10px;
  font-size: 12px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
}

.bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:active {
  outline: none !important;
}
.bootstrap-select .btn.dropdown-toggle,
.bootstrap-select .wizard > .actions a.dropdown-toggle,
.wizard > .actions .bootstrap-select a.dropdown-toggle,
.bootstrap-select .dt-buttons .dropdown-toggle.dt-button,
.dt-buttons .bootstrap-select .dropdown-toggle.dt-button,
.bootstrap-select .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle,
.tablesaw-sortable th.tablesaw-sortable-head .bootstrap-select button.dropdown-toggle,
.bootstrap-select .sweet-alert button.dropdown-toggle,
.sweet-alert .bootstrap-select button.dropdown-toggle,
.bootstrap-select .owl-theme .owl-nav .dropdown-toggle[class*='owl-'],
.owl-theme .owl-nav .bootstrap-select .dropdown-toggle[class*='owl-'],
.bootstrap-select button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,
.bootstrap-select button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,
.bootstrap-select button.dropdown-toggle.fc-agendaWeek-button,
.bootstrap-select .dropdown-toggle.fc-prev-button,
.bootstrap-select .dropdown-toggle.fc-next-button,
.bootstrap-select .dropdown-toggle.fc-today-button {
  box-shadow: none;
  border: 1px solid rgba(33, 33, 33, 0.12);
}
.bootstrap-select .btn .filter-option,
.bootstrap-select .wizard > .actions a .filter-option,
.wizard > .actions .bootstrap-select a .filter-option,
.bootstrap-select .dt-buttons .dt-button .filter-option,
.dt-buttons .bootstrap-select .dt-button .filter-option,
.bootstrap-select .tablesaw-sortable th.tablesaw-sortable-head button .filter-option,
.tablesaw-sortable th.tablesaw-sortable-head .bootstrap-select button .filter-option,
.bootstrap-select .sweet-alert button .filter-option,
.sweet-alert .bootstrap-select button .filter-option,
.bootstrap-select .owl-theme .owl-nav [class*='owl-'] .filter-option,
.owl-theme .owl-nav .bootstrap-select [class*='owl-'] .filter-option,
.bootstrap-select button.fc-agendaDay-button.fc-state-default.fc-corner-right .filter-option,
.bootstrap-select button.fc-month-button.fc-state-default.fc-corner-left .filter-option,
.bootstrap-select button.fc-agendaWeek-button .filter-option,
.bootstrap-select .fc-prev-button .filter-option,
.bootstrap-select .fc-next-button .filter-option,
.bootstrap-select .fc-today-button .filter-option {
  color: #212121;
}

.sm-bootstrap-select .bootstrap-select .btn.dropdown-toggle,
.sm-bootstrap-select .bootstrap-select .wizard > .actions a.dropdown-toggle,
.wizard > .actions .sm-bootstrap-select .bootstrap-select a.dropdown-toggle,
.sm-bootstrap-select .bootstrap-select .dt-buttons .dropdown-toggle.dt-button,
.dt-buttons .sm-bootstrap-select .bootstrap-select .dropdown-toggle.dt-button,
.sm-bootstrap-select .bootstrap-select .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle,
.tablesaw-sortable th.tablesaw-sortable-head .sm-bootstrap-select .bootstrap-select button.dropdown-toggle,
.sm-bootstrap-select .bootstrap-select .sweet-alert button.dropdown-toggle,
.sweet-alert .sm-bootstrap-select .bootstrap-select button.dropdown-toggle,
.sm-bootstrap-select .bootstrap-select .owl-theme .owl-nav .dropdown-toggle[class*='owl-'],
.owl-theme .owl-nav .sm-bootstrap-select .bootstrap-select .dropdown-toggle[class*='owl-'],
.sm-bootstrap-select .bootstrap-select button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,
.sm-bootstrap-select .bootstrap-select button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,
.sm-bootstrap-select .bootstrap-select button.dropdown-toggle.fc-agendaWeek-button,
.sm-bootstrap-select .bootstrap-select .dropdown-toggle.fc-prev-button,
.sm-bootstrap-select .bootstrap-select .dropdown-toggle.fc-next-button,
.sm-bootstrap-select .bootstrap-select .dropdown-toggle.fc-today-button {
  font-size: 12px;
  height: 27px;
  padding: 3px 25px 3px 15px;
}
.sm-bootstrap-select .bootstrap-select .dropdown-menu li a span.text {
  font-size: 12px;
}

.switchery {
  margin-right: 10px;
  margin-top: 15px;
}

.no-margin-switcher .switchery {
  margin-top: 0;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn,
.bootstrap-touchspin .wizard > .actions .disabled .input-group-btn-vertical > a,
.bootstrap-touchspin .wizard > .actions .input-group-btn-vertical > a,
.wizard > .actions .bootstrap-touchspin .input-group-btn-vertical > a,
.bootstrap-touchspin .dt-buttons .input-group-btn-vertical > .dt-button,
.dt-buttons .bootstrap-touchspin .input-group-btn-vertical > .dt-button,
.bootstrap-touchspin .tablesaw-sortable th.tablesaw-sortable-head .input-group-btn-vertical > button,
.tablesaw-sortable th.tablesaw-sortable-head .bootstrap-touchspin .input-group-btn-vertical > button,
.bootstrap-touchspin .sweet-alert .input-group-btn-vertical > button,
.sweet-alert .bootstrap-touchspin .input-group-btn-vertical > button,
.bootstrap-touchspin .owl-theme .owl-nav .input-group-btn-vertical > [class*='owl-'],
.owl-theme .owl-nav .bootstrap-touchspin .input-group-btn-vertical > [class*='owl-'],
.bootstrap-touchspin .input-group-btn-vertical > button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.bootstrap-touchspin .input-group-btn-vertical > button.fc-month-button.fc-state-default.fc-corner-left,
.bootstrap-touchspin .input-group-btn-vertical > button.fc-agendaWeek-button,
.bootstrap-touchspin .input-group-btn-vertical > .fc-prev-button,
.bootstrap-touchspin .input-group-btn-vertical > .fc-next-button,
.bootstrap-touchspin .input-group-btn-vertical > .fc-today-button {
  border-radius: 0;
  padding: 10px;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn i,
.bootstrap-touchspin .wizard > .actions .input-group-btn-vertical > a i,
.wizard > .actions .bootstrap-touchspin .input-group-btn-vertical > a i,
.bootstrap-touchspin .dt-buttons .input-group-btn-vertical > .dt-button i,
.dt-buttons .bootstrap-touchspin .input-group-btn-vertical > .dt-button i,
.bootstrap-touchspin .tablesaw-sortable th.tablesaw-sortable-head .input-group-btn-vertical > button i,
.tablesaw-sortable th.tablesaw-sortable-head .bootstrap-touchspin .input-group-btn-vertical > button i,
.bootstrap-touchspin .sweet-alert .input-group-btn-vertical > button i,
.sweet-alert .bootstrap-touchspin .input-group-btn-vertical > button i,
.bootstrap-touchspin .owl-theme .owl-nav .input-group-btn-vertical > [class*='owl-'] i,
.owl-theme .owl-nav .bootstrap-touchspin .input-group-btn-vertical > [class*='owl-'] i,
.bootstrap-touchspin .input-group-btn-vertical > button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.bootstrap-touchspin .input-group-btn-vertical > button.fc-month-button.fc-state-default.fc-corner-left i,
.bootstrap-touchspin .input-group-btn-vertical > button.fc-agendaWeek-button i,
.bootstrap-touchspin .input-group-btn-vertical > .fc-prev-button i,
.bootstrap-touchspin .input-group-btn-vertical > .fc-next-button i,
.bootstrap-touchspin .input-group-btn-vertical > .fc-today-button i {
  top: 6px;
}

.bootstrap-touchspin .btn.btn-default.dropdown-toggle,
.bootstrap-touchspin .wizard > .actions a.btn-default.dropdown-toggle,
.wizard > .actions .bootstrap-touchspin a.btn-default.dropdown-toggle,
.bootstrap-touchspin .dt-buttons .btn-default.dropdown-toggle.dt-button,
.dt-buttons .bootstrap-touchspin .btn-default.dropdown-toggle.dt-button,
.bootstrap-touchspin .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle,
.tablesaw-sortable th.tablesaw-sortable-head .bootstrap-touchspin button.dropdown-toggle,
.bootstrap-touchspin .sweet-alert button.btn-default.dropdown-toggle,
.sweet-alert .bootstrap-touchspin button.btn-default.dropdown-toggle,
.bootstrap-touchspin .owl-theme .owl-nav .dropdown-toggle[class*='owl-'],
.owl-theme .owl-nav .bootstrap-touchspin .dropdown-toggle[class*='owl-'],
.bootstrap-touchspin button.btn-default.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,
.bootstrap-touchspin button.btn-default.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,
.bootstrap-touchspin button.btn-default.dropdown-toggle.fc-agendaWeek-button,
.bootstrap-touchspin .btn-default.dropdown-toggle.fc-prev-button,
.bootstrap-touchspin .btn-default.dropdown-toggle.fc-next-button,
.bootstrap-touchspin .btn-default.dropdown-toggle.fc-today-button {
  color: #fff !important;
}

.ms-container .ms-list {
  border: 1px solid #dedede;
  border-radius: 0;
  box-shadow: none;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  background: #8bc34a;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  background: #8bc34a;
  border-radius: 0;
}

.bootstrap-switch {
  border-radius: 0;
  border-color: #dedede;
  box-shadow: none;
}
.bootstrap-switch.bootstrap-switch-focused {
  border-color: #dedede;
  box-shadow: none;
}

.bootstrap-tagsinput {
  border: 1px solid #dedede;
  border-radius: 0;
  box-shadow: none;
  color: #878787;
}
.bootstrap-tagsinput .tag {
  border-radius: 0;
  color: white;
  background: #8bc34a;
}

.tag.label,
.tag.jvectormap-tip {
  padding: 8px 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
}

.editableform .editable-buttons .btn.btn-sm,
.editableform .editable-buttons .wizard > .actions a.btn-sm,
.wizard > .actions .editableform .editable-buttons a.btn-sm,
.editableform .editable-buttons .dt-buttons .btn-sm.dt-button,
.dt-buttons .editableform .editable-buttons .btn-sm.dt-button,
.editableform .editable-buttons .tablesaw-sortable th.tablesaw-sortable-head button.btn-sm,
.tablesaw-sortable th.tablesaw-sortable-head .editableform .editable-buttons button.btn-sm,
.editableform .editable-buttons .sweet-alert button.btn-sm,
.sweet-alert .editableform .editable-buttons button.btn-sm,
.editableform .editable-buttons .owl-theme .owl-nav .btn-sm[class*='owl-'],
.owl-theme .owl-nav .editableform .editable-buttons .btn-sm[class*='owl-'],
.editableform .editable-buttons button.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,
.editableform .editable-buttons button.btn-sm.fc-month-button.fc-state-default.fc-corner-left,
.editableform .editable-buttons button.btn-sm.fc-agendaWeek-button,
.editableform .editable-buttons .btn-sm.fc-prev-button,
.editableform .editable-buttons .btn-sm.fc-next-button,
.editableform .editable-buttons .btn-sm.fc-today-button {
  padding: 9px 25px;
}
.editableform .form-control.input-sm,
.editableform .select2-container--default .select2-selection--single .input-sm.select2-selection__rendered,
.select2-container--default .select2-selection--single .editableform .input-sm.select2-selection__rendered,
.editableform .select2-container--default .input-sm.select2-selection--single,
.select2-container--default .editableform .input-sm.select2-selection--single,
.editableform .input-sm.select2-selection.select2-selection--multiple,
.editableform .wizard .content > .body input.input-sm,
.wizard .content > .body .editableform input.input-sm,
.editableform .mce-floatpanel .input-sm.mce-textbox,
.mce-floatpanel .editableform .input-sm.mce-textbox,
.editableform .mce-floatpanel .input-sm.mce-btn,
.mce-floatpanel .editableform .input-sm.mce-btn,
.editableform .dataTables_wrapper .dataTables_filter input.input-sm,
.dataTables_wrapper .dataTables_filter .editableform input.input-sm,
.editableform .dataTables_wrapper .dataTables_length select.input-sm,
.dataTables_wrapper .dataTables_length .editableform select.input-sm,
.editableform .jsgrid-cell input.input-sm,
.jsgrid-cell .editableform input.input-sm,
.editableform .jsgrid-cell select.input-sm,
.jsgrid-cell .editableform select.input-sm,
.editableform .tablesaw-bar .btn-select select.input-sm,
.tablesaw-bar .btn-select .editableform select.input-sm,
.editableform .input-sm.dd-handle,
.editableform .input-sm.dd3-content {
  padding: 6px 12px;
  font-size: 14px;
}
.editableform select.form-control.input-sm,
.editableform .select2-container--default .select2-selection--single select.input-sm.select2-selection__rendered,
.select2-container--default .select2-selection--single .editableform select.input-sm.select2-selection__rendered,
.editableform .select2-container--default select.input-sm.select2-selection--single,
.select2-container--default .editableform select.input-sm.select2-selection--single,
.editableform select.input-sm.select2-selection.select2-selection--multiple,
.editableform .mce-floatpanel select.input-sm.mce-textbox,
.mce-floatpanel .editableform select.input-sm.mce-textbox,
.editableform .mce-floatpanel select.input-sm.mce-btn,
.mce-floatpanel .editableform select.input-sm.mce-btn,
.editableform .dataTables_wrapper .dataTables_length select.input-sm,
.dataTables_wrapper .dataTables_length .editableform select.input-sm,
.editableform .jsgrid-cell select.input-sm,
.jsgrid-cell .editableform select.input-sm,
.editableform .tablesaw-bar .btn-select select.input-sm,
.tablesaw-bar .btn-select .editableform select.input-sm,
.editableform select.input-sm.dd-handle,
.editableform select.input-sm.dd3-content {
  height: 42px;
  line-height: 1.428;
}

.dropify-wrapper {
  border: 1px solid #dedede;
}

.dropzone {
  border: 1px solid #dedede;
  min-height: 200px;
  background: transparent;
}
.dropzone .dz-preview.dz-image-preview {
  background: transparent none repeat scroll 0 0;
}

/*Form-Wizard*/
.wizard .content {
  background: transparent;
  border-radius: 0;
  min-height: 100px;
  margin: 0;
  border: none;
  border-top: none;
  margin-bottom: 30px;
}
.wizard .content .table-responsive {
  margin: 0 -30px;
}
.wizard .content > .body {
  width: 100%;
  height: auto;
  padding: 30px;
  position: relative;
}
.wizard .content > .body input.error {
  border-color: rgba(241, 91, 38, 0.5) !important;
  background: transparent;
}
.wizard .steps .number {
  font-size: 18px;
  color: #878787;
}
.wizard .steps ul {
  display: table;
  width: 100%;
}
.wizard .steps ul > li {
  display: table-cell;
  background: #dedede;
}
.wizard .steps ul > li.done {
  background: #8bc34a;
}
.wizard .steps ul > li.current {
  background: #f8b32d !important;
}
.wizard .steps ul > li > a {
  margin: 0;
  padding: 14px 20px;
  background: transparent !important;
}
.wizard .steps ul > li > a:hover,
.wizard .steps ul > li > a:focus,
.wizard .steps ul > li > a:active {
  margin: 0;
  padding: 14px 20px;
}
.wizard .steps ul > li span.number {
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  padding-top: 3px;
  margin-right: 8px;
  text-align: center;
  background: white;
}
.wizard .steps ul > li.disabled a {
  cursor: not-allowed !important;
}
.wizard > .actions {
  padding-right: 15px;
}
.wizard > .actions > ul > li {
  background: transparent none repeat scroll 0 0;
  display: inline-block;
  float: none;
  margin: 0;
  margin-left: 10px;
  padding: 0;
}
.wizard .bootstrap-touchspin {
  width: 70px;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.65;
}

.wizard > .content > .body label.error {
  display: none !important;
}

.current {
  color: inherit !important;
}

.wizard > .content > .body ul.cards > li {
  display: inline-block;
}

.tabcontrol > .content {
  border-top: medium none;
  height: auto;
  padding-top: 0;
}
.tabcontrol > .content > .body {
  position: relative;
  height: auto;
  padding: 15px 0 0 0;
  width: 100%;
}

.tabcontrol > .steps > ul {
  border-bottom: 1px solid #f7f7f9;
  margin-top: 0;
  float: left;
  width: 100%;
  top: 0;
}
.tabcontrol > .steps > ul > li {
  border: medium none;
  margin: 0 5px 0 0;
  padding: 0;
  background: transparent;
}
.tabcontrol > .steps > ul > li:hover,
.tabcontrol > .steps > ul > li:focus,
.tabcontrol > .steps > ul > li:active {
  background: transparent;
  border: medium none;
}
.tabcontrol > .steps > ul > li > a {
  background: transparent;
  border: none;
  padding: 10px 20px;
  color: #878787;
  margin: 0;
  border-radius: 0;
  text-transform: capitalize;
}
.tabcontrol > .steps > ul > li > a:active,
.tabcontrol > .steps > ul > li > a:focus,
.tabcontrol > .steps > ul > li > a:hover {
  color: #212121;
}
.tabcontrol > .steps > ul > li.current {
  border: medium none;
  padding: 0;
}
.tabcontrol > .steps > ul > li.current > a {
  color: #212121;
  padding: 10px 20px;
  border-bottom: 3px solid #8bc34a;
}
.tabcontrol > .steps > ul > li.current > a:active,
.tabcontrol > .steps > ul > li.current > a:focus,
.tabcontrol > .steps > ul > li.current > a:hover {
  color: #212121;
}
.tabcontrol > .steps > ul > li:first-child > a {
  border-top-left-radius: 10px;
}
.tabcontrol > .steps > ul > li:last-child {
  margin-right: 0;
}
.tabcontrol > .steps > ul > li:last-child > a {
  border-top-right-radius: 10px;
}

.review-tag {
  font-size: 18px;
}

/*Product Deatails*/
.item-big > img {
  border-radius: 2px;
}

.product-detail-wrap .product-price {
  font-size: 18px;
  color: #8bc34a;
}
.product-detail-wrap .bootstrap-touchspin {
  float: left;
  margin-right: 15px;
  width: 70px;
}

.product-rating a {
  color: #f33923;
  margin-right: 5px;
}

/*Product Cart*/
.product-overview .bootstrap-touchspin {
  width: 70px;
}

/*Popover*/
.popover {
  border: medium none;
  border-radius: 0;
  padding: 0;
}
.popover h3.popover-title,
.popover .popover-title.error-comment {
  background-color: #8bc34a;
  border-bottom: 1px solid #dedede;
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  font-size: 15px;
}
.popover .popover-content {
  font-family: 'Roboto', sans-serif;
}

.popover.bottom > .arrow::after {
  border-bottom-color: #8bc34a;
}

/*Typerhead*/
.typeahead,
.tt-query,
.tt-hint {
  width: 396px;
}

.tt-menu {
  width: 422px;
  margin: 12px 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
  padding: 3px 20px;
  border-radius: 0;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #8bc34a;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}

#custom-templates .empty-message {
  padding: 5px 10px;
  text-align: center;
}

#multiple-datasets .league-name {
  margin: 0 20px 5px 20px;
  padding: 3px 0;
  border-bottom: 1px solid #ccc;
}

#scrollable-dropdown-menu .tt-menu {
  max-height: 150px;
  overflow-y: auto;
}

#rtl-support .tt-menu {
  text-align: right;
}

/*Editors*/
.bootstrap-wysihtml5-insert-image-modal h3,
.bootstrap-wysihtml5-insert-image-modal .error-comment {
  font-size: 18px;
}

.bootstrap-wysihtml5-insert-link-modal h3,
.bootstrap-wysihtml5-insert-link-modal .error-comment {
  font-size: 18px;
}
.bootstrap-wysihtml5-insert-link-modal .checkbox {
  margin-bottom: inherit;
  margin-top: inherit;
  min-height: inherit;
  padding-bottom: inherit;
  padding-top: inherit;
}
.bootstrap-wysihtml5-insert-link-modal .checkbox input[type='checkbox'] {
  opacity: 1;
}
.bootstrap-wysihtml5-insert-link-modal .checkbox label::before {
  display: none;
}

ul.wysihtml5-toolbar a.btn.wysihtml5-command-active,
ul.wysihtml5-toolbar .wizard > .actions a.wysihtml5-command-active,
.wizard > .actions ul.wysihtml5-toolbar a.wysihtml5-command-active,
ul.wysihtml5-toolbar .dt-buttons a.wysihtml5-command-active.dt-button,
.dt-buttons ul.wysihtml5-toolbar a.wysihtml5-command-active.dt-button,
ul.wysihtml5-toolbar .owl-theme .owl-nav a.wysihtml5-command-active[class*='owl-'],
.owl-theme .owl-nav ul.wysihtml5-toolbar a.wysihtml5-command-active[class*='owl-'],
ul.wysihtml5-toolbar a.wysihtml5-command-active.fc-prev-button,
ul.wysihtml5-toolbar a.wysihtml5-command-active.fc-next-button,
ul.wysihtml5-toolbar a.wysihtml5-command-active.fc-today-button {
  box-shadow: none;
}

.mce-container,
.mce-container *,
.mce-widget,
.mce-widget *,
.mce-reset {
  font-family: 'Roboto', sans-serif !important;
}

.mce-ico {
  font-family: 'tinymce', Arial !important;
}

.mce-floatpanel .mce-btn {
  background: transparent;
}
.mce-floatpanel .mce-btn:hover {
  background: transparent;
}
.mce-floatpanel .mce-btn.mce-active {
  background-color: transparent !important;
}
.mce-floatpanel .mce-btn.mce-active:hover {
  background-color: transparent;
  border-color: #dedede;
}
.mce-floatpanel .mce-window-body .mce-listbox {
  border-color: #dedede;
}
.mce-floatpanel .mce-window-head .mce-title {
  font-size: 18px;
  font-weight: 400;
}
.mce-floatpanel .mce-primary button {
  background: #8bc34a;
  border: solid 2px #8bc34a;
}
.mce-floatpanel .mce-primary button:hover,
.mce-floatpanel .mce-primary button:active,
.mce-floatpanel .mce-primary button:focus {
  background: rgba(139, 195, 74, 0.85);
  border: solid 2px rgba(139, 195, 74, 0.1);
}
.mce-floatpanel .mce-menu-item-normal.mce-active {
  background-color: #8bc34a;
}
.mce-floatpanel .mce-menu-item:hover,
.mce-floatpanel .mce-menu-item.mce-selected,
.mce-floatpanel .mce-menu-item:focus {
  background-color: #8bc34a;
}

.mce-foot button {
  background: #f33923;
  color: #fff !important;
  border: solid 2px #f33923;
}
.mce-foot button:hover,
.mce-foot button:active,
.mce-foot button:focus {
  background: rgba(243, 57, 35, 0.85);
  border: solid 2px rgba(243, 57, 35, 0.1);
}

.mce-panel {
  border-color: #dedede !important;
}

.btn-group.open .note-btn.dropdown-toggle {
  box-shadow: none;
}

.note-editor.panel {
  border-radius: 0;
  box-shadow: none;
}
.note-editor.panel .panel-heading {
  background: transparent;
}
.note-editor .checkbox {
  margin-bottom: inherit;
  margin-top: inherit;
  min-height: inherit;
  padding-bottom: inherit;
  padding-top: inherit;
}
.note-editor .checkbox input[type='checkbox'] {
  opacity: 1;
}
.note-editor .checkbox label::before {
  display: none;
}
.note-editor .note-btn.btn-sm {
  padding: 9px 25px;
  font-size: 14px;
}
.note-editor.note-frame {
  border: 1px solid #dedede;
}
.note-editor .note-toolbar.panel-heading {
  border: 1px solid #dedede;
}
.note-editor h4 {
  font-size: 18px;
}

/*Accordion*/
.accordion-struct.panel-group .panel {
  border: medium none;
  border-radius: 0;
  margin-bottom: 0;
  margin-top: 0;
  box-shadow: none;
}
.accordion-struct.panel-group .panel .panel-heading {
  border-radius: 0;
  padding: 0;
  background: rgba(33, 33, 33, 0.05);
  border-bottom: 1px solid #dedede;
}
.accordion-struct.panel-group .panel .panel-heading.activestate {
  background: #8bc34a;
  border-bottom: 1px solid #8bc34a;
}
.accordion-struct.panel-group .panel .panel-heading.activestate a {
  color: #fff;
}
.accordion-struct.panel-group .panel .panel-heading a {
  color: #878787;
  text-transform: capitalize;
  padding: 10px 20px;
  width: 100%;
  display: block;
}
.accordion-struct.panel-group .panel:last-child .panel-heading {
  border-bottom: none;
}
.accordion-struct.panel-group .panel .panel-body {
  border: none;
}
.accordion-struct.accordion-style-1.panel-group .panel .panel-heading {
  background: transparent;
  border-bottom: 1px solid #dedede;
}
.accordion-struct.accordion-style-1.panel-group .panel .panel-heading.activestate {
  background: transparent;
}
.accordion-struct.accordion-style-1.panel-group .panel .panel-heading.activestate .icon-ac-wrap .minus-ac {
  opacity: 1;
}
.accordion-struct.accordion-style-1.panel-group .panel .panel-heading.activestate .icon-ac-wrap .plus-ac {
  opacity: 0;
}
.accordion-struct.accordion-style-1.panel-group .panel .panel-heading.activestate a {
  color: #878787;
}
.accordion-struct.accordion-style-1.panel-group .panel .panel-heading a {
  color: #878787;
}
.accordion-struct.accordion-style-1 .icon-ac-wrap {
  display: inline;
  position: relative;
  color: #8bc34a;
}
.accordion-struct.accordion-style-1 .icon-ac-wrap .minus-ac {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.accordion-struct.accordion-style-1 .icon-ac-wrap .plus-ac {
  opacity: 1;
}

/*Tabs*/
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border: none;
}

.tab-struct .tab-pane {
  padding-top: 15px;
}
.tab-struct.vertical-tab .tab-pane {
  padding-top: 0;
  padding-left: 15px;
}
.tab-struct.vertical-tab-reverse .tab-pane {
  padding-left: 0;
  padding-right: 15px;
}

.pills-struct .tab-pane {
  padding-top: 15px;
}
.pills-struct.vertical-pills .tab-pane {
  padding-top: 0;
  padding-left: 15px;
}
.pills-struct.vertical-pills-reverse .tab-pane {
  padding-left: 0;
  padding-right: 15px;
}

.nav-tabs {
  border-bottom: 1px solid #dedede;
}
.nav-tabs > li {
  margin-right: 5px;
}
.nav-tabs > li > a {
  background: #dedede;
  border: none;
  padding: 10px 20px;
  color: #878787;
  margin: 0;
  border-radius: 0;
  text-transform: capitalize;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  transition: 0.2s ease;
}
.nav-tabs > li > a:active,
.nav-tabs > li > a:focus,
.nav-tabs > li > a:hover {
  background: #f2f2f2;
  color: #212121;
}
.nav-tabs > li.active > a,
.nav-tabs > li.open > a {
  background: #f2f2f2;
  color: #212121;
  border-color: transparent;
}
.nav-tabs > li.active > a:active,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.open > a:active,
.nav-tabs > li.open > a:focus,
.nav-tabs > li.open > a:hover {
  background: #f2f2f2;
  color: #212121;
  border-color: transparent;
}
.nav-tabs > li:first-child > a {
  border-top-left-radius: 10px;
}
.nav-tabs > li:last-child {
  margin-right: 0;
}
.nav-tabs > li:last-child > a {
  border-top-right-radius: 10px;
}

.nav-tab-reverse .nav-tabs > li {
  float: right;
  margin-right: 0;
  margin-left: 5px;
}
.nav-tab-reverse .nav-tabs > li:first-child > a {
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
}
.nav-tab-reverse .nav-tabs > li:last-child {
  margin-left: 0;
}
.nav-tab-reverse .nav-tabs > li:last-child > a {
  border-top-right-radius: 0;
  border-top-left-radius: 10px;
}

.vertical-tab .tab-content {
  overflow: hidden;
  border-left: 1px solid #dedede;
}
.vertical-tab .ver-nav-tab {
  float: left;
  border-bottom: none;
}
.vertical-tab .ver-nav-tab > li {
  margin-bottom: 5px;
  margin-right: -1px;
  float: none;
}
.vertical-tab .ver-nav-tab > li > a {
  border: none;
}
.vertical-tab .ver-nav-tab > li:first-child > a {
  border-top-left-radius: 10px;
}
.vertical-tab .ver-nav-tab > li:last-child {
  margin-bottom: 0;
}
.vertical-tab .ver-nav-tab > li:last-child > a {
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0;
}
.vertical-tab.vertical-tab-reverse .tab-content {
  border-left: none;
  border-right: 1px solid #dedede;
}
.vertical-tab.vertical-tab-reverse .ver-nav-tab {
  float: right;
}
.vertical-tab.vertical-tab-reverse .ver-nav-tab > li:first-child > a {
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
}
.vertical-tab.vertical-tab-reverse .ver-nav-tab > li:last-child > a {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 0;
}

.custom-tab-1 .nav-tabs > li > a {
  background: transparent !important;
  border: none;
}
.custom-tab-1 .nav-tabs > li.active > a {
  border-bottom: 3px solid #8bc34a;
}
.custom-tab-1.vertical-tab .ver-nav-tab > li {
  background: transparent !important;
}
.custom-tab-1.vertical-tab .ver-nav-tab > li.active > a {
  border-bottom: none;
  border-right: 3px solid #8bc34a;
}
.custom-tab-1.vertical-tab.vertical-tab-reverse > li.active > a {
  border-right: none;
  border-left: 3px solid #8bc34a;
}

.custom-tab-2 .nav-tabs {
  border: none;
}
.custom-tab-2 .nav-tabs > li > a {
  background: transparent;
  border-radius: 0;
}
.custom-tab-2 .nav-tabs > li > a:active,
.custom-tab-2 .nav-tabs > li > a:focus,
.custom-tab-2 .nav-tabs > li > a:hover {
  color: #8bc34a;
}
.custom-tab-2 .nav-tabs > li.active > a,
.custom-tab-2 .nav-tabs > li.open > a {
  color: #8bc34a;
  background: #f2f2f2;
}
.custom-tab-2 .nav-tabs > li.active > a:active,
.custom-tab-2 .nav-tabs > li.active > a:focus,
.custom-tab-2 .nav-tabs > li.active > a:hover,
.custom-tab-2 .nav-tabs > li.open > a:active,
.custom-tab-2 .nav-tabs > li.open > a:focus,
.custom-tab-2 .nav-tabs > li.open > a:hover {
  color: #8bc34a;
  background: #f2f2f2;
}
.custom-tab-2 .nav-tabs > li:first-child > a,
.custom-tab-2 .nav-tabs > li:last-child > a {
  border-radius: 0;
}
.custom-tab-2.nav-tab-reverse .nav-tabs > li:first-child > a,
.custom-tab-2.nav-tab-reverse .nav-tabs > li:last-child > a {
  border-radius: 0;
}
.custom-tab-2 .tab-pane {
  background: #f2f2f2;
  padding: 15px !important;
}

.nav-pills > li {
  margin-right: 5px;
}
.nav-pills > li > a {
  background: #f7f7f9;
  border: none;
  padding: 10px 20px;
  color: #878787;
  margin: 0;
  border-radius: 0;
  text-transform: capitalize;
}
.nav-pills > li > a:active,
.nav-pills > li > a:focus,
.nav-pills > li > a:hover {
  background: #8bc34a;
  color: #fff;
}
.nav-pills > li.active > a,
.nav-pills > li.open > a {
  background: #8bc34a;
  color: #fff;
}
.nav-pills > li.active > a:active,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover,
.nav-pills > li.open > a:active,
.nav-pills > li.open > a:focus,
.nav-pills > li.open > a:hover {
  background: #8bc34a;
  color: #fff;
}
.nav-pills > li:last-child {
  margin-right: 0;
}

.nav-pills-reverse .nav-pills > li {
  float: right;
  margin-right: 0;
  margin-left: 5px;
}
.nav-pills-reverse .nav-pills > li:last-child {
  margin-left: 0;
}

.vertical-pills .tab-content {
  overflow: hidden;
  padding-left: 15px;
}
.vertical-pills .ver-nav-pills {
  float: left;
}
.vertical-pills .ver-nav-pills > li {
  margin: 0 0 5px;
  float: none;
}
.vertical-pills .ver-nav-pills > li:last-child {
  margin-bottom: 0;
}
.vertical-pills.vertical-pills-reverse .tab-content {
  padding-left: 0;
  padding-right: 15px;
}
.vertical-pills.vertical-pills-reverse .ver-nav-pills {
  float: right;
}

.nav-pills-rounded.nav-pills > li > a {
  border-radius: 60px;
}

.nav-pills-outline.nav-pills > li > a {
  background-color: transparent !important;
  border: 2px solid #f7f7f9;
  color: #878787;
}
.nav-pills-outline.nav-pills > li > a:hover,
.nav-pills-outline.nav-pills > li > a:active,
.nav-pills-outline.nav-pills > li > a:focus {
  border-color: #8bc34a;
  color: #212121;
}
.nav-pills-outline.nav-pills > li.active > a {
  background-color: transparent !important;
  border: 2px solid #8bc34a;
  color: #212121;
}
.nav-pills-outline.nav-pills > li.active > a:hover,
.nav-pills-outline.nav-pills > li.active > a:active,
.nav-pills-outline.nav-pills > li.active > a:focus {
  border: 2px solid #8bc34a;
  color: #212121;
}

/*Table*/
.table > tbody > tr.active > td,
.jsgrid-table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.jsgrid-table > tbody > tr.active > th,
.table > tbody > tr > td.active,
.jsgrid-table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.jsgrid-table > tbody > tr > th.active,
.table > tfoot > tr.active > td,
.jsgrid-table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
.jsgrid-table > tfoot > tr.active > th,
.table > tfoot > tr > td.active,
.jsgrid-table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.jsgrid-table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.jsgrid-table > thead > tr.active > td,
.table > thead > tr.active > th,
.jsgrid-table > thead > tr.active > th,
.table > thead > tr > td.active,
.jsgrid-table > thead > tr > td.active,
.table > thead > tr > th.active,
.jsgrid-table > thead > tr > th.active {
  background: rgba(220, 220, 220, 0.08);
}

.table > tbody > tr.success > td,
.jsgrid-table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.jsgrid-table > tbody > tr.success > th,
.table > tbody > tr > td.success,
.jsgrid-table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.jsgrid-table > tbody > tr > th.success,
.table > tfoot > tr.success > td,
.jsgrid-table > tfoot > tr.success > td,
.table > tfoot > tr.success > th,
.jsgrid-table > tfoot > tr.success > th,
.table > tfoot > tr > td.success,
.jsgrid-table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.jsgrid-table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.jsgrid-table > thead > tr.success > td,
.table > thead > tr.success > th,
.jsgrid-table > thead > tr.success > th,
.table > thead > tr > td.success,
.jsgrid-table > thead > tr > td.success,
.table > thead > tr > th.success,
.jsgrid-table > thead > tr > th.success {
  background: rgba(139, 195, 74, 0.08);
}

.table > tbody > tr.info > td,
.jsgrid-table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.jsgrid-table > tbody > tr.info > th,
.table > tbody > tr > td.info,
.jsgrid-table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.jsgrid-table > tbody > tr > th.info,
.table > tfoot > tr.info > td,
.jsgrid-table > tfoot > tr.info > td,
.table > tfoot > tr.info > th,
.jsgrid-table > tfoot > tr.info > th,
.table > tfoot > tr > td.info,
.jsgrid-table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.jsgrid-table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.jsgrid-table > thead > tr.info > td,
.table > thead > tr.info > th,
.jsgrid-table > thead > tr.info > th,
.table > thead > tr > td.info,
.jsgrid-table > thead > tr > td.info,
.table > thead > tr > th.info,
.jsgrid-table > thead > tr > th.info {
  background: rgba(231, 54, 141, 0.08);
}

.table > tbody > tr.warning > td,
.jsgrid-table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.jsgrid-table > tbody > tr.warning > th,
.table > tbody > tr > td.warning,
.jsgrid-table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.jsgrid-table > tbody > tr > th.warning,
.table > tfoot > tr.warning > td,
.jsgrid-table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th,
.jsgrid-table > tfoot > tr.warning > th,
.table > tfoot > tr > td.warning,
.jsgrid-table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.jsgrid-table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.jsgrid-table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.jsgrid-table > thead > tr.warning > th,
.table > thead > tr > td.warning,
.jsgrid-table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.jsgrid-table > thead > tr > th.warning {
  background: rgba(248, 179, 45, 0.08);
}

.table > tbody > tr.danger > td,
.jsgrid-table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.jsgrid-table > tbody > tr.danger > th,
.table > tbody > tr > td.danger,
.jsgrid-table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.jsgrid-table > tbody > tr > th.danger,
.table > tfoot > tr.danger > td,
.jsgrid-table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th,
.jsgrid-table > tfoot > tr.danger > th,
.table > tfoot > tr > td.danger,
.jsgrid-table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.jsgrid-table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.jsgrid-table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.jsgrid-table > thead > tr.danger > th,
.table > thead > tr > td.danger,
.jsgrid-table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.jsgrid-table > thead > tr > th.danger {
  background: rgba(243, 57, 35, 0.08);
}

.table-responsive {
  border: none;
}
.table-responsive .table,
.table-responsive .jsgrid-table {
  max-width: none;
  -webkit-overflow-scrolling: touch !important;
}

.table,
.jsgrid-table {
  border-color: #dedede !important;
}

.table > tbody > tr > td,
.jsgrid-table > tbody > tr > td,
.table > tbody > tr > th,
.jsgrid-table > tbody > tr > th,
.table > tfoot > tr > td,
.jsgrid-table > tfoot > tr > td,
.table > tfoot > tr > th,
.jsgrid-table > tfoot > tr > th,
.table > thead > tr > td,
.jsgrid-table > thead > tr > td,
.table > thead > tr > th,
.jsgrid-table > thead > tr > th {
  padding: 16px;
  border-top: 1px solid #dedede;
  vertical-align: middle;
}

.table > thead > tr > th,
.jsgrid-table > thead > tr > th,
.table > tfoot > tr > th,
.jsgrid-table > tfoot > tr > th {
  border-bottom: 2px solid #dedede !important;
  color: #212121;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.table > tfoot > tr > th,
.jsgrid-table > tfoot > tr > th {
  border-bottom: 1px solid #dedede !important;
}

.table-hover > tbody > tr:hover {
  background-color: rgba(33, 33, 33, 0.05) !important;
}

.table-bordered {
  border: 1px solid #dedede;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #dedede;
}

.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: rgba(33, 33, 33, 0.05);
}

.color-bg-table .table > caption + thead > tr:first-child > td,
.color-bg-table .jsgrid-table > caption + thead > tr:first-child > td,
.color-bg-table .table > caption + thead > tr:first-child > th,
.color-bg-table .jsgrid-table > caption + thead > tr:first-child > th,
.color-bg-table .table > colgroup + thead > tr:first-child > td,
.color-bg-table .jsgrid-table > colgroup + thead > tr:first-child > td,
.color-bg-table .table > colgroup + thead > tr:first-child > th,
.color-bg-table .jsgrid-table > colgroup + thead > tr:first-child > th,
.color-bg-table .table > thead:first-child > tr:first-child > td,
.color-bg-table .jsgrid-table > thead:first-child > tr:first-child > td,
.color-bg-table .table > thead:first-child > tr:first-child > th,
.color-bg-table .jsgrid-table > thead:first-child > tr:first-child > th {
  background: #8bc34a;
}
.color-bg-table .table > thead > tr > th,
.color-bg-table .jsgrid-table > thead > tr > th {
  color: #fff;
}

.bootstrap-table .fixed-table-container {
  border: 1px solid #dedede;
  border-radius: 0;
}
.bootstrap-table .fixed-table-container tbody td {
  border: 1px solid #dedede;
}

.fixed-table-body .card-view {
  background: transparent;
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0;
  padding: 0;
}
.fixed-table-body .card-view .title {
  font-weight: 400;
}
.fixed-table-body .card-view:hover {
  box-shadow: none;
}

table.dataTable tbody tr {
  background-color: transparent;
}

table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: inherit;
}

table.dataTable.display.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background: rgba(33, 33, 33, 0.05);
}

table.dataTable.order-column tbody tr > .sorting_1,
table.dataTable.order-column tbody tr > .sorting_2,
table.dataTable.order-column tbody tr > .sorting_3,
table.dataTable.display tbody tr > .sorting_1,
table.dataTable.display tbody tr > .sorting_2,
table.dataTable.display tbody tr > .sorting_3,
table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1,
table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd,
table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: inherit;
}

table.dataTable.display.table-striped tbody tr > .sorting_1,
table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  background: transparent;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #dedede;
  border-left: none;
  border-bottom: none;
}

table.dataTable > tfoot > tr > th {
  border-bottom: 0 solid #dedede !important;
  border-left: medium none !important;
}

table.dataTable {
  border-right: medium none !important;
}

table.dataTable > caption + thead > tr:first-child > td,
table.dataTable > caption + thead > tr:first-child > th,
table.dataTable > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.jsgrid-table > colgroup + thead > tr:first-child > th,
table.dataTable > thead:first-child > tr:first-child > td,
table.dataTable > thead:first-child > tr:first-child > th {
  border-left: medium none !important;
}

.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_length {
  margin-bottom: 30px;
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
  padding-top: 20px;
  margin-bottom: 14px;
  color: #878787;
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_info {
  padding-left: 15px;
}
.dataTables_wrapper .dataTables_info {
  color: #878787;
}
.dataTables_wrapper .dataTables_filter {
  padding-right: 15px;
}
.dataTables_wrapper .dataTables_filter input,
.dataTables_wrapper .dataTables_length select {
  height: 25px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:active,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus {
  background: #8bc34a;
  border-color: #8bc34a;
  color: #fff !important;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #212121;
}

.sorting,
.sorting_asc,
.sorting_desc {
  position: relative;
}

table.dataTable thead .sorting::after {
  content: '\f0dc';
  opacity: 0.2;
}

table.dataTable thead .sorting_asc::after {
  content: '\f0de';
}

table.dataTable thead .sorting_desc::after {
  content: '\f0dd';
}

table.dataTable thead .sorting::after,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after {
  display: block;
  font-family: FontAwesome;
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 14px;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  background: none;
  border-left: medium none;
  border-top: medium none;
}

.dt-buttons {
  float: left;
}
.dt-buttons .dt-button {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.dt-buttons .dt-button:hover {
  color: #fff;
}
.dt-buttons .dt-button:last-child {
  margin-right: 0;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child::before {
  background-color: #8bc34a;
  box-shadow: none;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child::before {
  background-color: #f33923;
  box-shadow: none;
}

table.footable > tbody > tr > td.footable-sortable,
table.footable > tbody > tr > th.footable-sortable,
table.footable > tfoot > tr > td.footable-sortable,
table.footable > tfoot > tr > th.footable-sortable,
table.footable > thead > tr > td.footable-sortable,
table.footable > thead > tr > th.footable-sortable {
  padding: 16px;
  border-top: none;
}

table.footable > tr.footable-filtering > th,
table.footable > thead > tr.footable-filtering > th {
  border: none !important;
}

tr.footable-header > th {
  border-top: none !important;
}

.footable-details.table,
.footable-details.jsgrid-table {
  background: transparent;
}

.footable-editing .btn.footable-edit,
.footable-editing .wizard > .actions a.footable-edit,
.wizard > .actions .footable-editing a.footable-edit,
.footable-editing .dt-buttons .footable-edit.dt-button,
.dt-buttons .footable-editing .footable-edit.dt-button,
.footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-edit,
.tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-edit,
.footable-editing .sweet-alert button.footable-edit,
.sweet-alert .footable-editing button.footable-edit,
.footable-editing .owl-theme .owl-nav .footable-edit[class*='owl-'],
.owl-theme .owl-nav .footable-editing .footable-edit[class*='owl-'],
.footable-editing button.footable-edit.fc-agendaDay-button.fc-state-default.fc-corner-right,
.footable-editing button.footable-edit.fc-month-button.fc-state-default.fc-corner-left,
.footable-editing button.footable-edit.fc-agendaWeek-button,
.footable-editing .footable-edit.fc-prev-button,
.footable-editing .footable-edit.fc-next-button,
.footable-editing .footable-edit.fc-today-button,
.footable-editing .btn.footable-delete,
.footable-editing .wizard > .actions a.footable-delete,
.wizard > .actions .footable-editing a.footable-delete,
.footable-editing .dt-buttons .footable-delete.dt-button,
.dt-buttons .footable-editing .footable-delete.dt-button,
.footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-delete,
.tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-delete,
.footable-editing .sweet-alert button.footable-delete,
.sweet-alert .footable-editing button.footable-delete,
.footable-editing .owl-theme .owl-nav .footable-delete[class*='owl-'],
.owl-theme .owl-nav .footable-editing .footable-delete[class*='owl-'],
.footable-editing button.footable-delete.fc-agendaDay-button.fc-state-default.fc-corner-right,
.footable-editing button.footable-delete.fc-month-button.fc-state-default.fc-corner-left,
.footable-editing button.footable-delete.fc-agendaWeek-button,
.footable-editing .footable-delete.fc-prev-button,
.footable-editing .footable-delete.fc-next-button,
.footable-editing .footable-delete.fc-today-button {
  background: transparent;
  border: none !important;
}
.footable-editing .btn.footable-edit .fooicon.fooicon-pencil,
.footable-editing .wizard > .actions a.footable-edit .fooicon.fooicon-pencil,
.wizard > .actions .footable-editing a.footable-edit .fooicon.fooicon-pencil,
.footable-editing .dt-buttons .footable-edit.dt-button .fooicon.fooicon-pencil,
.dt-buttons .footable-editing .footable-edit.dt-button .fooicon.fooicon-pencil,
.footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-edit .fooicon.fooicon-pencil,
.tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-edit .fooicon.fooicon-pencil,
.footable-editing .sweet-alert button.footable-edit .fooicon.fooicon-pencil,
.sweet-alert .footable-editing button.footable-edit .fooicon.fooicon-pencil,
.footable-editing .owl-theme .owl-nav .footable-edit[class*='owl-'] .fooicon.fooicon-pencil,
.owl-theme .owl-nav .footable-editing .footable-edit[class*='owl-'] .fooicon.fooicon-pencil,
.footable-editing button.footable-edit.fc-agendaDay-button.fc-state-default.fc-corner-right .fooicon.fooicon-pencil,
.footable-editing button.footable-edit.fc-month-button.fc-state-default.fc-corner-left .fooicon.fooicon-pencil,
.footable-editing button.footable-edit.fc-agendaWeek-button .fooicon.fooicon-pencil,
.footable-editing .footable-edit.fc-prev-button .fooicon.fooicon-pencil,
.footable-editing .footable-edit.fc-next-button .fooicon.fooicon-pencil,
.footable-editing .footable-edit.fc-today-button .fooicon.fooicon-pencil,
.footable-editing .btn.footable-delete .fooicon.fooicon-pencil,
.footable-editing .wizard > .actions a.footable-delete .fooicon.fooicon-pencil,
.wizard > .actions .footable-editing a.footable-delete .fooicon.fooicon-pencil,
.footable-editing .dt-buttons .footable-delete.dt-button .fooicon.fooicon-pencil,
.dt-buttons .footable-editing .footable-delete.dt-button .fooicon.fooicon-pencil,
.footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-delete .fooicon.fooicon-pencil,
.tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-delete .fooicon.fooicon-pencil,
.footable-editing .sweet-alert button.footable-delete .fooicon.fooicon-pencil,
.sweet-alert .footable-editing button.footable-delete .fooicon.fooicon-pencil,
.footable-editing .owl-theme .owl-nav .footable-delete[class*='owl-'] .fooicon.fooicon-pencil,
.owl-theme .owl-nav .footable-editing .footable-delete[class*='owl-'] .fooicon.fooicon-pencil,
.footable-editing button.footable-delete.fc-agendaDay-button.fc-state-default.fc-corner-right .fooicon.fooicon-pencil,
.footable-editing button.footable-delete.fc-month-button.fc-state-default.fc-corner-left .fooicon.fooicon-pencil,
.footable-editing button.footable-delete.fc-agendaWeek-button .fooicon.fooicon-pencil,
.footable-editing .footable-delete.fc-prev-button .fooicon.fooicon-pencil,
.footable-editing .footable-delete.fc-next-button .fooicon.fooicon-pencil,
.footable-editing .footable-delete.fc-today-button .fooicon.fooicon-pencil {
  color: #8bc34a;
}
.footable-editing .btn.footable-edit .fooicon.fooicon-trash,
.footable-editing .wizard > .actions a.footable-edit .fooicon.fooicon-trash,
.wizard > .actions .footable-editing a.footable-edit .fooicon.fooicon-trash,
.footable-editing .dt-buttons .footable-edit.dt-button .fooicon.fooicon-trash,
.dt-buttons .footable-editing .footable-edit.dt-button .fooicon.fooicon-trash,
.footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-edit .fooicon.fooicon-trash,
.tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-edit .fooicon.fooicon-trash,
.footable-editing .sweet-alert button.footable-edit .fooicon.fooicon-trash,
.sweet-alert .footable-editing button.footable-edit .fooicon.fooicon-trash,
.footable-editing .owl-theme .owl-nav .footable-edit[class*='owl-'] .fooicon.fooicon-trash,
.owl-theme .owl-nav .footable-editing .footable-edit[class*='owl-'] .fooicon.fooicon-trash,
.footable-editing button.footable-edit.fc-agendaDay-button.fc-state-default.fc-corner-right .fooicon.fooicon-trash,
.footable-editing button.footable-edit.fc-month-button.fc-state-default.fc-corner-left .fooicon.fooicon-trash,
.footable-editing button.footable-edit.fc-agendaWeek-button .fooicon.fooicon-trash,
.footable-editing .footable-edit.fc-prev-button .fooicon.fooicon-trash,
.footable-editing .footable-edit.fc-next-button .fooicon.fooicon-trash,
.footable-editing .footable-edit.fc-today-button .fooicon.fooicon-trash,
.footable-editing .btn.footable-delete .fooicon.fooicon-trash,
.footable-editing .wizard > .actions a.footable-delete .fooicon.fooicon-trash,
.wizard > .actions .footable-editing a.footable-delete .fooicon.fooicon-trash,
.footable-editing .dt-buttons .footable-delete.dt-button .fooicon.fooicon-trash,
.dt-buttons .footable-editing .footable-delete.dt-button .fooicon.fooicon-trash,
.footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-delete .fooicon.fooicon-trash,
.tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-delete .fooicon.fooicon-trash,
.footable-editing .sweet-alert button.footable-delete .fooicon.fooicon-trash,
.sweet-alert .footable-editing button.footable-delete .fooicon.fooicon-trash,
.footable-editing .owl-theme .owl-nav .footable-delete[class*='owl-'] .fooicon.fooicon-trash,
.owl-theme .owl-nav .footable-editing .footable-delete[class*='owl-'] .fooicon.fooicon-trash,
.footable-editing button.footable-delete.fc-agendaDay-button.fc-state-default.fc-corner-right .fooicon.fooicon-trash,
.footable-editing button.footable-delete.fc-month-button.fc-state-default.fc-corner-left .fooicon.fooicon-trash,
.footable-editing button.footable-delete.fc-agendaWeek-button .fooicon.fooicon-trash,
.footable-editing .footable-delete.fc-prev-button .fooicon.fooicon-trash,
.footable-editing .footable-delete.fc-next-button .fooicon.fooicon-trash,
.footable-editing .footable-delete.fc-today-button .fooicon.fooicon-trash {
  color: #f33923;
}

.footable-show .fooicon.fooicon-pencil {
  padding-right: 6px;
}

.footable-filtering .checkbox {
  margin-bottom: inherit;
  margin-top: inherit;
  min-height: inherit;
  padding: 3px 20px !important;
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize;
}
.footable-filtering .checkbox input[type='checkbox'] {
  opacity: 1;
}
.footable-filtering .checkbox label::before {
  display: none;
}

.fooicon.fooicon-sort,
.fooicon.fooicon-sort-asc,
.fooicon.fooicon-sort-desc {
  font-family: FontAwesome !important;
  font-size: 14px;
}

.fooicon.fooicon-sort:before {
  content: '\f0dc';
}

.fooicon.fooicon-sort-asc:before {
  content: '\f0de';
}

.fooicon.fooicon-sort-desc:before {
  content: '\f0dd';
}

table.footable > tbody > tr.footable-empty > td {
  font-size: 18px;
}

.jsgrid {
  height: auto !important;
}

.jsgrid-grid-header {
  overflow: auto;
}

.jsgrid-pager-page {
  display: inline-block;
  margin-bottom: 5px;
}

.jsgrid-row > .jsgrid-cell,
.jsgrid-alt-row > .jsgrid-cell {
  background: transparent;
  border-color: #dedede;
}

.jsgrid-header-row > .jsgrid-header-cell {
  background: inherit;
  font-size: 12px;
  color: #212121;
  text-align: left;
  text-transform: uppercase;
}

.jsgrid-filter-row > .jsgrid-cell,
.jsgrid-header-row > .jsgrid-header-cell,
.jsgrid-insert-row > .jsgrid-cell {
  background: inherit;
  border-bottom: 1px solid #dedede;
}

.jsgrid-edit-row > .jsgrid-cell,
.jsgrid-filter-row > .jsgrid-cell,
.jsgrid-grid-body,
.jsgrid-grid-header,
.jsgrid-header-row > .jsgrid-header-cell,
.jsgrid-insert-row > .jsgrid-cell {
  background: inherit;
  border-color: #dedede;
  height: auto !important;
  overflow: auto;
}

.jsgrid-alt-row > .jsgrid-cell {
  background: inherit;
}

.jsgrid-selected-row > .jsgrid-cell {
  background: inherit;
  border-color: #dedede;
}

.jsgrid-pager {
  color: #212121;
  margin-top: 10px;
}
.jsgrid-pager a {
  color: #212121;
}

.jsgrid-align-center,
.jsgrid-align-center input,
.jsgrid-align-center select,
.jsgrid-align-center textarea,
.jsgrid-align-right,
.jsgrid-align-right input,
.jsgrid-align-right select,
.jsgrid-align-right textarea {
  text-align: left;
}

.jsgrid-pager-current-page,
.jsgrid-pager-page a:hover,
.jsgrid-pager-page a:active,
.jsgrid-pager-page a:focus {
  background: #8bc34a;
  border: 1px solid #8bc34a;
  color: #fff;
  font-weight: 400;
}

.jsgrid-pager-page,
.jsgrid-pager-page a {
  padding: 5px 10px;
  border: 1px solid transparent;
}

.tablesaw-swipe .tablesaw-cell-persist {
  box-shadow: none;
  border-right: inherit;
}

.tablesaw-sortable th.tablesaw-sortable-head button {
  padding: 0;
  border: none !important;
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
}

.tablesaw {
  margin-bottom: 0 !important;
}
.tablesaw thead {
  background: transparent;
  border: none;
}
.tablesaw thead tr:first-child th {
  border-right: medium none;
  padding: 16px !important;
}
.tablesaw td a {
  color: #878787 !important;
}
.tablesaw td,
.tablesaw tbody th {
  vertical-align: middle;
  font-size: inherit;
  color: #878787 !important;
}

.tablesaw-toolbar {
  font-size: 100%;
  margin-bottom: 30px;
}
.tablesaw-toolbar label {
  color: #212121;
  display: block;
  margin-right: 0;
  padding: 0;
  text-transform: uppercase;
}

.tablesaw-bar .btn-select select {
  opacity: 1;
  font-family: 'Roboto', sans-serif;
  color: #212121 !important;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: capitalize;
  filter: alpha(opacity=1);
  display: inline-block;
  color: inherit;
  background: transparent;
  position: static;
}

.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .wizard > .actions a:hover,
.wizard > .actions .tablesaw-enhanced .tablesaw-bar a:hover,
.tablesaw-enhanced .tablesaw-bar .dt-buttons .dt-button:hover,
.dt-buttons .tablesaw-enhanced .tablesaw-bar .dt-button:hover,
.tablesaw-enhanced .tablesaw-bar .tablesaw-sortable th.tablesaw-sortable-head button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .tablesaw-enhanced .tablesaw-bar button:hover,
.tablesaw-enhanced .tablesaw-bar .sweet-alert button:hover,
.sweet-alert .tablesaw-enhanced .tablesaw-bar button:hover,
.tablesaw-enhanced .tablesaw-bar .owl-theme .owl-nav [class*='owl-']:hover,
.owl-theme .owl-nav .tablesaw-enhanced .tablesaw-bar [class*='owl-']:hover,
.tablesaw-enhanced .tablesaw-bar button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.tablesaw-enhanced .tablesaw-bar button.fc-month-button.fc-state-default.fc-corner-left:hover,
.tablesaw-enhanced .tablesaw-bar button.fc-agendaWeek-button:hover,
.tablesaw-enhanced .tablesaw-bar .fc-prev-button:hover,
.tablesaw-enhanced .tablesaw-bar .fc-next-button:hover,
.tablesaw-enhanced .tablesaw-bar .fc-today-button:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus,
.tablesaw-enhanced .tablesaw-bar .wizard > .actions a:focus,
.wizard > .actions .tablesaw-enhanced .tablesaw-bar a:focus,
.tablesaw-enhanced .tablesaw-bar .dt-buttons .dt-button:focus,
.dt-buttons .tablesaw-enhanced .tablesaw-bar .dt-button:focus,
.tablesaw-enhanced .tablesaw-bar .tablesaw-sortable th.tablesaw-sortable-head button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .tablesaw-enhanced .tablesaw-bar button:focus,
.tablesaw-enhanced .tablesaw-bar .sweet-alert button:focus,
.sweet-alert .tablesaw-enhanced .tablesaw-bar button:focus,
.tablesaw-enhanced .tablesaw-bar .owl-theme .owl-nav [class*='owl-']:focus,
.owl-theme .owl-nav .tablesaw-enhanced .tablesaw-bar [class*='owl-']:focus,
.tablesaw-enhanced .tablesaw-bar button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.tablesaw-enhanced .tablesaw-bar button.fc-month-button.fc-state-default.fc-corner-left:focus,
.tablesaw-enhanced .tablesaw-bar button.fc-agendaWeek-button:focus,
.tablesaw-enhanced .tablesaw-bar .fc-prev-button:focus,
.tablesaw-enhanced .tablesaw-bar .fc-next-button:focus,
.tablesaw-enhanced .tablesaw-bar .fc-today-button:focus {
  background: transparent;
}

.tablesaw-bar .tablesaw-toolbar .btn,
.tablesaw-bar .tablesaw-toolbar .wizard > .actions a,
.wizard > .actions .tablesaw-bar .tablesaw-toolbar a,
.tablesaw-bar .tablesaw-toolbar .dt-buttons .dt-button,
.dt-buttons .tablesaw-bar .tablesaw-toolbar .dt-button,
.tablesaw-bar .tablesaw-toolbar .tablesaw-sortable th.tablesaw-sortable-head button,
.tablesaw-sortable th.tablesaw-sortable-head .tablesaw-bar .tablesaw-toolbar button,
.tablesaw-bar .tablesaw-toolbar .sweet-alert button,
.sweet-alert .tablesaw-bar .tablesaw-toolbar button,
.tablesaw-bar .tablesaw-toolbar .owl-theme .owl-nav [class*='owl-'],
.owl-theme .owl-nav .tablesaw-bar .tablesaw-toolbar [class*='owl-'],
.tablesaw-bar .tablesaw-toolbar button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.tablesaw-bar .tablesaw-toolbar button.fc-month-button.fc-state-default.fc-corner-left,
.tablesaw-bar .tablesaw-toolbar button.fc-agendaWeek-button,
.tablesaw-bar .tablesaw-toolbar .fc-prev-button,
.tablesaw-bar .tablesaw-toolbar .fc-next-button,
.tablesaw-bar .tablesaw-toolbar .fc-today-button {
  font-size: 0 !important;
}

.btn.btn-small.btn-select,
.wizard > .actions a.btn-small.btn-select,
.dt-buttons .btn-small.btn-select.dt-button,
.tablesaw-sortable th.tablesaw-sortable-head button.btn-small.btn-select,
.sweet-alert button.btn-small.btn-select,
.owl-theme .owl-nav .btn-small.btn-select[class*='owl-'],
button.btn-small.btn-select.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.btn-small.btn-select.fc-month-button.fc-state-default.fc-corner-left,
button.btn-small.btn-select.fc-agendaWeek-button,
.btn-small.btn-select.fc-prev-button,
.btn-small.btn-select.fc-next-button,
.btn-small.btn-select.fc-today-button {
  padding-left: 20px;
  padding-right: 20px !important;
}

.tablesaw-bar .btn-select::after {
  display: none;
}

.tablesaw-enhanced .tablesaw-bar .btn:active,
.tablesaw-enhanced .tablesaw-bar .wizard > .actions a:active,
.wizard > .actions .tablesaw-enhanced .tablesaw-bar a:active,
.tablesaw-enhanced .tablesaw-bar .dt-buttons .dt-button:active,
.dt-buttons .tablesaw-enhanced .tablesaw-bar .dt-button:active,
.tablesaw-enhanced .tablesaw-bar .tablesaw-sortable th.tablesaw-sortable-head button:active,
.tablesaw-sortable th.tablesaw-sortable-head .tablesaw-enhanced .tablesaw-bar button:active,
.tablesaw-enhanced .tablesaw-bar .sweet-alert button:active,
.sweet-alert .tablesaw-enhanced .tablesaw-bar button:active,
.tablesaw-enhanced .tablesaw-bar .owl-theme .owl-nav [class*='owl-']:active,
.owl-theme .owl-nav .tablesaw-enhanced .tablesaw-bar [class*='owl-']:active,
.tablesaw-enhanced .tablesaw-bar button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.tablesaw-enhanced .tablesaw-bar button.fc-month-button.fc-state-default.fc-corner-left:active,
.tablesaw-enhanced .tablesaw-bar button.fc-agendaWeek-button:active,
.tablesaw-enhanced .tablesaw-bar .fc-prev-button:active,
.tablesaw-enhanced .tablesaw-bar .fc-next-button:active,
.tablesaw-enhanced .tablesaw-bar .fc-today-button:active {
  background-image: none;
  box-shadow: none !important;
}

.tablesaw-bar .btn select option,
.tablesaw-bar .wizard > .actions a select option,
.wizard > .actions .tablesaw-bar a select option,
.tablesaw-bar .dt-buttons .dt-button select option,
.dt-buttons .tablesaw-bar .dt-button select option,
.tablesaw-bar .tablesaw-sortable th.tablesaw-sortable-head button select option,
.tablesaw-sortable th.tablesaw-sortable-head .tablesaw-bar button select option,
.tablesaw-bar .sweet-alert button select option,
.sweet-alert .tablesaw-bar button select option,
.tablesaw-bar .owl-theme .owl-nav [class*='owl-'] select option,
.owl-theme .owl-nav .tablesaw-bar [class*='owl-'] select option,
.tablesaw-bar button.fc-agendaDay-button.fc-state-default.fc-corner-right select option,
.tablesaw-bar button.fc-month-button.fc-state-default.fc-corner-left select option,
.tablesaw-bar button.fc-agendaWeek-button select option,
.tablesaw-bar .fc-prev-button select option,
.tablesaw-bar .fc-next-button select option,
.tablesaw-bar .fc-today-button select option {
  color: #212121;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

/*Charts Label*/
.label-chatrs .clabels {
  width: 10px;
  height: 10px;
}
.label-chatrs .clabels.clabels-lg {
  width: 12px;
  height: 12px;
}
.label-chatrs .clabels.circular-clabels {
  border-radius: 50%;
}
.label-chatrs .clabels-text span:first-child {
  line-height: 13px;
}
.label-chatrs i.big-rpsn-icon {
  font-size: 35px;
}

/*Morris Chart*/
.morris-chart {
  width: 100%;
}
.morris-chart svg {
  width: 100%;
}
.morris-chart.donut-chart svg path {
  stroke: transparent;
}

.morris-hover.morris-default-style {
  background: #212121;
  border: none;
  border-radius: 0;
  color: #212121;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}
.morris-hover.morris-default-style .morris-hover-row-label {
  color: #fff;
  font-weight: 400;
}

/*Flot Chart*/
.flot-container {
  width: 100%;
  height: 400px;
}
.flot-container .legendColorBox > div {
  border: none !important;
}

.demo-placeholder {
  width: 100%;
  height: 100%;
}

.flotTip {
  padding: 8px 12px;
  background: rgba(47, 44, 44, 0.9);
  z-index: 100;
  color: #fff;
  font-size: 12px;
}

.legendLabel {
  font-family: 'Roboto', sans-serif;
  color: #212121;
  font-size: 12px;
  padding-left: 9px;
}

/*Chartist*/
.ct-line {
  stroke-width: 2px;
}

.ct-point {
  stroke-width: 7px;
}

.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: #f8b32d;
}

.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
  stroke: #8bc34a;
}

.ct-series-c .ct-bar,
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut {
  stroke: #2196f3;
}

.ct-series-d .ct-bar,
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut {
  stroke: #f33923;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #8bc34a;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #2196f3;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #f8b32d;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #f33923;
}

.ct-area {
  fill-opacity: 1;
}

.ct-grid {
  stroke: #878787;
  stroke-dasharray: 0;
  stroke-width: 1px;
  display: none;
}

.ct-label {
  color: #878787;
  fill: #fff;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  color: #878787;
}

.ct-chart-before-pad-zero:before {
  padding-bottom: 0;
}

/*Easy pie chart*/
.easypiechart {
  display: inline-block;
  height: 100px;
  position: relative;
  text-align: center;
  width: 100px;
}
.easypiechart canvas {
  left: 0;
  position: absolute;
  top: 0;
}
.easypiechart .percent {
  color: #212121;
  display: inline-block;
  font-size: 14px;
  line-height: 100px;
  z-index: 2;
}
.easypiechart .percent:after {
  content: '%';
  font-size: 14px;
  margin-left: 1px;
}

.easypiechart.easypiechart-widget-pie {
  margin-bottom: 5px;
  margin-top: 5px;
}

.pie-chart > span {
  left: 0;
  margin-top: -2px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

.easypiechart > span > img {
  height: 60%;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 60%;
}

/*Skills Counter*/
.skill-head {
  font-size: 10px;
  text-transform: uppercase;
  display: block;
  text-align: center;
}
.skill-head.skill-head-inside {
  line-height: 100px;
}

.skill-circle {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.skill-circle.skill-circle-fill {
  background: #f7f7f9;
  border-radius: 50%;
}

/*Nestable*/
.dd-handle {
  font-weight: 400;
  margin: 0 0 15px;
  font-size: 14px;
  padding-top: 10px;
  background: transparent;
  color: #878787;
}
.dd-handle:hover,
.dd-handle:focus,
.dd-handle:active {
  color: #212121;
}

.dd-item > button {
  margin: 0;
  height: 42px;
}

.dd-placeholder {
  background: transparent;
  border-color: #dedede;
}

.dd3-content {
  display: block;
  color: #878787;
  margin: 0 0 15px;
  font-size: 14px;
  padding-top: 10px;
  padding: 10px 10px 5px 52px;
}
.dd3-content:hover,
.dd3-content:focus,
.dd3-content:active {
  color: #212121;
}

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}

.dd3-item > button {
  margin-left: 30px;
}

.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 42px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.dd3-handle:before {
  font-family: FontAwesome;
  content: '\f047';
  color: #878787;
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

/*Treeview*/
.treeview .list-group {
  margin-bottom: 0;
}
.treeview .list-group .list-group-item {
  border: 1px solid #dedede;
  height: 42px;
  padding: 10px 12px;
}
.treeview .list-group .list-group-item.node-selected {
  background: #2196f3 !important;
}
.treeview .list-group .list-group-item:hover {
  background: #f7f7f9;
}
.treeview .list-group .list-group-item:first-child,
.treeview .list-group .list-group-item:last-child {
  border-radius: 0;
}

/*Modals*/
.modal-dialog .modal-content {
  border-radius: 2px;
  border: none;
}
.modal-dialog .modal-footer {
  border: none;
}

/*Sweet-alert*/
.sweet-alert {
  border-radius: 2px;
  font-family: inherit;
}
.sweet-alert h2 {
  font-weight: 400;
  color: #212121;
}
.sweet-alert button {
  box-shadow: none;
  font-size: inherit;
}

/*Notification*/
.alert {
  border-radius: 2px;
  border: none;
  color: #fff;
}
.alert i {
  font-size: 24px;
}
.alert.alert-success {
  background: #8bc34a;
}
.alert.alert-info {
  background: #e7368d;
}
.alert.alert-danger {
  background: #f33923;
}
.alert.alert-warning {
  background: #f8b32d;
}
.alert.alert-style-1 {
  padding-left: 70px;
  background: #fff;
  position: relative;
  color: #212121;
  border: 1px solid #dedede;
}
.alert.alert-style-1 i {
  height: 100%;
  left: 0;
  margin: 0;
  padding-top: 13px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 50px;
  color: #fff;
}
.alert.alert-style-1.alert-success i {
  color: #8bc34a;
  background: #f7f7f7;
}
.alert.alert-style-1.alert-info i {
  color: #e7368d;
  background: #f7f7f7;
}
.alert.alert-style-1.alert-danger i {
  color: #f33923;
  background: #f7f7f7;
}
.alert.alert-style-1.alert-warning i {
  color: #f8b32d;
  background: #f7f7f7;
}

.alert-dismissable .close,
.alert-dismissible .close {
  top: 0;
}

.jq-toast-wrap {
  width: 300px;
}
.jq-toast-wrap .jq-toast-single {
  color: #fff;
  border-radius: 2px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #212121;
}
.jq-toast-wrap .jq-toast-single .jq-toast-loader {
  border-radius: 2px 2px 0 0;
  height: 3px;
}
.jq-toast-wrap .jq-toast-single .jq-toast-heading {
  font-size: 16px;
}
.jq-toast-wrap .jq-toast-single.jq-has-icon {
  padding: 13px 10px 13px 15px;
}
.jq-toast-wrap .jq-toast-single.jq-has-icon:before {
  position: absolute;
  font-family: 'themify';
  font-size: 22px;
  left: 12px;
  top: 13px;
}
.jq-toast-wrap .jq-toast-single.jq-icon-info {
  background: #e7368d;
  padding-left: 50px;
}
.jq-toast-wrap .jq-toast-single.jq-icon-info:before {
  content: '\e717';
}
.jq-toast-wrap .jq-toast-single.jq-icon-error {
  background: #f33923;
  padding-left: 50px;
}
.jq-toast-wrap .jq-toast-single.jq-icon-error:before {
  content: '\e620';
}
.jq-toast-wrap .jq-toast-single.jq-icon-success {
  background: #8bc34a;
  padding-left: 50px;
}
.jq-toast-wrap .jq-toast-single.jq-icon-success:before {
  content: '\e64c';
}
.jq-toast-wrap .jq-toast-single.jq-icon-warning {
  background: #f8b32d;
  padding-left: 50px;
}
.jq-toast-wrap .jq-toast-single.jq-icon-warning:before {
  content: '\e6c5';
}

.bottom-center-fullwidth .jq-toast-wrap {
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0 !important;
}

.top-center-fullwidth .jq-toast-wrap {
  left: 0;
  right: 0;
  width: 100%;
  top: 2px !important;
}

/*Tooltop*/
.tooltip-inner {
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  background: #212121;
}

.tooltip.left .tooltip-arrow {
  border-left-color: #212121;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #212121;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #212121;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #212121;
}

/*Icons*/
.icon-container {
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
}
.icon-container:hover {
  color: #212121;
}
.icon-container i,
.icon-container span.glyphicon {
  margin-right: 20px;
  font-size: 18px;
}

.glyphs.character-mapping li,
.glyphs.character-mapping input:hover,
.glyphs.character-mapping input:focus {
  color: #212121;
  border-color: #212121;
}

.font-icon-detail span:first-child {
  color: #212121;
  border: 1px solid #dedede;
}

.font-icon-list:hover .font-icon-detail span:first-child,
.font-icon-detail.zeroclipboard-is-hover span:first-child {
  background-color: #212121;
}

.checkbox label:before {
  border-radius: 0;
}

.checkbox input[type='checkbox']:focus + label::before,
.checkbox input[type='radio']:focus + label::before,
.radio input[type='radio']:focus + label::before {
  outline: none;
}

.checkbox-primary input[type='checkbox']:checked + label::before,
.checkbox-primary input[type='radio']:checked + label::before {
  background-color: #2196f3;
  border-color: #2196f3;
}

.checkbox-danger input[type='checkbox']:checked + label::before,
.checkbox-danger input[type='radio']:checked + label::before {
  background-color: #f33923;
  border-color: #f33923;
}

.checkbox-info input[type='checkbox']:checked + label::before,
.checkbox-info input[type='radio']:checked + label::before {
  background-color: #e7368d;
  border-color: #e7368d;
}

.checkbox-warning input[type='checkbox']:checked + label::before,
.checkbox-warning input[type='radio']:checked + label::before {
  background-color: #f8b32d;
  border-color: #f8b32d;
}

.checkbox-success input[type='checkbox']:checked + label::before,
.checkbox-success input[type='radio']:checked + label::before {
  background-color: #8bc34a;
  border-color: #8bc34a;
}

.checkbox-primary input[type='checkbox']:indeterminate + label::before,
.checkbox-primary input[type='radio']:indeterminate + label::before {
  background-color: #2196f3;
  border-color: #2196f3;
}

.checkbox-danger input[type='checkbox']:indeterminate + label::before,
.checkbox-danger input[type='radio']:indeterminate + label::before {
  background-color: #f33923;
  border-color: #f33923;
}

.checkbox-info input[type='checkbox']:indeterminate + label::before,
.checkbox-info input[type='radio']:indeterminate + label::before {
  background-color: #e7368d;
  border-color: #e7368d;
}

.checkbox-warning input[type='checkbox']:indeterminate + label::before,
.checkbox-warning input[type='radio']:indeterminate + label::before {
  background-color: #f8b32d;
  border-color: #f8b32d;
}

.checkbox-success input[type='checkbox']:indeterminate + label::before,
.checkbox-success input[type='radio']:indeterminate + label::before {
  background-color: #8bc34a;
  border-color: #8bc34a;
}

.radio-primary input[type='radio'] + label::after {
  background-color: #2196f3;
}

.radio-primary input[type='radio']:checked + label::before {
  border-color: #2196f3;
}

.radio-primary input[type='radio']:checked + label::after {
  background-color: #2196f3;
}

.radio-danger input[type='radio'] + label::after {
  background-color: #f33923;
}

.radio-danger input[type='radio']:checked + label::before {
  border-color: #f33923;
}

.radio-danger input[type='radio']:checked + label::after {
  background-color: #f33923;
}

.radio-info input[type='radio'] + label::after {
  background-color: #e7368d;
}

.radio-info input[type='radio']:checked + label::before {
  border-color: #e7368d;
}

.radio-info input[type='radio']:checked + label::after {
  background-color: #e7368d;
}

.radio-warning input[type='radio'] + label::after {
  background-color: #f8b32d;
}

.radio-warning input[type='radio']:checked + label::before {
  border-color: #f8b32d;
}

.radio-warning input[type='radio']:checked + label::after {
  background-color: #f8b32d;
}

.radio-success input[type='radio'] + label::after {
  background-color: #8bc34a;
}

.radio-success input[type='radio']:checked + label::before {
  border-color: #8bc34a;
}

.radio-success input[type='radio']:checked + label::after {
  background-color: #8bc34a;
}

/*File Upload*/
.fileupload {
  overflow: hidden;
  position: relative;
}

.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: -3px;
  top: -3px;
  padding: 5px;
}

/*File Manager*/
.file-box {
  float: left;
  margin-bottom: 20px;
}

.file-sec {
  border-left: 1px solid rgba(33, 33, 33, 0.05);
}

.file-manager {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}
.file-manager h5 {
  text-transform: uppercase;
}

.folder-list li {
  display: block;
}
.folder-list li.active a {
  background: rgba(33, 33, 33, 0.05);
}
.folder-list li a {
  display: block;
  padding: 10px 15px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
}
.folder-list li a:hover {
  background: rgba(33, 33, 33, 0.05);
}
.folder-list li i {
  margin-right: 8px;
  color: #878787;
}

.category-list li {
  display: block;
}
.category-list li a {
  color: #666666;
  display: block;
  padding: 5px 0;
}
.category-list li i {
  margin-right: 8px;
  color: #3d4d5d;
}

.file-manager h5.tag-title {
  margin-top: 20px;
}

.tag-list li {
  float: left;
}
.tag-list li a {
  font-size: 12px;
  background-color: #dedede;
  padding: 5px 12px;
  border: 1px solid #dedede;
  margin-right: 5px;
  margin-top: 5px;
  display: block;
}

.file-manager .hr-line-dashed {
  margin: 15px 0;
}

.file {
  border: 1px solid #dedede;
  border-radius: 2px;
  padding: 0;
  background-color: #fff;
  position: relative;
}
.file .icon,
.file .image {
  height: 150px;
  overflow: hidden;
  background-size: cover;
  background-position: top;
}
.file .icon {
  padding: 15px 10px;
  display: table;
  width: 100%;
  text-align: center;
}
.file .icon i {
  color: #2196f3;
  display: table-cell;
  font-size: 30px;
  vertical-align: middle;
}
.file .file-name {
  padding: 10px;
  background-color: rgba(33, 33, 33, 0.05);
  border-top: 1px solid rgba(33, 33, 33, 0.05);
}
.file .file-name span {
  font-size: 12px;
  color: #878787;
}

.file-control {
  font-size: 13px;
  margin-right: 10px;
  text-transform: uppercase;
}
.file-control.active {
  color: #f33923;
}

.file-name small {
  color: #878787;
}

ul.tag-list li {
  list-style: none;
}

a.compose-mail {
  padding: 8px 10px;
}

.mail-search {
  max-width: 300px;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}
.ibox.collapsed .ibox-content {
  display: none;
}
.ibox.collapsed .fa.fa-chevron-up:before {
  content: '\f078';
}
.ibox.collapsed .fa.fa-chevron-down:before {
  content: '\f077';
}
.ibox:after,
.ibox:before {
  display: table;
}

.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #fff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}

.ibox-content {
  background-color: #fff;
  color: inherit;
}

.ibox-footer {
  color: inherit;
  border-top: 1px solid #dedede;
  font-size: 90%;
  background: #fff;
  padding: 10px 15px;
}

/*Carousel*/
.carousel-control.left,
.carousel-control.right {
  background: none;
  text-shadow: none;
}
.carousel-control:hover.left,
.carousel-control:hover.right {
  background: none;
  text-shadow: none;
}
.carousel-control span {
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: 5;
}

.carousel-indicators {
  z-index: 1;
}
.carousel-indicators li {
  border-color: #878787;
  background: #878787;
  opacity: 0.6;
}
.carousel-indicators li:hover {
  background: #fff;
}
.carousel-indicators li,
.carousel-indicators li.active {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  border: none;
}
.carousel-indicators li.active {
  margin: 1px;
  opacity: 1;
}

.text-slider .carousel-indicators li {
  border: none;
}
.text-slider .carousel-indicators li:hover {
  background: #8bc34a;
  opacity: 1;
}
.text-slider .carousel-indicators li.active {
  border: none;
  background: #8bc34a;
}

.owl-theme .owl-dots .owl-dot span {
  height: 8px;
  margin: 5px 3px;
  width: 8px;
  background: #878787;
  opacity: 0.6;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  opacity: 1;
  background: #8bc34a;
}

/*Testimonial slider*/
.testimonial-slider-wrap .carousel-indicators {
  bottom: -50px;
}

/*Twitter slider*/
.twitter-icon-wrap i {
  font-size: 30px;
  color: #fff;
}

.twitter-slider-wrap .carousel-indicators {
  bottom: -50px;
}
.twitter-slider-wrap .carousel-indicators li {
  border: none;
  background: #fff;
  opacity: 0.4;
}
.twitter-slider-wrap .carousel-indicators li:hover {
  background: #fff;
  opacity: 0.4;
}
.twitter-slider-wrap .carousel-indicators li.active {
  background: #fff;
  opacity: 1;
}
.twitter-slider-wrap .carousel-inner {
  text-align: center;
}
.twitter-slider-wrap .carousel-inner .user,
.twitter-slider-wrap .carousel-inner .interact {
  display: none;
}
.twitter-slider-wrap .carousel-inner .tweet {
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
}
.twitter-slider-wrap .carousel-inner .tweet > a {
  color: #fff;
}
.twitter-slider-wrap .carousel-inner .timePosted > a {
  font-size: 12px;
  color: #fff;
}

/*Instafeed*/
.instagram-lite > li {
  display: inline-block;
  float: left;
  font-size: 0;
  height: auto;
  width: 25%;
  border: 1px solid #fff;
}
.instagram-lite > li > a {
  display: block;
  width: 100%;
  height: 100%;
}
.instagram-lite > li > a img {
  height: auto;
  width: 100%;
}

/*Range Slider*/
.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
  background: url('../images/sprite-skin-modern.png');
}

.irs-slider {
  background-position: 0 -120px;
}

.irs-bar {
  background-position: 0 -60px;
}

.irs-from,
.irs-to,
.irs-single {
  font-size: 10px;
  padding: 4px 8px;
  background: #212121;
  border-radius: 4px;
  border-radius: 0;
}

.irs-from::after,
.irs-to::after,
.irs-single::after {
  border-color: #212121 transparent transparent;
}

.irs-grid-text {
  font-size: 10px;
}

/*Gallery*/
.project-gallery a {
  display: block;
  overflow: hidden;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;
  position: relative;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.1s ease 0s;
  -moz-transition: all 0.1s ease 0s;
  transition: all 0.1s ease 0s;
}
.project-gallery a img {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  image-rendering: optimizeQuality;
}
.project-gallery a .hover-cap {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  background: #fff none repeat scroll 0 0;
  bottom: 0;
  color: black;
  height: 65px;
  padding-top: 25px;
  left: 0;
  overflow: hidden;
  perspective: 1px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: translateY(75px);
  -moz-transform: translateY(75px);
  -ms-transform: translateY(75px);
  -o-transform: translateY(75px);
  transform: translateY(75px);
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 100%;
}
.project-gallery a:hover {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}
.project-gallery a:hover img {
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transform: translateY(-20px);
}
.project-gallery a:hover .hover-cap {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.lg-sub-html h6,
.lg-sub-html .panel-title {
  margin-bottom: 0;
}

#filters li {
  margin-right: 5px;
  margin-bottom: 10px;
}
#filters li a {
  background: #f7f7f9 none repeat scroll 0 0;
  border-radius: 60px;
  color: #878787;
  display: inline-block;
  padding: 10px 20px;
  text-transform: capitalize;
  font-size: 14px;
}
#filters li a.active,
#filters li a:active,
#filters li a:hover,
#filters li a:focus {
  background: #8bc34a;
  color: #fff;
}
#filters:last-child {
  margin-right: 0;
}

/*Typography*/
.grid-block [class^='col-'] {
  padding-bottom: 10px;
  padding-top: 10px;
  border: 1px solid #dedede;
  border-radius: 0;
  box-shadow: none;
  color: #878787;
  height: 42px;
}

/*Panel*/
.panel-title > small {
  color: #878787;
  font-size: 14px;
}

.panel .panel-heading a {
  position: relative;
}
.panel .panel-heading a i {
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.panel .panel-heading a i.zmdi-chevron-down {
  opacity: 1;
  position: absolute;
  top: 1px;
}
.panel .panel-heading a i.zmdi-chevron-up {
  opacity: 0;
}
.panel .panel-heading a.collapsed i.zmdi-chevron-down {
  opacity: 0;
}
.panel .panel-heading a.collapsed i.zmdi-chevron-up {
  opacity: 1;
}
.panel .panel-heading .pull-right i {
  font-size: 20px;
  color: #878787;
}
.panel .panel-heading .pull-right .dropdown i {
  font-size: 16px;
}
.panel .panel-heading .pull-right .dropdown i.caret-down {
  position: static;
  margin-left: 2px;
}
.panel .panel-heading .pull-right.right-float-sub-text {
  font-size: 11px;
  padding-top: 2px;
  text-transform: uppercase;
}
.panel .panel-footer {
  background-color: transparent;
  border-top: 1px solid #dedede;
  margin: 0 -15px;
  padding: 5px 20px 0;
}
.panel.panel-refresh {
  position: relative;
}
.panel.panel-refresh .refresh-container {
  background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  display: none;
  z-index: 5;
  cursor: wait;
  overflow: hidden;
}
.panel.panel-refresh .refresh-container .la-anim-1 {
  position: absolute;
}
.panel.panel-refresh .refresh-container .la-anim-1.la-animate {
  -webkit-transition: -webkit-transform 2s ease;
  -moz-transition: -moz-transform 2s ease;
  transition: transform 2s ease;
}

.panel-info > .panel-heading {
  background-color: #e7368d;
}

.panel-danger > .panel-heading {
  background-color: #f33923;
}

.panel-warning > .panel-heading {
  background-color: #f8b32d;
}

.panel-success > .panel-heading {
  background-color: #8bc34a;
}

.panel-primary > .panel-heading {
  background-color: #2196f3;
}

.panel-inverse > .panel-heading {
  background-color: #dedede;
  color: #212121 !important;
}

.well {
  border-radius: 0;
  box-shadow: none;
  padding: 20px;
}
.well.well-lg {
  padding: 25px;
}
.well.well-sm {
  padding: 15px;
}

/*Pricing Table*/
.panel-pricing {
  background: #f7f7f9;
  border-radius: 0;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
}
.panel-pricing:hover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
}
.panel-pricing .list-group-item {
  background: transparent;
  border: none;
  border-radius: 0;
}
.panel-pricing .panel-body hr {
  border-color: #dedede;
  border-width: 2px;
}
.panel-pricing .panel-body .list-group hr {
  border-width: 1px;
}
.panel-pricing .panel-body .list-group .list-group-item i {
  color: #8bc34a;
  padding-right: 15px;
}
.panel-pricing .panel-heading i {
  display: block;
  font-size: 50px;
  margin-bottom: 25px;
  margin-top: 50px;
  color: #212121;
}
.panel-pricing .panel-heading .panel-price {
  font-size: 60px;
  color: #212121;
  font-weight: 700;
  display: block;
  margin-top: 20px;
  text-transform: capitalize;
}
.panel-pricing .panel-heading .panel-price .pricing-dolor {
  display: inline-block;
  font-size: 18px;
  padding-top: 10px;
  vertical-align: top;
}
.panel-pricing .panel-footer {
  border: none;
}
.panel-pricing .panel-footer .btn,
.panel-pricing .panel-footer .wizard > .actions a,
.wizard > .actions .panel-pricing .panel-footer a,
.panel-pricing .panel-footer .dt-buttons .dt-button,
.dt-buttons .panel-pricing .panel-footer .dt-button,
.panel-pricing .panel-footer .tablesaw-sortable th.tablesaw-sortable-head button,
.tablesaw-sortable th.tablesaw-sortable-head .panel-pricing .panel-footer button,
.panel-pricing .panel-footer .sweet-alert button,
.sweet-alert .panel-pricing .panel-footer button,
.panel-pricing .panel-footer .owl-theme .owl-nav [class*='owl-'],
.owl-theme .owl-nav .panel-pricing .panel-footer [class*='owl-'],
.panel-pricing .panel-footer button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.panel-pricing .panel-footer button.fc-month-button.fc-state-default.fc-corner-left,
.panel-pricing .panel-footer button.fc-agendaWeek-button,
.panel-pricing .panel-footer .fc-prev-button,
.panel-pricing .panel-footer .fc-next-button,
.panel-pricing .panel-footer .fc-today-button {
  font-size: 14px;
}

/*Dropdown*/
.show-on-hover:hover > ul.dropdown-menu {
  display: block;
}

.dropdown-menu {
  border-radius: 2px;
  border: none;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.175);
}
.dropdown-menu > li > a {
  color: #212121;
}
.dropdown-menu > li > a:hover {
  color: #212121;
  background: rgba(33, 33, 33, 0.05);
}
.dropdown-menu > li > a i {
  color: #878787;
  font-size: 18px;
  margin-right: 10px;
  width: 18px;
}
.dropdown-menu > li.sub-menu {
  position: relative;
}
.dropdown-menu > li.sub-menu .dropdown-menu:before,
.dropdown-menu > li.sub-menu .dropdown-menu:after {
  display: none !important;
}
.dropdown-menu > li.sub-menu > ul {
  top: 0;
}
.dropdown-menu > li.sub-menu > ul.open-left-side {
  right: 100% !important;
}
.dropdown-menu > li.sub-menu > ul.open-right-side {
  left: 100% !important;
}
.dropdown-menu .divider {
  background-color: #dedede;
}
.dropdown-menu > .active > a {
  background: #8bc34a;
}
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background: #8bc34a;
}

.dropdown-menu.animated {
  -webkit-animation-duration: 0.55s;
  animation-duration: 0.55s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}

/*Weather*/
.forcast-days > li {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  width: 14.28%;
}
.forcast-days > li:last-child {
  margin-right: 0;
}
.forcast-days > li span.forcast-high-deg {
  color: #f33923;
}
.forcast-days > li img {
  width: 50px;
  margin: 0 auto;
}

.temprature {
  font-size: 60px;
  color: #212121;
}
.temprature .unit {
  display: inline-block;
  font-size: 14px;
  padding-top: 10px;
  vertical-align: top;
}

.currently {
  font-size: 16px;
  color: #212121;
}

.other-details {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  margin: 15px -15px;
  padding: 15px;
}
.other-details li {
  display: table;
  width: 100%;
}
.other-details li span {
  display: table-cell;
  padding-bottom: 5px;
  padding-top: 5px;
  text-transform: capitalize;
  width: 25%;
}
.other-details li span:nth-child(2),
.other-details li span:nth-child(4) {
  color: #212121;
  text-transform: capitalize;
}

.left-block-wrap,
.right-block-wrap {
  min-height: 175px;
}

.right-block-wrap {
  text-align: center;
}
.right-block-wrap .right-block .temprature-icon img {
  width: 80px;
}

.left-block-wrap .left-block .temprature {
  font-size: 40px;
}

.weather-inverse .panel-body {
  background: #f7f7f9;
}
.weather-inverse .left-block-wrap {
  background: #fff;
}

.weather-info .left-block-wrap {
  background: #e7368d;
  color: #fff !important;
}
.weather-info .left-block-wrap h5,
.weather-info .left-block-wrap .temprature {
  color: #fff !important;
}

.weather-warning .left-block-wrap {
  background: #f8b32d;
  color: #fff !important;
}
.weather-warning .left-block-wrap h5,
.weather-warning .left-block-wrap .temprature {
  color: #fff !important;
}

.weather-danger .left-block-wrap {
  background: #f33923;
  color: #fff !important;
}
.weather-danger .left-block-wrap h5,
.weather-danger .left-block-wrap .temprature {
  color: #fff !important;
}

.weather-success .left-block-wrap {
  background: #8bc34a;
  color: #fff !important;
}
.weather-success .left-block-wrap h5,
.weather-success .left-block-wrap .temprature {
  color: #fff !important;
}

.weather-primary .left-block-wrap {
  background: #2196f3;
  color: #fff !important;
}
.weather-primary .left-block-wrap h5,
.weather-primary .left-block-wrap .temprature {
  color: #fff !important;
}

/*Sparkline*/
.jqstooltip {
  width: auto !important;
  height: auto !important;
  background: #212121 !important;
  border: none !important;
}
.sp-small-chart {
  width: 100px;
  overflow: hidden;
  margin: 0px auto;
}

/*Pagination*/
.pagination > li > a,
.pagination > li > span {
  border-color: #dedede;
  border-radius: 0 !important;
  color: #8bc34a;
}
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > a:active,
.pagination > li > span:hover,
.pagination > li > span:focus,
.pagination > li > span:active {
  border-color: #dedede;
  background-color: #dedede;
}
.pagination > li.active > a,
.pagination > li.active > span {
  background: #8bc34a;
  border-color: #dedede;
}
.pagination > li.active > a:hover,
.pagination > li.active > a:focus,
.pagination > li.active > a:active,
.pagination > li.active > span:hover,
.pagination > li.active > span:focus,
.pagination > li.active > span:active {
  background: #8bc34a;
  border-color: #dedede;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  border-color: #dedede;
  color: #8bc34a;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #878787;
}

/*Pager*/
.pager li > a,
.pager li > span {
  border: 1px solid #dedede;
  border-radius: 60px;
}

/*Labels*/
.label,
.jvectormap-tip {
  padding: 3px 6px 3px;
  font-size: 11px;
  border-radius: 50px;
  text-transform: capitalize;
  font-weight: 400;
}
.label.label-info,
.label-info.jvectormap-tip {
  background: #e7368d;
}
.label.label-primary,
.label-primary.jvectormap-tip {
  background: #2196f3;
}
.label.label-success,
.label-success.jvectormap-tip {
  background: #8bc34a;
}
.label.label-danger,
.label-danger.jvectormap-tip {
  background: #f33923;
}
.label.label-warning,
.label-warning.jvectormap-tip {
  background: #f8b32d;
}

/*Badge*/
.badge {
  background-color: #dcdcdc;
  font-weight: 400;
  padding: 5px 10px;
  color: #212121;
}
.badge.badge-info {
  background: #e7368d;
  color: #fff;
}
.badge.badge-primary {
  background: #2196f3;
  color: #fff;
}
.badge.badge-success {
  background: #8bc34a;
  color: #fff;
}
.badge.badge-danger {
  background: #f33923;
  color: #fff;
}
.badge.badge-warning {
  background: #f8b32d;
  color: #fff;
}
.badge.transparent-badge {
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}
.badge.transparent-badge.badge-info {
  color: #e7368d;
}
.badge.transparent-badge.badge-primary {
  color: #2196f3;
}
.badge.transparent-badge.badge-success {
  color: #8bc34a;
}
.badge.transparent-badge.badge-danger {
  color: #f33923;
}
.badge.transparent-badge.badge-warning {
  color: #f8b32d;
}

/*Task Panel*/
.task-panel a.list-group-item {
  background-color: transparent;
  color: #212121;
  border-color: #dedede;
  border-image: none;
  border-radius: 0 !important;
  border-style: solid none;
  border-width: 1px medium;
  border-bottom: none;
  padding: 15px;
}
.task-panel a.list-group-item i {
  color: #878787;
  padding-right: 10px;
  font-size: 18px;
  width: 25px;
  line-height: 24px;
}
.task-panel a.list-group-item:first-child {
  border-top: none;
}
.task-panel a.list-group-item:hover {
  background: rgba(33, 33, 33, 0.05);
}

/*Vecotor Map*/
/*Progressbar*/
.progress {
  -webkit-box-shadow: none !important;
  background-color: rgba(120, 130, 140, 0.13);
  box-shadow: none !important;
  height: 6px;
  border-radius: 50px;
  margin-bottom: 18px;
  overflow: hidden;
}
.progress .progress-bar {
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
}
.progress .progress-bar:last-child {
  border-radius: 0 60px 60px 0;
}
.progress.progress-xs {
  height: 5px !important;
}
.progress.progress-sm {
  height: 8px !important;
}
.progress.progress-md {
  height: 15px !important;
}
.progress.progress-md .progress-bar {
  font-size: 8px;
  font-weight: 400;
  line-height: 14.4px;
}
.progress.progress-lg {
  height: 20px !important;
}
.progress.progress-lg .progress-bar {
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.progress-bar-info {
  background: #e7368d;
}

.progress-bar-primary {
  background: #2196f3;
}

.progress-bar-success {
  background: #8bc34a;
}

.progress-bar-danger {
  background: #f33923;
}

.progress-bar-warning {
  background: #f8b32d;
}

.progress-bar-light {
  background: #fff;
}

.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 8px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}
.progress-vertical .progress-bar {
  width: 100%;
}
.progress-vertical .progress-bar:last-child {
  border-radius: 0 60px 60px;
}

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  width: 8px;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}
.progress-vertical-bottom .progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.progress-vertical-bottom .progress-bar:last-child {
  border-radius: 60px 60px 60px 0 !important;
}

.progress-vertical.progress-sm,
.progress-vertical-bottom.progress-sm {
  width: 8px !important;
}

.progress-vertical.progress-sm .progress-bar,
.progress-vertical-bottom.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px;
}

.progress-vertical.progress-md,
.progress-vertical-bottom.progress-md {
  width: 15px !important;
}

.progress-vertical.progress-md .progress-bar,
.progress-vertical-bottom.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}

.progress-vertical.progress-lg,
.progress-vertical-bottom.progress-lg {
  width: 20px !important;
}

.progress-vertical.progress-lg .progress-bar,
.progress-vertical-bottom.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px;
}

.progress-bar-striped,
.progress-striped .progress-bar {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  ) !important;
  background-size: 40px 40px !important;
}

/*Search Bar*/
.app-search {
  margin: 0;
  position: relative;
}
.app-search a {
  color: #4c5667;
  position: absolute;
  right: 10px;
  top: 15px;
}
.app-search .form-control,
.app-search .select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .app-search .select2-selection__rendered,
.app-search .select2-container--default .select2-selection--single,
.select2-container--default .app-search .select2-selection--single,
.app-search .select2-selection.select2-selection--multiple,
.app-search .wizard .content > .body input,
.wizard .content > .body .app-search input,
.app-search .mce-floatpanel .mce-textbox,
.mce-floatpanel .app-search .mce-textbox,
.app-search .mce-floatpanel .mce-btn,
.mce-floatpanel .app-search .mce-btn,
.app-search .dataTables_wrapper .dataTables_filter input,
.dataTables_wrapper .dataTables_filter .app-search input,
.app-search .dataTables_wrapper .dataTables_length select,
.dataTables_wrapper .dataTables_length .app-search select,
.app-search .jsgrid-cell input,
.jsgrid-cell .app-search input,
.app-search .jsgrid-cell select,
.jsgrid-cell .app-search select,
.app-search .tablesaw-bar .btn-select select,
.tablesaw-bar .btn-select .app-search select,
.app-search .dd-handle,
.app-search .dd3-content,
.app-search .form-control:focus,
.app-search .jsgrid-cell input:focus,
.jsgrid-cell .app-search input:focus,
.app-search .jsgrid-cell select:focus,
.jsgrid-cell .app-search select:focus,
.app-search .dd-handle:focus,
.app-search .dd3-content:focus,
.app-search .form-control:focus,
.app-search .select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.select2-container--default .select2-selection--single .app-search .select2-selection__rendered:focus,
.app-search .select2-container--default .select2-selection--single:focus,
.select2-container--default .app-search .select2-selection--single:focus,
.app-search .select2-selection.select2-selection--multiple:focus,
.app-search .wizard .content > .body input:focus,
.wizard .content > .body .app-search input:focus,
.app-search .mce-floatpanel .mce-textbox:focus,
.mce-floatpanel .app-search .mce-textbox:focus,
.app-search .mce-floatpanel .mce-btn:focus,
.mce-floatpanel .app-search .mce-btn:focus,
.app-search .dataTables_wrapper .dataTables_filter input:focus,
.dataTables_wrapper .dataTables_filter .app-search input:focus,
.app-search .dataTables_wrapper .dataTables_length select:focus,
.dataTables_wrapper .dataTables_length .app-search select:focus,
.app-search .jsgrid-cell input:focus,
.jsgrid-cell .app-search input:focus,
.app-search .jsgrid-cell select:focus,
.jsgrid-cell .app-search select:focus,
.app-search .tablesaw-bar .btn-select select:focus,
.tablesaw-bar .btn-select .app-search select:focus,
.app-search .dd-handle:focus,
.app-search .dd3-content:focus {
  background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
  border: medium none;
  border-radius: 40px;
  box-shadow: none;
  color: #4c5667;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 40px;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  width: 180px;
}
.app-search .form-control:focus,
.app-search .select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.select2-container--default .select2-selection--single .app-search .select2-selection__rendered:focus,
.app-search .select2-container--default .select2-selection--single:focus,
.select2-container--default .app-search .select2-selection--single:focus,
.app-search .select2-selection.select2-selection--multiple:focus,
.app-search .wizard .content > .body input:focus,
.wizard .content > .body .app-search input:focus,
.app-search .mce-floatpanel .mce-textbox:focus,
.mce-floatpanel .app-search .mce-textbox:focus,
.app-search .mce-floatpanel .mce-btn:focus,
.mce-floatpanel .app-search .mce-btn:focus,
.app-search .dataTables_wrapper .dataTables_filter input:focus,
.dataTables_wrapper .dataTables_filter .app-search input:focus,
.app-search .dataTables_wrapper .dataTables_length select:focus,
.dataTables_wrapper .dataTables_length .app-search select:focus,
.app-search .jsgrid-cell input:focus,
.jsgrid-cell .app-search input:focus,
.app-search .jsgrid-cell select:focus,
.jsgrid-cell .app-search select:focus,
.app-search .tablesaw-bar .btn-select select:focus,
.tablesaw-bar .btn-select .app-search select:focus,
.app-search .dd-handle:focus,
.app-search .dd3-content:focus,
.app-search .form-control:focus:focus,
.app-search .select2-container--default .select2-selection--single .select2-selection__rendered:focus:focus,
.select2-container--default .select2-selection--single .app-search .select2-selection__rendered:focus:focus,
.app-search .select2-container--default .select2-selection--single:focus:focus,
.select2-container--default .app-search .select2-selection--single:focus:focus,
.app-search .select2-selection.select2-selection--multiple:focus:focus,
.app-search .wizard .content > .body input:focus:focus,
.wizard .content > .body .app-search input:focus:focus,
.app-search .mce-floatpanel .mce-textbox:focus:focus,
.mce-floatpanel .app-search .mce-textbox:focus:focus,
.app-search .mce-floatpanel .mce-btn:focus:focus,
.mce-floatpanel .app-search .mce-btn:focus:focus,
.app-search .dataTables_wrapper .dataTables_filter input:focus:focus,
.dataTables_wrapper .dataTables_filter .app-search input:focus:focus,
.app-search .dataTables_wrapper .dataTables_length select:focus:focus,
.dataTables_wrapper .dataTables_length .app-search select:focus:focus,
.app-search .jsgrid-cell input:focus:focus,
.jsgrid-cell .app-search input:focus:focus,
.app-search .jsgrid-cell select:focus:focus,
.jsgrid-cell .app-search select:focus:focus,
.app-search .tablesaw-bar .btn-select select:focus:focus,
.tablesaw-bar .btn-select .app-search select:focus:focus,
.app-search .dd-handle:focus:focus,
.app-search .dd3-content:focus:focus {
  width: 300px;
}

/*Chat UI*/
.chat-search {
  width: 100%;
  border-bottom: 1px solid #dedede;
}
.chat-search .input-group {
  width: 100%;
}
.chat-search .input-group .form-control,
.chat-search .input-group .select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .chat-search .input-group .select2-selection__rendered,
.chat-search .input-group .select2-container--default .select2-selection--single,
.select2-container--default .chat-search .input-group .select2-selection--single,
.chat-search .input-group .select2-selection.select2-selection--multiple,
.chat-search .input-group .wizard .content > .body input,
.wizard .content > .body .chat-search .input-group input,
.chat-search .input-group .mce-floatpanel .mce-textbox,
.mce-floatpanel .chat-search .input-group .mce-textbox,
.chat-search .input-group .mce-floatpanel .mce-btn,
.mce-floatpanel .chat-search .input-group .mce-btn,
.chat-search .input-group .dataTables_wrapper .dataTables_filter input,
.dataTables_wrapper .dataTables_filter .chat-search .input-group input,
.chat-search .input-group .dataTables_wrapper .dataTables_length select,
.dataTables_wrapper .dataTables_length .chat-search .input-group select,
.chat-search .input-group .jsgrid-cell input,
.jsgrid-cell .chat-search .input-group input,
.chat-search .input-group .jsgrid-cell select,
.jsgrid-cell .chat-search .input-group select,
.chat-search .input-group .tablesaw-bar .btn-select select,
.tablesaw-bar .btn-select .chat-search .input-group select,
.chat-search .input-group .dd-handle,
.chat-search .input-group .dd3-content {
  height: 36px;
  border-radius: 50px;
  padding-right: 30px;
  background: #fff;
}
.chat-search .input-group .input-group-btn {
  position: absolute;
  right: 22px;
  top: 8px;
  z-index: 4;
}
.chat-search .input-group .input-group-btn > .btn,
.chat-search .input-group .wizard > .actions .disabled .input-group-btn > a,
.chat-search .input-group .wizard > .actions .input-group-btn > a,
.wizard > .actions .chat-search .input-group .input-group-btn > a,
.chat-search .input-group .dt-buttons .input-group-btn > .dt-button,
.dt-buttons .chat-search .input-group .input-group-btn > .dt-button,
.chat-search .input-group .tablesaw-sortable th.tablesaw-sortable-head .input-group-btn > button,
.tablesaw-sortable th.tablesaw-sortable-head .chat-search .input-group .input-group-btn > button,
.chat-search .input-group .sweet-alert .input-group-btn > button,
.sweet-alert .chat-search .input-group .input-group-btn > button,
.chat-search .input-group .owl-theme .owl-nav .input-group-btn > [class*='owl-'],
.owl-theme .owl-nav .chat-search .input-group .input-group-btn > [class*='owl-'],
.chat-search .input-group .input-group-btn > button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.chat-search .input-group .input-group-btn > button.fc-month-button.fc-state-default.fc-corner-left,
.chat-search .input-group .input-group-btn > button.fc-agendaWeek-button,
.chat-search .input-group .input-group-btn > .fc-prev-button,
.chat-search .input-group .input-group-btn > .fc-next-button,
.chat-search .input-group .input-group-btn > .fc-today-button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  padding: 0;
}
.chat-search .input-group .input-group-btn > .btn i,
.chat-search .input-group .wizard > .actions .input-group-btn > a i,
.wizard > .actions .chat-search .input-group .input-group-btn > a i,
.chat-search .input-group .dt-buttons .input-group-btn > .dt-button i,
.dt-buttons .chat-search .input-group .input-group-btn > .dt-button i,
.chat-search .input-group .tablesaw-sortable th.tablesaw-sortable-head .input-group-btn > button i,
.tablesaw-sortable th.tablesaw-sortable-head .chat-search .input-group .input-group-btn > button i,
.chat-search .input-group .sweet-alert .input-group-btn > button i,
.sweet-alert .chat-search .input-group .input-group-btn > button i,
.chat-search .input-group .owl-theme .owl-nav .input-group-btn > [class*='owl-'] i,
.owl-theme .owl-nav .chat-search .input-group .input-group-btn > [class*='owl-'] i,
.chat-search .input-group .input-group-btn > button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.chat-search .input-group .input-group-btn > button.fc-month-button.fc-state-default.fc-corner-left i,
.chat-search .input-group .input-group-btn > button.fc-agendaWeek-button i,
.chat-search .input-group .input-group-btn > .fc-prev-button i,
.chat-search .input-group .input-group-btn > .fc-next-button i,
.chat-search .input-group .input-group-btn > .fc-today-button i {
  color: #878787;
  font-size: 20px;
}

.add-friend {
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.add-friend span {
  text-transform: capitalize;
  font-size: 16px;
  width: 33.33%;
  float: left;
  text-align: center;
  vertical-align: middle;
}
.add-friend a {
  width: 33.33%;
  float: left;
  font-size: 18px;
}

.chat-list-wrap .chat-body > a {
  border-bottom: 1px solid #dedede;
  display: block;
}
.chat-list-wrap .chat-body .chat-data {
  padding: 10px 15px;
  position: relative;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  transition: 0.2s ease;
}
.chat-list-wrap .chat-body .chat-data:hover {
  background: rgba(33, 33, 33, 0.05);
}
.chat-list-wrap .chat-body .chat-data.active-user {
  background: rgba(33, 33, 33, 0.05);
}
.chat-list-wrap .chat-body .chat-data .user-img {
  height: 35px;
  width: 35px;
  float: left;
  margin-right: 15px;
}
.chat-list-wrap .chat-body .chat-data .user-data {
  float: left;
}
.chat-list-wrap .chat-body .chat-data .user-data .time {
  font-size: 12px;
  margin-top: 2px;
  width: 165px;
}
.chat-list-wrap .chat-body .chat-data .status {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 10px;
  position: absolute;
  left: 40px;
  top: 37px;
  width: 10px;
}
.chat-list-wrap .chat-body .chat-data .status.away {
  background: #f8b32d;
}
.chat-list-wrap .chat-body .chat-data .status.offline {
  background: #f33923;
}
.chat-list-wrap .chat-body .chat-data .status.online {
  background: #8bc34a;
}

.recent-chat-wrap .panel-heading {
  padding: 0;
  background: transparent;
}
.recent-chat-wrap .panel-heading .goto-back {
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #dedede;
}
.recent-chat-wrap .panel-heading .goto-back span {
  text-transform: capitalize;
  font-size: 16px;
  width: 33.33%;
  float: left;
  text-align: center;
  vertical-align: middle;
}
.recent-chat-wrap .panel-heading .goto-back a {
  width: 33.33%;
  float: left;
  font-size: 18px;
}
.recent-chat-wrap.card-view {
  border: none;
  padding: 0;
  margin: 0;
}
.recent-chat-wrap .input-group {
  width: 100%;
  display: block;
}
.recent-chat-wrap .input-group .input-msg-send {
  background: rgba(33, 33, 33, 0.05);
  border: medium none;
  height: 60px;
  padding-right: 60px;
}
.recent-chat-wrap .input-group .input-group-btn {
  position: absolute;
  right: 22px;
  top: 20px;
  z-index: 4;
}
.recent-chat-wrap .input-group .input-group-btn .btn,
.recent-chat-wrap .input-group .input-group-btn .wizard > .actions a,
.wizard > .actions .recent-chat-wrap .input-group .input-group-btn a,
.recent-chat-wrap .input-group .input-group-btn .dt-buttons .dt-button,
.dt-buttons .recent-chat-wrap .input-group .input-group-btn .dt-button,
.recent-chat-wrap .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button,
.tablesaw-sortable th.tablesaw-sortable-head .recent-chat-wrap .input-group .input-group-btn button,
.recent-chat-wrap .input-group .input-group-btn .sweet-alert button,
.sweet-alert .recent-chat-wrap .input-group .input-group-btn button,
.recent-chat-wrap .input-group .input-group-btn .owl-theme .owl-nav [class*='owl-'],
.owl-theme .owl-nav .recent-chat-wrap .input-group .input-group-btn [class*='owl-'],
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.recent-chat-wrap .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaWeek-button,
.recent-chat-wrap .input-group .input-group-btn .fc-prev-button,
.recent-chat-wrap .input-group .input-group-btn .fc-next-button,
.recent-chat-wrap .input-group .input-group-btn .fc-today-button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  padding: 0;
}
.recent-chat-wrap .input-group .input-group-btn .btn:hover,
.recent-chat-wrap .input-group .input-group-btn .wizard > .actions a:hover,
.wizard > .actions .recent-chat-wrap .input-group .input-group-btn a:hover,
.recent-chat-wrap .input-group .input-group-btn .dt-buttons .dt-button:hover,
.dt-buttons .recent-chat-wrap .input-group .input-group-btn .dt-button:hover,
.recent-chat-wrap .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .recent-chat-wrap .input-group .input-group-btn button:hover,
.recent-chat-wrap .input-group .input-group-btn .sweet-alert button:hover,
.sweet-alert .recent-chat-wrap .input-group .input-group-btn button:hover,
.recent-chat-wrap .input-group .input-group-btn .owl-theme .owl-nav [class*='owl-']:hover,
.owl-theme .owl-nav .recent-chat-wrap .input-group .input-group-btn [class*='owl-']:hover,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.recent-chat-wrap .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:hover,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaWeek-button:hover,
.recent-chat-wrap .input-group .input-group-btn .fc-prev-button:hover,
.recent-chat-wrap .input-group .input-group-btn .fc-next-button:hover,
.recent-chat-wrap .input-group .input-group-btn .fc-today-button:hover,
.recent-chat-wrap .input-group .input-group-btn .btn:active,
.recent-chat-wrap .input-group .input-group-btn .wizard > .actions a:active,
.wizard > .actions .recent-chat-wrap .input-group .input-group-btn a:active,
.recent-chat-wrap .input-group .input-group-btn .dt-buttons .dt-button:active,
.dt-buttons .recent-chat-wrap .input-group .input-group-btn .dt-button:active,
.recent-chat-wrap .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:active,
.tablesaw-sortable th.tablesaw-sortable-head .recent-chat-wrap .input-group .input-group-btn button:active,
.recent-chat-wrap .input-group .input-group-btn .sweet-alert button:active,
.sweet-alert .recent-chat-wrap .input-group .input-group-btn button:active,
.recent-chat-wrap .input-group .input-group-btn .owl-theme .owl-nav [class*='owl-']:active,
.owl-theme .owl-nav .recent-chat-wrap .input-group .input-group-btn [class*='owl-']:active,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.recent-chat-wrap .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:active,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaWeek-button:active,
.recent-chat-wrap .input-group .input-group-btn .fc-prev-button:active,
.recent-chat-wrap .input-group .input-group-btn .fc-next-button:active,
.recent-chat-wrap .input-group .input-group-btn .fc-today-button:active,
.recent-chat-wrap .input-group .input-group-btn .btn:focus,
.recent-chat-wrap .input-group .input-group-btn .wizard > .actions a:focus,
.wizard > .actions .recent-chat-wrap .input-group .input-group-btn a:focus,
.recent-chat-wrap .input-group .input-group-btn .dt-buttons .dt-button:focus,
.dt-buttons .recent-chat-wrap .input-group .input-group-btn .dt-button:focus,
.recent-chat-wrap .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .recent-chat-wrap .input-group .input-group-btn button:focus,
.recent-chat-wrap .input-group .input-group-btn .sweet-alert button:focus,
.sweet-alert .recent-chat-wrap .input-group .input-group-btn button:focus,
.recent-chat-wrap .input-group .input-group-btn .owl-theme .owl-nav [class*='owl-']:focus,
.owl-theme .owl-nav .recent-chat-wrap .input-group .input-group-btn [class*='owl-']:focus,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.recent-chat-wrap .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:focus,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaWeek-button:focus,
.recent-chat-wrap .input-group .input-group-btn .fc-prev-button:focus,
.recent-chat-wrap .input-group .input-group-btn .fc-next-button:focus,
.recent-chat-wrap .input-group .input-group-btn .fc-today-button:focus {
  background: transparent none repeat scroll 0 0;
}
.recent-chat-wrap .input-group .input-group-btn .btn i,
.recent-chat-wrap .input-group .input-group-btn .wizard > .actions a i,
.wizard > .actions .recent-chat-wrap .input-group .input-group-btn a i,
.recent-chat-wrap .input-group .input-group-btn .dt-buttons .dt-button i,
.dt-buttons .recent-chat-wrap .input-group .input-group-btn .dt-button i,
.recent-chat-wrap .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button i,
.tablesaw-sortable th.tablesaw-sortable-head .recent-chat-wrap .input-group .input-group-btn button i,
.recent-chat-wrap .input-group .input-group-btn .sweet-alert button i,
.sweet-alert .recent-chat-wrap .input-group .input-group-btn button i,
.recent-chat-wrap .input-group .input-group-btn .owl-theme .owl-nav [class*='owl-'] i,
.owl-theme .owl-nav .recent-chat-wrap .input-group .input-group-btn [class*='owl-'] i,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.recent-chat-wrap .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left i,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaWeek-button i,
.recent-chat-wrap .input-group .input-group-btn .fc-prev-button i,
.recent-chat-wrap .input-group .input-group-btn .fc-next-button i,
.recent-chat-wrap .input-group .input-group-btn .fc-today-button i {
  color: rgba(33, 33, 33, 0.2);
  font-size: 20px;
}
.recent-chat-wrap .input-group .input-group-btn.emojis {
  right: 50px;
}
.recent-chat-wrap .chat-content .user-img {
  height: 27px;
  width: 27px;
  position: absolute;
}
.recent-chat-wrap .chat-content ul li {
  padding: 0 15px;
  margin-bottom: 20px;
  position: relative;
}
.recent-chat-wrap .chat-content .msg {
  padding: 7px 15px;
  color: #212121;
  max-width: 100%;
  overflow-wrap: break-word;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}
.recent-chat-wrap .chat-content .msg .msg-per-detail span {
  font-size: 10px;
  text-transform: uppercase;
}
.recent-chat-wrap .chat-content .friend .friend-msg-wrap .msg {
  margin-left: 37px;
  background: rgba(33, 33, 33, 0.04) none repeat scroll 0 0;
  border-radius: 15px;
}
.recent-chat-wrap .chat-content .self .self-msg-wrap .msg {
  background: rgba(139, 195, 74, 0.24) none repeat scroll 0 0;
  margin-left: 100px;
  border-radius: 15px;
}

.chat-cmplt-wrap {
  position: relative;
}
.chat-cmplt-wrap .chat-box-wrap {
  left: 0;
  position: relative;
  -webkit-transition: left 0.4s ease 0s;
  -moz-transition: left 0.4s ease 0s;
  transition: left 0.4s ease 0s;
}
.chat-cmplt-wrap .recent-chat-box-wrap {
  position: absolute;
  right: -300px;
  top: 0;
  -webkit-transition: right 0.4s ease 0s;
  -moz-transition: right 0.4s ease 0s;
  transition: right 0.4s ease 0s;
  width: 100%;
  z-index: 2;
}
.chat-cmplt-wrap.chat-box-slide .chat-box-wrap {
  left: -300px;
}
.chat-cmplt-wrap.chat-box-slide .recent-chat-box-wrap {
  right: 0;
}
.chat-cmplt-wrap.chat-for-widgets,
.chat-cmplt-wrap.chat-for-widgets-1 {
  height: 370px;
  overflow: hidden;
}
.chat-cmplt-wrap.chat-for-widgets .recent-chat-box-wrap,
.chat-cmplt-wrap.chat-for-widgets-1 .recent-chat-box-wrap {
  position: absolute;
  right: -100%;
}
.chat-cmplt-wrap.chat-for-widgets.chat-box-slide .chat-box-wrap,
.chat-cmplt-wrap.chat-box-slide.chat-for-widgets-1 .chat-box-wrap {
  left: -100%;
}
.chat-cmplt-wrap.chat-for-widgets.chat-box-slide .recent-chat-box-wrap,
.chat-cmplt-wrap.chat-box-slide.chat-for-widgets-1 .recent-chat-box-wrap {
  right: 0;
}
.chat-cmplt-wrap.chat-for-widgets-1 {
  height: 596px;
}
.chat-cmplt-wrap.chat-for-widgets-1 .chat-search {
  padding: 0;
}
.chat-cmplt-wrap.chat-for-widgets-1 .chat-search .input-group input {
  height: 52px;
  border-radius: 0;
  border: none;
}
.chat-cmplt-wrap.chat-for-widgets-1 .chat-search .input-group .input-group-btn {
  top: 18px;
}
.chat-cmplt-wrap.chat-for-widgets-1 .chat-box-wrap {
  float: left;
  width: 30%;
}
.chat-cmplt-wrap.chat-for-widgets-1 .recent-chat-box-wrap {
  border-left: 1px solid rgba(33, 33, 33, 0.05);
  float: left;
  position: relative;
  right: 0;
  width: 70%;
}

/*Widgets*/
.top-countries img {
  width: 20px;
}

/*Profile*/
.profile-box .fileupload.btn,
.profile-box .wizard > .actions a.fileupload,
.wizard > .actions .profile-box a.fileupload,
.profile-box .dt-buttons .fileupload.dt-button,
.dt-buttons .profile-box .fileupload.dt-button,
.profile-box .tablesaw-sortable th.tablesaw-sortable-head button.fileupload,
.tablesaw-sortable th.tablesaw-sortable-head .profile-box button.fileupload,
.profile-box .sweet-alert button.fileupload,
.sweet-alert .profile-box button.fileupload,
.profile-box .owl-theme .owl-nav .fileupload[class*='owl-'],
.owl-theme .owl-nav .profile-box .fileupload[class*='owl-'],
.profile-box button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right,
.profile-box button.fileupload.fc-month-button.fc-state-default.fc-corner-left,
.profile-box button.fileupload.fc-agendaWeek-button,
.profile-box .fileupload.fc-prev-button,
.profile-box .fileupload.fc-next-button,
.profile-box .fileupload.fc-today-button {
  background: rgba(33, 33, 33, 0.5);
  border-radius: 0;
  padding: 3px 10px;
  border: none;
}
.profile-box .fileupload.btn:hover,
.profile-box .wizard > .actions a.fileupload:hover,
.wizard > .actions .profile-box a.fileupload:hover,
.profile-box .dt-buttons .fileupload.dt-button:hover,
.dt-buttons .profile-box .fileupload.dt-button:hover,
.profile-box .tablesaw-sortable th.tablesaw-sortable-head button.fileupload:hover,
.tablesaw-sortable th.tablesaw-sortable-head .profile-box button.fileupload:hover,
.profile-box .sweet-alert button.fileupload:hover,
.sweet-alert .profile-box button.fileupload:hover,
.profile-box .owl-theme .owl-nav .fileupload[class*='owl-']:hover,
.owl-theme .owl-nav .profile-box .fileupload[class*='owl-']:hover,
.profile-box button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.profile-box button.fileupload.fc-month-button.fc-state-default.fc-corner-left:hover,
.profile-box button.fileupload.fc-agendaWeek-button:hover,
.profile-box .fileupload.fc-prev-button:hover,
.profile-box .fileupload.fc-next-button:hover,
.profile-box .fileupload.fc-today-button:hover,
.profile-box .fileupload.btn:active,
.profile-box .wizard > .actions a.fileupload:active,
.wizard > .actions .profile-box a.fileupload:active,
.profile-box .dt-buttons .fileupload.dt-button:active,
.dt-buttons .profile-box .fileupload.dt-button:active,
.profile-box .tablesaw-sortable th.tablesaw-sortable-head button.fileupload:active,
.tablesaw-sortable th.tablesaw-sortable-head .profile-box button.fileupload:active,
.profile-box .sweet-alert button.fileupload:active,
.sweet-alert .profile-box button.fileupload:active,
.profile-box .owl-theme .owl-nav .fileupload[class*='owl-']:active,
.owl-theme .owl-nav .profile-box .fileupload[class*='owl-']:active,
.profile-box button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.profile-box button.fileupload.fc-month-button.fc-state-default.fc-corner-left:active,
.profile-box button.fileupload.fc-agendaWeek-button:active,
.profile-box .fileupload.fc-prev-button:active,
.profile-box .fileupload.fc-next-button:active,
.profile-box .fileupload.fc-today-button:active,
.profile-box .fileupload.btn:focus,
.profile-box .wizard > .actions a.fileupload:focus,
.wizard > .actions .profile-box a.fileupload:focus,
.profile-box .dt-buttons .fileupload.dt-button:focus,
.dt-buttons .profile-box .fileupload.dt-button:focus,
.profile-box .tablesaw-sortable th.tablesaw-sortable-head button.fileupload:focus,
.tablesaw-sortable th.tablesaw-sortable-head .profile-box button.fileupload:focus,
.profile-box .sweet-alert button.fileupload:focus,
.sweet-alert .profile-box button.fileupload:focus,
.profile-box .owl-theme .owl-nav .fileupload[class*='owl-']:focus,
.owl-theme .owl-nav .profile-box .fileupload[class*='owl-']:focus,
.profile-box button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.profile-box button.fileupload.fc-month-button.fc-state-default.fc-corner-left:focus,
.profile-box button.fileupload.fc-agendaWeek-button:focus,
.profile-box .fileupload.fc-prev-button:focus,
.profile-box .fileupload.fc-next-button:focus,
.profile-box .fileupload.fc-today-button:focus {
  background: rgba(33, 33, 33, 0.5);
}
.profile-box .fileupload.btn .btn-text,
.profile-box .wizard > .actions a.fileupload .btn-text,
.wizard > .actions .profile-box a.fileupload .btn-text,
.profile-box .dt-buttons .fileupload.dt-button .btn-text,
.dt-buttons .profile-box .fileupload.dt-button .btn-text,
.profile-box .tablesaw-sortable th.tablesaw-sortable-head button.fileupload .btn-text,
.tablesaw-sortable th.tablesaw-sortable-head .profile-box button.fileupload .btn-text,
.profile-box .sweet-alert button.fileupload .btn-text,
.sweet-alert .profile-box button.fileupload .btn-text,
.profile-box .owl-theme .owl-nav .fileupload[class*='owl-'] .btn-text,
.owl-theme .owl-nav .profile-box .fileupload[class*='owl-'] .btn-text,
.profile-box button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-text,
.profile-box button.fileupload.fc-month-button.fc-state-default.fc-corner-left .btn-text,
.profile-box button.fileupload.fc-agendaWeek-button .btn-text,
.profile-box .fileupload.fc-prev-button .btn-text,
.profile-box .fileupload.fc-next-button .btn-text,
.profile-box .fileupload.fc-today-button .btn-text {
  color: #fff;
  font-size: 13px;
}
.profile-box .profile-cover-pic {
  position: relative;
  min-height: 197px;
  background-image: url('../images/mock6.jpg');
}
.profile-box .profile-cover-pic .profile-image-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #212121;
  opacity: 0.4;
}
.profile-box .profile-cover-pic .fileupload.btn,
.profile-box .profile-cover-pic .wizard > .actions a.fileupload,
.wizard > .actions .profile-box .profile-cover-pic a.fileupload,
.profile-box .profile-cover-pic .dt-buttons .fileupload.dt-button,
.dt-buttons .profile-box .profile-cover-pic .fileupload.dt-button,
.profile-box .profile-cover-pic .tablesaw-sortable th.tablesaw-sortable-head button.fileupload,
.tablesaw-sortable th.tablesaw-sortable-head .profile-box .profile-cover-pic button.fileupload,
.profile-box .profile-cover-pic .sweet-alert button.fileupload,
.sweet-alert .profile-box .profile-cover-pic button.fileupload,
.profile-box .profile-cover-pic .owl-theme .owl-nav .fileupload[class*='owl-'],
.owl-theme .owl-nav .profile-box .profile-cover-pic .fileupload[class*='owl-'],
.profile-box .profile-cover-pic button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right,
.profile-box .profile-cover-pic button.fileupload.fc-month-button.fc-state-default.fc-corner-left,
.profile-box .profile-cover-pic button.fileupload.fc-agendaWeek-button,
.profile-box .profile-cover-pic .fileupload.fc-prev-button,
.profile-box .profile-cover-pic .fileupload.fc-next-button,
.profile-box .profile-cover-pic .fileupload.fc-today-button {
  position: relative;
  float: right;
  z-index: 1;
}
.profile-box .profile-info {
  position: relative;
  z-index: 1;
}
.profile-box .profile-info .profile-img-wrap {
  border-radius: 2px;
  height: 143px;
  margin: -80px auto 0;
  padding: 4px;
  position: relative;
  width: 143px;
  background: #fff;
}
.profile-box .profile-info .profile-img-wrap img {
  width: 135px;
  height: 135px;
}
.profile-box .profile-info .profile-img-wrap .fileupload.btn,
.profile-box .profile-info .profile-img-wrap .wizard > .actions a.fileupload,
.wizard > .actions .profile-box .profile-info .profile-img-wrap a.fileupload,
.profile-box .profile-info .profile-img-wrap .dt-buttons .fileupload.dt-button,
.dt-buttons .profile-box .profile-info .profile-img-wrap .fileupload.dt-button,
.profile-box .profile-info .profile-img-wrap .tablesaw-sortable th.tablesaw-sortable-head button.fileupload,
.tablesaw-sortable th.tablesaw-sortable-head .profile-box .profile-info .profile-img-wrap button.fileupload,
.profile-box .profile-info .profile-img-wrap .sweet-alert button.fileupload,
.sweet-alert .profile-box .profile-info .profile-img-wrap button.fileupload,
.profile-box .profile-info .profile-img-wrap .owl-theme .owl-nav .fileupload[class*='owl-'],
.owl-theme .owl-nav .profile-box .profile-info .profile-img-wrap .fileupload[class*='owl-'],
.profile-box .profile-info .profile-img-wrap button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right,
.profile-box .profile-info .profile-img-wrap button.fileupload.fc-month-button.fc-state-default.fc-corner-left,
.profile-box .profile-info .profile-img-wrap button.fileupload.fc-agendaWeek-button,
.profile-box .profile-info .profile-img-wrap .fileupload.fc-prev-button,
.profile-box .profile-info .profile-img-wrap .fileupload.fc-next-button,
.profile-box .profile-info .profile-img-wrap .fileupload.fc-today-button {
  position: absolute;
  right: 4px;
  bottom: 4px;
}
.profile-box .social-info {
  border-top: 1px solid rgba(33, 33, 33, 0.05);
  padding: 30px 15px;
  color: #212121;
}
.profile-box .social-info .counts {
  font-size: 21px;
}
.profile-box .social-info .counts-text {
  color: #878787;
}

/*Followers*/
.followers-list-wrap .follo-body .follo-data {
  padding: 10px 15px;
  border-bottom: 1px solid #dedede;
  display: block;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  transition: 0.2s ease;
}
.followers-list-wrap .follo-body .follo-data .user-img {
  height: 35px;
  width: 35px;
  float: left;
  margin-right: 15px;
}
.followers-list-wrap .follo-body .follo-data .user-data {
  float: left;
}
.followers-list-wrap .follo-body .follo-data .user-data .name {
  color: #212121;
}
.followers-list-wrap .follo-body .follo-data .user-data .time {
  font-size: 12px;
  margin-top: 2px;
  width: 165px;
}

/*Small graph*/
.flex-stat {
  overflow: hidden;
}
.flex-stat li {
  float: left;
  width: 33.33%;
  text-align: center;
}
.flex-stat li > span {
  text-transform: capitalize;
}

.sm-data-box .counter {
  font-size: 24px;
}
.sm-data-box .data-wrap-left,
.sm-data-box .data-wrap-right {
  padding-top: 19px;
  padding-bottom: 19px;
  min-height: 99px;
}
.sm-data-box .data-wrap-right .data-right-rep-icon {
  display: inline-block;
  font-size: 40px;
  padding-top: 10px;
}
.sm-data-box.data-with-border .data-wrap-left {
  border-right: 1px solid rgba(135, 135, 135, 0.2);
}

.sm-data-box-1 .cus-sat-stat {
  font-size: 42px;
}

.sm-data-box-2 .table > tbody > tr > td,
.sm-data-box-2 .jsgrid-table > tbody > tr > td,
.sm-data-box-2 .table > tbody > tr > th,
.sm-data-box-2 .jsgrid-table > tbody > tr > th,
.sm-data-box-2 .table > tfoot > tr > td,
.sm-data-box-2 .jsgrid-table > tfoot > tr > td,
.sm-data-box-2 .table > tfoot > tr > th,
.sm-data-box-2 .jsgrid-table > tfoot > tr > th,
.sm-data-box-2 .table > thead > tr > td,
.sm-data-box-2 .jsgrid-table > thead > tr > td,
.sm-data-box-2 .table > thead > tr > th,
.sm-data-box-2 .jsgrid-table > thead > tr > th {
  padding: 12.5px 15px;
}

.sm-data-box-3 .panel-heading .pull-left i.zmdi {
  padding-top: 11px;
}
.sm-data-box-3 .easypiechart {
  height: 165px;
  width: 165px;
}
.sm-data-box-3 .easypiechart .percent {
  font-size: 30px;
  line-height: 30px;
  margin-top: 56px;
}
.sm-data-box-3 .easypiechart .percent::after {
  font-size: 30px;
}
.sm-data-box-3 i.zmdi-caret-up,
.sm-data-box-3 i.zmdi-caret-down {
  position: relative;
  top: 2px;
}

.lg-progress-box i {
  color: #e5e5e5;
  font-size: 56px;
}

/*Timeline*/
.timeline {
  list-style: none;
  padding: 10px 0;
  position: relative;
}
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 1px;
  background: #dedede;
  left: 50%;
  margin-left: -1.5px;
}
.timeline > li {
  margin-bottom: 150px;
  position: relative;
  width: 50%;
  float: left;
  clear: left;
}
.timeline > li:last-child {
  margin-bottom: 0;
}
.timeline > li > .timeline-panel {
  width: calc(100% - 50px);
  float: left;
  position: relative;
  border-radius: 2px;
  background-color: rgba(33, 33, 33, 0.05);
}
.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 30px;
  right: -10px;
  display: inline-block;
  border-top: 10px solid transparent;
  border-left: 10px solid;
  border-right: 0 solid #fff;
  border-bottom: 10px solid transparent;
  border-left-color: rgba(33, 33, 33, 0.05);
  content: ' ';
}
.timeline > li > .timeline-panel.bg-yellow:after {
  border-left-color: #f8b32d;
}
.timeline > li > .timeline-panel.bg-red:after {
  border-left-color: #f33923;
}
.timeline > li > .timeline-panel.bg-blue:after {
  border-left-color: #2196f3;
}
.timeline > li > .timeline-panel.bg-green:after {
  border-left-color: #8bc34a;
}
.timeline > li > .timeline-badge {
  color: #fff;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 16px;
  right: -22px;
  z-index: 100;
}
.timeline > li > .timeline-badge i {
  font-size: 16px;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 10px;
  left: -10px;
  right: auto;
  border-right-color: rgba(33, 33, 33, 0.05);
}
.timeline > li.timeline-inverted > .timeline-panel.bg-yellow:after {
  border-right-color: #f8b32d;
}
.timeline > li.timeline-inverted > .timeline-panel.bg-pink:after {
  border-right-color: #e7368d;
}
.timeline > li.timeline-inverted > .timeline-panel.bg-red:after {
  border-right-color: #f33923;
}
.timeline > li.timeline-inverted > .timeline-panel.bg-blue:after {
  border-right-color: #2196f3;
}
.timeline > li.timeline-inverted > .timeline-panel.bg-green:after {
  border-right-color: #8bc34a;
}

.timeline > li.timeline-inverted {
  float: right;
  clear: right;
  margin-top: 150px;
}
.timeline > li.timeline-inverted > .timeline-badge {
  left: -22px;
}

/*Caledar*/
.calendar-event {
  cursor: move;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
}
.calendar-event a {
  color: #fff;
  font-size: 10px;
  margin: 4px 0 0 10px;
  opacity: 0.6;
}

.fc button {
  height: auto !important;
  font-size: 14px !important;
  text-shadow: none !important;
}

button.fc-agendaDay-button.fc-state-default.fc-corner-right,
button.fc-month-button.fc-state-default.fc-corner-left,
button.fc-agendaWeek-button {
  padding: 9px 25px;
}

.fc-month-button,
.fc-agendaWeek-button {
  margin-right: 15px !important;
}

.fc-event {
  background-color: rgba(139, 195, 74, 0.2);
  border: none;
  border-radius: 0;
  font-size: 12px;
  color: #212121;
}

.fc-day-grid-event {
  margin: 5px 0 0;
  padding: 5px;
}

.fc-prev-button,
.fc-next-button,
.fc-today-button {
  border-radius: 0 !important;
}

.fc-unthemed .fc-today {
  background: #8bc34a;
  color: #fff;
}

.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
  color: #212121;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover {
  border-color: #dedede;
}

.small-calendar .fc-left h2 {
  font-size: 16px;
  line-height: 20px;
}
.small-calendar .fc-next-button,
.small-calendar .fc-prev-button {
  background: transparent;
  border: none;
  color: #878787;
  font-size: 20px !important;
  padding: 0;
  line-height: 0;
}
.small-calendar .fc-next-button .fc-icon,
.small-calendar .fc-prev-button .fc-icon {
  display: inline-block;
  font-family: 'Material-Design-Iconic-Font';
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-size: inherit;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-rendering: auto;
}
.small-calendar .fc-next-button .fc-icon-right-single-arrow:before {
  content: '\f2fb';
}
.small-calendar .fc-next-button .fc-icon-right-single-arrow:after {
  content: '';
  font-size: inherit;
  font-weight: inherit;
  top: inherit;
}
.small-calendar .fc-prev-button {
  margin-right: 5px !important;
}
.small-calendar .fc-prev-button .fc-icon-left-single-arrow:before {
  content: '\f2fa';
}
.small-calendar .fc-prev-button .fc-icon-left-single-arrow:after {
  content: '';
  font-size: inherit;
  font-weight: inherit;
  top: inherit;
}

/*Todo List*/
.todo-box-wrap .add-todo {
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #dedede;
}
.todo-box-wrap .add-todo span {
  text-transform: capitalize;
  font-size: 16px;
  width: 33.33%;
  float: left;
  text-align: center;
  vertical-align: middle;
}
.todo-box-wrap .add-todo a {
  width: 33.33%;
  float: left;
  font-size: 18px;
}
.todo-box-wrap .new-todo .input-group {
  width: 100%;
  display: block;
}
.todo-box-wrap .new-todo .input-group input {
  background: rgba(33, 33, 33, 0.05);
  border: medium none;
  height: 60px;
  padding-right: 60px;
}
.todo-box-wrap .new-todo .input-group .input-group-btn {
  position: absolute;
  right: 22px;
  top: 20px;
  z-index: 4;
}
.todo-box-wrap .new-todo .input-group .input-group-btn .btn,
.todo-box-wrap .new-todo .input-group .input-group-btn .wizard > .actions a,
.wizard > .actions .todo-box-wrap .new-todo .input-group .input-group-btn a,
.todo-box-wrap .new-todo .input-group .input-group-btn .dt-buttons .dt-button,
.dt-buttons .todo-box-wrap .new-todo .input-group .input-group-btn .dt-button,
.todo-box-wrap .new-todo .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button,
.tablesaw-sortable th.tablesaw-sortable-head .todo-box-wrap .new-todo .input-group .input-group-btn button,
.todo-box-wrap .new-todo .input-group .input-group-btn .sweet-alert button,
.sweet-alert .todo-box-wrap .new-todo .input-group .input-group-btn button,
.todo-box-wrap .new-todo .input-group .input-group-btn .owl-theme .owl-nav [class*='owl-'],
.owl-theme .owl-nav .todo-box-wrap .new-todo .input-group .input-group-btn [class*='owl-'],
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaWeek-button,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-prev-button,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-next-button,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-today-button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  padding: 0;
}
.todo-box-wrap .new-todo .input-group .input-group-btn .btn:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn .wizard > .actions a:hover,
.wizard > .actions .todo-box-wrap .new-todo .input-group .input-group-btn a:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn .dt-buttons .dt-button:hover,
.dt-buttons .todo-box-wrap .new-todo .input-group .input-group-btn .dt-button:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:hover,
.tablesaw-sortable th.tablesaw-sortable-head .todo-box-wrap .new-todo .input-group .input-group-btn button:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn .sweet-alert button:hover,
.sweet-alert .todo-box-wrap .new-todo .input-group .input-group-btn button:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn .owl-theme .owl-nav [class*='owl-']:hover,
.owl-theme .owl-nav .todo-box-wrap .new-todo .input-group .input-group-btn [class*='owl-']:hover,
.todo-box-wrap
  .new-todo
  .input-group
  .input-group-btn
  button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaWeek-button:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-prev-button:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-next-button:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-today-button:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn .btn:active,
.todo-box-wrap .new-todo .input-group .input-group-btn .wizard > .actions a:active,
.wizard > .actions .todo-box-wrap .new-todo .input-group .input-group-btn a:active,
.todo-box-wrap .new-todo .input-group .input-group-btn .dt-buttons .dt-button:active,
.dt-buttons .todo-box-wrap .new-todo .input-group .input-group-btn .dt-button:active,
.todo-box-wrap .new-todo .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:active,
.tablesaw-sortable th.tablesaw-sortable-head .todo-box-wrap .new-todo .input-group .input-group-btn button:active,
.todo-box-wrap .new-todo .input-group .input-group-btn .sweet-alert button:active,
.sweet-alert .todo-box-wrap .new-todo .input-group .input-group-btn button:active,
.todo-box-wrap .new-todo .input-group .input-group-btn .owl-theme .owl-nav [class*='owl-']:active,
.owl-theme .owl-nav .todo-box-wrap .new-todo .input-group .input-group-btn [class*='owl-']:active,
.todo-box-wrap
  .new-todo
  .input-group
  .input-group-btn
  button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:active,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaWeek-button:active,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-prev-button:active,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-next-button:active,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-today-button:active,
.todo-box-wrap .new-todo .input-group .input-group-btn .btn:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn .wizard > .actions a:focus,
.wizard > .actions .todo-box-wrap .new-todo .input-group .input-group-btn a:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn .dt-buttons .dt-button:focus,
.dt-buttons .todo-box-wrap .new-todo .input-group .input-group-btn .dt-button:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:focus,
.tablesaw-sortable th.tablesaw-sortable-head .todo-box-wrap .new-todo .input-group .input-group-btn button:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn .sweet-alert button:focus,
.sweet-alert .todo-box-wrap .new-todo .input-group .input-group-btn button:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn .owl-theme .owl-nav [class*='owl-']:focus,
.owl-theme .owl-nav .todo-box-wrap .new-todo .input-group .input-group-btn [class*='owl-']:focus,
.todo-box-wrap
  .new-todo
  .input-group
  .input-group-btn
  button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaWeek-button:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-prev-button:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-next-button:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-today-button:focus {
  background: transparent none repeat scroll 0 0;
}
.todo-box-wrap .new-todo .input-group .input-group-btn .btn i,
.todo-box-wrap .new-todo .input-group .input-group-btn .wizard > .actions a i,
.wizard > .actions .todo-box-wrap .new-todo .input-group .input-group-btn a i,
.todo-box-wrap .new-todo .input-group .input-group-btn .dt-buttons .dt-button i,
.dt-buttons .todo-box-wrap .new-todo .input-group .input-group-btn .dt-button i,
.todo-box-wrap .new-todo .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button i,
.tablesaw-sortable th.tablesaw-sortable-head .todo-box-wrap .new-todo .input-group .input-group-btn button i,
.todo-box-wrap .new-todo .input-group .input-group-btn .sweet-alert button i,
.sweet-alert .todo-box-wrap .new-todo .input-group .input-group-btn button i,
.todo-box-wrap .new-todo .input-group .input-group-btn .owl-theme .owl-nav [class*='owl-'] i,
.owl-theme .owl-nav .todo-box-wrap .new-todo .input-group .input-group-btn [class*='owl-'] i,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left i,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaWeek-button i,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-prev-button i,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-next-button i,
.todo-box-wrap .new-todo .input-group .input-group-btn .fc-today-button i {
  color: rgba(33, 33, 33, 0.2);
  font-size: 20px;
}

.todo-list hr {
  margin: 0;
}
.todo-list .todo-item {
  padding: 10px 15px;
  cursor: pointer;
}
.todo-list .todo-item:hover {
  background: rgba(33, 33, 33, 0.05);
}
.todo-list .todo-item .checkbox input[type='checkbox'] {
  width: 100%;
}
.todo-list .todo-item .checkbox input[type='checkbox'] + label {
  color: #212121;
  padding-left: 10px;
}
.todo-list .todo-item .checkbox input[type='checkbox']:checked + label {
  color: #878787;
  text-decoration: line-through;
}

/*Activity*/
.sl-item {
  position: relative;
  padding-bottom: 18px;
  border-left: 1px solid #dedede;
  margin-left: 15px;
}
.sl-item .sl-content {
  margin-left: 30px;
}
.sl-item .sl-content p {
  margin: 0;
}

.user-activity .sl-item .sl-content {
  border-bottom: 1px solid rgba(33, 33, 33, 0.05);
  padding-bottom: 15px;
}
.user-activity .sl-item .sl-content .activity-thumbnail {
  margin-top: 5px;
}
.user-activity .sl-item .sl-content .activity-thumbnail img {
  height: 65px;
  width: 65px;
  border-radius: 2px;
  margin-right: 10px;
}
.user-activity .sl-item .sl-content .activity-thumbnail img:last-child {
  margin-right: 0;
}
.user-activity .sl-item:last-child .sl-content {
  border-bottom: 0;
  padding-bottom: 0;
}

.sl-avatar {
  position: absolute;
  left: -15px;
}
.sl-avatar img {
  height: 30px;
  width: 30px;
}

/*Message Box*/
.message-box-wrap .msg-search {
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #dedede;
}
.message-box-wrap .msg-search span {
  text-transform: capitalize;
  font-size: 16px;
  width: 33.33%;
  float: left;
  text-align: center;
  vertical-align: middle;
}
.message-box-wrap .msg-search a {
  width: 33.33%;
  float: left;
  font-size: 18px;
}
.message-box-wrap .message-box-bottom-wrap .read-all {
  padding: 5px 15px;
  font-size: 13px;
  color: #878787;
  text-transform: capitalize;
}

.message-box > a,
.review-box > a {
  display: block;
  border-bottom: 1px solid #dedede;
}
.message-box > a .sl-item,
.review-box > a .sl-item {
  border: none;
  padding: 10px 15px;
  margin-left: 0;
  background: rgba(33, 33, 33, 0.05);
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  transition: 0.2s ease;
}
.message-box > a .sl-item:before,
.review-box > a .sl-item:before,
.message-box > a .sl-item:after,
.review-box > a .sl-item:after {
  display: none;
}
.message-box > a .sl-item .sl-avatar,
.review-box > a .sl-item .sl-avatar {
  left: auto;
}
.message-box > a .sl-item .sl-avatar img,
.review-box > a .sl-item .sl-avatar img {
  width: 35px;
  height: 35px;
}
.message-box > a .sl-item .sl-content,
.review-box > a .sl-item .sl-content {
  margin-left: 50px;
}
.message-box > a .sl-item .sl-content .message-time,
.review-box > a .sl-item .sl-content .message-time {
  color: #878787;
}
.message-box > a .sl-item .sl-content .message-subject,
.review-box > a .sl-item .sl-content .message-subject {
  font-size: 13px;
}
.message-box > a .sl-item .sl-content > p,
.review-box > a .sl-item .sl-content > p {
  font-size: 13px;
  line-height: 16px;
}
.message-box > a .sl-item.unread-message,
.review-box > a .sl-item.unread-message {
  background: transparent;
}
.message-box > a .sl-item.unread-message:hover,
.review-box > a .sl-item.unread-message:hover {
  background: rgba(33, 33, 33, 0.05);
}
.message-box > a .sl-item.unread-message .sl-content .message-per,
.review-box > a .sl-item.unread-message .sl-content .message-per,
.message-box > a .sl-item.unread-message .sl-content .message-subject,
.review-box > a .sl-item.unread-message .sl-content .message-subject {
  font-weight: 500;
}

.review-box hr {
  margin: 0;
}
.review-box .per-rating a {
  color: #8bc34a;
  margin-right: 4px;
  font-size: 17px;
}
.review-box .per-rating span {
  font-size: 17px;
  color: #212121;
}
.review-box .sl-item {
  margin-left: 0;
  border: none;
  padding: 20px 15px;
}
.review-box .sl-item:before {
  display: none;
}
.review-box .sl-item .sl-content {
  margin-left: 0;
}

/*Inbox*/
.mail-box .panel {
  border: none;
  box-shadow: none;
  margin-bottom: 0;
  border-radius: 0;
  border-left: 1px solid #dedede;
  min-height: 1000px;
}
.mail-box .inbox-search .input-group {
  width: 100%;
}
.mail-box .inbox-search .input-group input {
  height: 25px;
  padding-right: 30px;
  width: 150px;
}
.mail-box .inbox-search .input-group .input-group-btn {
  position: absolute;
  right: 22px;
  top: 3px;
  z-index: 4;
  pointer-events: none;
}
.mail-box .inbox-search .input-group .input-group-btn .btn,
.mail-box .inbox-search .input-group .input-group-btn .wizard > .actions a,
.wizard > .actions .mail-box .inbox-search .input-group .input-group-btn a,
.mail-box .inbox-search .input-group .input-group-btn .dt-buttons .dt-button,
.dt-buttons .mail-box .inbox-search .input-group .input-group-btn .dt-button,
.mail-box .inbox-search .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button,
.tablesaw-sortable th.tablesaw-sortable-head .mail-box .inbox-search .input-group .input-group-btn button,
.mail-box .inbox-search .input-group .input-group-btn .sweet-alert button,
.sweet-alert .mail-box .inbox-search .input-group .input-group-btn button,
.mail-box .inbox-search .input-group .input-group-btn .owl-theme .owl-nav [class*='owl-'],
.owl-theme .owl-nav .mail-box .inbox-search .input-group .input-group-btn [class*='owl-'],
.mail-box .inbox-search .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.mail-box .inbox-search .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left,
.mail-box .inbox-search .input-group .input-group-btn button.fc-agendaWeek-button,
.mail-box .inbox-search .input-group .input-group-btn .fc-prev-button,
.mail-box .inbox-search .input-group .input-group-btn .fc-next-button,
.mail-box .inbox-search .input-group .input-group-btn .fc-today-button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  padding: 0;
}

.user-head {
  position: relative;
}
.user-head .user-name h5 {
  margin-top: 15px;
}

ul.inbox-nav {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
}
ul.inbox-nav li {
  display: inline-block;
  width: 100%;
}
ul.inbox-nav li a {
  display: inline-block;
  padding: 10px 15px;
  width: 100%;
  text-transform: capitalize;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
}
ul.inbox-nav li a i {
  font-size: 16px;
  padding-right: 10px;
  color: #878787;
}

ul.inbox-nav li a:hover,
ul.inbox-nav li.active a,
ul.inbox-nav li a:focus {
  background: rgba(33, 33, 33, 0.05);
}

.inbox-pagination {
  float: right;
}
.inbox-pagination li {
  float: left;
}

.inbox-head {
  color: #fff;
  min-height: 80px;
}

.table-inbox tr td {
  padding: 7px 15px !important;
}
.table-inbox tr td:hover {
  cursor: pointer;
}

.table-inbox tr td .zmdi-star.inbox-started,
.table-inbox tr td .zmdi-star:hover {
  color: #f33923;
}

.table-inbox tr.unread td {
  color: #212121;
  font-weight: 500;
}
.table-inbox tr.unread td i {
  color: #878787;
}
.table-inbox.table-hover > tbody > tr {
  background: rgba(33, 33, 33, 0.05) !important;
}
.table-inbox.table-hover > tbody > tr td {
  color: #212121;
}
.table-inbox.table-hover > tbody > tr td i {
  color: #878787;
}
.table-inbox.table-hover > tbody > tr.unread {
  background: transparent !important;
}
.table-inbox.table-hover > tbody > tr.unread:hover {
  background-color: rgba(33, 33, 33, 0.05) !important;
}

.time-chat-history {
  min-width: 70px;
}

.mail-option {
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
}
.mail-option .chk-all {
  display: inline-block;
  padding: 0;
}
.mail-option .chk-all .btn,
.mail-option .chk-all .wizard > .actions a,
.wizard > .actions .mail-option .chk-all a,
.mail-option .chk-all .dt-buttons .dt-button,
.dt-buttons .mail-option .chk-all .dt-button,
.mail-option .chk-all .tablesaw-sortable th.tablesaw-sortable-head button,
.tablesaw-sortable th.tablesaw-sortable-head .mail-option .chk-all button,
.mail-option .chk-all .sweet-alert button,
.sweet-alert .mail-option .chk-all button,
.mail-option .chk-all .owl-theme .owl-nav [class*='owl-'],
.owl-theme .owl-nav .mail-option .chk-all [class*='owl-'],
.mail-option .chk-all button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.mail-option .chk-all button.fc-month-button.fc-state-default.fc-corner-left,
.mail-option .chk-all button.fc-agendaWeek-button,
.mail-option .chk-all .fc-prev-button,
.mail-option .chk-all .fc-next-button,
.mail-option .chk-all .fc-today-button,
.mail-option .chk-all .dropdown {
  display: inline-block;
  padding: 0 5px;
  box-shadow: none;
  margin-right: 5px;
  background: transparent;
  color: #212121;
}
.mail-option .chk-all .btn i,
.mail-option .chk-all .wizard > .actions a i,
.wizard > .actions .mail-option .chk-all a i,
.mail-option .chk-all .dt-buttons .dt-button i,
.dt-buttons .mail-option .chk-all .dt-button i,
.mail-option .chk-all .tablesaw-sortable th.tablesaw-sortable-head button i,
.tablesaw-sortable th.tablesaw-sortable-head .mail-option .chk-all button i,
.mail-option .chk-all .sweet-alert button i,
.sweet-alert .mail-option .chk-all button i,
.mail-option .chk-all .owl-theme .owl-nav [class*='owl-'] i,
.owl-theme .owl-nav .mail-option .chk-all [class*='owl-'] i,
.mail-option .chk-all button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.mail-option .chk-all button.fc-month-button.fc-state-default.fc-corner-left i,
.mail-option .chk-all button.fc-agendaWeek-button i,
.mail-option .chk-all .fc-prev-button i,
.mail-option .chk-all .fc-next-button i,
.mail-option .chk-all .fc-today-button i,
.mail-option .chk-all .dropdown i {
  color: #878787;
}
.mail-option .chk-all input[type='checkbox'] {
  margin-top: 0;
}
.mail-option .chk-all .checkbox {
  min-height: auto !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.mail-option .btn-group {
  margin-right: 5px;
}
.mail-option .btn-group a.all {
  border: medium none;
}

.heading-inbox .btn,
.heading-inbox .wizard > .actions a,
.wizard > .actions .heading-inbox a,
.heading-inbox .dt-buttons .dt-button,
.dt-buttons .heading-inbox .dt-button,
.heading-inbox .tablesaw-sortable th.tablesaw-sortable-head button,
.tablesaw-sortable th.tablesaw-sortable-head .heading-inbox button,
.heading-inbox .sweet-alert button,
.sweet-alert .heading-inbox button,
.heading-inbox .owl-theme .owl-nav [class*='owl-'],
.owl-theme .owl-nav .heading-inbox [class*='owl-'],
.heading-inbox button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.heading-inbox button.fc-month-button.fc-state-default.fc-corner-left,
.heading-inbox button.fc-agendaWeek-button,
.heading-inbox .fc-prev-button,
.heading-inbox .fc-next-button,
.heading-inbox .fc-today-button,
.heading-inbox .dropdown {
  display: inline-block;
  padding: 0 5px;
  box-shadow: none;
  background: transparent;
}
.heading-inbox .btn i,
.heading-inbox .wizard > .actions a i,
.wizard > .actions .heading-inbox a i,
.heading-inbox .dt-buttons .dt-button i,
.dt-buttons .heading-inbox .dt-button i,
.heading-inbox .tablesaw-sortable th.tablesaw-sortable-head button i,
.tablesaw-sortable th.tablesaw-sortable-head .heading-inbox button i,
.heading-inbox .sweet-alert button i,
.sweet-alert .heading-inbox button i,
.heading-inbox .owl-theme .owl-nav [class*='owl-'] i,
.owl-theme .owl-nav .heading-inbox [class*='owl-'] i,
.heading-inbox button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.heading-inbox button.fc-month-button.fc-state-default.fc-corner-left i,
.heading-inbox button.fc-agendaWeek-button i,
.heading-inbox .fc-prev-button i,
.heading-inbox .fc-next-button i,
.heading-inbox .fc-today-button i,
.heading-inbox .dropdown i {
  font-size: 17px;
  color: #878787;
}
.heading-inbox .btn i.zmdi-chevron-left,
.heading-inbox .wizard > .actions a i.zmdi-chevron-left,
.wizard > .actions .heading-inbox a i.zmdi-chevron-left,
.heading-inbox .dt-buttons .dt-button i.zmdi-chevron-left,
.dt-buttons .heading-inbox .dt-button i.zmdi-chevron-left,
.heading-inbox .tablesaw-sortable th.tablesaw-sortable-head button i.zmdi-chevron-left,
.tablesaw-sortable th.tablesaw-sortable-head .heading-inbox button i.zmdi-chevron-left,
.heading-inbox .sweet-alert button i.zmdi-chevron-left,
.sweet-alert .heading-inbox button i.zmdi-chevron-left,
.heading-inbox .owl-theme .owl-nav [class*='owl-'] i.zmdi-chevron-left,
.owl-theme .owl-nav .heading-inbox [class*='owl-'] i.zmdi-chevron-left,
.heading-inbox button.fc-agendaDay-button.fc-state-default.fc-corner-right i.zmdi-chevron-left,
.heading-inbox button.fc-month-button.fc-state-default.fc-corner-left i.zmdi-chevron-left,
.heading-inbox button.fc-agendaWeek-button i.zmdi-chevron-left,
.heading-inbox .fc-prev-button i.zmdi-chevron-left,
.heading-inbox .fc-next-button i.zmdi-chevron-left,
.heading-inbox .fc-today-button i.zmdi-chevron-left,
.heading-inbox .dropdown i.zmdi-chevron-left {
  font-size: 27px;
}

.file-name {
  color: #212121;
}

.date {
  font-size: 13px;
}

.download-blocks a {
  color: #878787;
}

.sender-img-wrap img {
  width: 40px;
  height: 40px;
}

.heading-inbox .compose-btn .btn,
.heading-inbox .compose-btn .wizard > .actions a,
.wizard > .actions .heading-inbox .compose-btn a,
.heading-inbox .compose-btn .dt-buttons .dt-button,
.dt-buttons .heading-inbox .compose-btn .dt-button,
.heading-inbox .compose-btn .tablesaw-sortable th.tablesaw-sortable-head button,
.tablesaw-sortable th.tablesaw-sortable-head .heading-inbox .compose-btn button,
.heading-inbox .compose-btn .sweet-alert button,
.sweet-alert .heading-inbox .compose-btn button,
.heading-inbox .compose-btn .owl-theme .owl-nav [class*='owl-'],
.owl-theme .owl-nav .heading-inbox .compose-btn [class*='owl-'],
.heading-inbox .compose-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.heading-inbox .compose-btn button.fc-month-button.fc-state-default.fc-corner-left,
.heading-inbox .compose-btn button.fc-agendaWeek-button,
.heading-inbox .compose-btn .fc-prev-button,
.heading-inbox .compose-btn .fc-next-button,
.heading-inbox .compose-btn .fc-today-button {
  box-shadow: none;
  color: #878787;
  display: inline-block;
  padding: 0 5px;
  background: transparent;
}

.sender-info .pull-right .dropdown-toggle > i {
  font-size: 17px;
  color: #878787;
  vertical-align: middle;
}

.attachment-mail li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.attachment-mail li .attached-img-container {
  width: 120px;
  height: 120px;
  display: block;
  background-position: center top;
  background-size: cover;
  margin-bottom: 10px;
  border-radius: 2px;
}
.attachment-mail li:last-child {
  margin-right: 0;
}

/*Contact List*/
.contact-list .panel {
  border: none;
  box-shadow: none;
  margin-bottom: 0;
  border-radius: 0;
  border-left: 1px solid #dedede;
}
.contact-list .table > tbody > tr > td,
.contact-list .jsgrid-table > tbody > tr > td {
  padding: 12px 15px;
}
.contact-list .table > tbody > tr > td a,
.contact-list .jsgrid-table > tbody > tr > td a {
  font-weight: 500;
}
.contact-list .create-label {
  display: block;
}

/*Contact Cards*/
.contact-card .card-user-img {
  height: 65px;
  width: 65px;
}
.contact-card .panel-heading .user-detail-wrap {
  margin-top: 8px;
}
.contact-card .panel-heading .user-detail-wrap .card-user-name {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: #fff;
}
.contact-card .panel-heading .user-detail-wrap .card-user-desn {
  color: #fff;
  text-transform: capitalize;
  font-size: 13px;
}
.contact-card .panel-heading .pull-right {
  margin-top: 22px;
}
.contact-card .card-user-email,
.contact-card .card-user-phone {
  color: #878787;
}

/*Login Form*/
.page-wrapper.auth-page {
  background: #fff;
}

.sp-header {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.box-layout .sp-header {
  max-width: 1400px;
  left: auto;
  right: auto;
}

.sp-logo-wrap {
  padding-top: 19px;
  padding-left: 15px;
}
.sp-logo-wrap {
  position: relative;
  top: 2px;
}
.sp-logo-wrap .brand-text {
  color: #212121;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-size: 21px;
  text-transform: capitalize;
}

.form-group.pull-right {
  padding-top: 19px;
  padding-right: 15px;
}

/* .auth-form-wrap {
  min-height: 100vh; } */

.auth-form {
  width: 400px;
}
.auth-form a.txt-danger:hover {
  color: #f33923;
}

/*Error Page*/
.error-page .brand-text {
  color: #212121;
}

.error-head {
  font-size: 112px;
  font-style: italic;
  line-height: 112px;
}

.error-bg-img {
  background: url('../images/errorbg.jpg');
  background-size: cover;
}

/*Products*/
.col-item {
  background: #fff;
}
.col-item .photo {
  overflow: hidden;
  position: relative;
}
.col-item .photo .options {
  position: absolute;
  right: 10px;
  top: 5px;
}
.col-item .photo img {
  margin: 0 auto;
  width: 85%;
}
.col-item .info {
  background: #f7f7f9 none repeat scroll 0 0;
  padding: 15px 0 15px 15px;
}

/*Cropperjs*/
.img-container,
.img-preview {
  background-color: #f7f7f7;
  width: 100%;
  text-align: center;
}

.img-container {
  min-height: 200px;
  max-height: 469px;
  margin-bottom: 16px;
}
.img-container > img {
  max-width: 100%;
}

.docs-preview {
  margin-right: -16px;
}

.img-preview {
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
  overflow: hidden;
}
.img-preview > img {
  max-width: 100%;
}

.preview-lg {
  width: 255px;
  height: 143px;
}

.preview-md {
  width: 128px;
  height: 72px;
}

.preview-sm {
  width: 72px;
  height: 41px;
}

.preview-xs {
  width: 40px;
  height: 24px;
  margin-right: 0;
}

.docs-data > .input-group {
  margin-bottom: 8px;
}
.docs-data > .input-group > label {
  min-width: 80px;
}
.docs-data > .input-group > span {
  min-width: 50px;
}

.docs-buttons > .btn,
.wizard > .actions .disabled .docs-buttons > a,
.wizard > .actions .docs-buttons > a,
.dt-buttons .docs-buttons > .dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .docs-buttons > button,
.sweet-alert .docs-buttons > button,
.owl-theme .owl-nav .docs-buttons > [class*='owl-'],
.docs-buttons > button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.docs-buttons > button.fc-month-button.fc-state-default.fc-corner-left,
.docs-buttons > button.fc-agendaWeek-button,
.docs-buttons > .fc-prev-button,
.docs-buttons > .fc-next-button,
.docs-buttons > .fc-today-button,
.docs-buttons > .btn-group,
.docs-buttons > .form-control,
.select2-container--default .select2-selection--single .docs-buttons > .select2-selection__rendered,
.select2-container--default .docs-buttons > .select2-selection--single,
.docs-buttons > .select2-selection.select2-selection--multiple,
.wizard .content > .body .docs-buttons > input,
.mce-floatpanel .docs-buttons > .mce-textbox,
.mce-floatpanel .docs-buttons > .mce-btn,
.dataTables_wrapper .dataTables_filter .docs-buttons > input,
.dataTables_wrapper .dataTables_length .docs-buttons > select,
.jsgrid-cell .docs-buttons > input,
.jsgrid-cell .docs-buttons > select,
.tablesaw-bar .btn-select .docs-buttons > select,
.docs-buttons > .dd-handle,
.docs-buttons > .dd3-content,
.app-search .docs-buttons > .form-control:focus,
.select2-container--default .select2-selection--single .app-search .docs-buttons > .select2-selection__rendered:focus,
.select2-container--default .app-search .docs-buttons > .select2-selection--single:focus,
.app-search .docs-buttons > .select2-selection.select2-selection--multiple:focus,
.wizard .content > .body .app-search .docs-buttons > input:focus,
.mce-floatpanel .app-search .docs-buttons > .mce-textbox:focus,
.mce-floatpanel .app-search .docs-buttons > .mce-btn:focus,
.dataTables_wrapper .dataTables_filter .app-search .docs-buttons > input:focus,
.dataTables_wrapper .dataTables_length .app-search .docs-buttons > select:focus,
.app-search .jsgrid-cell .docs-buttons > input:focus,
.jsgrid-cell .app-search .docs-buttons > input:focus,
.app-search .jsgrid-cell .docs-buttons > select:focus,
.jsgrid-cell .app-search .docs-buttons > select:focus,
.tablesaw-bar .btn-select .app-search .docs-buttons > select:focus,
.app-search .docs-buttons > .dd-handle:focus,
.app-search .docs-buttons > .dd3-content:focus {
  margin-right: 4px;
  margin-bottom: 8px;
}

.docs-toggles > .btn,
.wizard > .actions .disabled .docs-toggles > a,
.wizard > .actions .docs-toggles > a,
.dt-buttons .docs-toggles > .dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .docs-toggles > button,
.sweet-alert .docs-toggles > button,
.owl-theme .owl-nav .docs-toggles > [class*='owl-'],
.docs-toggles > button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.docs-toggles > button.fc-month-button.fc-state-default.fc-corner-left,
.docs-toggles > button.fc-agendaWeek-button,
.docs-toggles > .fc-prev-button,
.docs-toggles > .fc-next-button,
.docs-toggles > .fc-today-button,
.docs-toggles > .btn-group,
.docs-toggles > .dropdown {
  margin-bottom: 8px;
}

.docs-tooltip {
  display: block;
  margin: -8px -12px;
  padding: 8px 12px;
}

.docs-aspect-ratios,
.docs-view-modes {
  width: 100%;
}

.docs-aspect-ratios > .btn,
.wizard > .actions .disabled .docs-aspect-ratios > a,
.wizard > .actions .docs-aspect-ratios > a,
.dt-buttons .docs-aspect-ratios > .dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .docs-aspect-ratios > button,
.sweet-alert .docs-aspect-ratios > button,
.owl-theme .owl-nav .docs-aspect-ratios > [class*='owl-'],
.docs-aspect-ratios > button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.docs-aspect-ratios > button.fc-month-button.fc-state-default.fc-corner-left,
.docs-aspect-ratios > button.fc-agendaWeek-button,
.docs-aspect-ratios > .fc-prev-button,
.docs-aspect-ratios > .fc-next-button,
.docs-aspect-ratios > .fc-today-button {
  width: 20%;
}

.docs-view-modes > .btn,
.wizard > .actions .disabled .docs-view-modes > a,
.wizard > .actions .docs-view-modes > a,
.dt-buttons .docs-view-modes > .dt-button,
.tablesaw-sortable th.tablesaw-sortable-head .docs-view-modes > button,
.sweet-alert .docs-view-modes > button,
.owl-theme .owl-nav .docs-view-modes > [class*='owl-'],
.docs-view-modes > button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.docs-view-modes > button.fc-month-button.fc-state-default.fc-corner-left,
.docs-view-modes > button.fc-agendaWeek-button,
.docs-view-modes > .fc-prev-button,
.docs-view-modes > .fc-next-button,
.docs-view-modes > .fc-today-button {
  width: 25%;
}

.docs-options .dropdown-menu {
  width: 100%;
}
.docs-options .dropdown-menu > li {
  padding: 4px 16px;
}
.docs-options .dropdown-menu > li:hover {
  background-color: #f7f7f7;
}
.docs-options .dropdown-menu > li > label {
  margin-bottom: 0;
}

.docs-cropped .modal-body {
  text-align: center;
}
.docs-cropped .modal-body > img,
.docs-cropped .modal-body > canvas {
  max-width: 100%;
}

.common-credits > li {
  display: block;
  margin-bottom: 15px;
}
.common-credits > li:last-child {
  margin-bottom: 0;
}

/****************************/
/** Mediaqueries **/
/****************************/
@media (max-width: 1400px) {
  .fixed-sidebar-left {
    width: 44px;
  }
  .fixed-sidebar-left .side-nav li.navigation-header > span {
    display: none;
  }
  .fixed-sidebar-left .side-nav li.navigation-header > i {
    display: block;
  }
  .fixed-sidebar-left .collapse.in {
    display: none;
  }
  .fixed-sidebar-left:hover {
    width: 225px;
  }
  .fixed-sidebar-left:hover .side-nav li.navigation-header > span {
    display: block;
  }
  .fixed-sidebar-left:hover .side-nav li.navigation-header > i {
    display: none;
  }
  .fixed-sidebar-left:hover .collapse.in {
    display: block;
  }
  .fixed-sidebar-left:hover .right-nav-text {
    opacity: 1;
  }
  .fixed-sidebar-left .right-nav-text {
    opacity: 0;
  }

  .page-wrapper {
    margin-left: 44px;
  }

  .navbar.navbar-inverse.navbar-fixed-top .nav-header {
    width: 44px;
  }
  .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
    opacity: 0;
    visibility: hidden;
  }
  .navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.mega-menu {
    width: calc(100% - 100px);
  }

  .sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header {
    width: 225px;
  }
  .sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
    opacity: 1;
    visibility: visible;
  }

  .slide-nav-toggle .fixed-sidebar-left {
    width: 225px;
  }
  .slide-nav-toggle .fixed-sidebar-left .side-nav li.navigation-header > span {
    display: block;
  }
  .slide-nav-toggle .fixed-sidebar-left .side-nav li.navigation-header > i {
    display: none;
  }
  .slide-nav-toggle .fixed-sidebar-left .collapse.in {
    display: block;
  }
  .slide-nav-toggle .fixed-sidebar-left .right-nav-text {
    opacity: 1;
  }
  .slide-nav-toggle .page-wrapper {
    margin-left: 0;
    left: 225px;
  }
  .slide-nav-toggle .navbar.navbar-inverse.navbar-fixed-top .nav-header {
    width: 225px;
  }
  .slide-nav-toggle .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
    opacity: 1;
    visibility: visible;
  }
}
@media (max-width: 1200px) {
  .table-responsive .table > tbody > tr > td,
  .table-responsive .jsgrid-table > tbody > tr > td,
  .table-responsive .table > tbody > tr > th,
  .table-responsive .jsgrid-table > tbody > tr > th,
  .table-responsive .table > tfoot > tr > td,
  .table-responsive .jsgrid-table > tfoot > tr > td,
  .table-responsive .table > tfoot > tr > th,
  .table-responsive .jsgrid-table > tfoot > tr > th,
  .table-responsive .table > thead > tr > td,
  .table-responsive .jsgrid-table > thead > tr > td,
  .table-responsive .table > thead > tr > th,
  .table-responsive .jsgrid-table > thead > tr > th {
    white-space: nowrap;
  }
}
@media (min-width: 1025px) {
  .mobile-only-nav.collapse,
  .navbar.navbar-inverse.navbar-fixed-top .top-nav-search.collapse {
    display: block;
    height: auto !important;
  }

  .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn {
    pointer-events: none;
  }
}
@media (max-width: 1024px) {
  .mobile-only-view {
    display: block !important;
  }

  .fixed-sidebar-left {
    margin-left: -225px;
    width: 225px;
  }
  .fixed-sidebar-left .right-nav-text {
    opacity: 1;
  }

  .mobile-nav-open .fixed-sidebar-right,
  .mobile-nav-open .fixed-sidebar-left {
    top: 132px;
  }

  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .slide-nav-toggle .fixed-sidebar-left {
    margin-left: 0;
  }
  .slide-nav-toggle .page-wrapper {
    left: 225px;
    margin-left: 0;
  }
  .slide-nav-toggle.sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header {
    width: 100%;
  }

  .navbar.navbar-inverse.navbar-fixed-top {
    padding: 0;
  }
  .navbar.navbar-inverse.navbar-fixed-top .mobile-only-brand {
    width: 100%;
  }
  .navbar.navbar-inverse.navbar-fixed-top .mobile-only-brand .nav-header {
    padding-left: 0;
    text-align: center;
    width: 100%;
  }
  .navbar.navbar-inverse.navbar-fixed-top .mobile-only-brand .nav-header .logo-wrap {
    width: 100%;
  }
  .navbar.navbar-inverse.navbar-fixed-top .mobile-only-brand .nav-header .logo-wrap .brand-text {
    opacity: 1;
    visibility: visible;
  }
  .navbar.navbar-inverse.navbar-fixed-top a.toggle-left-nav-btn {
    left: 1px;
    position: absolute;
    top: 0;
  }
  .navbar.navbar-inverse.navbar-fixed-top a#toggle_mobile_nav {
    position: absolute;
    right: 21px;
    top: 0;
  }
  .navbar.navbar-inverse.navbar-fixed-top a#toggle_mobile_search {
    position: absolute;
    right: 50px;
    top: 0;
  }
  .navbar.navbar-inverse.navbar-fixed-top .mobile-only-brand {
    background: #f2f2f2;
    position: relative;
    z-index: 2;
  }
  .navbar.navbar-inverse.navbar-fixed-top .mobile-only-nav {
    float: left;
    width: 100%;
    -webkit-transition: 0.4s ease;
    -moz-transition: 0.4s ease;
    transition: 0.4s ease;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
    background: #fff;
    padding: 0 15px;
    border-bottom: 1px solid #dedede;
  }
  .navbar.navbar-inverse.navbar-fixed-top .top-nav-search {
    left: 0;
    margin-left: 0;
    position: absolute;
    width: 100%;
    padding-top: 0;
  }
  .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group input {
    border-radius: 0;
    height: 66px;
    border: medium none;
  }
  .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn {
    top: 23px;
  }
  .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn button.btn-default i:before,
  .navbar.navbar-inverse.navbar-fixed-top
    .top-nav-search
    .input-group
    .input-group-btn
    .tablesaw-sortable
    th.tablesaw-sortable-head
    button
    i:before,
  .tablesaw-sortable
    th.tablesaw-sortable-head
    .navbar.navbar-inverse.navbar-fixed-top
    .top-nav-search
    .input-group
    .input-group-btn
    button
    i:before,
  .navbar.navbar-inverse.navbar-fixed-top
    .top-nav-search
    .input-group
    .input-group-btn
    .owl-theme
    .owl-nav
    button[class*='owl-']
    i:before,
  .owl-theme
    .owl-nav
    .navbar.navbar-inverse.navbar-fixed-top
    .top-nav-search
    .input-group
    .input-group-btn
    button[class*='owl-']
    i:before {
    content: '\f136';
  }
  .navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.mega-menu {
    right: 0;
    width: 100%;
  }

  .mobile-nav-open .navbar.navbar-inverse.navbar-fixed-top .mobile-only-nav {
    top: 66px;
  }

  .navbar.navbar-inverse.navbar-fixed-top .mobile-only-nav {
    background: #f5f5f5;
  }

  .inbox-pagination li:first-child {
    display: none;
  }

  .sender-info .inbox-detail-time-2 {
    display: none;
  }

  /*Chat App*/
  .chat-cmplt-wrap.chat-for-widgets-1 .chat-box-wrap {
    float: none;
    width: auto;
  }
  .chat-cmplt-wrap.chat-for-widgets-1 .recent-chat-box-wrap {
    border-left: medium none;
    float: none;
    position: absolute;
    right: -100%;
    width: 100%;
  }
  .chat-cmplt-wrap.chat-for-widgets-1 .goto-back i.zmdi-account-add:before {
    content: '\f2fa';
  }

  /*Responsive Tabs*/
  .nav.nav-tabs.nav-tabs-responsive {
    text-align: center;
    position: relative;
  }
  .nav.nav-tabs.nav-tabs-responsive > li {
    display: none;
    float: none;
  }
  .nav.nav-tabs.nav-tabs-responsive > li.active,
  .nav.nav-tabs.nav-tabs-responsive > li.prev,
  .nav.nav-tabs.nav-tabs-responsive > li.next {
    display: inline-block;
  }
  .nav.nav-tabs.nav-tabs-responsive > li.prev,
  .nav.nav-tabs.nav-tabs-responsive > li.next {
    position: absolute;
    top: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .nav.nav-tabs.nav-tabs-responsive > li.prev > a:before,
  .nav.nav-tabs.nav-tabs-responsive > li.prev > a:after,
  .nav.nav-tabs.nav-tabs-responsive > li.next > a:before,
  .nav.nav-tabs.nav-tabs-responsive > li.next > a:after {
    display: inline-block;
    font-family: 'Material-Design-Iconic-Font';
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-size: inherit;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    position: relative;
    top: 3px;
    font-size: 20px;
    line-height: 12px;
  }
  .nav.nav-tabs.nav-tabs-responsive > li.prev {
    left: 0;
  }
  .nav.nav-tabs.nav-tabs-responsive > li.prev > a:before {
    content: '\f2fa';
    margin-right: 10px;
  }
  .nav.nav-tabs.nav-tabs-responsive > li.next {
    right: 0;
  }
  .nav.nav-tabs.nav-tabs-responsive > li.next > a:after {
    content: '\f2fb';
    margin-left: 10px;
  }

  .fc-toolbar > div {
    display: block;
    width: 100%;
    float: left !important;
    margin-bottom: 25px;
  }

  .fc-toolbar {
    overflow: hidden;
  }
}
@media (max-width: 991px) {
  .dataTables_wrapper .dataTables_length {
    display: none;
  }

  .product-detail-wrap,
  .inbox-head {
    margin-top: 30px;
  }

  .mail-box aside.col-lg-3.col-md-4 {
    padding-right: 15px !important;
  }
  .mail-box aside.col-lg-3.col-md-4 h6,
  .mail-box aside.col-lg-3.col-md-4 .panel-title,
  .mail-box aside.col-lg-3.col-md-4 .inbox-nav,
  .mail-box aside.col-lg-3.col-md-4 .chat-list-wrap {
    display: none;
  }
  .mail-box aside.col-lg-9.col-md-8 {
    padding-left: 15px !important;
  }
  .mail-box aside.col-lg-9.col-md-8 .view-message .label,
  .mail-box aside.col-lg-9.col-md-8 .view-message .jvectormap-tip {
    display: none;
  }

  .contact-list aside.col-lg-2.col-md-4 {
    padding-right: 15px !important;
  }
  .contact-list aside.col-lg-2.col-md-4 .inbox-nav,
  .contact-list aside.col-lg-2.col-md-4 a.create-label {
    display: none;
  }
  .contact-list aside.col-lg-10.col-md-8 {
    padding-left: 15px !important;
  }

  .file-manager h6,
  .file-manager .panel-title,
  .file-manager .tag-list {
    display: none;
  }

  .ibox {
    margin-bottom: 0;
  }
}
@media (max-width: 950px) {
  .panel-dropdown .panel-heading > .pull-left,
  .panel-dropdown .panel-heading > .pull-right,
  .panel-tabs .panel-heading > .pull-left,
  .panel-tabs .panel-heading > .pull-right {
    width: 100%;
  }
  .panel-dropdown .panel-heading > .pull-right,
  .panel-tabs .panel-heading > .pull-right {
    margin-top: 10px;
  }

  .panel-dropdown .panel-heading > .pull-right .dropdown-menu-right {
    left: 0;
    right: auto;
  }
}
@media (max-width: 828px) {
  .wizard .steps ul > li {
    width: 100%;
  }
}
@media (max-width: 796px) {
  footer.footer .col-sm-5 {
    display: none;
  }
  footer.footer .col-sm-7 {
    width: 100%;
    text-align: center !important;
  }
}
@media (max-width: 767px) {
  .breadcrumb,
  .heading-bg .btn-outline-white.btn-rounded,
  .heading-bg button.btn-outline-white.fc-agendaDay-button.fc-state-default.fc-corner-right,
  .heading-bg button.btn-outline-white.fc-month-button.fc-state-default.fc-corner-left,
  .heading-bg button.btn-outline-white.fc-agendaWeek-button {
    display: none;
  }

  ul.timeline:before {
    left: 23px;
  }
  ul.timeline > li {
    margin-bottom: 0px;
    position: relative;
    width: 100%;
    float: left;
    clear: left;
  }
  ul.timeline > li > .timeline-panel {
    width: calc(100% - 65px);
    width: -moz-calc(100% - 65px);
    width: -webkit-calc(100% - 65px);
  }
  ul.timeline > li > .timeline-badge {
    left: 0px;
    margin-left: 0;
    top: 16px;
  }
  ul.timeline > li > .timeline-panel {
    float: right;
  }
  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 10px;
    left: -10px;
    right: auto;
  }
  ul.timeline > li > .timeline-panel.bg-yellow:after {
    border-right-color: #f8b32d;
  }
  ul.timeline > li > .timeline-panel.bg-pink:after {
    border-right-color: #e7368d;
  }
  ul.timeline > li > .timeline-panel.bg-red:after {
    border-right-color: #f33923;
  }
  ul.timeline > li > .timeline-panel.bg-blue:after {
    border-right-color: #2196f3;
  }
  ul.timeline > li > .timeline-panel.bg-green:after {
    border-right-color: #8bc34a;
  }

  .timeline > li.timeline-inverted {
    float: left;
    clear: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-badge {
    left: 0;
  }
}
@media (max-width: 600px) {
  .product-detail-wrap {
    margin-top: 30px;
  }
  .product-detail-wrap .btn-group {
    margin-top: 20px;
    width: 100%;
  }

  .product-detail-description ul li {
    width: 100%;
  }
  .product-detail-description ul li a {
    padding-left: 0 !important;
  }

  .clear-todo-btn {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .img-container {
    min-height: 469px;
  }
}
@media (max-width: 768px) {
  .col-item .photo img {
    width: 100%;
  }
}
@media (max-width: 565px) {
  .clear-todo-btn {
    margin-top: 20px;
  }

  .nav-tabs > li,
  .nav-tab-reverse .nav-tabs > li {
    float: none;
    margin-right: 0;
    margin-left: 0;
  }
  .nav-tabs > li:first-child > a,
  .nav-tab-reverse .nav-tabs > li:first-child > a {
    border-radius: 0;
    border-top-left-radius: 10px;
  }
  .nav-tabs > li:last-child,
  .nav-tab-reverse .nav-tabs > li:last-child {
    margin-bottom: -1px;
  }
  .nav-tabs > li:last-child > a,
  .nav-tab-reverse .nav-tabs > li:last-child > a {
    border-radius: 0;
    border-bottom-left-radius: 10px;
  }

  .custom-tab-1 .nav-tabs > li:first-child > a,
  .nav-tab-reverse .nav-tabs > li:first-child > a {
    border-top-left-radius: 0;
  }
  .custom-tab-1 .nav-tabs > li:last-child > a,
  .nav-tab-reverse .nav-tabs > li:last-child > a {
    border-bottom-left-radius: 0;
  }

  .nav.nav-tabs.nav-tabs-responsive > li.next a span,
  .nav.nav-tabs.nav-tabs-responsive > li.prev a span {
    display: none;
  }

  .nav-pills > li {
    float: none;
    margin-bottom: 5px;
    margin-left: 0 !important;
  }
  .nav-pills > li:last-child {
    margin-bottom: 0;
  }

  .fixed-sidebar-right .right-sidebar .nav-tabs > li,
  .fixed-sidebar-right .right-sidebar .nav-tab-reverse .nav-tabs > li {
    float: left;
  }

  .navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.mega-menu li.col-menu-list {
    width: 100%;
  }
  .navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.alert-dropdown {
    width: 100%;
    min-width: auto;
  }
  .navbar.navbar-inverse.navbar-fixed-top .nav > li.alert-drp {
    position: static;
  }

  .sp-header .form-group.pull-right span {
    display: none !important;
  }
}
@media (max-width: 458px) {
  .auth-form {
    width: 100%;
  }
}
@media (max-width: 414px) {
  .navbar.navbar-inverse.navbar-fixed-top .nav > li .dropdown-menu.app-dropdown {
    width: 100%;
    min-width: auto;
  }
  .navbar.navbar-inverse.navbar-fixed-top .nav > li.app-drp {
    position: static;
  }
}
@media (max-width: 400px) {
  .btn-group-crop {
    margin-right: -16px !important;
  }
  .btn-group-crop > .btn,
  .wizard > .actions .disabled .btn-group-crop > a,
  .wizard > .actions .btn-group-crop > a,
  .dt-buttons .btn-group-crop > .dt-button,
  .tablesaw-sortable th.tablesaw-sortable-head .btn-group-crop > button,
  .sweet-alert .btn-group-crop > button,
  .owl-theme .owl-nav .btn-group-crop > [class*='owl-'],
  .btn-group-crop > button.fc-agendaDay-button.fc-state-default.fc-corner-right,
  .btn-group-crop > button.fc-month-button.fc-state-default.fc-corner-left,
  .btn-group-crop > button.fc-agendaWeek-button,
  .btn-group-crop > .fc-prev-button,
  .btn-group-crop > .fc-next-button,
  .btn-group-crop > .fc-today-button {
    padding-left: 8px;
    padding-right: 8px;
  }

  .typeahead,
  .tt-query,
  .tt-hint {
    width: 100%;
  }
}
@media (max-width: 378px) {
  .form-actions button.btn.btn-success,
  .form-actions .dt-buttons button.btn-success.dt-button,
  .dt-buttons .form-actions button.btn-success.dt-button,
  .form-actions .dt-buttons button.dt-button.fc-agendaWeek-button,
  .dt-buttons .form-actions button.dt-button.fc-agendaWeek-button,
  .form-actions .dt-buttons button.dt-button.fc-prev-button,
  .dt-buttons .form-actions button.dt-button.fc-prev-button,
  .form-actions .dt-buttons button.dt-button.fc-next-button,
  .dt-buttons .form-actions button.dt-button.fc-next-button,
  .form-actions .dt-buttons button.dt-button.fc-today-button,
  .dt-buttons .form-actions button.dt-button.fc-today-button,
  .form-actions .tablesaw-sortable th.tablesaw-sortable-head button.btn-success,
  .tablesaw-sortable th.tablesaw-sortable-head .form-actions button.btn-success,
  .form-actions .tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button,
  .tablesaw-sortable th.tablesaw-sortable-head .form-actions button.fc-agendaWeek-button,
  .form-actions .tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button,
  .tablesaw-sortable th.tablesaw-sortable-head .form-actions button.fc-prev-button,
  .form-actions .tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button,
  .tablesaw-sortable th.tablesaw-sortable-head .form-actions button.fc-next-button,
  .form-actions .tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button,
  .tablesaw-sortable th.tablesaw-sortable-head .form-actions button.fc-today-button,
  .form-actions .sweet-alert button.btn-success,
  .sweet-alert .form-actions button.btn-success,
  .form-actions .sweet-alert button.fc-agendaWeek-button,
  .sweet-alert .form-actions button.fc-agendaWeek-button,
  .form-actions .sweet-alert button.fc-prev-button,
  .sweet-alert .form-actions button.fc-prev-button,
  .form-actions .sweet-alert button.fc-next-button,
  .sweet-alert .form-actions button.fc-next-button,
  .form-actions .sweet-alert button.fc-today-button,
  .sweet-alert .form-actions button.fc-today-button,
  .form-actions .owl-theme .owl-nav button.btn-success[class*='owl-'],
  .owl-theme .owl-nav .form-actions button.btn-success[class*='owl-'],
  .form-actions .owl-theme .owl-nav button[class*='owl-'].fc-agendaWeek-button,
  .owl-theme .owl-nav .form-actions button[class*='owl-'].fc-agendaWeek-button,
  .form-actions .owl-theme .owl-nav button[class*='owl-'].fc-prev-button,
  .owl-theme .owl-nav .form-actions button[class*='owl-'].fc-prev-button,
  .form-actions .owl-theme .owl-nav button[class*='owl-'].fc-next-button,
  .owl-theme .owl-nav .form-actions button[class*='owl-'].fc-next-button,
  .form-actions .owl-theme .owl-nav button[class*='owl-'].fc-today-button,
  .owl-theme .owl-nav .form-actions button[class*='owl-'].fc-today-button,
  .form-actions button.fc-agendaDay-button.fc-state-default.fc-corner-right,
  .form-actions button.fc-month-button.fc-state-default.fc-corner-left,
  .form-actions button.fc-agendaWeek-button,
  .form-actions button.fc-prev-button,
  .form-actions button.fc-next-button,
  .form-actions button.fc-today-button {
    margin-bottom: 20px;
  }
}
.navbar-fixed-bottom,
.navbar-fixed-top {
  z-index: 1000 !important;
}
/*# sourceMappingURL=style.css.map */

.tasks_table tr:nth-child(2n) {
  background-color: #f3f6ff;
}
.task_stat_mobile {
  display: none;
}
.task_product__cycles {
  background-color: #efefef;
  padding: 1rem;
  border-radius: 10px;
}
.task_product__cycles > div:not(:last-child) {
  border-bottom: 2px solid #ccc;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.task_product__cycles .ant-form-item {
  margin-bottom: 0;
}
.add_product_popup .ant-drawer-content-wrapper {
  min-width: 400px !important;
}

@media (max-width: 1500px) {
  .task_modal__select {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .task_modal__select .ant-form-item {
    max-width: calc(33% - 2rem);
    margin: 0 1rem 0.5rem;
    height: auto;
  }
  .task_modal__select .row_task_items {
    width: calc(33% - 2rem);
    margin: 0 1rem 0.5rem;
    height: auto;
    flex-direction: column;
  }
  .task_modal__select .row_task_items .ant-form-item {
    max-width: unset;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .task_modal__select .row_task_items .ant-form-item .ant-form-item-label {
    margin-right: 1rem;
  }
  .task_form {
    flex-wrap: wrap;
  }
  .task_product__select {
    width: 100%;
    margin-top: 2rem;
  }
}
@media (max-width: 768px) {
  .header-admin_items {
    flex-wrap: wrap;
  }
  .header-admin_items .mobile-only-brand {
    max-width: 115px;
  }
  .navbar.navbar-inverse.navbar-fixed-top .mobile-only-nav {
    display: none;
  }
  .add_product_popup .ant-drawer-content-wrapper {
    min-width: 90% !important;
  }
  .task_modal__select .ant-form-item {
    max-width: unset;
    margin: 0 0 0.5rem;
  }
  .task_modal__select .row_task_items {
    width: 100%;
    margin: 0 0 0.5rem;
    flex-direction: row;
  }
  .task_modal__select .row_task_items .ant-form-item {
    max-width: unset;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .task_cycle-form {
    gap: 0 !important;
  }

  .task_product__select .customer-list_select-item button {
    font-size: 12px;
    max-width: 250px;
  }
  .add-new-processor_buttons {
    position: static;
  }

  table td,
  table th {
    padding: 0.75rem !important;
  }
  .tasks_table tr {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    border-top: 1px solid #eee;
  }
  .tasks_table td,
  .tasks_table th {
    padding: 0.75rem !important;
  }
  .tasks_table td:first-child,
  .tasks_table th:first-child {
    width: 55px;
  }
  .tasks_table td:nth-child(2) {
    width: calc(100% - 145px);
  }
  .tasks_table th:nth-child(2) {
    width: calc(100% - 135px);
  }
  .tasks_table td:nth-child(6),
  .tasks_table th:nth-child(6) {
    width: 90px;
  }
  .tasks_table td:nth-child(7),
  .tasks_table th:nth-child(7) {
    width: 110px;
  }
  .tasks_table td:nth-child(9),
  .tasks_table th:nth-child(9) {
    width: calc(100% - 110px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tasks_table td:nth-child(3),
  .tasks_table td:nth-child(4),
  .tasks_table td:nth-child(5),
  .tasks_table td:nth-child(8) {
    display: none;
  }
  .tasks_table th:nth-child(3),
  .tasks_table th:nth-child(4),
  .tasks_table th:nth-child(5),
  .tasks_table th:nth-child(8) {
    display: none;
  }
  .task_filter_item {
    width: 100%;
  }

  .customer_modal {
    width: 95% !important;
  }
  .task_stat {
    display: none;
  }
  .task_stat_mobile {
    display: block;
  }

  .task_stat_customers tr {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    border-top: 1px solid #eee;
  }
  .task_stat_customers td,
  .task_stat_customers th {
    padding: 0.75rem !important;
  }
  .task_stat_customers td:first-child,
  .task_stat_customers th:first-child {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .task_stat_customers td:nth-child(3),
  .task_stat_customers th:nth-child(3) {
    width: calc(100% - 100px);
  }
  .task_stat_customers td:nth-child(2),
  .task_stat_customers th:nth-child(2) {
    width: 100%;
    order: 3;
  }
  .task_stat_customers td:nth-child(2) {
    padding: 0 !important;
  }
  .task_stat_customers .ant-collapse-content-box {
    padding: 0 !important;
  }
  .task_stat_customers .ant-collapse-content-box th,
  .task_stat_customers .ant-collapse-content-box td {
    width: auto;
  }
  .task_stat_customers .ant-collapse-content-box th,
  .task_stat_customers .ant-collapse-content-box td {
    font-size: 10px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .task_stat_customers .ant-collapse-content-box table {
    display: flex;
    justify-content: flex-start;
  }
  .task_stat_customers .ant-collapse-content-box tbody {
    display: flex;
  }
  .task_stat_customers .ant-collapse-content-box tr {
    flex-direction: column;
  }
  .slide-nav-toggle .fixed-sidebar-left {
    left: 0;
  }
  .fixed-sidebar-left {
    position: absolute;
    left: -225px;
    width: 225px;
  }
  .page-wrapper {
    left: 0 !important;
  }
  .navbar.navbar-inverse.navbar-fixed-top a.toggle-left-nav-btn {
    margin-left: 15px !important;
  }
}

.spareGroupsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spareGroupsRow .ant-form-item:first-child {
  flex-grow: 2;
}
.spareGroupsRow .ant-form-item {
  margin-bottom: 5px;
}
.ant-modal-footer{
  padding: 10px 16px 30px!important;
}

.rebatesCampaign_popup .ant-form-item{
  margin-bottom: 10px;
}